import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { PoeQuestionData } from './poe-question-list.types';

// deprecated service ref.: getPoeQuestionListService_DEPRECATED

// implementado ✅
export async function listPoeQuestionService() {
  const { response, data } = await BACKEND.get<PoeQuestionData>(ENDPOINT.POE);

  return {
    response,
    summary: data,
  };
}
