import CountrysidePin from '@assets/svg/domain/map/countryside-pin.svg';
import { convertSvgToDataImage } from './map-root.utils';

export function initConstants(google: typeof window.google) {
  const silobagPinSettings = {
    labelOrigin: new google.maps.Point(23, 46),
    scaledSize: new google.maps.Size(80, 80), // 80 - 80
  };

  const PIN = {
    cluster: {
      url: CountrysidePin,
      labelOrigin: new google.maps.Point(31, 31),
      scaledSize: new google.maps.Size(63, 80),
    },
    countryside: {
      url: CountrysidePin,
      labelOrigin: new google.maps.Point(28, 27),
      scaledSize: new google.maps.Size(57, 70), // 46 - 60
    },
    silobag: (specie: string, color: string) => ({
      url: convertSvgToDataImage(specie, color),
      ...silobagPinSettings,
    }),
  };

  const LABEL_TEXT_SIZE_BY_LENGTH: { [key: number]: string } = {
    1: '22px',
    2: '20px',
    3: '17px',
    4: '13px',
    5: '10px',
    6: '8px',
  };

  const LABEL = {
    cluster: (text: string) => ({
      text,
      color: '#33333',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '600',
      fontSize: LABEL_TEXT_SIZE_BY_LENGTH[text.length] ?? '6px',
    }),
    countryside: (text: string) => ({
      text,
      color: 'white',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: '600',
      fontSize: LABEL_TEXT_SIZE_BY_LENGTH[text.length] ?? '6px',
    }),
    /* // Silobag label is not required at the moment.
      silobag: (text: string) => ({
        text,
        color: 'black',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: '600',
        fontSize: '10px',
      }),
      */
  };

  const CENTER = {
    lat: -32,
    lng: -64,
  };

  const ZOOM = 4;

  return {
    PIN,
    LABEL,
    CENTER,
    ZOOM,
  };
}
