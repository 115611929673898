import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { NotifListPayload, UpdatedNotifListResponse } from './notification-setting-edition.types';

// deprecated service ref.: updateNotificationRecipientsService_DEPRECATED
export async function updateNotificationSettingsService({ body }: NotifListPayload) {
  const { response, data } = await BACKEND.put<UpdatedNotifListResponse>(ENDPOINT.NOTIFICATION_RECIPIENTS, {
    body,
  });

  return {
    response,
    summary: data,
  };
}
