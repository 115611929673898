import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ExtractionListData } from './extraction-list.types';
import { useGlobalStore } from '@global-store/use-store';
import { transformIntoQueryParamString } from '@utils/service';

// implementado ✅
// deprecated service ref.: getSilobagExtraction_DEPRECATED
export async function extractionListService() {
  const { organizationId } = useGlobalStore.getState();
  const query = transformIntoQueryParamString({ organization_id: organizationId });
  const { response, data } = await BACKEND.get<ExtractionListData>(ENDPOINT.SILOBAG_EXTRACTION, { query });

  return {
    response,
    summary: data,
  };
}
