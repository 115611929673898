// TODO: usar estos casos para probar el parseo de query params cuando tengamos testing en el proyecto
// const query = {
//   bar: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, // puede ser cambiado de lugar para probar que el query param tiene '/' al principio
//   num: 1,
//   bigInt: 1n,
//   boolean: true,
//   orderBy: [1,2,3,4,5],
//   longNumber: 1_000_000,
//   foo: (a) => { return a+1;},
//   'kebab-case': 'supported',
//   'snake_case': 'supported',
// }
// const query = 1;
// const query = 1n;
// const query = true;
// const query = false;
// const query = 1_000_000;
// const query = [];
// const query = [1,2,3];
// const query = {};
// const query = 'HR144FC';
// const query = undefined;
// const query = null;
// const query = NaN;
// const query = {a:1,b:2,c:3,d:[],e:()=>{},f:NaN,g:null,h:undefined,i:1n,j:true,k:false};
// const query = {a:1,b:2,c:3,d:[],e:()=>{},f:NaN,g:null,h:undefined,i:1n,j:true,k:false,l:0,m:1_000_000,n:'HR144FC',o:'snake_case',p:'kebab-case', q: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, r: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, s: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, t: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, u: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, v: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, w: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, x: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, y: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}, z: {a:1,b:2,c:3, z: {h:[9,99,999], k:100}}};
// transformIntoQueryParamString(14, 144, 'Hola', {x:1,y:2,z:3});

export function transformIntoQueryParamString(...args: unknown[]) {
  const isValidQueryOrderPath = args.slice(0, -1).reduce((rec, query) => rec && !isPureObject(query), true);

  if (!isValidQueryOrderPath) {
    console.warn(
      '[SiloReal][QUERY-TRANSFORM] The query order path is invalid. Only the last query parameter can be an object.'
    );
    return '';
  }

  return args.map((query) => internalQueryTransformToString(query)).join('');
}

type HasLengthOrNullable = unknown[] | string | undefined;
function internalQueryTransformToString(query: unknown) {
  const valueMustBeOmitted = (value: unknown) => {
    const isEmpty = (value as HasLengthOrNullable)?.length === 0;
    const isNullableValue = value == null;
    const isFunctionValue = typeof value === 'function';
    const isNaN = Number.isNaN(value);

    return isEmpty || isNullableValue || isFunctionValue || isNaN;
  };

  const valueWarningTextID = (value: unknown) => {
    if ((value as HasLengthOrNullable)?.length === 0) return 'An empty object, string or array';
    if (value == null) return 'A nullable';
    if (typeof value === 'function') return 'A function';
    if (Number.isNaN(value)) return 'A NaN';
    return '';
  };

  const logWarnMsg = (value: unknown, key: string = '') => {
    const messageWithProperty = key ? ` on property '${key}'` : '';
    console.warn(
      `[SiloReal][QUERY-TRANSFORM] ${valueWarningTextID(
        value
      )} value has been detected (and omitted) as a query parameter${messageWithProperty}.`
    );
  };

  const recursiveParse = (entries: [string, unknown][], baseCase: string): string => {
    return entries.reduce((rec, [key, value]) => {
      if (valueMustBeOmitted(value)) {
        logWarnMsg(value, key);
        return rec;
      }

      if (isPureObject(value)) {
        const newEntries = Object.entries(value);
        if (!newEntries.length) {
          logWarnMsg(newEntries, key);
          return rec;
        }
        return recursiveParse(Object.entries(value), rec);
      }

      if (rec === '') return `?${key}=${value}`;
      return `${rec}&${key}=${value}`;
    }, baseCase);
  };

  if (isPureObject(query)) {
    const newEntries = Object.entries(query);
    if (!newEntries.length) {
      logWarnMsg(newEntries);
      return '';
    }
    return recursiveParse(Object.entries(query), '');
  }

  const isInvalidQuery = valueMustBeOmitted(query);

  if (isInvalidQuery) logWarnMsg(query);
  return !isInvalidQuery ? `/${query}` : '';
}

function isPureObject(value: unknown): value is object {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}
