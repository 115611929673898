import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { AlertListResponse } from './global-alert-list.types';

// deprecated service ref.: AlertsService_DEPRECATED

// ✅ implementado
export async function listGlobalAlertService() {
  const { response, data } = await BACKEND.get<AlertListResponse>(ENDPOINT.ALERTS);

  return {
    response,
    summary: data,
  };
}
