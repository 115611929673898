import { useContext } from 'react';
import { MapContext } from './map.context';

export function useMap() {
  const mapContext = useContext(MapContext);

  if (!mapContext) throw new Error('MapContext has to be used within <MapContext.Provider>');

  return {
    ...mapContext,
  };
}
