import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { DashboardInfoData } from './dashboard-info.types';
import { useGlobalStore } from '@global-store/use-store';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: getDashboardInformation_DEPRECATED

// implementado ✅
export async function dashboardInfoService() {
  const { organizationId } = useGlobalStore.getState();
  const query = transformIntoQueryParamString({ organization_id: organizationId });

  const { response, data } = await BACKEND.get<DashboardInfoData>(ENDPOINT.DASHBOARD_INFO, { query });

  return {
    response,
    summary: data,
  };
}
