import { Box, Drawer } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = {
  open: boolean;
  close: () => void;
};

export function SidePanel({ children, open, close }: PropsWithChildren<Props>) {
  return (
    <Drawer anchor="right" open={open} transitionDuration={150} onClose={close}>
      <Box
        p={1}
        role="presentation"
        sx={{ width: '85vw', maxWidth: 400, overflow: 'auto' }}
        style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
      >
        {children}
      </Box>
    </Drawer>
  );
}
