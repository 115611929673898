import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CompanyDeletionPayload } from './company-deletion.types';

// deprecated service ref.: deleteCompanyService_DEPRECATED

// ✅ Implementado
export async function deleteCompanyService({ query: rawQuery }: CompanyDeletionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.delete(ENDPOINT.COMPANIES, { query });

  return {
    response,
    summary: data,
  };
}
