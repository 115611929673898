import { useGlobalStore } from '@global-store/use-store';

import { BackendAbstraction, FetchBackendParams, FetchBackendResponse } from './fetch-backend.types';

async function fetchBackend<TData = unknown>({
  method,
  endpoint,
  body,
  query,
  type = 'json',
}: FetchBackendParams): FetchBackendResponse<TData> {
  const { accessToken } = useGlobalStore.getState();
  const isAuthenticated = Boolean(accessToken);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (isAuthenticated) headers.append('Authorization', `Bearer ${accessToken}`);

  const response = await fetch(import.meta.env.VITE_IOF_API + endpoint + (query ?? ''), {
    mode: 'cors',
    method,
    headers,
    body: JSON.stringify(body),
  });

  const data = await response[type]();

  /* eslint-disable no-console */
  console.groupCollapsed('[fetchBackend]', method, endpoint);
  console.log('[fetchBackend] [PARAMS] ', query);
  console.log('[fetchBackend] [BODY] ', body);
  console.log('------------------------------------');
  console.log('[fetchBackend] [RESPONSE]', response);
  console.log('[fetchBackend] [DATA]', data);
  console.groupEnd();

  if (response.ok) return { response, data };

  return Promise.reject({
    response,
    data,
  });
}

async function fetchBackendRaw<TData = unknown>({
  method,
  endpoint,
  body,
  query, // type = 'json',
}: FetchBackendParams): FetchBackendResponse<TData> {
  const { accessToken } = useGlobalStore.getState();
  const isAuthenticated = Boolean(accessToken);

  const headers = new Headers();
  if (isAuthenticated) headers.append('Authorization', `Bearer ${accessToken}`);

  const response = await fetch(import.meta.env.VITE_IOF_API + endpoint + (query ?? ''), {
    mode: 'cors',
    method,
    headers,
    body: (body as RequestInit['body']) ?? undefined,
  });

  const TYPE = {
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  };

  const responseType = response.headers.get('content-type') ?? '';

  let type2: 'json' | 'blob' = 'json';
  if (responseType.includes(TYPE.excel)) type2 = 'blob';

  // console.log('response', response.headers.get('content-type'));

  const data = await response[type2]();

  /* eslint-disable no-console */
  console.groupCollapsed('[fetchBackend]', method, endpoint);
  console.log('[fetchBackend] [PARAMS] ', query);
  console.log('[fetchBackend] [BODY] ', body);
  console.log('------------------------------------');
  console.log('[fetchBackend] [RESPONSE]', response);
  console.log('[fetchBackend] [DATA]', data);
  console.groupEnd();

  if (response.ok) return { response, data };

  return Promise.reject({
    response,
    data,
  });
}

export const BACKEND: BackendAbstraction = {
  get: async (endpoint, config) => await fetchBackend({ method: 'GET', endpoint, ...config }),
  post: async (endpoint, config) => await fetchBackend({ method: 'POST', endpoint, ...config }),
  put: async (endpoint, config) => await fetchBackend({ method: 'PUT', endpoint, ...config }),
  patch: async (endpoint, config) => await fetchBackend({ method: 'PATCH', endpoint, ...config }),
  delete: async (endpoint, config) => await fetchBackend({ method: 'DELETE', endpoint, ...config }),
  raw: {
    get: async (endpoint, config) => await fetchBackendRaw({ method: 'GET', endpoint, ...config }),
    post: async (endpoint, config) => await fetchBackendRaw({ method: 'POST', endpoint, ...config }),
    put: async (endpoint, config) => await fetchBackendRaw({ method: 'PUT', endpoint, ...config }),
    patch: async (endpoint, config) => await fetchBackendRaw({ method: 'PATCH', endpoint, ...config }),
    delete: async (endpoint, config) => await fetchBackendRaw({ method: 'DELETE', endpoint, ...config }),
  },
  BLOB: {
    get: async (endpoint, config) => await fetchBackend({ method: 'GET', type: 'blob', endpoint, ...config }),
    post: async (endpoint, config) =>
      await fetchBackend({ method: 'POST', type: 'blob', endpoint, ...config }),
    put: async (endpoint, config) => await fetchBackend({ method: 'PUT', type: 'blob', endpoint, ...config }),
    patch: async (endpoint, config) =>
      await fetchBackend({ method: 'PATCH', type: 'blob', endpoint, ...config }),
    delete: async (endpoint, config) =>
      await fetchBackend({ method: 'DELETE', type: 'blob', endpoint, ...config }),
  },
};
