import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CreatedSilobagData, SilobagCreationPayload } from './silobag-creation.types';

// deprecated service ref.: createSilobagService_DEPRECATED
export async function createSilobagService({ body }: SilobagCreationPayload) {
  const { response, data } = await BACKEND.post<CreatedSilobagData>(ENDPOINT.SILOBAGS, { body });

  return {
    response,
    summary: data,
  };
}
