import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CountrysideDeletionPayload, DeletedCountrysideData } from './countryside-deletion.types';
//TODO VER CON EMI
// deprecated service ref.: deleteCountrysideService_DEPRECATED
export async function deleteCountrysideService({ query: rawQuery }: CountrysideDeletionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.delete<DeletedCountrysideData>(ENDPOINT.COUNTRYSIDES, { query });

  return {
    response,
    summary: data,
  };
}
