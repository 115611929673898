import '../../../pages/private/s3Init';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import DataButtonIcon from '@assets/svg/core/eye-white.svg';
import UploadLogoButtonIcon from '@assets/svg/specific/admin/square-mountain.svg';
import { useToggle } from '@hooks/core';
import { OrgDataModal } from './org-data-modal';
import { useGlobalStore } from '@global-store/use-store';
import AWS from 'aws-sdk';
import { useService } from '@hooks/use-service';
import i18n from '../../../libs/language';
import { ToastAction } from '@ui/toast/toast-action';
import { organizationDataService } from '@services/domain/base/organization/organization-data';
import { updateOrganizationDataService } from '@services/domain/base/organization/organization-edition';
import { ChangeEvent } from 'react';

type OrgSummary = Awaited<ReturnType<typeof organizationDataService>>['summary'];
type OrgPayload = Parameters<typeof organizationDataService>[0];

type Props = {
  orgData: OrgSummary | undefined;
  onSuccessTrigger: () => void;
  updateAdminData: (o: OrgPayload) => void;
};

export function AdminOrgDataPanel({ orgData, onSuccessTrigger, updateAdminData }: Props) {
  const [openOrgDataModal, toggleOpenOrgDataModal] = useToggle(false);
  const [adminLogoChanged, setAdminLogoChange] = useToggle(false);
  const [orgDataChange, setOrgDataChange] = useToggle(false);
  const handleCloseOrgDataModal = () => toggleOpenOrgDataModal(false);

  const theme = useTheme();
  const matchLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchMD = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {adminLogoChanged && (
        <ToastAction
          message={i18n.t('app.org_data_admin.org_logo_changed')}
          severity="success"
          setOpenToast={setAdminLogoChange}
          openToast={adminLogoChanged}
        />
      )}
      {orgDataChange && (
        <ToastAction
          message={i18n.t('app.org_data_admin.org_data_changed')}
          severity="success"
          setOpenToast={setOrgDataChange}
          openToast={orgDataChange}
        />
      )}
      <Paper
        style={{
          padding: 15,
          borderRadius: 15,
          marginBottom: 15,
        }}
        elevation={0}
      >
        <Stack
          direction="row"
          spacing={matchMD ? 0 : 5}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          rowGap={3}
        >
          <Box style={{ width: matchMD ? '100%' : '350px' }}>
            <InputFileUpload
              orgDataImg={orgData?.logo_url ?? undefined}
              updateAdminData={updateAdminData}
              setAdminLogoChange={setAdminLogoChange}
            />
          </Box>
          <Stack
            direction="row"
            spacing={5}
            justifyContent={matchMD ? 'center' : 'left'}
            alignItems="center"
            style={{ flex: 1 }}
          >
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Box>{i18n.t('app.organization')}</Box>
              {orgData ? (
                <Box style={{ width: 'max-content', fontWeight: '600' }}>{orgData?.name}</Box>
              ) : (
                <CircularProgress size={16} />
              )}
            </Stack>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Box>{i18n.t('app.cuit')}</Box>
              {orgData ? (
                <Box sx={{ fontWeight: '600' }}>{orgData?.tax_id}</Box>
              ) : (
                <CircularProgress size={16} />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              startIcon={<img src={DataButtonIcon} alt="eye" />}
              disabled={!orgData}
              style={{
                color: '#FFF',
                width: 115,
                borderRadius: 100,
                textTransform: 'none',
                marginRight: matchLG ? 0 : '1rem',
              }}
              onClick={() => toggleOpenOrgDataModal(true)}
            >
              <Typography style={{ fontWeight: '600' }}>{i18n.t('app.data')}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Paper>
      {orgData ? (
        <OrgDataModal
          open={openOrgDataModal}
          onClose={handleCloseOrgDataModal}
          orgData={orgData}
          onSuccessTrigger={onSuccessTrigger}
          setOrgDataChange={setOrgDataChange}
        />
      ) : null}
    </>
  );
}

type InputFileProps = {
  orgDataImg: string | undefined;
  updateAdminData: Props['updateAdminData'];
  setAdminLogoChange: (val: boolean) => void;
};

function InputFileUpload({ orgDataImg, updateAdminData, setAdminLogoChange }: InputFileProps) {
  const {
    awsTempCredentials, //
    organizationTaxId,
    organizationId,
    setOrganizationLogo,
    userOrganizationId,
  } = useGlobalStore();

  const handleSuccessUpload = () => {
    updateAdminData({ query: userOrganizationId! });
    setAdminLogoChange(true);
  };

  const {
    trigger: updateLogo, //
  } = useService(updateOrganizationDataService, { onSuccess: handleSuccessUpload });

  AWS.config.update({
    region: 'us-east-1',
    accessKeyId: awsTempCredentials.AccessKeyId,
    secretAccessKey: awsTempCredentials.SecretAccessKey,
    sessionToken: awsTempCredentials.SessionToken,
  });

  const BUCKET = 'iofbucket';

  const s3 = new AWS.S3();

  const handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    const date = new Date().toISOString();
    const params = {
      Body: file,
      Bucket: BUCKET,
      Key: `logos/${organizationTaxId}_${date}.${fileExtension}`, // TODO: CAMBIAR 'env' POR VARIABLE DE ENTORNO DEV/PROD/QA DEPENDIENDO DEL AMBIENTE EN EL QUE SE ENCUENTRE
    };

    const { Location } = await s3.upload(params).promise();

    const organization_logo_body = {
      logo_url: Location,
    };
    setOrganizationLogo(Location);

    updateLogo({
      query: organizationId!,
      body: organization_logo_body,
    });
  };

  return (
    <Button
      component="label"
      variant="contained"
      startIcon={orgDataImg ? null : <img src={UploadLogoButtonIcon} style={{ paddingRight: 5 }} />}
      disableElevation
      sx={{
        border: '2px dashed rgba(122, 122, 122, 0.5)',
        height: '100px',
        width: '100%',
        color: '#7a7a7a',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        {orgDataImg ? (
          <Box
            style={{
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              height: '90px',
            }}
          >
            <img
              src={orgDataImg}
              alt="organization-logo"
              style={{ width: '100%', maxHeight: '100%', maxWidth: '100%' }}
            />
          </Box>
        ) : (
          <span>{i18n.t('app.administrator.add_logo')}</span>
        )}
      </Box>

      <input type="file" style={{ display: 'none' }} onChange={handleChangeImage} />
    </Button>
  );
}
