import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { UserData } from './user-list.types';

// deprecated service ref.: getUsersService_DEPRECATED

// ✅ Implementado
export async function listUsersService() {
  const hardcodedParams = { active: true };
  const query = transformIntoQueryParamString(hardcodedParams);

  const { response, data } = await BACKEND.get<UserData>(ENDPOINT.USERS, { query });

  return {
    response,
    summary: data,
  };
}
