import { Button, Stack } from '@mui/material';
import { useMap } from './use-map';

import ShowingUiIcon from '@assets/svg/core/eye-hollow-green.svg';
import HiddenUiIcon from '@assets/svg/core/crossed-eye-green.svg';

export function Controls() {
  const { map, hideUI, toggleHideUI } = useMap();

  return (
    <Stack
      ref={(node) => {
        const mapControls = map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM];
        const mapControlsArray = mapControls?.getArray();

        if (node && !mapControlsArray?.includes(node))
          map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(node);
      }}
      spacing={1}
      style={{ margin: 18 }}
    >
      <Button
        onClick={toggleHideUI}
        sx={{
          minWidth: 0,
          width: 35,
          height: 35,
          backgroundColor: 'white',
          fontSize: 10,
          '&:hover': {
            backgroundColor: '#b7cf8d',
          },
        }}
      >
        <img src={hideUI ? HiddenUiIcon : ShowingUiIcon} />
      </Button>
      <ZoomBtn inc />
      <ZoomBtn dec />
    </Stack>
  );
}

type ZoomBtnProps =
  | {
      inc: true;
      dec?: never;
    }
  | {
      inc?: never;
      dec: true;
    };

function ZoomBtn({ inc, dec }: ZoomBtnProps) {
  const { map } = useMap();

  let displayText;
  let handleClick;

  if (inc) {
    displayText = '+';
    handleClick = () => {
      map?.setZoom(map.getZoom()! + 1);
    };
  }

  if (dec) {
    displayText = '-';
    handleClick = () => {
      map?.setZoom(map.getZoom()! - 1);
    };
  }

  return (
    <Button
      sx={{
        minWidth: 0,
        width: 35,
        height: 35,
        background: 'white',
        fontSize: 22,
        fontWeight: 100,
        '&:hover': {
          backgroundColor: '#b7cf8d',
        },
      }}
      onClick={handleClick}
    >
      {displayText}
    </Button>
  );
}
