import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { ForgotPasswordForm } from '@features/public/forgot-password/forgot-password-form';

export function ForgotPasswordPage() {
  return (
    <AuthLayoutComponent>
      <ForgotPasswordForm />
    </AuthLayoutComponent>
  );
}
