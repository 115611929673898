import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CompanyCreationPayload, CreatedCompanyData } from './company-creation.types';

// deprecated service ref.: createCompanyService_DEPRECATED

// ✅ Implementado
export async function createCompanyService({ body }: CompanyCreationPayload) {
  const { response, data } = await BACKEND.post<CreatedCompanyData>(ENDPOINT.COMPANIES, { body: body });

  return {
    response,
    summary: data,
  };
}
