import { useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Button, Stack } from '@mui/material';
import { stylesInput } from '../../styles/generic-styles';
import { DashboardStock } from '@features/report-stock/dashboard-stock';
import TabletStock from '@features/report-stock/tablet-stock';
import { BannerStockTotal } from '@features/report-stock/banner-stock-total';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../libs/language';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { stockMovementListService } from '@services/domain/page/report/movement-stock/report-mov-stock-info';
import { formatDateReverse } from '@utils/helper';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';

interface countryside {
  id: number;
  name: string;
}

export function StockPage() {
  const today = dayjs();
  const lastYear = today.subtract(1, 'year');
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([lastYear, today]);
  const [startDate, endDate] = dateRange;
  const { organizationId, filtersReportStock, setFiltersReportStock } = useGlobalStore();
  const hasFilters = Object.values(filtersReportStock).some((s) => s !== '');
  //Report Stock Service
  const {
    trigger: getAllReportStockData,
    summary: stockData,
    isLoading,
  } = useService(stockMovementListService, {
    fetchOnMount: true,
  });
  //Countrysides Service
  const queryCountrysideList = { organization_id: organizationId };
  const { summary: countrysides, trigger: getCountrysides } = useService(listCountrysideService, {
    fetchOnMount: [{ query: queryCountrysideList }],
  });

  //Silobags Service
  const querySilobagList = { organization_id: organizationId };
  const { summary: silobags, trigger: getSilobags } = useService(listSilobagService, {
    fetchOnMount: [{ query: querySilobagList }],
  });

  useEffectOnUpdate(() => {
    getAllReportStockData();
    getCountrysides({ query: queryCountrysideList });
    getSilobags({ query: querySilobagList });
  }, [organizationId]);

  const silobagList = silobags?.data;

  //Functions Selects Params
  const handleSelectCountryside = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === 0) {
      setFiltersReportStock({
        ...filtersReportStock,
        countrysideId: '',
        countrysideName: '',
      });
    } else {
      const selectedCountry = countrysides?.find((item: countryside) => item.id === selectedValue);
      setFiltersReportStock({
        ...filtersReportStock,
        countrysideId: selectedCountry ? selectedCountry.id : '',
        countrysideName: selectedCountry ? selectedCountry.name : '',
      });
    }
  };

  const silosByCountrysideId = silobagList?.filter(
    (item: any) => item.lots.countrysides.id == filtersReportStock.countrysideId
  );

  const silobagRender = filtersReportStock.countrysideId !== '' ? silosByCountrysideId : silobagList;

  const handleSelectSilobag = (event: any) => {
    const selectedValue = event.target.value;
    if (selectedValue === 0) {
      setFiltersReportStock({
        ...filtersReportStock,
        silobagId: '',
        silobagName: '',
      });
    } else {
      const selectedSilobag =
        siloByCountrysideId.find((silo: any) => silo.id === selectedValue) ||
        silobagList?.find((silo: any) => silo.id === selectedValue);
      setFiltersReportStock({
        ...filtersReportStock,
        silobagId: selectedSilobag?.id,
        silobagName: selectedSilobag?.name,
      });
    }
  };

  const handleDateChange = (newDate: Dayjs | null, index: number) => {
    if (newDate) {
      const updatedDateRange = [...dateRange];
      updatedDateRange[index] = newDate;
      setDateRange(updatedDateRange as [Dayjs, Dayjs]);
    }
  };

  useEffectOnUpdate(() => {
    setFiltersReportStock({
      ...filtersReportStock,
      startDate: formatDateReverse(startDate.toDate()),
      endDate: formatDateReverse(endDate.toDate()),
    });
  }, [startDate, endDate]);

  useEffectOnUpdate(() => {
    getAllReportStockData();
  }, [filtersReportStock]);

  const removeFilter = () => {
    setDateRange([lastYear, today]);
    setFiltersReportStock({ countrysideId: '', silobagId: '', silobagName: '', countrysideName: '' });
  };

  const selectedCountrysideId = filtersReportStock.countrysideId;
  const siloByCountrysideId =
    silobagList && silobagList.length > 0
      ? silobagList.filter((item: any) => item.lots.countrysides.id == selectedCountrysideId)
      : [];
  return (
    <Box style={{ width: '95%', margin: 'auto' }}>
      <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
        {i18n.t('app.report_stock.stock_report_title')}
      </Box>
      <BannerStockTotal />
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
        marginTop={2}
        alignItems={'center'}
        justifyContent={{ xd: 'center', md: 'start' }}
      >
        <Stack
          direction={'row'}
          spacing={{ xs: 1, md: 2 }}
          alignItems={'center'}
          justifyContent={{ xd: 'center', md: 'start' }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label={i18n.t('app.report_stock.init_date')}
              value={startDate}
              onChange={(date) => handleDateChange(date ?? today, 0)}
              sx={stylesInput}
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    onKeyPress: (event: React.KeyboardEvent) => {
                      event.preventDefault();
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DatePicker
              label={i18n.t('app.report_stock.close_date')}
              value={endDate}
              onChange={(date) => handleDateChange(date ?? lastYear, 1)}
              sx={stylesInput}
              slotProps={{
                textField: {
                  size: 'small',
                  inputProps: {
                    onKeyPress: (event: React.KeyboardEvent) => {
                      event.preventDefault();
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
        <Stack
          direction={'row'}
          spacing={{ xs: 1, md: 2 }}
          marginTop={2}
          width={{ xs: '100%', md: '90%' }}
          alignItems={'center'}
          justifyContent={{ xd: 'center', md: 'start' }}
        >
          <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
            <InputLabel id="demo-multiple-name-label">{i18n.t('app.countryside')}</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="countryside_id"
              style={{ border: 'none' }}
              input={<OutlinedInput label={i18n.t('app.countryside')} />}
              onChange={handleSelectCountryside}
              value={filtersReportStock.countrysideId}
            >
              <MenuItem value={0}>{i18n.t('app.countrysides')}</MenuItem>
              {countrysides?.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
            <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobags')}</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="silobag_id"
              style={{ border: 'none' }}
              input={<OutlinedInput label={i18n.t('app.silobags')} />}
              onChange={(e) => handleSelectSilobag(e)}
              value={filtersReportStock.silobagId}
              disabled={silobagRender?.length === 0}
            >
              <MenuItem value={0}>{i18n.t('app.silobags')}</MenuItem>
              {silobagRender?.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Button
          sx={{
            fontSize: 12,
            fontWeight: 500,
            paddingInline: 3,
            textTransform: 'uppercase',
            visibility: hasFilters ? '' : 'hidden',
            color: '#FF0000',
            '&:hover': { background: '#FF000022' },
          }}
          onClick={removeFilter}
          startIcon={<img src={DeleteIcon} alt="delete-icon" style={{ width: 14, height: 14 }} />}
        >
          {i18n.t('app.clear_filters')}
        </Button>
      </Stack>
      <DashboardStock
        totalsData={stockData?.totals}
        data={stockData?.data}
        isLoading={isLoading}
        startDate={startDate.toISOString().split('T')[0]}
        endDate={endDate.toISOString().split('T')[0]}
        countrysideName={filtersReportStock.countrysideName}
        silobagName={filtersReportStock.silobagName}
      />
      <TabletStock data={stockData?.data} isLoading={isLoading} />
    </Box>
  );
}
