import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { HumidityListResponse } from './humidity-option-list.types';

// deprecated service ref.: getHumiditySourcesService_DEPRECATED

// ✅ Implementado
export async function humidityOptionListService() {
  const { response, data } = await BACKEND.get<HumidityListResponse>(ENDPOINT.SILOBAG_HUMIDITY_SOURCE);

  return {
    response,
    summary: data,
  };
}
