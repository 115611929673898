import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';



export async function getSilobagHistoryService(event_id: number) {
  const { response, data } = await BACKEND.get(ENDPOINT.SILOBAGS_HISTORY, { query: `/${event_id}` });

  return {
    response,
    summary: data,
  };
}