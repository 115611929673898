import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { DeletedUserData, UserDeletionPayload } from './user-deletion.types';

// deprecated service ref.: deleteUserService_DEPRECATED

// ❌ NO implementado (requiere modificar <DeleteModal />)
export async function deleteUserService({ query: rawQuery }: UserDeletionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.delete<DeletedUserData>(ENDPOINT.USERS, { query });

  return {
    response,
    summary: data,
  };
}
