import { Box, Button, Stack, Typography } from '@mui/material';
import { configButtonStylesOutlined } from '@styles/generic-styles';
import FondoVerde from '@assets/svg/404/fondo-verde.svg';
import TrigoSvg from '@assets/svg/404/trigo.svg';
import { useGlobalStore } from '@global-store/use-store';
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import i18n from '../../libs/language';
import { useEffectOnMount } from '@hooks/core';

export default function NotFoundPage() {
  const { email } = useGlobalStore();
  const navigate = useNavigate();

  const { setLanguage } = useGlobalStore();

  useEffectOnMount(() => {
    const browserLanguage = navigator.language;
    switch (browserLanguage) {
      case 'pt':
      case 'pt-BR':
        i18n.locale = 'pt';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'pt');
        break;
      case 'es':
      case 'es-ES':
        i18n.locale = 'es';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'es');
        break;
    }
  });

  const textButton = email ? i18n.t('app.not_found.return_dashboard') : i18n.t('app.not_found.return_login'),
    navigation = email ? '/dashboard' : '/login';

  return (
    <Stack direction={'row'} style={{ overflow: 'hidden' }} className="keep-scrolling-without-scroll">
      <Box width={'50vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Stack
          style={{ zIndex: 5, position: 'relative' }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'start'}
          width={{ xs: '100%', md: '50%' }}
          marginLeft={'30%'}
        >
          <Typography variant="h1" fontWeight={'bold'} sx={{ color: { xs: '#FFFF', md: '#36620D' } }}>
            404
          </Typography>
          <Typography width={'100%'} sx={{ color: { xs: '#ffff', md: '#49454F' } }} fontWeight={'bold'}>
            {i18n.t('app.not_found.message')}
          </Typography>
          <Button
            size={'medium'}
            color="primary"
            variant="contained"
            sx={{
              ...configButtonStylesOutlined,
              margin: 0,
              marginTop: 2,
              color: { xs: '#6BAA00', md: '#ffff' },
              background: { xs: '#fff', md: '#36620d' },
              '&:hover': { background: { xs: '#fff', md: '#36620d' } },
            }}
            onClick={() => navigate(navigation)}
          >
            {textButton}
          </Button>
        </Stack>
      </Box>
      <Box width={'50vw'} height={'100vh'} margin={0} display={'flex'} position="relative">
        <Box
          style={{ zIndex: 2, position: 'relative' }}
          height={'100vh'}
          display={'flex'}
          alignItems={'center'}
        >
          <img src={TrigoSvg} style={{ zIndex: 2, position: 'relative', width: '100%' }} />
        </Box>
        <Box
          width={'50%'}
          height={'100vh'}
          display={'flex'}
          justifyContent={'end'}
          alignItems={'end'}
          style={{ zIndex: 1, position: 'relative' }}
        >
          <img
            src={FondoVerde}
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: '#6BAA00',
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
