import { EffectCallback, useCallback, useRef } from 'react';
import { useEffectOnUnmount, useEffectOnUpdate } from './use-effect';

export function useTimeout(callback: EffectCallback, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const set = useCallback(() => {
    clear();
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  useEffectOnUpdate(() => {
    callbackRef.current = callback;
  }, [callback]);
  useEffectOnUnmount(clear);

  return { set, clear };
}
