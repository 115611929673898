import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CreatedUserData, UserCreationPayload } from './user-creation.types';

// deprecated service ref.: createUserService_DEPRECATED

// ✅ Implementado
export async function createUserService({ body }: UserCreationPayload) {
  const { response, data } = await BACKEND.post<CreatedUserData>(ENDPOINT.USERS, { body });

  return {
    response,
    summary: data,
  };
}
