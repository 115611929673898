import { ModalIOF } from '@ui/modal/modal';
import { configButtonStylesContained } from '@styles/generic-styles';
// import i18n from '../../../libs/language';
import {
  Stack,
  Typography,
  Box,
  Button,
  Grid,
  Select,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  MenuItem,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { useService } from '@hooks/use-service';
import { postLaSegundaInsurance_DEPRECATED } from '@services/silobag-insurance/insurance-external-link';
import { useState } from 'react';
import redSojaIcon from '../../../assets/svg/icons-species-red/soja-red.svg';
import redMaizIcon from '../../../assets/svg/icons-species-red/maiz-red.svg';
import redTrigoIcon from '../../../assets/svg/icons-species-red/trigo-red.svg';
import redCebadaIcon from '../../../assets/svg/icons-species-red/cebada-red.svg';
import redGirasolIcon from '../../../assets/svg/icons-species-red/girasol-red.svg';
import { Plans, taxStatus, coins, validityInsurance, paymentMethods } from '../selects-insurance-data';
import { useGlobalStore } from '@global-store/use-store';
import { isValidEmail } from '@utils/helper';

type InsuranceLaSegundaModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  silobagIds?: string[];
  vigencyProps: number;
  totalSumAssured: number;
  tons: number;
  typeCoin: number;
};

interface InsuranceForm {
  plan: string;
  duration: string;
  payment_method: string;
  fiscal_condition: string;
  currency: string;
  sum_assured: number | string;
  zip_code: string;
  organization_name: string;
  fullname: string;
  email: string;
  phone: string;
  silobags: string[];
  [key: string]: string | number | string[];
}

export function InsuranceLaSegundaModal(props: InsuranceLaSegundaModalProps) {
  const { open, setOpen, silobagIds, vigencyProps, totalSumAssured, tons, typeCoin } = props;
  const { organizationName, userFullname, email } = useGlobalStore();
  const [isQuoting, setIsQuoting] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [insuranceForm, setInsuranceForm] = useState<InsuranceForm>({
    plan: Plans[1].description,
    duration: vigencyProps === 6 ? validityInsurance[1].description : validityInsurance[0].description,
    payment_method: paymentMethods[0].description,
    fiscal_condition: taxStatus[0].description,
    currency: coins.find((coin) => coin.id === typeCoin)?.description || coins[0].description,
    sum_assured: totalSumAssured,
    zip_code: '',
    organization_name: organizationName ? organizationName : 'Sin Organización',
    fullname: userFullname ? userFullname : '',
    email: email ? email : '',
    phone: '',
    silobags: silobagIds ?? [],
  });

  const { trigger, isLoading } = useService(postLaSegundaInsurance_DEPRECATED, {
    onSuccess: () => {
      setIsSend(true);
      setTimeout(() => {
        setInsuranceForm({
          plan: Plans[1].description,
          duration: vigencyProps === 6 ? validityInsurance[1].description : validityInsurance[0].description,
          payment_method: paymentMethods[0].description,
          fiscal_condition: taxStatus[0].description,
          currency: coins[0].description,
          sum_assured: totalSumAssured,
          zip_code: '',
          organization_name: organizationName ? organizationName : 'Sin Organización',
          fullname: '',
          email: '',
          phone: '',
          silobags: silobagIds ?? [],
        });
      }, 2000);
    },
  });

  const handleQuoteClick = () => {
    setIsQuoting(true);
  };
  const laSegundaLogo = 'https://iofbucket.s3.amazonaws.com/logo/logo+la+segunda+rojo.svg';

  const handleSubmitInsurance = (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);
    for (let key in insuranceForm) {
      if (insuranceForm[key] === '' || insuranceForm[key] === 0) {
        return;
      }
    }
    trigger(insuranceForm);
  };

  return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      {!isQuoting ? (
        <>
          {/* PASO 1 */}
          <ModalIOF.HeaderCenter>
            <Box>
              <img src={laSegundaLogo} alt="La Segunda Logo" style={{ width: '140px', padding: 10 }} />
            </Box>
          </ModalIOF.HeaderCenter>
          <ModalIOF.Body>
            <Stack direction="column" spacing={2} sx={{ textAlign: 'center' }}>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Granos Asegurables
              </Typography>
              <Stack direction={'row'} justifyContent={'center'}>
                <Box>
                  <img
                    src={redSojaIcon}
                    alt="Soja"
                    style={{ width: '50px', margin: '15px 25px 10px 25px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Soja
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={redMaizIcon}
                    alt="Maíz"
                    style={{ width: '50px', margin: '15px 25px 10px 25px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Maíz
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={redTrigoIcon}
                    alt="Trigo"
                    style={{ width: '50px', margin: '15px 25px 10px 25px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Trigo
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={redCebadaIcon}
                    alt="Cebada"
                    style={{ width: '50px', margin: '15px 25px 10px 25px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Cebada
                  </Typography>
                </Box>
                <Box>
                  <img
                    src={redGirasolIcon}
                    alt="Girasol"
                    style={{ width: '50px', margin: '15px 25px 10px 25px' }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Girasol
                  </Typography>
                </Box>
              </Stack>
            </Stack>
            <Stack
              direction={'column'}
              justifyContent={'center'}
              sx={{ textAlign: 'center', margin: '15px' }}
            >
              <Typography variant="h5" sx={{ fontWeight: 500, marginBottom: '15px' }}>
                Coberturas
              </Typography>
              <Typography>
                Daños ocasionados exclusivamente a los granos almacenados por incendio, rayo, explosión,
                vandalismo, malevolencia, huelga, lock out, impactos de aeronaves, vehículos terrestes y humo.
              </Typography>
            </Stack>
            <Grid container justifyContent={'space-around'}>
              <Grid item xs={12} sm={8}>
                <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                  Adicionales Incluidos *
                </Typography>
                <Stack direction={'row'} justifyContent={'space-around'} sx={{ fontSize: '14px' }}>
                  <Box>
                    <ul>
                      <li>Combustión espontánea</li>
                      <li>Inundación</li>
                      <li>Gastos de vaciado y llenado de bolsas</li>
                      <li>Vendaval</li>
                    </ul>
                    <p style={{ textAlign: 'left', fontWeight: 600, fontSize: '12px' }}>
                      Según condiciones de polizas *
                    </p>
                  </Box>
                  <ul>
                    <li>Combustión espontánea</li>
                    <li>Inundación</li>
                    <li>Gastos de vaciado y llenado de bolsas</li>
                    <li>Vendaval</li>
                  </ul>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                  Opcionales *
                </Typography>
                <Typography sx={{ fontSize: '14px', marginTop: '15px' }}>
                  Robo de granos a primer riesgo absoluto.
                </Typography>
              </Grid>
            </Grid>
            <Stack direction={'row'} justifyContent={'right'} spacing={2}>
              <Button color="error" onClick={() => setOpen(false)} sx={{ color: 'black' }}>
                Cerrar
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleQuoteClick}
                sx={configButtonStylesContained}
              >
                Solicitar Cotización
              </Button>
            </Stack>
          </ModalIOF.Body>
        </>
      ) : (
        <>
          {/* PASO 2 */}
          <ModalIOF.Header>
            <Box>
              <img src={laSegundaLogo} alt="La Segunda Logo" style={{ width: '140px', padding: 10 }} />
            </Box>
          </ModalIOF.Header>
          {!isSend ? (
            <ModalIOF.Body>
              <Typography variant="h5" sx={{ fontWeight: 600, margin: '15px' }}>
                Completa el formulario para obtener tu cotización
              </Typography>
              <Stack direction={'row'} spacing={2} sx={{ marginBottom: 3 }}>
                <Typography variant="body2" sx={{ color: '#9d9d9d' }}>
                  Silobolsas Seleccionados{' '}
                  <span style={{ fontWeight: 600, color: 'black' }}>{silobagIds?.length}</span>
                </Typography>
                <Typography variant="body2" sx={{ color: '#9d9d9d' }}>
                  Toneladas cotizadas: <span style={{ fontWeight: 600, color: 'black' }}>{tons}</span>
                </Typography>
              </Stack>
              <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>Plan *</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, plan: e.target.value as string });
                      }}
                      required
                      input={<OutlinedInput label="Plan" />}
                      error={formSubmitted && !insuranceForm.plan}
                      value={insuranceForm.plan}
                    >
                      {Plans?.map((item: any) => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>Vigencia *</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, duration: e.target.value as string });
                      }}
                      required
                      input={<OutlinedInput label="Vigencia *" />}
                      error={formSubmitted && !insuranceForm.duration}
                      value={insuranceForm.duration}
                    >
                      {validityInsurance?.map((item: any) => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>Forma de Pago *</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      required
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, payment_method: e.target.value as string });
                      }}
                      input={<OutlinedInput label="Forma de Pago *" />}
                      error={formSubmitted && !insuranceForm.payment_method}
                      value={insuranceForm.payment_method}
                    >
                      {paymentMethods?.map((item: any) => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>Condición Fiscal *</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      required
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, fiscal_condition: e.target.value as string });
                      }}
                      input={<OutlinedInput label="Condición Fiscal *" />}
                      error={formSubmitted && !insuranceForm.fiscal_condition}
                      value={insuranceForm.fiscal_condition}
                    >
                      {taxStatus?.map((item: any) => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <InputLabel>Moneda *</InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, currency: e.target.value as string });
                      }}
                      required
                      input={<OutlinedInput label="Moneda *" />}
                      error={formSubmitted && !insuranceForm.currency}
                      value={insuranceForm.currency}
                    >
                      {coins?.map((item: any) => (
                        <MenuItem key={item.id} value={item.description}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <Tooltip
                      title={
                        insuranceForm.sum_assured != 0
                          ? ''
                          : 'La Suma Asegurada es el valor de los granos almacenados en el silobolsa. Se calcula como la cantidad toneladas multiplicadas por el precio del grano.'
                      }
                    >
                      <TextField
                        style={{ width: '100%' }}
                        size="small"
                        required
                        label="Suma Asegurada"
                        type="number"
                        value={insuranceForm.sum_assured}
                        onChange={(e) => {
                          setInsuranceForm({
                            ...insuranceForm,
                            sum_assured: e.target.value ? parseInt(e.target.value) : '',
                          });
                        }}
                        error={formSubmitted && !insuranceForm.sum_assured}
                      />
                    </Tooltip>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <TextField
                      style={{ width: '100%' }}
                      size="small"
                      required
                      label="Codigo Postal del riesgo a asegurar"
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, zip_code: e.target.value });
                      }}
                      error={formSubmitted && !insuranceForm.zip_code}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Typography sx={{ margin: '10px', fontWeight: 600 }}>Tus datos de contacto</Typography>
              <TextField
                size="small"
                sx={{ width: '100%' }}
                required
                label="Organización"
                value={organizationName}
                disabled
                // error={formSubmitted && !insuranceForm.organization_name}
              />
              <Grid container sx={{ marginTop: '15px' }} spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <TextField
                      style={{ width: '100%' }}
                      size="small"
                      required
                      label="Nombre y apellido"
                      value={insuranceForm.fullname}
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, fullname: e.target.value });
                      }}
                      error={formSubmitted && !insuranceForm.fullname}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <TextField
                      style={{ width: '100%' }}
                      size="small"
                      required
                      label="Email"
                      value={insuranceForm.email}
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, email: e.target.value });
                      }}
                      error={
                        (formSubmitted && !insuranceForm.email) ||
                        (insuranceForm.email ? !isValidEmail(insuranceForm.email) : false)
                      }
                      type="email"
                      helperText={
                        isValidEmail(insuranceForm.email) || insuranceForm.email == '' ? '' : 'Email inválido'
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                    <TextField
                      style={{ width: '100%' }}
                      size="small"
                      required
                      label="Celular"
                      onChange={(e) => {
                        setInsuranceForm({ ...insuranceForm, phone: e.target.value });
                      }}
                      error={formSubmitted && !insuranceForm.phone}
                    />
                    <p style={{ fontSize: '12px', margin: '0', textAlign: 'left', color: '#A7A6A6' }}>
                      Ejemplo: +5491157059192
                    </p>
                  </FormControl>
                </Grid>
              </Grid>
              <Stack direction="row" spacing={2} justifyContent={'right'} sx={{ marginTop: '15px' }}>
                <Button
                  color="error"
                  onClick={() => {
                    setIsQuoting(false);
                    setOpen(false);
                  }}
                  sx={{ color: 'black' }}
                >
                  Cerrar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={configButtonStylesContained}
                  onClick={handleSubmitInsurance}
                >
                  {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Enviar'}
                </Button>
              </Stack>
            </ModalIOF.Body>
          ) : (
            <ModalIOF.Body>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h6" fontWeight={600}>
                  ¡Gracias por completar el Formulario!
                </Typography>
                <Typography fontSize={'16px'} fontWeight={300} sx={{ marginTop: '10px' }}>
                  El pedido de cotización fue enviado correctamente, un ejecutivo de La Segunda se pondrá en
                  contacto.
                </Typography>
                <Button
                  color="error"
                  onClick={() => {
                    setIsQuoting(false);
                    setOpen(false);
                    setIsSend(false);
                  }}
                  sx={{ color: 'black', marginTop: '15px' }}
                >
                  Cerrar
                </Button>
              </Box>
            </ModalIOF.Body>
          )}
        </>
      )}
    </ModalIOF>
  );
}
