import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { SelectableOrgData } from './organization-selectable-list.types';

// deprecated service ref.: userSelectableOrgsService_DEPRECATED

// ✅ implementado
export async function listSelectableOrgService() {
  const { response, data } = await BACKEND.get<SelectableOrgData>(ENDPOINT.USER_SELECTABLE_ORGS);

  return {
    response,
    summary: data,
  };
}
