import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';
import { useGlobalStore } from '@global-store/use-store';
import { StockStatusResponse } from './report-status-stock.info.types';

// deprecated service ref.: getAllStateStockService_DEPRECATED

// Implementado ✅
export async function stockStatusListService() {
  const { organizationId, filtersReportStateStock } = useGlobalStore.getState();
  const RawQuery = {
    organization_id: organizationId,
    countryside_id: filtersReportStateStock.countrysideId,
    name: filtersReportStateStock.name,
    page: filtersReportStateStock.paginationModel.page,
    take: filtersReportStateStock.paginationModel.pageSize,
  };
  const query = transformIntoQueryParamString(RawQuery);
  const { response, data } = await BACKEND.get<StockStatusResponse>(ENDPOINT.REPORT_STATE_STOCK, { query });
  return {
    response,
    summary: data,
  };
}
