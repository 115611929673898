import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { updateLabProtocolService } from '@services/domain/laboratory/lab-protocol-edition';
import { exportLabProtocolService } from '@services/domain/laboratory/lab-protocol-export';
import { importLabProtocolService } from '@services/domain/laboratory/lab-protocol-import';
import { labProtocolBySilobagListService } from '@services/domain/laboratory/lab-protocol-list';
import {
  lastSilobagLaboratoryProtocol,
  silobagLaboratoryProtocolsHistory,
} from '@services/domain/laboratory/lab-protocol-silobag-info';
import { SpeciesLocale } from '@typed/domain/base/silobag/silobag-species.types';
import { Silobag } from '@typed/domain/base/silobag/silobag.types';
import { LabUnitTypeID } from '@typed/domain/laboratory/lab-unit-type.types';
import { ToastAction } from '@ui/toast/toast-action';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { ChangeEvent, MouseEvent, PropsWithChildren, useRef, useState } from 'react';
import i18n from '../../../../../libs/language';

import ChevronDown from '@assets/svg/core/chevron-down-green.svg';
import UploadIcon from '@assets/svg/core/upload.svg';
import UploadIconDisabled from '@assets/svg/core/upload-disabled.svg';

import EditIcon from '@assets/svg/core/edit-square-hollow-white.svg';
import DownloadIcon from '@assets/svg/core/download-alt-green.svg';
import DownloadIconDisabled from '@assets/svg/core/download-alt-disabled.svg';

type Unpacked<T> = T extends (infer U)[] ? U : T;

type LabProtocol = Unpacked<Awaited<ReturnType<typeof silobagLaboratoryProtocolsHistory>>['summary']>;
// type LabTemplate = LabProtocol['lab_templates'];
type Determination = Unpacked<LabProtocol['lab_protocols_results']>['lab_determinations'];

type DownloadLabProtocol = Awaited<ReturnType<typeof labProtocolBySilobagListService>>['summary'];
type DownloadLabTemplate = Unpacked<
  Unpacked<DownloadLabProtocol['lab_grouped_silobag_by_specie_type']>['lab_templates']
>;
type Description = Unpacked<DownloadLabTemplate['lab_templates_translations']>['description'];

type LabModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  siloID: Silobag['id'];
  siloVariety: Silobag['variety'];
  siloSpecies: SpeciesLocale['description'];
};
export function LabModal({ open, onClose, onSuccess, siloID, siloVariety, siloSpecies }: LabModalProps) {
  /// --- [SERVICES] --- \\\
  const {
    // trigger: triggerLastProtocol, //
    summary: lastProtocolSummary, //
    isLoading: isLastProtocolLoading,
  } = useService(lastSilobagLaboratoryProtocol, { fetchOnMount: [{ query: siloID }] });

  const labProtocolListPayload = { body: { silobags: [siloID] } };
  const {
    summary: summaryOfLabProtocolList, //
    isLoading: isLabProtocolListLoading,
  } = useService(labProtocolBySilobagListService, { fetchOnMount: [labProtocolListPayload] });

  const {
    trigger: importLabProtocol,
    isError: isTemplateImportError,
    error: templateImportError,
    isSuccess: isTemplateImportSuccess,
    isLoading: isImportLabProtocolLoading,
  } = useService(importLabProtocolService, { onSuccess });

  const {
    trigger: exportLabProtocol,
    isSuccess: isExportLabProtocolSuccess,
    isLoading: isExportLabProtocolLoading,
  } = useService(exportLabProtocolService, {
    onSuccess: (data) => {
      setURL(window.URL.createObjectURL(data));
    },
  });

  const {
    trigger: updateProtocol, //
    isLoading: isUpdateProtocolLoading,
  } = useService(updateLabProtocolService, { onSuccess });

  /// --- [LAB-TEMPLATE] Export template logic --- \\\
  const downloadLabTempAnchorRef = useRef<HTMLAnchorElement>(null);
  const [url, setURL] = useState('');
  useEffectOnUpdate(() => {
    const { current } = downloadLabTempAnchorRef;
    if (!current || !url) return;

    // Generating file name
    const templateName = downloadTemplateTargetName;
    const branding = 'SiloReal';
    const ext = 'xlsx';
    const fileName = templateName ? `${branding} - ${templateName} - ${siloID}.${ext}` : 'template.xlsx';

    // Downloading file
    current.setAttribute('href', url);
    current.setAttribute('download', fileName);
    current.click();
  }, [url]);

  /// --- [LAB-TEMPLATE] Import template logic --- \\\
  const uploadLabTemplateRef = useRef<HTMLInputElement>(null);
  const handleOpenFileExplorer = () => uploadLabTemplateRef.current?.click();

  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [targetUploadedTemplateName, setTargetUploadedTemplateName] = useState('');
  const handleSubmitLabTemplate = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const hasFiles = files && files.length > 0;
    if (!hasFiles) return;

    const [file] = Array.from(files);

    const acceptedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if (!acceptedFileTypes.includes(file.type)) {
      setIsInvalidFileType(true);
      return;
    }

    setTargetUploadedTemplateName(file.name.replace(/\.[^/.]+$/, ''));
    importLabProtocol({ query: siloID, body: { inputFile: file } });
  };

  /// --- [LAB-TEMPLATE] Download excel with importing errors logic --- \\\
  // const downloadLabTemplateErrorRef = useRef<HTMLAnchorElement>(null);
  useEffectOnUpdate(() => {
    const { current } = downloadLabTempAnchorRef;
    const isExcelError = isTemplateImportError && templateImportError?.data?.message == null;

    if (!isExcelError || !current) return;

    const { URL } = window;
    current.setAttribute('href', URL.createObjectURL(templateImportError.data));
    current.setAttribute('download', targetUploadedTemplateName + ' - ERROR.xlsx');
    current.click();
  }, [isTemplateImportError]);

  /// --- [LAB-TEMPLATE] Export template logic --- \\\
  // TODO: falta agregar el chevron down en caso de que haya varias opciones
  const [downloadTemplateTargetName, setDownloadTemplateTargetName] = useState<Description>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openDropdown = Boolean(anchorEl);
  const handleOpenDropdown = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);
  const handleCloseDropdown = () => setAnchorEl(null);

  const handleDownloadLabTemplate = (template: DownloadLabTemplate) => {
    if (!template) return;

    const [templateLocale] = template.lab_templates_translations;
    const { description } = templateLocale;
    setDownloadTemplateTargetName(description);

    exportLabProtocol({
      body: {
        lab_template_id: template.id,
        silobags: [siloID],
      },
    });
    handleCloseDropdown();
  };

  /// --- [LAB-RESULTS] Edit results logic --- \\\
  // const inputsRef = useRef<HTMLInputElement[]>([]);

  const [editMode, setEditMode] = useToggle(false);
  const handleChangeToEditMode = () => setEditMode(true);
  // const handleChangeToViewMode = () => setEditMode(false); // TODO: consultar ¿debería estar, no?

  // Submit edition
  const submit = () => {
    if (!lastProtocolSummary) return;

    let error = false;
    Object.entries(formData).forEach(([determinationID, result]) => {
      if (result === '') {
        error = true;
        handleChangeFormErrors(determinationID, i18n.t('app.lab.input_error.required_field'));
      }

      const floatUnitTypeID = [LabUnitTypeID.PERCENTAGE, LabUnitTypeID.DECIMAL];
      const integerUnitTypeID = [LabUnitTypeID.INTEGER];

      const unitTypeID = targetLabResults!.find(
        (entry) => entry.lab_determinations.id === parseInt(determinationID, 10)
      )!.lab_determinations.lab_unit.lab_unit_type_id;

      const isIntegerUnit = integerUnitTypeID.includes(unitTypeID);
      const hasIntegerInputError = isIntegerUnit && VALIDATION.ONLY_NUMS(result);

      const isFloatUnit = floatUnitTypeID.includes(unitTypeID);
      const hasFloatInputError = isFloatUnit && VALIDATION.FLOAT_NUMS(result);

      if (hasIntegerInputError || hasFloatInputError) {
        error = true;
        handleChangeFormErrors(determinationID, i18n.t('app.lab.input_error.invalid_value'));
      }
    });
    if (error) return;

    const newLabProtocolResults = Object.entries(formData).map(([determinationID, result]) => {
      const detID = parseInt(determinationID, 10);
      const protocolID = lastProtocolSummary.lab_protocols_results.find(
        (entry) => entry.lab_determination_id === detID
      )!.id;

      return {
        id: protocolID,
        lab_determination_id: detID,
        result,
      };
    });

    const query = lastProtocolSummary.id;
    const body = {
      description: lastProtocolSummary.description,
      id: lastProtocolSummary.id,
      protocol_number: lastProtocolSummary.protocol_number,
      acquisition_date: dayjs.utc(lastProtocolSummary.acquisition_date).format('YYYY-MM-DD'),
      protocol_date: dayjs.utc(lastProtocolSummary.protocol_date).format('YYYY-MM-DD'),
      lab_template_id: lastProtocolSummary.lab_template_id,
      silobag_id: siloID,
      laboratory: lastProtocolSummary.laboratory,
      lab_protocols_results: newLabProtocolResults,
    };

    updateProtocol({ query, body });
  };

  // Extra

  const targetLabResults = lastProtocolSummary?.lab_protocols_results;

  const HEADER_DATA = {
    species: siloSpecies,
    variety: siloVariety,
    acquisitionDate: lastProtocolSummary?.acquisition_date,
    analysisDate: lastProtocolSummary?.protocol_date,
    lab: lastProtocolSummary?.laboratory,
    analysisNumber: lastProtocolSummary?.protocol_number,
  };

  const targetDownloadProtocol = summaryOfLabProtocolList?.lab_grouped_silobag_by_specie_type[0];

  const hasNoAvailableLabProtocol = targetDownloadProtocol?.lab_templates.length === 0;
  const hasManyLabProtocolOptions = (targetDownloadProtocol?.lab_templates.length ?? -1) > 1;

  const hasLabData = Boolean(lastProtocolSummary);
  const processingDownloadTemplate = isLabProtocolListLoading || isExportLabProtocolLoading;

  /// --- [FORM] Form data to send and error tracking --- \\\
  const [formData, setFormData] = useState<Record<number, string>>({});
  const [formErrors, setFormErrors] = useState<Record<number, string>>({});
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^-?[0-9]*$/.test(value),
    FLOAT_NUMS: (value: string) => !/^-?[0-9]*((?<=\d)\.(?=\d))?[0-9]*$/.test(value) || value === '-',
    TYPING_FLOAT_NUMS: (value: string) => !/^-?[0-9]*\.?[0-9]*$/.test(value),
  };

  const handleChangeFormErrors = (id: number | string, error: string) =>
    setFormErrors((prev) => ({ ...prev, [id]: error }));

  const handleChangeFormData = (determination: Determination, input: string) => {
    const { max, min } = determination.lab_unit.lab_unit_types;

    setDisableSaveButton(false);

    const { id } = determination;
    const updateFormDataWithNewValue = () => setFormData((prev) => ({ ...prev, [id]: input }));

    const validateInputUnderRange = (num: number) => num < min;
    const validateInputOverRange = (num: number) => num > max;

    const DETERMINATION_VALIDATION = {
      skip: updateFormDataWithNewValue,
      integer: () => {
        if (VALIDATION.ONLY_NUMS(input)) {
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.only_numbers'));
          return;
        }
        if (validateInputUnderRange(parseInt(input, 10))) {
          const minMessage = min ? ` (min: ${min})` : '';
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.out_of_range') + minMessage);
          return;
        }
        if (validateInputOverRange(parseInt(input, 10))) {
          const maxMessage = max ? ` (max: ${max})` : '';
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.out_of_range') + maxMessage);
          return;
        }
        handleChangeFormErrors(id, '');
        updateFormDataWithNewValue();
      },
      float: () => {
        if (VALIDATION.TYPING_FLOAT_NUMS(input)) {
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.only_numbers'));
          return;
        }

        if (validateInputUnderRange(parseFloat(input))) {
          const minMessage = min ? ` (min: ${min})` : '';
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.out_of_range') + minMessage);
          return;
        }
        if (validateInputOverRange(parseFloat(input))) {
          const maxMessage = max ? ` (max: ${max})` : '';
          handleChangeFormErrors(id, i18n.t('app.lab.input_error.out_of_range') + maxMessage);
          return;
        }

        handleChangeFormErrors(id, '');

        const floatValue = parseFloat(input);
        const isFloat = !Number.isNaN(floatValue);

        setFormData((prev) => ({
          ...prev,
          [id]: isFloat && !input.startsWith('-0') && !input.endsWith('.') ? floatValue.toString() : input,
        }));
      },
    };

    // prettier-ignore
    const HANDLER: Record<LabUnitTypeID, () => void> = {
      [LabUnitTypeID.PERCENTAGE]: DETERMINATION_VALIDATION.float,
      [LabUnitTypeID.OPTIONS]   : DETERMINATION_VALIDATION.skip,
      [LabUnitTypeID.BOOLEAN]   : DETERMINATION_VALIDATION.skip,
      [LabUnitTypeID.DECIMAL]   : DETERMINATION_VALIDATION.float,
      [LabUnitTypeID.INTEGER]   : DETERMINATION_VALIDATION.integer,
      [LabUnitTypeID.DATE]      : DETERMINATION_VALIDATION.skip,
      [LabUnitTypeID.STRING]    : DETERMINATION_VALIDATION.skip,
    } // ⚠ important: if some validation change to float, it will be necessary update the edition submit function too.

    HANDLER[determination.lab_unit.lab_unit_type_id as LabUnitTypeID]();
  };

  useEffectOnUpdate(() => {
    if (!targetLabResults) return;

    const initFormData = targetLabResults.reduce(
      (rec, entry) => ({ ...rec, [entry.lab_determinations.id]: entry.result }),
      {}
    );
    setFormData(initFormData);
  }, [lastProtocolSummary]);

  const ERROR_MSG: Record<number, string> = {
    10019: `${i18n.t('app.lab.error.err10019')} (E.#10019)`,
    10020: `${i18n.t('app.lab.error.err10020')} (E.#10020)`,
  };
  const errorCode = templateImportError?.data?.message?.code;
  const errorMessage = templateImportError?.data?.message?.message;
  const displayErrorMessage = ERROR_MSG[errorCode] ?? errorMessage ?? i18n.t('app.lab.error.default');

  return (
    <MyModal open={open} onClose={onClose}>
      <a ref={downloadLabTempAnchorRef} href={url} style={{ display: 'none' }} />
      <input
        ref={uploadLabTemplateRef}
        type="file"
        hidden
        accept=".xlsx"
        onChange={handleSubmitLabTemplate}
      />
      {isInvalidFileType ? (
        <ToastAction
          key="invalid-file-type"
          message={i18n.t('app.lab.error.invalid_file_type')}
          severity="error"
          onClose={() => setIsInvalidFileType(false)}
        />
      ) : null}
      {isTemplateImportError ? <ToastAction severity="error" message={displayErrorMessage} /> : null}
      {isTemplateImportSuccess ? (
        <ToastAction severity="success" message={i18n.t('app.lab.success.upload')} />
      ) : null}
      {isExportLabProtocolSuccess ? (
        <ToastAction severity="success" message={i18n.t('app.lab.success.download')} />
      ) : null}
      <Stack direction="row" paddingRight={4} justifyContent="space-between" alignItems="center" spacing={8}>
        <Box component="h1" fontWeight={500} style={{ color: '#49454F', flex: 5 }}>
          {i18n.t('app.lab.detail_sample_modal.title')}
        </Box>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.species')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.species ?? '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            Variedad
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.variety ?? '―'}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.acquisition_date')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.acquisitionDate ? dayjs.utc(HEADER_DATA.acquisitionDate).format('DD/MM/YYYY') : '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.analysis_date')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.analysisDate ? dayjs.utc(HEADER_DATA.analysisDate).format('DD/MM/YYYY') : '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.laboratory')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.lab ?? '―'}
          </Typography>
        </Stack>
        <Stack flex={1}>
          <Typography fontSize={13} color="#7A7A7A">
            {i18n.t('app.lab.detail_sample_modal.analysis_number')}
          </Typography>
          <Typography fontSize={14} fontWeight={500} color="#49454F">
            {HEADER_DATA.analysisNumber ?? '―'}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Stack direction="row" flex={1} spacing={2} alignItems="center">
          <Button
            startIcon={
              processingDownloadTemplate ? null : (
                <img src={hasNoAvailableLabProtocol ? DownloadIconDisabled : DownloadIcon} alt="download" />
              )
            }
            onClick={
              hasManyLabProtocolOptions
                ? handleOpenDropdown
                : () =>
                    handleDownloadLabTemplate(
                      summaryOfLabProtocolList!.lab_grouped_silobag_by_specie_type[0].lab_templates[0]
                    )
            }
            endIcon={
              hasManyLabProtocolOptions && !processingDownloadTemplate ? <img src={ChevronDown} /> : null
            }
            variant="outlined"
            disabled={processingDownloadTemplate || hasNoAvailableLabProtocol}
            style={{
              minWidth: processingDownloadTemplate ? '240px' : 'max-content',
              padding: '5px 24px',
              fontSize: 14,
              borderRadius: 100,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            {processingDownloadTemplate ? (
              <CircularProgress size={22} />
            ) : (
              i18n.t('app.lab.detail_sample_modal.button.download')
            )}
          </Button>
          {hasManyLabProtocolOptions ? (
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={openDropdown}
              onClose={handleCloseDropdown}
              TransitionComponent={Fade}
            >
              {summaryOfLabProtocolList?.lab_grouped_silobag_by_specie_type[0].lab_templates.map(
                (labTemplate) => {
                  return (
                    <MenuItem
                      key={labTemplate.id}
                      onClick={() => handleDownloadLabTemplate(labTemplate)}
                      sx={{ color: '#26C4B7' }}
                    >
                      {labTemplate.lab_templates_translations[0].description}
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          ) : null}
          <Button
            startIcon={
              isImportLabProtocolLoading ? null : (
                <img src={hasNoAvailableLabProtocol ? UploadIconDisabled : UploadIcon} alt="download" />
              )
            }
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disabled={isImportLabProtocolLoading || hasNoAvailableLabProtocol}
            onClick={handleOpenFileExplorer}
            variant="outlined"
            style={{
              minWidth: isImportLabProtocolLoading ? '175px' : 'max-content',
              padding: '5px 24px',
              fontSize: 14,
              borderRadius: 100,
              textTransform: 'none',
              fontWeight: 600,
            }}
          >
            {isImportLabProtocolLoading ? (
              <CircularProgress size={22} />
            ) : (
              i18n.t('app.lab.detail_sample_modal.button.upload')
            )}
          </Button>
        </Stack>
        {hasLabData ? (
          <>
            {editMode ? (
              <Button
                // style={{ padding: '5px 24px' }}
                variant="contained"
                type="submit"
                style={{
                  color: '#FFF',
                  width: 108,
                  height: 40,
                  borderRadius: 100,
                  textTransform: 'none',
                }}
                disabled={isUpdateProtocolLoading || disableSaveButton}
                onClick={submit}
              >
                {isUpdateProtocolLoading ? <CircularProgress size={20} /> : i18n.t('app.save')}
              </Button>
            ) : (
              <IconButton
                size="large"
                disabled={isLastProtocolLoading}
                style={{ background: isLastProtocolLoading ? '#1481BC33' : '#1481BC' }}
                onClick={handleChangeToEditMode}
              >
                <img src={EditIcon} style={{ width: 18, height: 18 }} />
              </IconButton>
            )}
          </>
        ) : null}
      </Stack>
      <ResultTable
        editMode={editMode}
        hasLabData={hasLabData}
        targetLabResults={targetLabResults ?? []}
        formData={formData}
        formErrors={formErrors}
        onChangeFormData={handleChangeFormData}
      />
      <Stack direction="row" justifyContent="end" marginTop={2}>
        <Button
          style={{
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
            alignSelf: 'flex-end',
          }}
          onClick={onClose}
        >
          {i18n.t('app.close')}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ResultTableProps = {
  targetLabResults: LabProtocol['lab_protocols_results'];
  hasLabData: boolean;
  editMode: boolean;
  formData: Record<number, string>;
  formErrors: Record<number, string>;
  onChangeFormData: (determination: Determination, input: string) => void;
};
function ResultTable(props: ResultTableProps) {
  const { targetLabResults, hasLabData, editMode } = props;
  const { formData, formErrors, onChangeFormData } = props;

  return (
    <Box style={{ overflow: 'auto', height: '100%' }}>
      <TableContainer style={{ border: '1px solid #E2E5EC', height: '100%', minHeight: '400px' }}>
        <Table style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow style={{ borderCollapse: 'separate' }}>
              <TableCell style={{ width: '45%' }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.field')}
              </TableCell>
              <TableCell style={{ width: '25%' }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.result')}
              </TableCell>
              <TableCell style={{ width: '30%', borderLeft: '1px solid #E2E5EC', fontSize: 14 }}>
                {i18n.t('app.lab.detail_sample_modal.column_header.ref')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hasLabData ? (
              <>
                {targetLabResults.map((entry) => {
                  // Search for relevant data
                  const { lab_determinations } = entry;
                  const {
                    is_group_field,
                    lab_determinations_translations,
                    lab_unit: { lab_units_options, lab_units_translations },
                  } = lab_determinations;
                  const [determinationTranslation] = lab_determinations_translations;
                  const [unitTranslation] = lab_units_translations;

                  // Relevant data
                  const { id } = entry;
                  // const { result } = entry;
                  const { description, reference_values } = determinationTranslation;
                  const { symbol } = unitTranslation;
                  const hasOptions = lab_units_options?.length ?? -1 > 0;

                  // Preprocess data before render
                  const symbolDisplay = symbol ? ` (${symbol})` : '';
                  const descriptionDisplay = description
                    .toLocaleLowerCase()
                    .replace(/^.{1}/g, description[0].toUpperCase());
                  const fieldDisplay = descriptionDisplay + symbolDisplay;

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <Typography fontSize={12}>{fieldDisplay}</Typography>
                      </TableCell>
                      <TableCell padding={editMode ? 'none' : 'normal'}>
                        {!editMode ? (
                          <>{formData[lab_determinations.id]}</>
                        ) : (
                          <>
                            {hasOptions ? (
                              <OptionDropdown
                                determination={lab_determinations}
                                formData={formData}
                                onChangeFormData={onChangeFormData}
                              />
                            ) : (
                              <TextField
                                value={formData[lab_determinations.id]}
                                onChange={(e) => onChangeFormData(lab_determinations, e.target.value)}
                                error={Boolean(formErrors[lab_determinations.id])}
                                helperText={formErrors[lab_determinations.id]}
                                disabled={is_group_field}
                                sx={{
                                  '& .MuiInputBase-input': {
                                    height: '38px',
                                    fontSize: '0.875rem',
                                    padding: '0px 20px',
                                  },
                                }}
                              />
                            )}
                          </>
                        )}
                      </TableCell>
                      <TableCell>{reference_values}</TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={3} rowSpan={2} align="center">
                  {i18n.t('app.lab.detail_sample_modal.no_data')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

type OptionDropdownProps = {
  formData: Record<number, string>;
  determination: Determination;
  onChangeFormData: (determination: Determination, input: string) => void;
};
function OptionDropdown({ determination, onChangeFormData, formData }: OptionDropdownProps) {
  return (
    <Select
      value={formData[determination.id]}
      fullWidth
      onChange={(e) => onChangeFormData(determination, e.target.value)}
      sx={{
        '& .MuiSelect-select': {
          padding: '8px 14px',
          fontSize: '0.875rem',
        },
        '& .MuiInputBase-root': {
          height: '38px',
        },
      }}
    >
      {determination.lab_unit.lab_units_options.map((op) => {
        const { id, option } = op;
        return (
          <MenuItem key={id} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    height: '95vh',
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
