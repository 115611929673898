import { Paper, Typography, Box, Stack, Button, Tooltip } from '@mui/material';
import SiloID from '@assets/svg/siloreal/product-silo-id.svg';
import SiloIDDisabled from '@assets/svg/siloreal/product-silo-id-disabled.svg';
import SiloMetrix from '@assets/svg/siloreal/product-silo-metrix.svg';
import SiloMetrixDisabled from '@assets/svg/siloreal/product-silo-metrix-disabled.svg';
import SiloSat from '@assets/svg/siloreal/product-silo-sat.svg';
import SiloSatDisabled from '@assets/svg/siloreal/product-silo-sat-disabled.svg';
import i18n from '../../../../libs/language';
import { formatNumberWithSeparators } from '@utils/helper';
import copyIcon from '@assets/svg/copy.svg';
import { useState } from 'react';
import CheckIcon from '@assets/svg/new-check.svg';

type SectionSilobagProps = {
  data: any;
  isError: boolean;
};

export function SectionSilobag(prop: SectionSilobagProps) {
  const { data, isError } = prop;
  const specie_type = data?.silobag_species_types;
  const copyId = (id: string) => navigator.clipboard.writeText(id);
  const [idCopied, setIdCopied] = useState(false);

  const LOGO = {
    SiloID: (
      <img
        src={data?.silobag_data_status_id == 2 ? SiloID : SiloIDDisabled}
        style={{ width: 15, height: 15, marginLeft: '3px' }}
      />
    ),
    SiloMetrix: (
      <img
        src={data?.v_silobags_services_statuses?.silo_metrix_active ? SiloMetrix : SiloMetrixDisabled}
        style={{ width: 15, height: 15, marginLeft: '3px' }}
      />
    ),
    SiloSat: (
      <img
        src={data?.v_silobags_services_statuses?.sat_view_active ? SiloSat : SiloSatDisabled}
        style={{ width: 15, height: 15, marginLeft: '3px' }}
      />
    ),
  };

  return (
    <Paper elevation={0} sx={{ margin: '10px', borderRadius: '10px', padding: '15px', height: '270px' }}>
      <Typography variant="h6" fontWeight={500}>
        SiloReal
      </Typography>
      {isError ? (
        <Box>
          <p>error data sb</p>
        </Box>
      ) : (
        <Box sx={{ marginTop: '10px' }}>
          <Typography variant="h5" fontWeight={600} display={'flex'} alignContent={'center'}>
            {data?.name}
            <span style={{ marginLeft: '10px' }}>
              {' '}
              {LOGO.SiloID}
              {LOGO.SiloSat}
              {LOGO.SiloMetrix}
            </span>
          </Typography>
          <Stack direction={'row'} spacing={10} sx={{ marginTop: '20px' }}>
            <Typography variant="body1" fontWeight={600} fontSize={15}>
              <span style={{ color: '#A2A2A2', fontWeight: 300, marginRight: '5px', fontSize: '13px' }}>
                ID{' '}
              </span>{' '}
              {data?.id}{' '}
              <Tooltip title={i18n.t('app.copy') + ' ID'} placement="top">
                <Button
                  sx={{ padding: '3px', minWidth: 30, minHeight: 25 }}
                  onClick={() => {
                    setIdCopied(false);
                    copyId(data?.id).then(() => {
                      setTimeout(() => {
                        setIdCopied(true);
                      }, 100);
                    });
                  }}
                >
                  <img src={idCopied ? CheckIcon : copyIcon} style={{ width: 16, height: 16 }} />
                </Button>
              </Tooltip>
            </Typography>
            <Typography variant="body1" fontWeight={600} fontSize={15}>
              <span style={{ color: '#A2A2A2', fontWeight: 300, marginRight: '5px', fontSize: '13px' }}>
                {i18n.t('app.countryside')}
              </span>{' '}
              {data?.lots.countrysides.name}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={5} sx={{ marginTop: '20px' }}>
            <Typography variant="body1" fontWeight={600} fontSize={15}>
              <span style={{ color: '#A2A2A2', fontWeight: 300, marginRight: '5px', fontSize: '13px' }}>
                {i18n.t('app.silobag_data.lot_origin')}
              </span>{' '}
              {data?.silobags_origin_lots[0]?.lots?.name ?? '―'}
            </Typography>
            <Typography variant="body1" fontWeight={600} fontSize={15}>
              <span style={{ color: '#A2A2A2', fontWeight: 300, marginRight: '5px', fontSize: '13px' }}>
                {i18n.t('app.silobag_data.lot_origin')}
              </span>{' '}
              {data?.silobags_origin_lots[1]?.lots.name ?? '―'}
            </Typography>
            <Typography variant="body1" fontWeight={600} fontSize={15}>
              <span style={{ color: '#A2A2A2', fontWeight: 300, marginRight: '5px', fontSize: '13px' }}>
                {i18n.t('app.silobag_data.lot_origin')}
              </span>{' '}
              {data?.silobags_origin_lots[2]?.lots?.name ?? '―'}
            </Typography>
          </Stack>
          <Stack direction={'row'} spacing={10} sx={{ marginTop: '20px' }}>
            <Stack direction={'row'} spacing={1} mb={3} alignItems="center">
              <img
                src={specie_type?.image_url}
                alt={'image for silobag species type'}
                style={{ width: 40, height: 40 }}
              />
              <Stack direction={{ xs: 'column' }}>
                <Typography
                  color={'#49454F'}
                  fontWeight={600}
                  display={'block'}
                  width={'40px'}
                  fontSize={{ xs: '15px', md: '18px' }}
                >
                  {specie_type?.name}
                </Typography>
                <Typography style={{ fontSize: '13px', color: '#A2A2A2' }}>
                  {i18n.t('app.silobag_data.specie')}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction={'column'}>
              <Typography variant="body1" fontWeight={600} fontSize={18}>
                {formatNumberWithSeparators(+data?.input_weight)} Kg
              </Typography>
              <span
                style={{
                  color: '#A2A2A2',
                  fontWeight: 300,
                  marginRight: '10px',
                  fontSize: '13px',
                  display: 'block',
                }}
              >
                {i18n.t('app.silobag_data.weight_bagging')}
              </span>{' '}
            </Stack>
          </Stack>
        </Box>
      )}
    </Paper>
  );
}
