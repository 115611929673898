import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { VerifyResetAccountForm } from '@features/public/verify-reset-account/verify-reset-account-form';

export function VerifyResetAccount() { 
  return (
    <AuthLayoutComponent>
      <VerifyResetAccountForm />
    </AuthLayoutComponent>
  );
}
