import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { VerifyCredentialPayload, VerifiedCredentialData } from './verify-auth-credential.types';

// deprecated service ref.: verifyService_DEPRECATED
export async function verifyCredentialService({ body }: VerifyCredentialPayload) {
  const { response, data } = await BACKEND.post<VerifiedCredentialData>(ENDPOINT.VERIFY, { body });

  return {
    response,
    summary: data,
  };
}
