import { TrackedDates } from '@typed/core/base';
import { Prettify } from '@typed/extra/utils';

/* EXPORTING AREA */

// TYPE SECTION
export type LabUnitType = Prettify<RegistryData & TrackedDates & CoreData>;

// CONSTANT SECTION
export enum LabUnitTypeID {
  PERCENTAGE = 1,
  OPTIONS = 2,
  BOOLEAN = 3,
  DECIMAL = 4,
  INTEGER = 5,
  DATE = 6,
  STRING = 7,
}

/* PRIVATE TYPES AREA */

// CORE DATA SECTION
type CoreData = {
  min: number;
  max: number;
  comments: string;
};

// REGISTRY DATA
type LabUnitTypeRegID<TID, TVAL> = { id: TID; type: TVAL };
type RegistryData =
  | LabUnitTypeRegID<LabUnitTypeID.PERCENTAGE, 'PERCENTAGE'>
  | LabUnitTypeRegID<LabUnitTypeID.OPTIONS, 'OPTIONS'>
  | LabUnitTypeRegID<LabUnitTypeID.BOOLEAN, 'BOOLEAN'>
  | LabUnitTypeRegID<LabUnitTypeID.DECIMAL, 'DECIMAL'>
  | LabUnitTypeRegID<LabUnitTypeID.INTEGER, 'INTEGER'>
  | LabUnitTypeRegID<LabUnitTypeID.DATE, 'DATE'>
  | LabUnitTypeRegID<LabUnitTypeID.STRING, 'STRING'>;

// En lista de espera ❌
// lab_units_options: Partial<LabUnitOption>[];
