import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LanguageEditionPayload } from './language-update.types';

// deprecated service ref.: updateLanguageService_DEPRECATED

// ✅ Implementado
export async function updateUserLanguageService({ query: rawQuery, body }: LanguageEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put(ENDPOINT.USERS, { query, body });

  return {
    response,
    summary: data,
  };
}
