import {
  Box,
  Button,
  Chip,
  Divider,
  Fade,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import AppGridContainer from '@ui/app-grid-container';
import { GridColDef } from '@mui/x-data-grid-pro';
import { BaseTable } from '@ui/table';
import { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../../libs/language';
import { PATH } from '@data/enums';

import { formatDate } from '@utils/helper';
import ChevronRight from '@assets/svg/chevron-right.svg';
import BlueEyeIcon from '@assets/svg/core/eye-hollow-blue.svg';
import HelpIcon from '@assets/svg/helpIcon.svg';
import { deviceListService } from '@services/domain/page/report/device/report-device-info';

import WarningIcon from '@assets/svg/warning.svg';

type DeviceListDataProps = Awaited<ReturnType<typeof deviceListService>>['summary'];
type DevicesData = DeviceListDataProps['devices'];

interface DashboardDevicesProps {
  devices: DevicesData | undefined;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
  isLoading: boolean;
  rowCount: number;
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

const FONT_SIZE = 13;
const FONT_COLOR = '#49454F';
const FONT_WEIGHT = 600;

const formatDateRange = (toDate: Date) => {
  const today = new Date(toDate);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const twoWeeksAgo = new Date(today);
  twoWeeksAgo.setDate(today.getDate() - 13);

  return { today, yesterday, twoWeeksAgo };
};

export default function DashboardDevices({
  devices,
  paginationModel,
  setPaginationModel,
  isLoading,
  rowCount,
}: DashboardDevicesProps) {
  const rows = devices !== undefined ? devices : [];
  const [show, setShow] = useState(false);

  const [deviceDetail, setDeviceDetail] = useState({
    euiID: '',
    gateway_eui_id: '',
    state_gw: '',
    isInstalled: true,
  });

  const navigate = useNavigate();
  const openModal = (gateway_eui_id: string, eui_id: string, gatwayState: boolean, isInstalled: boolean) => {
    let stateGw = '―';

    if (gatwayState === false) stateGw = i18n.t('app.report_devices.deveui_modal.desconnected');
    if (gatwayState === true) stateGw = i18n.t('app.report_devices.deveui_modal.connected');

    setDeviceDetail({
      euiID: eui_id,
      gateway_eui_id: gateway_eui_id,
      state_gw: stateGw,
      isInstalled: isInstalled,
    });
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const devicesColumns: GridColDef<DevicesData[0]>[] = [
    {
      field: 'lance_number',
      minWidth: 100,
      maxWidth: 100,
      editable: false,
      renderHeader: () => headerTypography(i18n.t('app.name')),
      renderCell: (params) => (
        <Typography color={FONT_COLOR} fontSize={13}>
          {params.row.lance_number}
        </Typography>
      ),
    },
    {
      field: 'state',
      editable: false,
      sortable: false,
      minWidth: 120,
      renderHeader: () => headerTypography(i18n.t('app.report_devices.status')),
      renderCell: (params) => {
        const isInstalled = params.row.silobags_devices.length > 0;

        return (
          <Chip
            label={
              isInstalled
                ? i18n.t('app.report_devices.installed')
                : i18n.t('app.report_devices.not_installed')
            }
            style={{
              borderRadius: '10px',
              backgroundColor: isInstalled ? 'rgba(0, 123, 255, 0.10)' : '',
              gap: '10px',
              color: isInstalled ? '#22A7F0' : '#7A7A7A',
            }}
            color={isInstalled ? 'info' : 'default'}
          />
        );
      },
    },
    {
      field: 'reporting',
      headerName: '',
      editable: false,
      sortable: false,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        const isReporting = params.row.reporting;
        const isInstalled = params.row.silobags_devices.length > 0;

        return isInstalled ? (
          <Chip
            label={
              isReporting ? i18n.t('app.report_devices.reporting') : i18n.t('app.report_devices.delayed')
            }
            style={{
              borderRadius: '10px',
              border: 'none',
              backgroundColor: isReporting ? 'rgba(107, 170, 0, 0.15)' : 'rgba(255, 0, 0, 0.1)',
              color: isReporting ? '#73BD00' : '#FF0000',
            }}
            color={isReporting ? 'success' : 'error'}
            variant="outlined"
          />
        ) : null;
      },
    },
    {
      field: 'days_since_last_transmision',
      sortable: true,
      editable: false,
      align: 'center',
      flex: 1,
      renderHeader: () => headerTypography(i18n.t('app.report_devices.last_transmission')),
      renderCell: (params) => renderTransmissionStatus(params.row.days_since_last_transmision),
    },
    {
      field: 'id',
      editable: false,
      flex: 1,
      renderHeader: () => headerTypography('ID Silo'),
      renderCell: (params) =>
        params.row.silobags_devices.length > 0 ? (
          <Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
            <Stack
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'row'}
              spacing={1}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  color: '#05AADF',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(PATH.SILOBAG + '/' + params.row.silobags_devices[0].silobags.id)}
              >
                {params.row.silobags_devices[0].silobags.id}
              </Typography>
              <img src={ChevronRight} alt="go-to-silo" style={{ paddingTop: 5 }} />
            </Stack>
          </Box>
        ) : null,
    },
    {
      field: 'name',
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: () => headerTypography(i18n.t('app.report_devices.silobag_name')),
      renderCell: (params) => {
        const silobagName = params.row.silobags_devices[0]?.silobags.name.toString();

        return params.row.silobags_devices.length > 0 ? (
          <Typography color={FONT_COLOR} fontSize={13}>
            {silobagName}
          </Typography>
        ) : null;
      },
    },
    {
      field: 'countryside',
      sortable: false,
      editable: false,
      flex: 1,
      renderHeader: () => headerTypography(i18n.t('app.countryside')),
      renderCell: (params) => {
        const hasDevices = params.row.silobags_devices.length > 0;
        if (!hasDevices) return null;

        return (
          <Typography color={FONT_COLOR} fontSize={13}>
            {params.row.silobags_devices[0].silobags.countrysides.name}
          </Typography>
        );
      },
    },
    {
      field: 'battery_percent',
      width: 110,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: () => headerTypography(i18n.t('app.battery')),
      renderCell: (params) => {
        const measurementsToUse = params.row.check_all_measurements
          ? params.row.all_measurements
          : params.row.measurements;

        const battery_percent = measurementsToUse[0]
          ? measurementsToUse[0].battery_percent + '%'
          : i18n.t('app.report_devices.without_metrics');

        const font_color = measurementsToUse[0] ? '#49454F' : '#FF0000';

        return (
          <>
            <Typography variant="body2" color={font_color}>
              {battery_percent}
            </Typography>
          </>
        );
      },
    },
    {
      field: 'last_two_weeks_status',
      minWidth: 220,
      editable: false,
      sortable: false,
      flex: 1,
      renderHeader: () => headerTypography(i18n.t('app.report_devices.last_two_weeks')),
      renderCell: (params) => {
        const toDate = new Date();

        const { today, twoWeeksAgo } = formatDateRange(toDate);

        return params.row.silobags_devices.length > 0 ? (
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography color="#A7A7A7" fontSize={11}>
                {twoWeeksAgo.toLocaleDateString()}
              </Typography>
              <Typography color="#A7A7A7" fontSize={11}>
                {today.toLocaleDateString()}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1px',
                justifyContent: 'space-between',
                alignItems: 'end',
                borderLeft: '1px solid #A7A7A7',
                borderRight: '1px solid #A7A7A7',
                height: '16px',
              }}
            >
              <Box style={{ borderTop: '1px solid #A7A7A7', width: '3px', height: '100%' }} />
              {Object.keys(params.row.last_two_weeks_status)
                .reverse()
                .filter((key) => !key.includes('date'))
                .map((key) => {
                  const value = params.row.last_two_weeks_status[key as 'day_0'];
                  const date = formatDate(params?.row.last_two_weeks_status[(key + '_date') as 'day_0']);
                  return (
                    <Tooltip title={`Cantidad de métricas: ${value}, Dia: ${date}`} key={key}>
                      <Box
                        key={key}
                        style={{
                          width: '11px',
                          height: '11px',
                          margin: '0 1px 0 1px',
                          backgroundColor: value > 0 ? '#6baa00' : '#E2E5EC',
                          border: '1px solid',
                          borderColor: '#C5BFBF',
                          borderRadius: '2px',
                        }}
                      />
                    </Tooltip>
                  );
                })}
              <Box style={{ borderTop: '1px solid #A7A7A7', width: '3px', height: '100%' }} />
            </div>
          </div>
        ) : null;
      },
    },
    {
      field: 'modal',
      headerName: '',
      align: 'center',
      editable: false,
      maxWidth: 64,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const measurementsToUse = params.row.check_all_measurements
          ? params.row.all_measurements
          : params.row.measurements;

        const gatwayState = measurementsToUse[0]?.gateways?.gateway_states?.[0]?.state_gw;
        const gatewayEUI = measurementsToUse[0]?.gateway_eui_id ?? '—';
        const euiID = params.row?.eui_id ?? '—';
        const isInstalled = params.row.silobags_devices.length > 0;

        return (
          <IconButton>
            <img
              src={BlueEyeIcon}
              alt="eye-icon-to-open-modal"
              style={{ width: 18, height: 18 }}
              onClick={() => openModal(gatewayEUI, euiID, gatwayState, isInstalled)}
            />
          </IconButton>
        );
      },
    },
  ];

  return (
    <section id="data-table" style={{ marginTop: '1rem' }}>
      <AppGridContainer spacing={5}>
        <Grid item xs={12}>
          <Grid item xs={12} md={12}>
            <BaseTable
              rows={rows ?? []}
              columns={devicesColumns}
              initialPageSize={paginationModel?.pageSize ?? 50}
              paginationMode="server"
              paginationModel={paginationModel}
              setPaginationModel={setPaginationModel}
              loading={isLoading}
              rowCount={rowCount}
            />
          </Grid>
        </Grid>
      </AppGridContainer>
      <MyModal open={show} onClose={handleClose}>
        <Box>
          <Typography fontWeight={FONT_WEIGHT} fontSize={'24px'} color={'#49454F'}>
            {i18n.t('app.report_devices.deveui_modal.device_detail')}
          </Typography>
        </Box>
        <Stack direction={'row'} width={'100%'} display={'flex'} alignItems={'center'} spacing={12}>
          <Box width={'45%'}>
            <Typography sx={{ opacity: '85%' }}>DevEUI</Typography>
            <Typography>{deviceDetail.euiID}</Typography>
          </Box>
          <Box>
            <Typography sx={{ opacity: '85%' }}>
              {i18n.t('app.report_devices.deveui_modal.associated_gateway')}
            </Typography>
            <Typography>{deviceDetail.gateway_eui_id}</Typography>
          </Box>
        </Stack>
        <Stack direction={'row'} width={'100%'} display={'flex'} alignItems={'center'} spacing={12}>
          <Box width={'45%'}>
            <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
              <Typography sx={{ opacity: '85%', marginRight: 1 }}>
                {i18n.t('app.report_devices.deveui_modal.status_connection_gateway')}
              </Typography>
              <Tooltip title={i18n.t('app.report_devices.deveui_modal.gateway')} placement="top">
                <img src={HelpIcon} alt="help-icon" style={{ width: '18px', height: '18px' }} />
              </Tooltip>
            </Box>

            <Typography>{deviceDetail.state_gw}</Typography>
          </Box>
          <Box>
            <Typography>{i18n.t('app.report_devices.deveui_modal.other')}</Typography>
            <Typography>―</Typography>
          </Box>
        </Stack>
        {!deviceDetail.isInstalled && (
          <>
            <Divider />
            <Stack spacing={2}>
              <WarningSection
                icon={WarningIcon}
                title={i18n.t('app.report_devices.last_transmission')}
                message={i18n.t('app.report_devices.deveui_modal.warning_last_transmission')}
              />
              <WarningSection
                icon={WarningIcon}
                title={i18n.t('app.battery')}
                message={i18n.t('app.report_devices.deveui_modal.warning_battery')}
              />
            </Stack>
          </>
        )}

        <Box display={'flex'} justifyContent={'end'}>
          <Button onClick={handleClose}>Cerrar</Button>
        </Box>
      </MyModal>
    </section>
  );
}

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

const WarningSection = ({ icon, title, message }: { icon: string; title: string; message: string }) => (
  <Box>
    <Box display="flex" justifyContent="start" alignItems="center" gap={1}>
      <img src={icon} alt="warning-icon" />
      <Typography fontSize={{ xs: 16, sm: 18 }} color={FONT_COLOR} fontWeight={500}>
        {title}
      </Typography>
    </Box>
    <Typography fontSize={{ xs: 12, sm: 14 }} color={FONT_COLOR} fontWeight={400}>
      {message}
    </Typography>
  </Box>
);

const renderTransmissionStatus = (days: number | undefined) => {
  if (days !== undefined && days >= 0)
    return (
      <Typography color={FONT_COLOR} fontSize={FONT_SIZE}>
        {' '}
        {`${days} ${i18n.t('app.days')}`}
      </Typography>
    );
  return <Typography color="#FF0000">―</Typography>;
};

const headerTypography = (children: React.ReactNode) => (
  <Typography color={FONT_COLOR} fontSize={FONT_SIZE} fontWeight={FONT_WEIGHT}>
    {children}
  </Typography>
);
