import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { DeleteVinculateHeavyLogs, DeletetVinculateHeavyLogsResponse } from './delete-heavy-logs.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function deleteHeavyLogsCorvusService({ body, query: rawQuery }: DeleteVinculateHeavyLogs) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.delete<DeletetVinculateHeavyLogsResponse>(ENDPOINT.CORVUS_DELETE_HEAVYLOGS, { body, query });
  return {
    response,
    summary: data,
  };
}