//TODO: Solucion momentanea para la traduccion de las notificaciones
export const notificationsLabels = [
  {
    id: 3,
    name: 'Dióxido de Carbono (CO2)',
    description: 'Alerta generada al superar el valor umbral de CO2 permitido para la especie del silobolsa',
  },
  {
    id: 4,
    name: 'Movimiento (MOV)',
    description: 'Alerta generada al detectar movimiento de una lanza',
  },
  {
    id: 5,
    name: 'Humedad (HEG)',
    description:
      'Alerta generada al superar el valor umbral de humedad permitido para la especie del silobolsa',
  },
  {
    id: 6,
    name: 'Bateria (BAT)',
    description: 'Alerta generada al detectar un nivel de batería inferior al 20%',
  },
  {
    id: 7,
    name: 'Recorrida: Vencida',
    description:
      'Alerta generada por el vencimiento de la ultima recorrida realizada(transcurrieron mas días entre recorridas que el valor configurado)',
  },
  {
    id: 8,
    name: 'Recorrida: Resultado',
    description:
      'Alerta generada por el usuario al encontrar un silobolsa en condiciones de alerta al realizar una recorrida',
  },
  {
    id: 9,
    name: 'Existencia SiloSat',
    description: 'Alerta generada al no detectar un silobolsa con imagen satelital',
  },
];

export const notificationsLabelsPt = [
  {
    id: 3,
    name: 'Dióxido de Carbono (CO2)',
    description:
      'Alerta gerado ao ultrapassar o valor limite de CO2 permitido para a cultura armazenada no silo bolsa',
  },
  {
    id: 4,
    name: 'Movimento (MOV)',
    description: 'Alerta gerado ao detectar movimento no silo bolsa',
  },
  {
    id: 5,
    name: 'Umidade (HEG)',
    description:
      'Alerta gerado ao ultrapassar o valor limite de umidade permitido para a cultura armazenada no silo bolsa',
  },
  {
    id: 6,
    name: 'Bateria (BAT)',
    description: 'Alerta gerada ao detectar um nível de bateria inferior a 20%',
  },
  {
    id: 7,
    name: 'Vistoria: Atraso',
    description:
      'Alerta gerado pelo atraso da última vistoria prevista (passaram mais dias entre vistorias do que o sugerido)',
  },
  {
    id: 8,
    name: ' Vistoria: Avaliação',

    description: 'Alerta de atenção gerado pelo usuário ao avaliar um silo bolsa durante uma vistoria.',
  },
  {
    id: 9,
    name: 'Existência SiloSat',
    description: ' Alerta gerado ao não detectar um silo bolsa ativo através de imagem de satélite.',
  },
];
