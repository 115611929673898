import * as XLSX from 'xlsx';
import { ISilobags_DEPRECATED } from '@typed/types';
import { getProperty, formatDate } from './helper';
import i18n from '../../src/libs/language';
import { extractionListService } from '@services/domain/base/silobag/extraction/extraction-list';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';

const determineSilobagStatus = (isActive: boolean, isPartiallyExtracted: boolean) => {
  if (isActive && !isPartiallyExtracted) {
    return 'Activo';
  } else if (isActive && isPartiallyExtracted) {
    return 'Baja Parcial';
  } else {
    return 'Cerrada';
  }
};

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];

export const GenerateSilobagExcelData = (data: SummarySilobagList | string[], FileName: string) => {
  const columnMapping: Record<keyof SummarySilobagList[0], string> = {
    id: 'ID',
    name: i18n.t('app.name'),
    bagging_date: i18n.t('app.silobag_data.bagging_date2'),
    created_at: i18n.t('app.register_date'),
    'silobag_species_types.name': i18n.t('app.silobag_data.species'),
    'silobag_product_types.name': i18n.t('app.silobag_data.product'),
    variety: `${i18n.t('app.silobag_data.variety')} (%)`,
    main_lat: i18n.t('app.silobag_data.lat'),
    main_lon: i18n.t('app.silobag_data.long'),
    input_weight: `${i18n.t('app.silobag_data.weight_bagging')} (Kg)`,
    remaining_stock: `${i18n.t('app.field_weigth')} (Kg)`,
    total_extracted_weight: `${i18n.t('app.total_extracted_weigth')}`,
    Diferencia: `${i18n.t('app.silobag_detail.difference')}`,
    initial_humidity: `${i18n.t('app.state_stock_page.humidity')} (%)`,
    size: `${i18n.t('app.silobag_data.size')} (m)`,
    'silobag_harvests.name': `${i18n.t('app.silobag_data.harvest')}`,
    'lots.countrysides.name': `${i18n.t('app.countryside')}`,
    'lots.name': `${i18n.t('app.silobag_data.lot_stock')}`,
    lote_origen: `${i18n.t('app.silobag_data.lot_origin')}`,
    responsible_for_bagging: `${i18n.t('app.silobag_data.bagging_responsible')}`,
    silobag_brand: `${i18n.t('app.slobag_brand')}`,
    'humidity_sources.description': `${i18n.t('app.humidity_sources')}`,
    'weight_sources.description': `${i18n.t('app.weight_sources')}`,
    // 'silobag_data_statuses.name': 'Estado de Carga',
    fillying_coefficient: `${i18n.t('app.fillying_coefficient')}`,
    calculated_coefficient: `${i18n.t('app.calculated_coefficient')}`,
    observations: `${i18n.t('app.observations')}`,
    silobag_status: `${i18n.t('app.silobag_status')}`,
    'silobag_data_statuses.id': `${i18n.t('app.silobag_data_statuses')}`,
  };
  const extractedData: any[] = [];

  data.forEach((item: ISilobags_DEPRECATED) => {
    // Create an extracted item for each item in filteredData
    const extractedItem: { [key: string]: any } = {};

    for (let property in columnMapping) {
      const value = getProperty(item, property);

      if (
        // property === "confection_date" ||
        property === 'created_at' ||
        property === 'bagging_date'
      ) {
        extractedItem[columnMapping[property]] = value ? formatDate(value) : '-';
      } else if (property === 'silobags_origin_lots.lots') {
        // Handle the special case as needed
      } else if (property === 'silobags_devices.devices') {
        // Handle the special case as needed
      } else if (
        property === 'input_weight' ||
        property === 'remaining_stock' ||
        property === 'total_extracted_weight'
      ) {
        // Handle numeric values
        const formattedValue =
          value !== undefined && value !== null && value !== '' ? parseFloat(value) : '-';
        extractedItem[columnMapping[property]] = formattedValue;
      } else if (
        property === 'initial_humidity' ||
        property === 'varietal_purity' ||
        property === 'split_grain_percentage'
      ) {
        // Handle numeric values
        const formattedValue =
          value !== undefined && value !== null && value !== '' ? parseFloat(value) : '-';
        extractedItem[columnMapping[property]] = formattedValue;
      } else if (property === 'silobag_status') {
        const silobagStatus = determineSilobagStatus(item.active, item.partially_extracted);
        extractedItem[columnMapping[property]] = silobagStatus;
      } else if (property === 'silobag_data_statuses.id') {
        const silobagStatus = item?.silobag_data_statuses?.id === 1 ? i18n.t('app.no') : i18n.t('app.yes');
        extractedItem[columnMapping[property]] = silobagStatus;
      } else {
        // Handle other properties
        extractedItem[columnMapping[property]] =
          value !== undefined && value !== null && value !== '' ? value : '-';
      }
    }

    const inputWeight = getProperty(item, 'input_weight');
    const size = getProperty(item, 'size');

    const totalExtractedWeight = getProperty(item, 'total_extracted_weight');
    const diferencia =
      inputWeight !== '-' && totalExtractedWeight !== '-' ? inputWeight - totalExtractedWeight : '-';
    extractedItem[`${i18n.t('app.silobag_detail.difference')}`] = diferencia;

    const calculated_coefficient = parseFloat(inputWeight) / parseFloat(size);

    extractedItem[`${i18n.t('app.calculated_coefficient')}`] = !Number.isNaN(calculated_coefficient)
      ? calculated_coefficient.toFixed(0)
      : '-';

    let siloOriginLots = item.silobags_origin_lots.map((item: any) =>
      item && item.lots ? item.lots.name : null
    );

    let loteOrigen = siloOriginLots.join(', ');

    extractedItem[`${i18n.t('app.silobag_data.lot_origin')}`] = loteOrigen;

    extractedData.push(extractedItem);
  });

  const ws = XLSX.utils.json_to_sheet(extractedData, {
    header: Object.values(columnMapping),
  });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Silobags');
  XLSX.writeFile(wb, FileName + '.xlsx');
};

type ExtractionListServiceType = Awaited<ReturnType<typeof extractionListService>>['summary'];
type DataType = ExtractionListServiceType[0];
type ItemType = ExtractionListServiceType[0]['silobags']['silobags_origin_lots'][0];

export const GenerateExtractionsExcelData = async (response: ExtractionListServiceType, siloID?: string) => {
  const extractionData = siloID ? response.filter((data: DataType) => data.silobags.id === siloID) : response;

  const tabla = [
    {
      A: 'ID',
      B: i18n.t('app.silobag_name'),
      C: i18n.t('app.organization'),
      D: i18n.t('app.countryside'),
      E: i18n.t('app.silobag_data.lot_stock'),
      F: i18n.t('app.silobag_data.lot_origin'),
      G: i18n.t('app.silobag_data.specie'),
      H: i18n.t('app.state_stock_page.humidity'),
      I: i18n.t('app.status'),
      J: i18n.t('app.silobags_extractions.extraction_date'),
      K: i18n.t('app.public_pages.user'),
      L: i18n.t('app.silobags_extractions.freight_brok'),
      M: i18n.t('app.silobags_extractions.transport'),
      N: i18n.t('app.silobags_extractions.driver'),
      O: i18n.t('app.silobags_extractions.chassis_patent'),
      P: i18n.t('app.silobags_extractions.chassis_attached'),
      Q: i18n.t('app.silobags_extractions.ctg'),
      R: i18n.t('app.silobags_extractions.cp'),
      S: i18n.t('app.silobags_extractions.contract'),
      T: i18n.t('app.silobags_extractions.destiny'),
      U: i18n.t('app.silobags_extractions.gross_weight'),
      V: i18n.t('app.silobags_extractions.tara'),
      W: i18n.t('app.silobags_extractions.liquid'),
      X: i18n.t('app.observations'),
      Y: i18n.t('app.silobag_data_statuses'),
    },
  ];

  extractionData.forEach((silos: DataType) => {
    const siloOriginLots = silos.silobags.silobags_origin_lots.map((item: ItemType) => item.lots?.name);
    const loteOrigen = siloOriginLots.join(', ');

    tabla.push({
      A: silos.silobags.id,
      B: silos.silobags?.name,
      C: silos.silobags.organizations.name,
      D: silos.silobags.countrysides.name,
      E: silos.silobags.lots.name,
      F: loteOrigen,
      G: silos.silobags.silobag_species_types.silobag_species_types_translations[0].description,
      H: silos.silobags.initial_humidity?.toString() || '',
      I: silos.silobags.active ? 'Activo' : 'Cerrado',
      J: formatDate(silos.extraction_date.toString())?.toString() || '',
      K: silos.users.fullname,
      L: silos.freight_transport || '',
      M: silos.carrier || '',
      N: silos.driver || '',
      O: silos.back_plate || '',
      P: silos.trailer_back_plate || '',
      Q: silos.ctg_document || '',
      R: silos.waybill || '',
      S: silos.contract || '',
      T: silos.destination || '',
      U: silos.gross_weight?.toString() || '',
      V: silos.truck_weight?.toString() || '',
      W: silos.weight?.toString() || '',
      X: silos.observations || '',
      Y: silos.silobags.silobag_data_status_id === 1 ? i18n.t('app.no') : i18n.t('app.yes'),
    });
  });

  const dataFinal = [...tabla];

  const libro = XLSX.utils.book_new();
  const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

  XLSX.utils.book_append_sheet(libro, hoja, 'Extracciones');

  XLSX.writeFile(libro, i18n.t('app.silobags_extractions.extractions') + '.xlsx');
};

export const downloadPdf = async (
  getPdf: (params?: string) => void,
  siloId?: string,
  data?: any,
  isLoading?: boolean
) => {
  await getPdf(siloId);
  if (!isLoading && data) {
    const link = document.createElement('a');
    link.href = data.file_path;
    link.download = `${data.file_path}`;
    link.click();
  }
};
