import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDate } from '@utils/helper';
import { Stack, Box, Typography, IconButton, Tooltip } from '@mui/material';
import IconSiloID from '@assets/svg/siloreal/product-silo-id.svg';
import IconSiloMetrix from '@assets/svg/siloreal/product-silo-metrix.svg';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums';
import NewReleaseIcon from '@assets/svg/SiloBagPage/new_releases.svg';
import NewReleaseIconDisabled from '@assets/svg/SiloBagPage/new-release-disabled.svg';
import { useState } from 'react';
import i18n from '../../libs/language';
import { lancePositions } from '@utils/helper';
import { PoeModal } from '@features/silobags/silobag-detail/section-history/poe-silobag-modal';
import BlueViewIcon from '@assets/svg/SiloBagPage/view/eye-blue.svg';
import { Dispatch, SetStateAction } from 'react';
import ChevronRight from '@assets/svg/chevron-right.svg';
import { stockStatusListService } from '@services/domain/page/report/status-stock/report-status-stock.info';

type SummaryType = Awaited<ReturnType<typeof stockStatusListService>>['summary'];

type StateStockProps = {
  data: SummaryType['rows'];
  isLoading: boolean;
  count: number;
  paginationModel: any;
  setPaginationModel: Dispatch<SetStateAction<any>>;
};

export const TabletStateStock = (props: StateStockProps) => {
  const { data, isLoading, count, paginationModel, setPaginationModel } = props;
  const navigate = useNavigate();
  const [modalPoe, setModalPoe] = useState(false);
  const [eventId, setEventId] = useState();
  const [dataPoe, setDataPoe] = useState();

  const stateDataParsed = data?.map((item) => ({
    ...item,
    initial_humidity:
      typeof item.initial_humidity === 'string' ? parseFloat(item.initial_humidity) : item.initial_humidity,
    first_sbd_first_measurement_heg:
      item.first_sbd_first_measurement_heg !== null ? parseFloat(item.first_sbd_first_measurement_heg) : null,
    second_sbd_first_measurement_heg:
      item.second_sbd_first_measurement_heg !== null
        ? parseFloat(item.second_sbd_first_measurement_heg)
        : null,
    third_sbd_first_measurement_heg:
      item.third_sbd_first_measurement_heg !== null ? parseFloat(item.third_sbd_first_measurement_heg) : null,
  }));

  const handleOpenPoe = (event_id: any, data: any) => {
    setModalPoe(true);
    setEventId(event_id);
    setDataPoe(data);
  };
  //Objeto con los estados de los POE || recorrida
  const PoeStatus: any = {
    1: { status: i18n.t('app.state_stock_page.aproved'), color: '#73BD00' },
    2: { status: i18n.t('app.state_stock_page.caution'), color: '#E6EA02' },
    3: { status: i18n.t('app.state_stock_page.risk_elevate'), color: 'orange' },
    4: { status: i18n.t('app.state_stock_page.critical'), color: 'red' },
  };
  //funcion para generar el contenido del tooltip
  const generateTooltipContent = (
    lanceNumber: string,
    position: string,
    date: string,
    lastMetric: number | null
  ) => {
    const contentTooltip = {
      lanceNumber: i18n.t('app.state_stock_page.lance_id') + ': ' + lanceNumber,
      position: i18n.t('app.state_stock_page.position') + ': ' + lancePositions[position],
      date: i18n.t('app.date') + ': ' + formatDate(date, true),
      lastMetric:
        lastMetric !== null
          ? i18n.t('app.state_stock_page.last_metric') + lastMetric + '%'
          : i18n.t('app.state_stock_page.no_last_metric'),
    };

    return Object.entries(contentTooltip).map(([key, value]) => <p key={key}>{value}</p>);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      sortable: false,
      flex: 0.8,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          ID Silo
        </Typography>
      ),
      renderCell: (params) => {
        const siloId = params.value;
        return (
          <Box component="div" style={{ display: 'flex', alignItems: 'center' }}>
            <Stack
              width={'100%'}
              alignItems={'center'}
              justifyContent={'center'}
              direction={'row'}
              spacing={1}
            >
              <Typography
                variant="body2"
                style={{
                  fontWeight: 500,
                  fontSize: '13px',
                  color: '#05AADF',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`${PATH.SILOBAG}/${siloId}`)}
              >
                {siloId}
              </Typography>
              <img src={ChevronRight} alt="go-to-silo" style={{ paddingTop: 5 }} />
            </Stack>
          </Box>
        );
      },
    },
    {
      field: 'silobag_name',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.silobag')}
        </Typography>
      ),
      renderCell: (params) => {
        const silobagName = params.value;
        return (
          <Typography color={'#49454F'} fontSize={13}>
            {silobagName}
          </Typography>
        );
      },
    },
    {
      field: 'countryside_name',
      flex: 1.4,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.countrysides')}
        </Typography>
      ),
      renderCell: (params) => {
        const countrysideName = params.value;
        return (
          <Typography color={'#49454F'} fontSize={13}>
            {countrysideName}
          </Typography>
        );
      },
    },
    {
      field: 'bagging_date',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'end-grouping-cell',
      cellClassName: 'end-grouping-cell',
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.date_bagging')}
        </Typography>
      ),
      renderCell: (params) => {
        const certificationSiloSat = params.row.bagging_date_matches;
        const baggingDate = params.value;
        return (
          <Box>
            {baggingDate !== null && (
              <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                <Typography color="#49454F" fontSize={13}>
                  {formatDate(params.value as string)}{' '}
                </Typography>
                {certificationSiloSat !== null && (
                  <Tooltip
                    title={
                      certificationSiloSat
                        ? i18n.t('app.state_stock_page.certificate_silo_sat')
                        : i18n.t('app.state_stock_page.no_certificate_silo_sat')
                    }
                  >
                    <img
                      src={certificationSiloSat ? NewReleaseIcon : NewReleaseIconDisabled}
                      style={{ width: 16, height: 16 }}
                    />
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
        );
      },
    },
    {
      field: 'first_poe_timestamp',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.date_poe')}
        </Typography>
      ),
      renderCell: (params) => {
        const isPoeExpired = params.row.is_poe_expired;
        const poeTimestamp = params.value;

        const COLOR = { NORMAL: '#49454F', EXPIRED: '#FF0000' };
        const colorToShow = isPoeExpired ? COLOR.EXPIRED : COLOR.NORMAL;

        return (
          <Stack>
            <Typography color={colorToShow} fontSize={13}>
              {formatDate(poeTimestamp)}
            </Typography>
            {isPoeExpired && (
              <Typography color={colorToShow} fontSize={11}>
                {i18n.t('app.state_stock_page.expired')}
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'first_poe_status_id',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.result')}
        </Typography>
      ),
      renderCell: (params) => {
        const fstStatus = PoeStatus[params.value]?.status;
        const sndStatus = params.row.second_poe_status_id;

        const statusColor = PoeStatus[params.value]?.color;

        return (
          <Stack>
            <Stack direction="row" spacing={0.3} alignItems="center">
              <Box
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: statusColor,
                  borderRadius: '50%',
                  marginRight: '3px',
                }}
              />
              <Typography color={statusColor} fontSize={13}>
                {fstStatus}
              </Typography>
            </Stack>
            {sndStatus ? (
              <Typography color="#A1A1A0" fontSize={11}>
                {i18n.t('app.state_stock_page.former')} {PoeStatus[sndStatus]?.status}
              </Typography>
            ) : null}
          </Stack>
        );
      },
    },
    {
      field: 'first_poe_id',
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      flex: 0.6,
      headerClassName: 'end-grouping-cell',
      cellClassName: 'end-grouping-cell',
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.detail')}
        </Typography>
      ),
      renderCell: (params) => {
        const poeID = params.value;
        const poeData = poeID !== null ? [params.row.first_poe] : null;
        return (
          <>
            {poeID !== null ? (
              <IconButton onClick={() => handleOpenPoe(poeID, poeData)}>
                <img src={BlueViewIcon} alt="view-icon" style={{ width: '16px', height: '16px' }} />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'initial_humidity',
      flex: 1.2,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.silobag_data.initial_humidity')}
        </Typography>
      ),
      renderCell: (params) => {
        const initialHumidity = params.value;
        return (
          <Typography color="#49454F" fontSize={13}>
            {initialHumidity ? `${initialHumidity} %` : null}
          </Typography>
        );
      },
    },
    {
      field: 'first_sbd_first_measurement_heg',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.humidity')}
        </Typography>
      ),
      renderCell: (params) => {
        const firstHeg = parseFloat(params.value);
        const secondHeg = params.row.first_sbd_second_measurement_heg;
        const lanceNumber = params.row.first_sbd_lance_number;
        const position = params.row.first_sbd_position;
        const firstMeasurement = params.row.first_sbd_first_measurement_created_at;
        //genero el contenido del tooltip
        const tooltipContentMap = generateTooltipContent(lanceNumber, position, firstMeasurement, secondHeg);
        return (
          <>
            {firstHeg ? (
              <Tooltip title={tooltipContentMap}>
                <>
                  <Typography color="#49454F" fontSize={13}>
                    {firstHeg} %
                  </Typography>
                  <Box
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: params.row.first_sbd_heg_hex,
                      borderRadius: '50%',
                      marginLeft: '6px',
                    }}
                  />
                </>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'second_sbd_first_measurement_heg',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.humidity')}
        </Typography>
      ),
      renderCell: (params) => {
        const firstHeg = parseFloat(params.value);
        const secondHeg = params.row.second_sbd_second_measurement_heg;
        const lanceNumber = params.row.second_sbd_lance_number;
        const position = params.row.second_sbd_position;
        const firstMeasurement = params.row.second_sbd_first_measurement_created_at;
        const tooltipContentMap = generateTooltipContent(lanceNumber, position, firstMeasurement, secondHeg);
        return (
          <>
            {firstHeg ? (
              <Tooltip title={tooltipContentMap}>
                <>
                  <Typography color="#49454F" fontSize={13}>
                    {firstHeg}%{' '}
                  </Typography>
                  <Box
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: params.row.second_sbd_heg_hex,
                      borderRadius: '50%',
                      marginLeft: '6px',
                    }}
                  />
                </>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'third_sbd_first_measurement_heg',
      flex: 1,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.state_stock_page.humidity')}
        </Typography>
      ),
      renderCell: (params) => {
        const firstHeg = parseFloat(params.value);
        const secondHeg = parseFloat(params.row.third_sbd_second_measurement_heg);
        const lanceNumber = params.row.last_sbd_lance_number;
        const position = params.row.third_sbd_position;
        const firstMeasurement = params.row.third_sbd_first_measurement_created_at;
        const tooltipContentMap = generateTooltipContent(lanceNumber, position, firstMeasurement, secondHeg);
        return (
          <>
            {firstHeg ? (
              <Tooltip title={tooltipContentMap}>
                <>
                  <Typography color="#49454F" fontSize={13}>
                    {firstHeg}%{' '}
                  </Typography>
                  <Box
                    style={{
                      height: '10px',
                      width: '10px',
                      backgroundColor: params.row.third_sbd_heg_hex,
                      borderRadius: '50%',
                      marginLeft: '6px',
                    }}
                  />
                </>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <Box style={{ marginTop: '20px', backgroundColor: 'white', borderRadius: '10px' }}>
      <BaseTable
        loading={isLoading}
        rows={stateDataParsed}
        columns={columns}
        initialPageSize={paginationModel?.pageSize ?? 50}
        paginationMode="server"
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        rowCount={count}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={[
          {
            groupId: 'silobag',
            description: 'Silobag',
            headerClassName: 'end-grouping-cell',
            children: [
              { field: 'id' },
              { field: 'silobag_name' },
              { field: 'countryside_name' },
              { field: 'bagging_date' },
            ],
            renderHeaderGroup: () => (
              <Typography color="#49454F" fontSize={13} fontWeight={600}>
                {i18n.t('app.silobag')}
              </Typography>
            ),
          },
          {
            groupId: 'silo-id',
            description: 'Silo ID',
            headerClassName: 'end-grouping-cell',
            children: [
              { field: 'first_poe_timestamp' },
              { field: 'first_poe_status_id' },
              { field: 'first_poe_id' },
            ],
            renderHeaderGroup: () => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                  <img src={IconSiloID} alt="siloID-icon" style={{ width: 18, height: 18 }} />
                  <Typography color="#6baa00" display="flex" alignItems="center">
                    SILO <b>ID</b>
                  </Typography>
                </Stack>
                <Typography color={'#49454F'} fontSize={13}>
                  {i18n.t('app.poe_round')}
                </Typography>
              </Stack>
            ),
          },
          {
            groupId: 'silo-metrix',
            description: 'Silo Metrix',
            children: [
              { field: 'initial_humidity' },
              { field: 'first_sbd_first_measurement_heg' },
              { field: 'second_sbd_first_measurement_heg' },
              { field: 'third_sbd_first_measurement_heg' },
            ],
            renderHeaderGroup: () => (
              <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
                  <img src={IconSiloMetrix} alt="siloMetrix-icon" style={{ width: 18, height: 18 }} />
                  <Typography color="orange" display="flex" alignItems="center">
                    SILO <b>METRIX</b>
                  </Typography>
                </Stack>
                <Typography color={'#49454F'} fontSize={13}>
                  {i18n.t('app.state_stock_page.humidity')}
                </Typography>
              </Stack>
            ),
          },
        ]}
        sx={{
          '& .end-grouping-cell': { borderRight: '1px solid rgba(224, 224, 224, 1)' },
          '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
            border: 'none', // remove header grouping bottom border
          },
        }}
      />
      {PoeModal && <PoeModal open={modalPoe} setOpen={setModalPoe} data={dataPoe} poeId={eventId} />}
    </Box>
  );
};
