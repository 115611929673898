import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { DeletedFileData, FileDeletionPayload } from './file-deletion.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: deleteFiles_DEPRECATED
export async function removeFileService({ query: rawQuery }: FileDeletionPayload) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.delete<DeletedFileData>(ENDPOINT.FILES, { query });

  return {
    response,
    summary: data,
  };
}
