import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { ProductListResponse } from './product-list.types';

// deprecated service ref.: getProductsService_DEPRECATED

// ✅ Implementado
export async function productListService() {
  const { response, data } = await BACKEND.get<ProductListResponse>(ENDPOINT.SILOBAG_PRODUCT);

  return {
    response,
    summary: data,
  };
}
