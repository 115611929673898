import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { SilobagOptionsPayload, SilobagOptionsData } from './silobag-option-list.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: silobagsSelectService_DEPRECATED

// Implementado ✅
export async function silobagOptionListService({ query: rawQuery }: SilobagOptionsPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<SilobagOptionsData>(ENDPOINT.DEVICES_REPORT_SILOBAGS, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
