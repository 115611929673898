import { ModalIOF } from '@ui/modal/modal';
import {
  Typography,
  Stack,
  TextField,
  Chip,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  InputLabel,
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material';
import i18n from '../../../libs/language';
import { useState } from 'react';
import { isValidEmail, getPermissionDefaultValue, getRandomColor } from '@utils/helper';
import { stylesInput } from '@styles/generic-styles';
import { configButtonStylesContained } from '@styles/generic-styles';
import { useService } from '@hooks/use-service';
import { GetCountrysidePermissionService } from '@services/domain/base/countryside/countryside-permission-get';
import { PostCountrysidePermissionService } from '@services/domain/base/countryside/countryside-permission-post';
import ChevronDown from '@assets/svg/chevron-down.svg';
import { ToastAction } from '@ui/toast/toast-action';
import InviteIcon from '@assets/svg/core/invite.svg';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { ChangeEvent, KeyboardEvent } from 'react';

type SummaryCountryside = Awaited<ReturnType<typeof listCountrysideService>>['summary'][0];

type CountrysideShareModalProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  countryside: SummaryCountryside;
};
export default function CountrysideShareModal(props: CountrysideShareModalProps) {
  const { open, setOpen, countryside } = props;
  const { id: countrysideId, name } = countryside;
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [permissionValue, setPermissionValue] = useState(0);

  const {
    summary,
    trigger: getPermissions,
    isLoading,
  } = useService(GetCountrysidePermissionService, { fetchOnMount: [{ query: countrysideId }] });

  const {
    trigger: postPermissions,
    isError,
    isSuccess,
  } = useService(PostCountrysidePermissionService, {
    onSuccess: () => {
      getPermissions({ query: countrysideId });
      setEmails([]);
      setPermissionValue(0);
    },
  });

  const response = summary?.response;
  //User with permission inherited
  const userWithPermisionInherited = response && response.filter((user) => user.inherited === true);
  // User with permission not inherited
  const userWithPermisionNotInherited = response && response.filter((user) => user.inherited === false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ' ' || event.key === ',') {
      event.preventDefault();
      const email = inputValue.trim();
      if (email && isValidEmail(email) && !emails.includes(email)) {
        setEmails([...emails, email]);
        setInputValue('');
      }
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    setEmails(emails.filter((email: string) => email !== emailToDelete));
  };

  const SelectOnchange = (event: SelectChangeEvent) => {
    setPermissionValue(+event.target.value);
  };

  const transformEmailsToPermissions = (emails: string[], permissionValue: number) => {
    return emails.map((email) => ({
      email,
      read_permission: permissionValue == 1,
      write_permission: permissionValue == 2,
      full_permission: permissionValue == 3,
    }));
  };

  const ShareCountryside = (permissionValue: number, emails: string[]) => {
    const permissionsArray = transformEmailsToPermissions(emails, permissionValue);
    postPermissions({ query: countrysideId, body: permissionsArray });
  };

  return (
    <>
      <ModalIOF open={open} ModalIsOpen={setOpen}>
        <ModalIOF.Header>
          <ModalIOF.Title>
            <Typography variant="body1" sx={{ fontWeight: '300', fontSize: '1.5rem' }}>
              {i18n.t('app.countryside_share.share_countryside')}
              <span style={{ fontWeight: 600 }}> {name}</span>
            </Typography>
          </ModalIOF.Title>
        </ModalIOF.Header>
        {isLoading && !summary ? (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Stack>
        ) : (
          <ModalIOF.Body>
            <Typography variant="body2" sx={{ color: 'red', fontSize: '12px', marginBottom: '10px' }}>
              {i18n.t('app.silobag_share.press_enter_or_space')}
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <Box>
                <TextField
                  label={i18n.t('app.silobag_share.ingress_users')}
                  variant="outlined"
                  value={inputValue}
                  onChange={handleInputChange}
                  sx={{ width: '300px' }}
                  size="small"
                  onKeyDown={handleInputKeyDown}
                />
                {emails.map((email: string) => (
                  <Chip
                    key={email}
                    label={email}
                    onDelete={() => handleDeleteEmail(email)}
                    sx={{ margin: '5px' }}
                  />
                ))}
              </Box>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">
                  {i18n.t('app.silobag_share.type_permission')}
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="Permission_id"
                  input={<OutlinedInput label={i18n.t('app.silobag_share.type_permission')} />}
                  onChange={SelectOnchange}
                  value={permissionValue.toString()}
                >
                  <MenuItem value={1}>
                    <Box display={'flex'} flexDirection="column">
                      <Typography variant="body1">{i18n.t('app.silobag_share.view')}</Typography>
                      {permissionValue !== 1 && (
                        <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                          {i18n.t('app.silobag_share.view_description')}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                  <MenuItem value={2}>
                    <Box display={'flex'} flexDirection="column">
                      <Typography variant="body1">{i18n.t('app.silobag_share.edit')}</Typography>
                      {permissionValue !== 2 && (
                        <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                          {i18n.t('app.silobag_share.edit_description')}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                  <MenuItem value={3}>
                    <Box display={'flex'} flexDirection="column">
                      <Typography variant="body1">{i18n.t('app.silobag_share.access_complete')}</Typography>
                      {permissionValue !== 3 && (
                        <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                          {i18n.t('app.silobag_share.access_complete_description')}
                        </Typography>
                      )}
                    </Box>
                  </MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                sx={configButtonStylesContained}
                style={{ width: '250px', maxHeight: '40px' }}
                disabled={emails.length === 0 || permissionValue === 0}
                onClick={() => ShareCountryside(permissionValue, emails)}
              >
                {i18n.t('app.silobag_share.share')}
              </Button>
            </Stack>
            <Box>
              <Box>
                <Typography sx={{ fontSize: '12px', marginTop: '10px' }}>
                  {i18n.t('app.silobag_share.user_access')}
                </Typography>
              </Box>
            </Box>
            <Accordion
              sx={{
                borderRadius: '10px',
                marginTop: '10px',
                boxShadow: 'none',
                display: (userWithPermisionInherited?.length || 0) < 0 ? 'none' : 'block',
              }}
            >
              <AccordionSummary>
                <Stack direction="row" justifyContent={'space-between'}>
                  <Box>
                    <Typography variant="body1">Todos en Organizacion</Typography>
                    <Typography sx={{ fontSize: '12px' }}>
                      {userWithPermisionInherited?.length} {i18n.t('app.silobag_share.user_with_permission')}
                      <span>
                        {' '}
                        <img src={ChevronDown} alt="" />
                      </span>
                    </Typography>
                  </Box>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {userWithPermisionInherited?.map((user, index: number) => (
                  <Stack direction={'row'} key={user.users.id}>
                    <span>
                      <Avatar
                        alt={user.users.email}
                        sx={{
                          width: '30px',
                          height: '30px',
                          margin: '5px',
                          fontSize: '12px',
                          bgcolor: getRandomColor(index),
                        }}
                      >
                        {user.users.email[0].toUpperCase()}
                      </Avatar>
                    </span>
                    <Typography key={user.users.id} variant="body1" sx={{ margin: '5px' }}>
                      {user.users.email}
                    </Typography>
                  </Stack>
                ))}
              </AccordionDetails>
            </Accordion>
            {userWithPermisionNotInherited &&
              userWithPermisionNotInherited.length > 0 &&
              userWithPermisionNotInherited.map((userPermission, index: number) => (
                <Box style={{ marginTop: '10px' }} key={userPermission.permission_id}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Avatar
                        alt={userPermission.users.email}
                        sx={{
                          width: '30px',
                          height: '30px',
                          margin: '5px',
                          fontSize: '13px',
                          bgcolor: getRandomColor(index),
                        }}
                      >
                        {userPermission.users.email[0].toUpperCase()}
                      </Avatar>
                      <Typography variant="body1" sx={{ margin: '5px', fontSize: '14px' }}>
                        {userPermission.users.email}
                      </Typography>
                      {userPermission.is_guest && (
                        <Chip
                          label="Invitado"
                          icon={<img src={InviteIcon} alt="" />}
                          sx={{
                            margin: '5px',
                            fontSize: '10px',
                            bgcolor: '#6BAA0026',
                            color: '#6baa00',
                            fontWeight: 600,
                            borderRadius: '4px',
                          }}
                        />
                      )}
                    </Stack>
                    <FormControl variant="outlined" size="small" style={{ width: '35%' }} sx={stylesInput}>
                      <InputLabel id="demo-multiple-name-label">
                        {i18n.t('app.silobag_share.type_permission')}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="Permission_id"
                        input={<OutlinedInput label={i18n.t('app.silobag_share.type_permission')} />}
                        onChange={(event) =>
                          ShareCountryside(+event.target.value, [userPermission.users.email])
                        }
                        // value={permissionUpdateValue}
                        defaultValue={getPermissionDefaultValue(userPermission)}
                      >
                        <MenuItem value={1}>
                          <Box>
                            <Typography variant="body2">{i18n.t('app.silobag_share.view')}</Typography>
                          </Box>
                        </MenuItem>
                        <MenuItem value={2}>
                          <Box>
                            <Typography variant="body2">{i18n.t('app.silobag_share.edit')}</Typography>
                          </Box>
                        </MenuItem>
                        <MenuItem value={3}>
                          <Box>
                            <Typography variant="body2">
                              {i18n.t('app.silobag_share.access_complete')}
                            </Typography>
                          </Box>
                        </MenuItem>
                        <MenuItem value={4}>
                          <Box>
                            <Typography variant="body1" sx={{ color: 'red' }}>
                              {i18n.t('app.silobag_share.delete_permission')}
                            </Typography>
                          </Box>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Box>
              ))}
            <Stack direction="row" justifyContent={'space-between'} sx={{ marginTop: '20px' }}>
              <Box />
              <ModalIOF.Button
                close
                variant="contained"
                size="medium"
                sx={configButtonStylesContained}
                onClick={() => {
                  setOpen(false);
                }}
              >
                {i18n.t('app.close')}
              </ModalIOF.Button>
            </Stack>
          </ModalIOF.Body>
        )}
      </ModalIOF>
      {isError ? (
        <ToastAction message={i18n.t('app.countryside_share.share_countryside_error')} severity={'error'} />
      ) : null}
      {isSuccess ? (
        <ToastAction
          message={i18n.t('app.countryside_share.share_countryside_success')}
          severity={'success'}
        />
      ) : null}
    </>
  );
}
