import { PropsWithChildren } from 'react';
import { MapProvider } from '../map.context';
import { MapRoot } from './map-root';

export function MapBase({ children }: PropsWithChildren) {
  return (
    <MapProvider>
      <MapRoot>{children}</MapRoot>
    </MapProvider>
  );
}
