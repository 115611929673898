import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LotListData, LotQueryPayload } from './lot-list.types';

// deprecated service ref.: getAllLotsService_DEPRECATED
export async function listLotsService({ query: rawQuery }: LotQueryPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<LotListData>(ENDPOINT.LOTS, { query });

  return {
    response,
    summary: data,
  };
}
