import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, PropsWithChildren, useState } from 'react';
import i18n from '../../../libs/language';

import { listCountriesL0Service } from '@services/domain/location/country-L0';
import { organizationDataService } from '@services/domain/base/organization/organization-data';
import { updateOrganizationDataService } from '@services/domain/base/organization/organization-edition';

type OrgSummary = Awaited<ReturnType<typeof organizationDataService>>['summary'];

type OrgDataModalProps = {
  open: boolean;
  onClose: () => void;
  orgData: OrgSummary;
  onSuccessTrigger: () => void;
  setOrgDataChange: (val: boolean) => void;
};

type SelChg = SelectChangeEvent;
type InputChg = ChangeEvent<HTMLInputElement>;

export function OrgDataModal({
  open,
  onClose,
  orgData,
  onSuccessTrigger,
  setOrgDataChange,
}: OrgDataModalProps) {
  const data = {
    country: orgData.countries_l0.id,
    tax_id: orgData.tax_id,
    name: orgData.name,
    phone: orgData.phone,
    email: orgData.email,
  };

  const initErrorData = {
    tax_id: '',
    name: '',
    phone: '',
    email: '',
  };

  const phoneAreaDetails = {
    54: {
      flag: '🇦🇷',
      placeholder: '+54 ',
    },
    55: {
      flag: '🇧🇷',
      placeholder: '+55 ',
    },
  };
  const initPhoneArea = '54';

  const [formData, setFormData] = useState(data);
  useEffectOnUpdate(() => setFormData(data), [open, orgData]);
  const [errorData, setErrorData] = useState(initErrorData);
  const [phoneArea, setPhoneArea] = useState<'54' | '55'>(initPhoneArea);

  const [readonly, toggleReadonly] = useToggle(true);

  const handleClose = () => {
    onClose();
    setFormData(data);
    setErrorData(initErrorData);
    setPhoneArea(initPhoneArea);
  };

  const closeOnSuccess = () => {
    onClose();
    //window.location.reload(); // TODO: mejorar para el futuro
    setOrgDataChange(true);
    onSuccessTrigger();
  };

  const {
    //trigger: triggerL0,
    isLoading: isLoadingL0,
    summary: summaryL0,
  } = useService(listCountriesL0Service, { fetchOnMount: true });
  //useEffectOnMount(triggerL0);

  const {
    trigger: updateInBackend, //
    isLoading: isUpdateLoading,
  } = useService(updateOrganizationDataService, { onSuccess: closeOnSuccess });

  const updateData = (key: string, value: string) => setFormData((prev) => ({ ...prev, [key]: value }));
  const updateError = (key: string, value: string) => setErrorData((prev) => ({ ...prev, [key]: value }));

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
    ALPHANUMERIC_EXT: (value: string) => !/^[\w0-9\s-_.áàâãéêíóôõúñç]*$/i.test(value),
    EMAIL: 'missing',
  };

  const HANDLERS = {
    country: (e: SelChg) => updateData('country', e.target.value),
    tax_id: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        updateError('tax_id', 'Sólo se aceptan números.');
        return;
      }
      updateError('tax_id', '');
      updateData('tax_id', input);
    },
    name: (e: InputChg) => {
      const input = e.target.value;

      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-) y guiones bajos (_)';
        updateError('name', errorMsg);
        return;
      }
      updateError('name', '');
      updateData('name', input);
    },
    phone_area: (e: SelChg) => setPhoneArea(e.target.value as '54' | '55'),
    phone: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        updateError('phone', 'Sólo se aceptan números.');
        return;
      }
      updateError('phone', '');
      updateData('phone', input);
    },
    email: (e: InputChg) => {
      updateError('email', '');
      updateData('email', e.target.value);
    },
  };

  const submit = () => {
    const errorMsg = i18n.t('app.must_field');

    let error = false;
    if (formData.tax_id === '') (error = true), updateError('tax_id', errorMsg);
    if (formData.name === '') (error = true), updateError('name', errorMsg);
    if (formData.phone === '') (error = true), updateError('phone', errorMsg);
    if (formData.email === '') (error = true), updateError('email', errorMsg);

    if (error) return;

    updateInBackend({
      query: orgData.id,
      body: formData,
    });
  };

  return (
    <MyModal open={open} onClose={handleClose}>
      <Stack style={{ fontWeight: '600', fontSize: '24px' }}>{orgData.name}</Stack>
      <FormControl fullWidth size="small" required disabled>
        <InputLabel>País</InputLabel>
        <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
          <Select
            value={summaryL0 ? formData.country.toString() ?? '' : ''}
            label="País"
            onChange={HANDLERS.country}
            style={{ flex: 1 }}
          >
            {summaryL0?.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
          {isLoadingL0 ? <CircularProgress size={24} /> : null}
        </Stack>
      </FormControl>
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.cuit')}
        helperText={errorData.tax_id}
        error={Boolean(errorData.tax_id)}
        required
        fullWidth
        disabled={readonly}
        onChange={HANDLERS.tax_id}
        value={formData.tax_id}
      />
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.name')}
        helperText={errorData.name}
        error={Boolean(errorData.name)}
        required
        fullWidth
        disabled={readonly}
        onChange={HANDLERS.name}
        value={formData.name}
      />
      <Stack direction="row" spacing={1}>
        <FormControl size="small" required disabled={readonly} style={{ width: '144px' }}>
          <InputLabel>Área</InputLabel>
          <Select
            label="Área"
            renderValue={() => <div>{phoneAreaDetails[phoneArea]?.flag}</div>}
            value={phoneArea}
            onChange={HANDLERS.phone_area}
          >
            <MenuItem value={54}>
              🇦🇷 Argentina <span style={{ color: '#a7a7a7' }}>&nbsp;(+54)</span>
            </MenuItem>
            <MenuItem value={55}>
              🇧🇷 Brasil <span style={{ color: '#a7a7a7' }}>&nbsp;(+55)</span>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label="Teléfono"
          helperText={errorData.phone}
          error={Boolean(errorData.phone)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.phone}
          value={formData.phone}
          InputProps={{
            startAdornment: phoneAreaDetails[phoneArea] ? (
              <Box style={{ width: 44 }}>{phoneAreaDetails[phoneArea]?.placeholder}</Box>
            ) : null,
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="E-mail"
          helperText={errorData.email}
          error={Boolean(errorData.email)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.email}
          value={formData.email}
        />
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
        <Button
          variant="outlined"
          style={{
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
          }}
          onClick={onClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={isUpdateLoading}
          style={{
            color: '#FFF',
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
          }}
          onClick={readonly ? toggleReadonly : submit}
        >
          {isUpdateLoading ? (
            <CircularProgress style={{ width: 24, height: 24 }} />
          ) : readonly ? (
            'Editar'
          ) : (
            'Guardar'
          )}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
