import { Box, Button, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import ConfigIcon from '@assets/svg/specific/admin/gear-white.svg';
import { useEffect, useState } from 'react';
import { InspectionRoundModal } from './inspecton-round-modal';
import i18n from '../../../libs/language';
import { IntegrationsModal } from './new-integrations-modal';

interface AdminCustomizationBarProps {
  state?: boolean;
  onRefreshAlborList: (state: boolean) => void;
}

export function AdminCustomizationBar({ state, onRefreshAlborList }: AdminCustomizationBarProps) {

  useEffect(() => {
    if (state) {
      setOpenModal('integration');
    }
  }, [state]);

  const [openModal, setOpenModal] = useState<'' | 'integration' | 'patrol'>('');
  const handleOpenIntegrationModal = () => setOpenModal('integration');
  const handleOpenPatrolModal = () => setOpenModal('patrol');
  const handleCloseModal = () => {
    setOpenModal('');
  };
  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('lg'));

  const configButtonStyles = {
    color: '#FFF',
    fontSize: 12,
    borderRadius: 100,
    textTransform: 'none',
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: 4,
          padding: 2,
          gridColumn: matchMD ? '' : '3/5',
          gridRow: matchMD ? '' : '0/1',
        }}
        elevation={0}
      >
        <Stack
          direction="row"
          style={{
            display: 'flex',
            gap: 10,
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              fontWeight: '600',
              fontSize: 17,
              display: 'flex',
              flex: 1,
              [theme.breakpoints.down('lg')]: { justifyContent: 'center' },
              justifyContent: 'left',
              alignItems: 'center',
            })}
          >
            Personalizar
          </Box>
          <Stack
            direction="row"
            style={{
              display: 'flex',
              gap: 10,
              flexWrap: 'wrap',
            }}
          >
            <Stack
              direction="row"
              style={{
                display: 'flex',
                flex: 1,
                gap: 10,
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                size="small"
                disableElevation
                startIcon={<img src={ConfigIcon} alt="add" />}
                sx={configButtonStyles}
                onClick={handleOpenIntegrationModal}
                // disabled={country_id === 2}
              >
                {i18n.t('app.integration_modal.integrations')}
              </Button>
              <Button
                variant="contained"
                size="small"
                disableElevation
                startIcon={<img src={ConfigIcon} alt="add" />}
                sx={configButtonStyles}
                onClick={handleOpenPatrolModal}
              >
                {i18n.t('app.administrator.walkthrough')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      {openModal === 'integration' && (
        <IntegrationsModal
          open={openModal === 'integration'}
          close={handleCloseModal}
          refreshAlborList={onRefreshAlborList}
        />
      )}
      {openModal === 'patrol' && (
        <InspectionRoundModal open={openModal === 'patrol'} onClose={handleCloseModal} />
      )}
    </>
  );
}
