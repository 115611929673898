import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { SilobagData, SilobagPayload } from './silobag-list.types';

// deprecated service ref.: getAllSilobagService_DEPRECATED

// ✅ implementado
export async function listSilobagService({ query: rawQuery }: SilobagPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<SilobagData>(ENDPOINT.SILOBAGS, { query });

  return {
    response,
    summary: data,
  };
}
