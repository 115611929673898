import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { SilobagDetailPayload, SilobagDetailResponse } from './silobag-data-detail.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: silobagDetailByIDService_DEPRECATED

// ✅ implementado
export async function silobagDataDetailService({ query: RawQuery }: SilobagDetailPayload) {
  const query = transformIntoQueryParamString(RawQuery);

  const { response, data } = await BACKEND.get<SilobagDetailResponse>(ENDPOINT.SILOBAGS, { query });

  return {
    response,
    summary: data,
  };
}
