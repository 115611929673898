import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { PoeDaysPayload, PoeDaysResponse } from './poe-interval-days.types';

// deprecated service ref.: getDaysBetweenRoundsService_DEPRECATED

// ✅ Implementado
export async function poeIntervalDaysService({ query: orgID }: PoeDaysPayload) {
  const query = transformIntoQueryParamString(orgID);

  const { response, data } = await BACKEND.get<PoeDaysResponse>(ENDPOINT.ORGANIZATIONS, { query });
  const [summary] = data;
  const { poe_cycle } = summary;

  return {
    response,
    summary: { poe_cycle },
  };
}
