import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { MeasurementData, MeasurementPayload } from './measurement-data.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: getAllSilobagMeasurementService_DEPRECATED
export async function silobagMeasurementDataService({ query: rawQuery }: MeasurementPayload) {
  const { id: siloID, ...rangeDates } = rawQuery;
  const query = transformIntoQueryParamString(siloID, rangeDates);

  const { response, data } = await BACKEND.get<MeasurementData>(ENDPOINT.SILOBAGS_MEASUREMENTS, { query });

  return {
    response,
    summary: data,
  };
}
