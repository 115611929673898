import { Box, Button, Divider, Fade, Modal, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import i18n from '../../../libs/language';

type ModalInfoProps = {
  open: boolean;
  setIsOpen: (value: boolean) => void;
};

export function ModalInfoRegisterSilobags({ open, setIsOpen }: ModalInfoProps) {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <MyModal open={open} onClose={handleClose}>
      <Box>
        <Typography variant="h6" fontWeight={'bold'}>
          {i18n.t('app.register_silobag')}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <Typography variant="body2">
          {i18n.t('app.register_silobag_modal_info.missing_countrysides')}
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <Button onClick={handleClose}>{i18n.t('app.close')}</Button>
      </Box>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
