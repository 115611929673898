import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { ReportExistenceStockPayload, ReportExistenceStockData } from './report-existence-stock-info.types';

// deprecated service ref.: getAllExistenceStockService_DEPRECATED

// Implementado ✅
export async function stockExistenceListService({ query: rawQuery }: ReportExistenceStockPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<ReportExistenceStockData>(
    ENDPOINT.REPORT_SAT_SILOBAGS_EXISTENCE,
    { query }
  );

  return {
    response,
    summary: data,
  };
}
