import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

interface LoginIntegrationAlborResponse {
  response: unknown;
  results: unknown;
  token: string;
}

// ✅ Está procesado
// ❓ TODO: Revisar, puede que se tenga que borrar sin implementación (tal vez está aplicado en código huérfano)
export async function getIntegrationDataService_DEPRECATED(orgID: number) {
  const { response, data } = await BACKEND.get(ENDPOINT.ORGANIZATIONS, { params: '/' + orgID });

  return {
    response: response,
    summary: {
      agrotoken: {
        active: data.at(0).agrotoken_integration,
      },
    },
  };
}

// ✅ Está procesado
// ❓ TODO: Jesús debería haberlo implementado
export async function updateIntegrationSettingsService_DEPRECATED(orgID: number, body: unknown) {
  const { response, data } = await BACKEND.put(ENDPOINT.ORGANIZATIONS, { body, params: '/' + orgID });

  return {
    response,
    summary: data,
  };
}

// ✅ Está procesado
// ❓ TODO: Revisar, puede que se tenga que borrar sin implementación (tal vez está aplicado en código huérfano)
export async function LoginIntegrationAlborService_DEPRECATED(body: unknown) {
  const { response, data } = await BACKEND.post(ENDPOINT.ALBOR_LOGIN, { body });
  const typedData = data as LoginIntegrationAlborResponse;

  return {
    response,
    summary: typedData.results,
    AlborToken: typedData.token,
  };
}

// ✅ Está procesado
export async function getIntegrationAlborCountrysidesService_DEPRECATED() {
  const { response, data } = await BACKEND.get(ENDPOINT.ALBOR_COUNTRYSIDES);

  return {
    response,
    summary: data,
  };
}
