import { FC, ReactNode } from 'react';
import { Theme, useMediaQuery, Grid } from '@mui/material';

type AppGridContainerProps = {
  children: ReactNode;

  [x: string]: any;
};

const AppGridContainer: FC<AppGridContainerProps> = ({ children, ...others }) => {
  const isMDDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Grid container spacing={isMDDown ? 5 : 8} {...others}>
      {children}
    </Grid>
  );
};

export default AppGridContainer;
