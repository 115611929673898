import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { AlertActionPayload, UpdatedAlertActionData } from './alert-status-edition.types';

// deprecated service ref.: createAlertAction_DEPRECATED

// ✅ implementado
export async function updateAlertStatusService({ body }: AlertActionPayload) {
  const { response, data } = await BACKEND.post<UpdatedAlertActionData>(ENDPOINT.ALERT_ACTIONS, { body });

  return {
    response,
    summary: data,
  };
}
