import { useArray, useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, Fragment, PropsWithChildren, useCallback, useRef, useState } from 'react';

import DeleteLotIcon from '@assets/svg/core/trash-red.svg';
import DeleteDisabledLotIcon from '@assets/svg/core/trash-hollow-disabled.svg';
import AddLotIcon from '@assets/svg/core/plus-green.svg';
import { createCountrysideService } from '@services/domain/base/countryside/countryside-creation';
import { ToastAction } from '@ui/toast/toast-action';
import i18n from '../../../libs/language';
import { Wrapper } from '@googlemaps/react-wrapper';
import { listRawCompanyDataService } from '@services/domain/base/company/company-list';
import { updateCountrysideDataService } from '@services/domain/base/countryside/countryside-edition';

interface AdminModalProps {
  open: boolean;
  type: 'new' | 'view' | 'edit';
  parentFormData?: any;
  onSuccessTrigger: () => void;
  enableEditMode: () => void;
  setIsOpen: (value: boolean) => void;
  toggleNewCountrysideMsg: (val: boolean) => void;
  setActionInCountryside: (val: boolean) => void;
}

type Lot = {
  id?: number;
  active: boolean;
  name: string;
  polygon: string;
  error: string;
};

type SelChg = SelectChangeEvent;
type InputChg = ChangeEvent<HTMLInputElement>;

export function CountrysideViewEditModal({
  open,
  type,
  parentFormData,
  onSuccessTrigger,
  enableEditMode,
  setIsOpen,
  toggleNewCountrysideMsg,
  setActionInCountryside,
}: AdminModalProps) {
  const {
    // trigger,
    isLoading,
    summary: companies,
  } = useService(listRawCompanyDataService, { fetchOnMount: true });

  const handleClose = () => {
    setIsOpen(false);
    setFormData(initData);
    setLots(initLotsData);
    setErrorData(initErrorData);
  };

  const closeOnSuccess = () => {
    handleClose();
    onSuccessTrigger();
    toggleNewCountrysideMsg(true);
  };

  const {
    trigger: createInBackend,
    isLoading: isCreationLoading,
    isError: isCreationError,
  } = useService(createCountrysideService, {
    onSuccess: () => {
      closeOnSuccess();
      setActionInCountryside(true);
    },
  });
  const {
    trigger: updateInBackend,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useService(updateCountrysideDataService, {
    onSuccess: closeOnSuccess,
  });

  const readonly = type === 'view';

  const initData = {
    company_id: type !== 'new' ? parentFormData.company_id : '',
    name: type !== 'new' ? parentFormData.name : '',
    lat: type !== 'new' ? parentFormData.lat : '',
    lon: type !== 'new' ? parentFormData.lon : '',
    lot_name: '',
    lot_polygon: '',
  };

  const initErrorData = {
    company_id: '',
    name: '',
    lat: '',
    lon: '',
    lot_name: '',
  };

  const initLotsData =
    type !== 'new'
      ? parentFormData.lots.map((lot) => ({
          id: lot.id,
          active: lot.active,
          name: lot.name,
          polygon: '',
          error: '',
        }))
      : [];

  const [formData, setFormData] = useState(initData);
  const [errorData, setErrorData] = useState(initErrorData);
  const {
    array: lots,
    keys: lotsKeys,
    add: addLot,
    smash: updateLot,
    set: setLots,
  } = useArray<Lot>(initLotsData as Lot[]);
  const [openMapModal, toggleMapModal] = useToggle(false);
  const handleCloseMapModal = () => {
    toggleMapModal(false);
    refreshMapCoords.current = true;
  };

  useEffectOnUpdate(() => {
    setFormData(initData);
    setLots(initLotsData);
  }, [parentFormData, type]);

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
    FLOAT_NUMS: (value: string) => !/^-?[0-9]*((?<=\d)\.(?=\d))?[0-9]*$/.test(value) || value === '-',
    TYPING_FLOAT_NUMS: (value: string) => !/^-?[0-9]*\.?[0-9]*$/.test(value),
    ALPHANUMERIC_EXT: (value: string) => !/^[\w0-9\s-_&.áàâãéêíóôõúñç]*$/i.test(value),
    EMAIL: 'missing', // TODO: falta hacer la regex de email
  };

  const firstLevelDataChange = (key: string, value: string) =>
    setFormData((prev) => ({ ...prev, [key]: value }));

  const firstLevelDataError = (key: string, value: string) =>
    setErrorData((prev) => ({ ...prev, [key]: value }));

  const HANDLERS = {
    company_id: (e: SelChg) => {
      firstLevelDataError('company_id', '');
      firstLevelDataChange('company_id', e.target.value);
    },
    name: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-), guiones bajos (_) y ampersand (&)';
        firstLevelDataError('name', errorMsg);
        return;
      }
      firstLevelDataError('name', '');
      firstLevelDataChange('name', input);
    },
    lat: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.TYPING_FLOAT_NUMS(input)) {
        firstLevelDataError('lat', 'Solo se aceptan coordenadas.');
        return;
      }
      firstLevelDataError('lat', '');
      firstLevelDataChange('lat', input);
    },
    lon: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.TYPING_FLOAT_NUMS(input)) {
        firstLevelDataError('lon', 'Solo se aceptan coordenadas.');
        return;
      }
      firstLevelDataError('lon', '');
      firstLevelDataChange('lon', input);
    },
    lot_name: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-), guiones bajos (_) y ampersand (&)';
        firstLevelDataError('lot_name', errorMsg);
        return;
      }
      firstLevelDataError('lot_name', '');
      firstLevelDataChange('lot_name', input);
    },
    lot_polygon: (event: InputChg) => firstLevelDataChange('lot_polygon', event.target.value),
  };

  const submit = () => {
    const errorMsg = i18n.t('app.must_field');

    let error = false;

    if (VALIDATION.FLOAT_NUMS(formData.lat))
      (error = true), firstLevelDataError('lat', 'Esta coordenada no es válida.');
    if (VALIDATION.FLOAT_NUMS(formData.lon))
      (error = true), firstLevelDataError('lon', 'Esta coordenada no es válida.');

    if (formData.company_id === '') (error = true), firstLevelDataError('company_id', errorMsg);
    if (formData.name === '') (error = true), firstLevelDataError('name', errorMsg);
    if (formData.lat === '') (error = true), firstLevelDataError('lat', errorMsg);
    if (formData.lon === '') (error = true), firstLevelDataError('lon', errorMsg);

    lots.forEach((lot, i) => {
      if (lot.name === '') {
        error = true;
        updateLot(i, { ...lot, error: errorMsg });
      }
    });

    if (error) return;

    const body = {
      company_id: formData.company_id,
      name: formData.name,
      lat: formData.lat,
      lon: formData.lon,
      lots: lots.map((lot) => {
        // eslint-disable-next-line
        const { error, polygon, ...remainingLot } = lot;
        return remainingLot;
      }),
    };

    if (type === 'new') createInBackend({ body });
    if (type === 'edit')
      updateInBackend({
        query: parentFormData.id,
        body: {
          ...body,
          country_l2_id: parentFormData.country_l2_id,
          active: parentFormData.active,
        },
      });
  };

  const refreshMapCoords = useRef(true);

  const initMap = useCallback(
    (node: HTMLDivElement) => {
      if (!node) return;
      if (!window.google) return;
      if (!refreshMapCoords.current) return;
      refreshMapCoords.current = false;
      const hasValidCoords =
        formData.lat &&
        formData.lon &&
        !VALIDATION.FLOAT_NUMS(formData.lat) &&
        !VALIDATION.FLOAT_NUMS(formData.lon);

      const defaultCenter = { lat: -32.475, lng: -58.6111 };
      const center = hasValidCoords
        ? { lat: parseFloat(formData.lat), lng: parseFloat(formData.lon) }
        : defaultCenter;

      const newMap = new window.google.maps.Map(node, {
        center,
        zoom: 6,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true,
        zoomControl: true,
        controlSize: 25,
        maxZoom: 17,
        minZoom: 3,
      });

      let marker: google.maps.Marker | null = null;

      if (hasValidCoords) {
        const lat = parseFloat(formData.lat);
        const lon = parseFloat(formData.lon);
        marker = new window.google.maps.Marker({
          position: { lat, lng: lon },
          map: newMap,
          title: 'Countryside',
        });
      }

      google.maps.event.addListener(newMap, 'click', (event: google.maps.MapMouseEvent) => {
        const lat = event.latLng!.lat();
        const lon = event.latLng!.lng();
        firstLevelDataChange('lat', lat.toString());
        firstLevelDataChange('lon', lon.toString());
        firstLevelDataError('lat', '');
        firstLevelDataError('lon', '');

        if (marker) marker.setMap(null);

        marker = new window.google.maps.Marker({
          position: { lat, lng: lon },
          map: newMap,
          title: 'Countryside',
        });
      });
    },
    [formData.lat, formData.lon]
  );

  return (
    <>
      <MyModal open={open} onClose={handleClose}>
        <Box style={{ fontWeight: '600', fontSize: '24px' }}>
          {i18n.t('app.administrator.new_countryside')}
        </Box>

        <FormControl
          fullWidth
          disabled={readonly || isLoading}
          size="small"
          required
          error={Boolean(errorData.company_id)}
        >
          <InputLabel>{i18n.t('app.countryside_modal.company')}</InputLabel>
          <Select
            value={formData.company_id}
            label={i18n.t('app.countryside_modal.company')}
            onChange={HANDLERS.company_id}
          >
            {companies
              ?.sort((a, b) => a.id - b.id)
              .map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
          </Select>
          {errorData.company_id ? <FormHelperText>{errorData.company_id}</FormHelperText> : null}
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.name')}
          helperText={errorData.name}
          error={Boolean(errorData.name)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.name}
          value={formData.name}
        />
        <Stack direction="row" spacing={1}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.countryside_modal.latitude')}
            placeholder={i18n.t('app.countryside_modal.latitude') + ' (ej: -34.4750)'}
            helperText={errorData.lat}
            error={Boolean(errorData.lat)}
            required
            fullWidth
            disabled={readonly}
            onChange={HANDLERS.lat}
            onBlur={() =>
              VALIDATION.FLOAT_NUMS(formData.lat) &&
              firstLevelDataError('lat', 'Esta coordenada no es válida.')
            }
            value={formData.lat}
          />
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.countryside_modal.longitude')}
            placeholder={i18n.t('app.countryside_modal.longitude') + ' (ej: -58.6111)'}
            helperText={errorData.lon}
            error={Boolean(errorData.lon)}
            required
            fullWidth
            disabled={readonly}
            onBlur={() =>
              VALIDATION.FLOAT_NUMS(formData.lon) &&
              firstLevelDataError('lon', 'Esta coordenada no es válida.')
            }
            onChange={HANDLERS.lon}
            value={formData.lon}
          />
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            style={{ marginLeft: 25, marginRight: 100 }}
          >
            <Box style={{ minWidth: 180, color: '#a7a7a7' }}>
              {i18n.t('app.countryside_modal.geo_position')}
            </Box>
            <Button
              variant="contained"
              size="small"
              disableElevation
              style={{
                color: '#FFF',
                minWidth: 110,
                borderRadius: 100,
                textTransform: 'none',
              }}
              onClick={() => toggleMapModal(true)}
            >
              {i18n.t('app.countryside_modal.open_map')}
            </Button>
          </Stack>
        </Stack>
        <Box style={{ color: '#a7a7a7' }}>{i18n.t('app.countryside_modal.lots')}</Box>
        <Stack style={{ marginRight: 150 }} spacing={1}>
          {lots.map((lot, i) => {
            return (
              <Fragment key={lot.id ?? lotsKeys[i]}>
                {lot.active ? (
                  <Stack direction="row" spacing={1}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label={i18n.t('app.name')}
                      helperText={lot.error}
                      error={Boolean(lot.error)}
                      fullWidth
                      disabled={readonly || i === 0}
                      onChange={(e) => {
                        updateLot(i, { ...lots[i], name: e.target.value, error: '' });
                      }}
                      value={lot.name}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      label={i18n.t('app.countryside_modal.polygon')}
                      fullWidth
                      disabled={readonly || i === 0}
                      onChange={(e) => {
                        updateLot(i, { ...lots[i], polygon: e.target.value });
                      }}
                      value={lot.polygon}
                    />
                    {i === 0 ? (
                      <Box
                        style={{
                          cursor: 'pointer',
                          minWidth: 150,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={DeleteDisabledLotIcon} />
                      </Box>
                    ) : (
                      <Box
                        style={{
                          cursor: 'pointer',
                          minWidth: 150,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          if (readonly) return;
                          updateLot(i, { ...lots[i], active: false });
                        }}
                      >
                        <img src={DeleteLotIcon} />
                      </Box>
                    )}
                  </Stack>
                ) : null}
              </Fragment>
            );
          })}
        </Stack>
        <Divider style={{ width: '100%', margin: '8px 0' }} />
        <Stack direction="row" spacing={1}>
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.name')}
            helperText={errorData.lot_name}
            error={Boolean(errorData.lot_name)}
            fullWidth
            disabled={readonly}
            onChange={HANDLERS.lot_name}
            value={formData.lot_name}
          />
          <TextField
            variant="outlined"
            size="small"
            label={i18n.t('app.countryside_modal.polygon')}
            fullWidth
            disabled={readonly}
            onChange={HANDLERS.lot_polygon}
            value={formData.lot_polygon}
          />
          <Box
            sx={{
              cursor: 'pointer',
              color: '#6baa00',
              minWidth: 150,
              '&: hover': {
                color: '#6caa00cc',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1,
            }}
            onClick={() => {
              if (readonly) return;
              if (!formData.lot_name) {
                firstLevelDataError('lot_name', 'No se puede agregar un lote vacío.');
                return;
              }
              addLot(-1, { name: formData.lot_name, active: true, polygon: formData.lot_polygon, error: '' });
              firstLevelDataChange('lot_name', '');
              firstLevelDataChange('lot_polygon', '');
            }}
          >
            <img src={AddLotIcon} /> {i18n.t('app.countryside_modal.add_lot')}
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
            }}
            onClick={handleClose}
          >
            {i18n.t('app.cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isCreationLoading || isUpdateLoading}
            style={{
              color: '#FFF',
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
            }}
            onClick={readonly ? enableEditMode : submit}
          >
            {isCreationLoading || isUpdateLoading ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : readonly ? (
              'Editar'
            ) : (
              i18n.t('app.save')
            )}
          </Button>
        </Stack>
        {/*(isCreationSuccess || isUpdateSuccess) && (
        <ToastAction message="Testeando la wea" severity="success" />
      )*/}
        {(isCreationError || isUpdateError) && (
          <ToastAction message="error inesperado verifique los datos ingresados" severity="error" />
        )}
      </MyModal>
      <MyModal open={openMapModal} keepMounted={false} onClose={handleCloseMapModal}>
        <Stack spacing={2}>
          <Wrapper apiKey={import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_KEY}>
            <Box component="div" ref={initMap} style={{ height: '66svh' }} />
          </Wrapper>
          <Button
            variant="outlined"
            style={{
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
              alignSelf: 'flex-end',
            }}
            onClick={handleCloseMapModal}
          >
            {i18n.t('app.close')}
          </Button>
        </Stack>
      </MyModal>
    </>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
  keepMounted?: boolean;
};

function MyModal({ open, onClose, keepMounted = true, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted={keepMounted} onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
