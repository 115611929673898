import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { useService } from '@hooks/use-service';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../../libs/language';
import AlborLogo from '@assets/logos/albor.png';
import { loginAlborService } from '@services/integration/albor/albor-auth';

const VIEW_STEP1 = 'step1';
const VIEW_STEP2 = 'step2';

const { organizationId } = useGlobalStore.getState();

export const AlborIntegration = () => {
  const { organizationId: orgID } = useGlobalStore();
  const handleClose = () => {
    close();
    if (view === VIEW_STEP2) {
      window.location.reload();
    }
  };
  const [view, setView] = useState(VIEW_STEP1);
  const [formData, setFormData] = useState({ Username: '', Password: '' });
  const [, setIntegrationAlborToken] = useState<string | null>(null);
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
      padding: 10,
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 13,
    },
  }));

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const { trigger: loginIntegrationAlbor } = useService<typeof loginAlborService>(loginAlborService, {
    onSuccess: () => {
      setIntegrationAlborToken('token');
    },
  });

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (orgID === undefined) {
        throw new Error('Organization ID is required');
      }
      await loginIntegrationAlbor({
        body: {
          Username: formData.Username,
          Password: formData.Password,
          organization_id: orgID,
          albor_integration: true,
        },
      });
      setView(VIEW_STEP2);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (view === VIEW_STEP1 && !loading) {
      setView(VIEW_STEP1);
    }
  }, [view, loading]);
  useEffect(() => {}, [currentId]);

  const IntegrationTitle = () => (
    <Box style={{ fontWeight: '600', fontSize: '24px' }}>
      {i18n.t('app.integration_modal.Albor_intergration')}
    </Box>
  );

  const StepBox = ({ step }: { step: number }) => (
    <Box style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        style={{
          backgroundColor: '#9dca50',
          color: '#6caa00cc',
          fontWeight: 'bold',
          padding: '10px 20px',
          borderRadius: '5px',
        }}
      >
        Paso {step} de 2
      </Box>
    </Box>
  );

  return (
    <div>
      {loading ? (
        <>
          <IntegrationTitle />
          <StepBox step={1} />
          <Box style={{ fontWeight: '600', fontSize: '20px', textAlign: 'center' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography variant="h6">{i18n.t('app.integration_modal.Albor_loading')}</Typography>
            </Box>
            <Box style={{ fontSize: '14px', textAlign: 'center', marginBottom: '20px' }}>
              {i18n.t('app.integration_modal.Albor_loading2')}
            </Box>
            <CircularProgress />
          </Box>
        </>
      ) : view === VIEW_STEP1 ? (
        <>
          <IntegrationTitle />
          <StepBox step={1} />
          <Box style={{ fontWeight: '600', fontSize: '20px', textAlign: 'center' }}>
            {i18n.t('app.integration_modal.albor_title')}
          </Box>
          <Box style={{ fontSize: '14px', textAlign: 'center' }}>
            {i18n.t('app.integration_modal.albor_subtitle')}
            <br />
            {i18n.t('app.integration_modal.albor_subtitle2')}
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={AlborLogo} alt="logo" style={{ height: '75px', width: '75px' }} />
          </Box>

          <form onSubmit={handleLogin}>
            <Stack spacing={1}>
              <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                  size="small"
                  label={i18n.t('app.integration_modal.Albor_username')}
                  type="text"
                  variant="outlined"
                  style={{ width: '50%' }}
                  name="Username"
                  autoComplete="username"
                  value={formData.Username}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TextField
                  size="small"
                  label={i18n.t('app.integration_modal.Albor_password')}
                  type="password"
                  variant="outlined"
                  autoComplete="current-password"
                  style={{ width: '50%' }}
                  name="Password"
                  value={formData.Password}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="center">
              <Button
                variant="contained"
                type="submit"
                style={{
                  color: '#FFF',
                  width: '50%',
                  borderRadius: 100,
                  textTransform: 'none',
                  marginTop: '20px',
                }}
              >
                {i18n.t('app.integration_modal.login')}
              </Button>
            </Stack>
          </form>
        </>
      ) : (
        <>
          <IntegrationTitle />
          <StepBox step={2} />
          <Box style={{ fontWeight: '600', fontSize: '20px', textAlign: 'center' }}>
            {i18n.t('app.integration_modal.albor_joined')}
          </Box>

          <Box style={{ fontSize: '14px', textAlign: 'center', marginBottom: '20px' }}>
            {i18n.t('app.integration_modal.albor_joined3')}
          </Box>
        </>
      )}
    </div>
  );
};