import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CompanyEditionPayload, UpdatedCompanyData } from './company-edition.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: updateCompanyService_DEPRECATED

// ✅ Implementado
export async function updateCompanyDataService({ query: rawQuery, body }: CompanyEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<UpdatedCompanyData>(ENDPOINT.COMPANIES, { query, body });

  return {
    response,
    summary: data,
  };
}
