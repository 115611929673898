import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';
import { useGlobalStore } from '@global-store/use-store';
import { StockMovResponse } from './report-mov-stock-info.types';

// deprecated service ref.: getAllReportStockService_DEPRECATED
export async function stockMovementListService() {
  const { organizationId, filtersReportStock } = useGlobalStore.getState();
  const rawQuery = filtersReportStock;
  const query = transformIntoQueryParamString(organizationId, rawQuery);

  const { response, data } = await BACKEND.get<StockMovResponse>(ENDPOINT.REPORT_STOCK, { query });

  return {
    response,
    summary: data,
  };
}
