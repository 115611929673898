import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';
import { useGlobalStore } from '@global-store/use-store';

import { MapInfoData, MapInfoPayload } from './map-info.types';

// deprecated service ref.: mapService_DEPRECATED

// implementado ✅
export async function mapInfoService({ query: rawQuery }: MapInfoPayload) {
  const { organizationId } = useGlobalStore.getState();
  const parse = {
    ...rawQuery,
    organization_id: organizationId,
  };
  const query = transformIntoQueryParamString(parse);

  const { response, data } = await BACKEND.get<MapInfoData>(ENDPOINT.MAP, { query });

  return {
    response,
    summary: data,
  };
}
