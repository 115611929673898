import { useService } from '@hooks/use-service';
import { useParams } from 'react-router';
import { SilobagNewEditForm } from '@features/silobags/silobag-forms/silobag-new-edit-form';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

export function SilobagEditPage() {
  const { siloID } = useParams();

  const { isLoading, summary } = useService(silobagDataDetailService, {
    fetchOnMount: [{ query: siloID ?? 'id-not-found' }],
  });

  return <SilobagNewEditForm type="edit" parentFormData={summary!} isParentLoading={isLoading} />;
}
