import { Box, Button, Fade, Grid, Modal, Paper, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import dayjs from 'dayjs';
import i18n from '../../../libs/language';

import DownloadIcon from '@assets/svg/core/download-alt-blue.svg';
import bigIntSupport from 'dayjs/plugin/bigIntSupport';
dayjs.extend(bigIntSupport);

type ImageProps = {
  url: string;
  label?: string;
  init_azimuth?: string;
  init_timestamp?: string | bigint;
  init_lat?: string;
  init_lon?: string;
  init_accuracy?: string | number;
  main_azimuth?: string;
  main_timestamp?: string | bigint;
  main_lat?: string;
  main_lon?: string;
  main_accuracy?: string | number;
  end_azimuth?: string;
  end_timestamp?: string | bigint;
  end_lat?: string;
  end_lon?: string;
  end_accuracy?: string | number;
  init_observations?: string;
};

type CarouselProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  images: ImageProps[];
  sbId?: string;
};

export function CarouselModalPhotoGallery({ title, images, open, onClose, sbId }: CarouselProps) {
  const [listIDX, setListIDX] = useState(0);
  const SCROLL = {
    toLeft: () => setListIDX((prev) => (prev - 1 + images.length) % images.length),
    toRight: () => setListIDX((prev) => (prev + 1) % images.length),
  };

  const hasImages = images && images.length > 0;

  const img = images[listIDX] as ImageProps | undefined;

  const RAW = {
    timestamp: img?.init_timestamp ?? img?.main_timestamp ?? img?.end_timestamp,
    azimuth: img?.init_azimuth ?? img?.main_azimuth ?? img?.end_azimuth ?? '0.00',
    accuracy: img?.init_accuracy ?? img?.main_accuracy ?? img?.end_accuracy ?? '0.00',
    coords: {
      lat: img?.init_lat ?? img?.main_lat ?? img?.end_lat,
      lon: img?.init_lon ?? img?.main_lon ?? img?.end_lon,
    },
  };
  const hasCoords = RAW.coords.lat && RAW.coords.lon;
  const DISPLAY = {
    timestamp: RAW.timestamp
      ? dayjs(BigInt(RAW.timestamp)).format('D/M/YYYY - HH:mm')
      : i18n.t('app.water_mark.missing_date'),
    coords: hasCoords ? `[${RAW.coords.lat}, ${RAW.coords.lon}]` : 'Coordenadas no disponibles',
    azimuth: parseFloat(RAW.azimuth).toFixed(2) + ' °',
    accuracy: parseFloat(RAW.accuracy as string).toFixed(2) + ' mts',
  };
  const pascalCaseLabel = (images[listIDX]?.label?.match(/[a-zA-Z0-9]+/g) ?? [])
    .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
    .join('');
  const formattedDate = RAW.timestamp
    ? '-' + dayjs(BigInt(RAW.timestamp)).format('YYYY_MM_DD-HH_mm(G[M]TZ)')
    : '';

  return (
    <MyModal open={open} onClose={onClose}>
      {hasImages && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Box style={{ fontWeight: '600', fontSize: '24px' }}>
            {title} ({listIDX + 1}/{images.length})
          </Box>
          <Box style={{ flex: 1 }}>
            <DownloadLink url={images[listIDX].url} fileName={`${sbId}-${pascalCaseLabel}${formattedDate}`} />
          </Box>
          <Box sx={{ fontWeight: '600', marginRight: '10px' }}>{images[listIDX]?.label ?? ''}</Box>
        </Stack>
      )}
      <Grid container style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <Grid item xs={8}>
          {!hasImages ? (
            <Typography
              display={'flex'}
              justifyContent={'start'}
              alignItems={'center'}
              variant="h5"
              color={'#737373'}
            >
              {i18n.t('app.silobag_history.no_images')}
            </Typography>
          ) : (
            <>
              <Box component="section" className="container" style={{ position: 'relative' }}>
                <Box className="slider-wrapper">
                  <Paper
                    className="slider-control slider-control-left"
                    style={{
                      borderRadius: '100%',
                      position: 'absolute',
                      top: '50%',
                      left: '30px',
                      transform: 'translateY(-50%)',
                    }}
                    onClick={SCROLL.toLeft}
                  >
                    <Box>{'<'}</Box>
                  </Paper>
                  <Paper
                    className="slider-control slider-control-right"
                    style={{
                      borderRadius: '100%',
                      position: 'absolute',
                      top: '50%',
                      right: '30px',
                      transform: 'translateY(-50%)',
                    }}
                    onClick={SCROLL.toRight}
                  >
                    <Box>{'>'}</Box>
                  </Paper>
                  <Box className="slider keep-scrolling-without-scroll">
                    {images.map((image, i) => (
                      <img
                        ref={(node) => {
                          if (node && listIDX === i) {
                            node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                          }
                        }}
                        key={i}
                        src={image.url}
                        alt={image.label !== undefined ? image.label : ''}
                      />
                    ))}
                  </Box>
                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    className="slider-nav"
                  >
                    {images.map((_, i) => (
                      <Button
                        key={i}
                        className={listIDX === i ? 'active' : ''}
                        onClick={() => setListIDX(i)}
                      />
                    ))}
                  </Stack>
                </Box>
              </Box>
            </>
          )}
        </Grid>
        {hasImages && (
          <Grid item xs={3}>
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                {sbId}{' '}
              </Typography>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1" sx={{ fontSize: '14px' }}>
                    {DISPLAY.coords}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                  {i18n.t('app.date')}:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                  {DISPLAY.timestamp}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                  {i18n.t('app.water_mark.gps')}:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                  {DISPLAY.accuracy}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                  {i18n.t('app.water_mark.compass')}:
                </Typography>
                <Typography variant="body1" sx={{ fontSize: '14px' }}>
                  {DISPLAY.azimuth}
                </Typography>
              </Box>
              {images[listIDX]?.init_observations && (
                <Box width="100%">
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                    {i18n.t('app.silobag_history.reason')}:
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: '14px' }}>
                    {images[listIDX].init_observations}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
      {hasImages && (
        <Stack direction="row" justifyContent="right">
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
              alignSelf: 'right',
            }}
            onClick={onClose}
          >
            {i18n.t('app.close')}
          </Button>
        </Stack>
      )}
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = () => onClose();

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}

type DownloadLinkProps = {
  url: string;
  fileName?: string;
};
export function DownloadLink({ url, fileName = 'siloreal-image' }: DownloadLinkProps) {
  const ext = url.match(/\.([^./?#]+)($|\?|#)/)?.at(1);

  if (!ext) throw new Error('[SiloReal][DOWNLOAD-FILE] No extension found in the URL: ' + url);

  const handleDownload = () => {
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${ext}`;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
        console.warn('Refetching...');
        fetch(url, {
          method: 'GET',
          cache: 'no-cache',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.${ext}`;
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            console.info('Refetched successfully.');
          })
          .catch((error) => {
            console.error('Error (again) fetching the file:', error);
          });
      });
  };

  return (
    <Box>
      <Button
        sx={{
          color: '#22A7F0',
          textTransform: 'none',
          px: 2,
          '&:hover': { background: '#22a7f02a' },
        }}
        startIcon={<img src={DownloadIcon} />}
        onClick={handleDownload}
      >
        {i18n.t('app.download')}
      </Button>
    </Box>
  );
}
