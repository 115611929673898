import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { SilobagPermissionPayload, SilobagPermissionResponse } from './share-list.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: getAllSilobagsPermissions_DEPRECATED

// Implementado ✅
export async function sharePermissionListService({ query: rawQuery }: SilobagPermissionPayload) {

  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<SilobagPermissionResponse>(ENDPOINT.SILO_PERMISSION, { query });

  return {
    response,
    summary: data
  };
}
