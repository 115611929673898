import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { MassiveUploadPayload, MassiveUploadedResponse } from './massive-upload.types';

// deprecated service ref.: masiveSilobagService_DEPRECATED
export async function silobagMassiveUploadService({ body }: MassiveUploadPayload) {
  const { response, data } = await BACKEND.post<MassiveUploadedResponse>(ENDPOINT.SILOBAGS_MASSIVE, {
    body,
  });

  return {
    response,
    summary: data,
  };
}
