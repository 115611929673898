import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ChangePasswordCredentialPayload, ChangePasswordCredentialData } from './change-auth-credential.types';

// deprecated service ref.: ResetUserPasswordService_DEPRECATED
export async function changeCredentialService({ body }: ChangePasswordCredentialPayload) {

  const { response, data } = await BACKEND.patch<ChangePasswordCredentialData>(ENDPOINT.USERS, { body });

  return {
    response,
    summary: data,
  };
}
