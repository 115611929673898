export const Plans = [
    { id: 1, description: 'Con Robo' },
    { id: 2, description: 'Sin Robo' }
];

export const validityInsurance = [
    { id: 1, description: 'Trimestral' },
    { id: 2, description: 'Semestral' },
];

export const paymentMethods = [
    { id: 1, description: 'Contado' },
    { id: 2, description: 'Cuotas' },
];

export const coins = [
    { id: 1, name: 'ARS', description: 'Pesos' },
    { id: 2, name: 'USD', description: 'Dolares' },
];

export const taxStatus = [
    { id: 1, description: 'IVA Responsable Inscripto' },
    { id: 2, description: 'IVA Responsable no Inscripto' },
    { id: 3, description: 'IVA no Responsable' },
    { id: 4, description: 'Consumidor Final' },
    { id: 5, description: 'IVA Sujeto Exento' },
    { id: 6, description: 'Responsable Monotributo' },
    { id: 7, description: 'Sujeto no Categorizado' },
    { id: 8, description: 'Proveedor del Exterior' },
    { id: 9, description: 'Cliente de Exterior' },
    { id: 10, description: 'IVA Liberado - Ley N 19.640' },
    { id: 11, description: 'IVA Responsable Inscripto - Agente de Percepción' },
    { id: 12, description: 'Pequeño Contribuyente Eventual' },
    { id: 13, description: 'Monotributista Social' },
    { id: 14, description: 'Pequeño Contribuyente Eventual Social' },

];