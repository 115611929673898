import { styled } from '@mui/material/styles';
import backgroundImage from '../../../assets/images/field-background.webp';
import { Box, Grid, Link, Stack } from '@mui/material';
import siloRealLogo from '../../../assets/logos/siloreal-logo-blanco.png';
import i18n from '../../../libs/language';
import { useEffectOnMount } from '@hooks/core';
import { useGlobalStore } from '@global-store/use-store';
import { SILOREAL_WEBSITE_VERSION } from '@data/constants';

type AuthLayoutProps = {
  readonly children: any;
};

const bgContent = {
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  height: '100vh',
  width: '100vw',
  minHeight: 300,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const StyledGrid = styled(Grid)(bgContent);

const AuthLayoutComponent = (props: AuthLayoutProps) => {
  const { children } = props;
  const { setLanguage } = useGlobalStore();

  useEffectOnMount(() => {
    // Obtiene el idioma del navegador
    const browserLanguage = navigator.language;
    switch (browserLanguage) {
      case 'pt':
      case 'pt-BR':
        i18n.locale = 'pt';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'pt');
        break;
      case 'es':
      case 'es-ES':
        i18n.locale = 'es';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'es');
        break;
    }
  });

  const currentYear = new Date().getFullYear();

  return (
    <Grid container direction={{ xs: 'column', md: 'row' }}>
      <StyledGrid item xs={5}>
        <img src={siloRealLogo} alt="logo" style={{ width: '35%', height: '10%', objectFit: 'contain' }} />
      </StyledGrid>
      <Grid item xs={7}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems={{ xs: '', md: 'center' }}
          style={{
            height: '90vh',
            maxWidth: '95%',
          }}
        >
          {children}
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Link
            variant="body2"
            style={{ color: 'black', fontWeight: 300, textDecoration: 'none', marginLeft: '20px' }}
          >
            ©{currentYear} SiloReal v{SILOREAL_WEBSITE_VERSION}
          </Link>
          <Stack direction="row" justifyContent="space-around" spacing={2} marginRight="20px">
            <Link
              variant="body2"
              sx={{ color: 'black', textDecoration: 'none' }}
              href="https://iof-company.notion.site/Pol-tica-de-Privacidad-b703d03af18e42bb8229d24229c58d50"
              target="_blank"
            >
              {i18n.t('app.public_pages.policy_privacy')}
            </Link>
            <Link
              variant="body2"
              sx={{ color: 'black', textDecoration: 'none' }}
              href="https://iof-company.notion.site/T-rminos-y-Condiciones-Generales-9377d150dc764653b412fd68f80af8af"
              target="_blank"
            >
              {i18n.t('app.public_pages.terms_conditions')}
            </Link>
            <Link
              variant="body2"
              sx={{ color: 'black', textDecoration: 'none' }}
              href="mailto:info@siloreal.com"
              target="_blank"
            >
              {i18n.t('app.public_pages.contact')}
            </Link>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthLayoutComponent;
