import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

export const getWebSilobags = async (organizationId: number | undefined) => {
  const { response, data } = await BACKEND.get(ENDPOINT.WEB_SILOBAGS, {
    params: '/' + organizationId,
  });

  return {
    response,
    summary: data,
  };
};
