import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { PoeQuestionPayload, UpdatedPoeQuestionData } from './poe-question-edition.types';

// deprecated service ref.: updatePoeQuestionListService_DEPRECATED

// implementado ✅
export async function createPoeQuestionService({ body }: PoeQuestionPayload) {
  const { response, data } = await BACKEND.post<UpdatedPoeQuestionData>(ENDPOINT.POE, { body });

  return {
    response,
    summary: data,
  };
}
