import { Box, Button, CircularProgress, Divider, Fade, Modal, Stack } from '@mui/material';

import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import { PropsWithChildren } from 'react';

type DeleteModalProps = {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  setIsError?: (val: boolean) => void;
  targetTextRef: string;
  targetName: string | JSX.Element;
  targetID: string | number;
  service: Parameters<typeof useService>[0]; //(id: string | number) => unknown;
  onSuccessTrigger: () => void;
  toggleDeleteSnackbar: (tog: boolean) => void;
  setAction?: (value: boolean) => void;
};

export function DeleteModal({
  open,
  setIsOpen,
  setIsError,
  targetTextRef,
  targetName,
  targetID,
  service,
  onSuccessTrigger,
  toggleDeleteSnackbar,
  setAction,
}: DeleteModalProps) {
  const handleClose = () => {
    setIsOpen(false);
  };

  const closeOnSuccess = () => {
    setIsOpen(false);
    onSuccessTrigger();
    toggleDeleteSnackbar(true);
  };

  const { trigger, isLoading, isError } = useService(service, {
    onSuccess: () => {
      closeOnSuccess();
      if (setAction) setAction(true);
    },
  });

  useEffectOnUpdate(() => {
    if (isError && setIsError) {
      setIsError(isError);
    }
  }, [isError]);

  return (
    <MyModal open={open} onClose={handleClose}>
      <Stack direction="row" spacing={2} alignItems="center" style={{ fontWeight: '600', fontSize: '24px' }}>
        <img style={{ width: 20, height: 20 }} src={DeleteIcon} />
        <Box>Confirmar Eliminación</Box>
      </Stack>
      <Divider />
      <Box>
        ¿Está seguro que desea eliminar {targetTextRef}
        <Box style={{ fontWeight: 600, color: '#F00', display: 'inline-block' }}>&nbsp;{targetName}</Box>?
      </Box>
      <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={1}>
        <Button
          variant="outlined"
          style={{
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
          }}
          onClick={handleClose}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="error"
          disabled={isLoading}
          style={{
            color: '#FFF',
            width: 115,
            borderRadius: 100,
            fontWeight: 600,
            textTransform: 'none',
          }}
          onClick={() => trigger({ query: targetID })}
        >
          {isLoading ? <CircularProgress style={{ width: 24, height: 24, color: '#FF0000' }} /> : 'Eliminar'}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
