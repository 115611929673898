import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { OrganizationData, OrganizationPayload } from './organization-data.types';

// deprecated service ref.: getOrganizationData_DEPRECATED

// ✅ Implementado
export async function organizationDataService({ query: rawQuery }: OrganizationPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<OrganizationData>(ENDPOINT.ORGANIZATIONS, { query });
  const [summary] = data;

  return {
    response,
    summary,
  };
}
