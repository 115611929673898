import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { ShareSilobagBody, ShareSilobagResponse } from './share-creation.types';

// deprecated service ref.: postSilobagsPermissions_DEPRECATED

// Implementado ✅
export async function createSharePermissionService({ body }: ShareSilobagBody) {

  const { response, data } = await BACKEND.post<ShareSilobagResponse>(ENDPOINT.SILO_PERMISSION, { body });

  return {
    response,
    summary: data
  };
}
