import { useNavigate } from 'react-router-dom';
import { useGlobalStore } from '@global-store/use-store';
import PoweredBySiloReal from '../../../assets/svg/DashboardPage/PoweredBySiloReal.svg';
import { Stack } from '@mui/material';
import SiloRealLogo from '@assets/logos/siloreal-logo-black.webp';

export function MainLogo() {
  const { organizationLogo } = useGlobalStore();
  const navigate = useNavigate();

  return (
    <Stack mr={{ xs: 0, md: 2 }}>
      <img
        style={{
          width: 80,
          height: 40,
          borderRadius: 0,
          objectFit: 'contain',
          cursor: 'pointer',
          marginRight: 20,
        }}
        src={organizationLogo ?? SiloRealLogo}
        onClick={() => navigate('/')}
      />
      <img src={PoweredBySiloReal} alt="powered-by-siloreal" style={{ width: 100 }} />
    </Stack>
  );
}
