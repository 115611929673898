const Colors: any = {
  0: '#FD7E14',
  1: '#F4C525',
  2: '#6BAA00',
  3: '#6BAA00',
  4: '#F4C525',
  5: '#FD7E14',
};

const TrackerColors: any = {
  0: '#FD7E14',
  1: '#FD7E14',
  2: '#F4C525',
  3: '#6BAA00',
  4: '#F4C525',
  5: '#FD7E14',
};
export const renderHandleColor = (source: number) => {
  return Colors && Colors[source] ? Colors[source] : '#546C91';
};

export const renderTrackerColor = (source: string) => {
  return TrackerColors && TrackerColors[source] ? TrackerColors[source] : '#546C91';
};

export enum Fonts {
  LIGHT = '300',
  REGULAR = '400',
  MEDIUM = '500',
  SEMI_BOLD = '600',
  BOLD = '700',
}

export const IMAGES_STATUS: any = {
  1: 'Se Visualiza',
  2: 'No se Visualiza',
  3: 'Sin Analizar',
  4: 'Sin Determinar',
};

export const dataExcelEs = [
  [
    'Compañía',
    'Establecimiento',
    'Nombre Silobolsa',
    'Especie',
    'Producto',
    'Peso Embolsado (Kg)',
    'Lote Stock',
    'Fecha Embolsado',
    'Variedad',
    'Humedad (%)',
    'Largo (m)',
    'Cosecha',
    'Lotes origen',
    'Responsable embolsado',
    'Marca del silobolsa',
    '% Impureza',
    '% Defectuoso',
    'Coeficiente de llenado (kg/m)',
    'Fuente de la humedad',
    'Fuente del peso',
    'Camión carga sobre',
    'Observaciones',
  ],
];

export const dataExcelPt = [
  [
    'Empresa',
    'Estabelecimento',
    'Nome do Silobag',
    'Espécie',
    'Produto',
    'Peso Embolsado (Kg)',
    'Lote Stock',
    'Data de Embolsado',
    'Variedade',
    'Umidade (%)',
    'Comprimento (m)',
    'Colheita',
    'Lotes de origem',
    'Responsável pelo embolsado',
    'Marca do silobag',
    '% Impureza',
    '% Defeituoso',
    'Coeficiente de enchimento (kg/m)',
    'Fonte de umidade',
    'Fonte de peso',
    'Caminhão de carga sobre',
    'Observações',
  ],
];

export const dataCloseSilobag = [['ID']];

export const LANGUAGE_PREFERENCE: any = {
  ES: 'es',
  PT: 'pt',
  EN: 'en',
};

export const SILOBAG_STATUS: any = {
  es: {
    1: 'Optimo',
    2: 'Precaución',
    3: 'Riesgo elevado',
    4: 'Crítico',
    default: 'Sin datos',
  },
  pt: {
    1: 'Ótimo',
    2: 'Cuidado',
    3: 'Alto risco',
    4: 'Crítico',
    default: 'Sem dados',
  },
};

export const SILOBAG_STATUS_COLORS: any = {
  1: '#6BAA00',
  2: '#FFDD00',
  3: '#FD7E14',
  4: '#FF0000',
  default: '#6A6A6A',
};

export const SILOBAG_STATUSES_ID = {
  VALID_SILOBAG_STATUS_ID: 2,
  NON_VALID_SILOBAG_STATUS_ID: 1,
};

export const SILOBAG_EVENTS_IDS = {
  ALTA: 4,
  EMBOLSADO: 5,
  EDICION_SILOBOLSA: 7,
  COMPLETADO_DATOS: 14,
  ALTA_CAMPO: 15,
  ALTA_WEB: 16,
};
