import { Grid, Box, Typography, Tooltip, Stack } from '@mui/material';
import { customBgWhite, separatorReports } from '@styles/generic-styles';
import { formatTons } from '@utils/helper';
import { ChartStock } from './chart-stock';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from '../../libs/language';
import helpIcon from '@assets/svg/help-icon-blue.svg';
import { stockMovementListService } from '@services/domain/page/report/movement-stock/report-mov-stock-info';

type ReportStockSummaryType = Awaited<ReturnType<typeof stockMovementListService>>['summary'];
type DashboardStockProps = {
  totalsData?: ReportStockSummaryType['totals'];
  data?: ReportStockSummaryType['data'];
  isLoading?: boolean;
  startDate?: string | undefined;
  endDate?: string | undefined;
  countrysideName?: string | undefined;
  silobagName?: string | undefined;
};

export const DashboardStock = ({
  totalsData,
  data,
  isLoading,
  startDate,
  endDate,
  countrysideName,
  silobagName,
}: DashboardStockProps) => {
  return (
    <Box sx={customBgWhite}>
      {isLoading ? (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: '#6baa00' }} size={60} />
        </Box>
      ) : (
        <Box>
          <Box display="flex" mx={2} justifyContent={{ xs: 'center', md: 'right' }}>
            {startDate ? (
              <span style={{ marginRight: '10px', color: '#A7A7A7' }}>
                {i18n.t('app.date')}: {startDate} / {endDate}
              </span>
            ) : null}
            {countrysideName ? (
              <span style={{ marginRight: '10px', color: '#A7A7A7' }}>
                | {i18n.t('app.countryside')}: {countrysideName}
              </span>
            ) : null}
            {silobagName ? (
              <span style={{ marginRight: '10px', color: '#A7A7A7' }}>
                | {i18n.t('app.silobag')}: {silobagName}
              </span>
            ) : null}
          </Box>
          <Grid container marginTop={4} alignItems="center">
            <Grid
              item
              xs={6}
              md={3}
              sx={separatorReports}
              borderRight="1.6px solid #d3d0d0"
              paddingLeft={4}
              color={'#49454F'}
            >
              <Stack spacing={-0.5}>
                <Stack direction="row" spacing={1}>
                  <Typography color="#49454F" fontSize={{ xs: 11, sm: 14, lg: 20 }} fontWeight={600}>
                    {i18n.t('app.report_stock.initial_balance')}
                  </Typography>
                  <Tooltip title={i18n.t('app.report_stock.initial_description')} placement="top" arrow>
                    <img src={helpIcon} style={{ width: 16, height: 16 }} />
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="#49454F" fontSize={{ xs: 20, sm: 30, lg: 40 }} fontWeight={600}>
                    {i18n.numberToDelimited(formatTons(totalsData?.balanceInitial) || 0, {
                      delimiter: i18n.t('config.delimiter'),
                    }) || 0}
                  </Typography>
                  <Typography color="#49454F" fontSize={{ xs: 9, sm: 12, lg: 16 }}>
                    {i18n.t('app.tons')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={5}
              md={3}
              borderRight={{ xs: 'none', md: '1.6px solid #d3d0d0' }}
              paddingLeft={4}
              color={'#49454F'}
            >
              <Stack spacing={-0.5}>
                <Stack direction="row" spacing={1}>
                  <Typography color="#49454F" fontSize={{ xs: 11, sm: 14, lg: 20 }} fontWeight={600}>
                    {i18n.t('app.report_stock.incomes')}
                  </Typography>
                  <Tooltip title={i18n.t('app.report_stock.incomes_description')} placement="top" arrow>
                    <img src={helpIcon} style={{ width: 16, height: 16 }} />
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="#49454F" fontSize={{ xs: 20, sm: 30, lg: 40 }} fontWeight={600}>
                    {i18n.numberToDelimited(formatTons(totalsData?.ingressTotal) || 0, {
                      delimiter: i18n.t('config.delimiter'),
                    }) || 0}
                  </Typography>
                  <Typography color="#49454F" fontSize={{ xs: 9, sm: 12, lg: 16 }}>
                    {i18n.t('app.tons')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6} md={3} borderRight="1.6px solid #d3d0d0" paddingLeft={4} color={'#49454F'}>
              <Stack spacing={-0.5}>
                <Stack direction="row" spacing={1}>
                  <Typography color="#49454F" fontSize={{ xs: 11, sm: 14, lg: 20 }} fontWeight={600}>
                    {i18n.t('app.report_stock.outcomes')}
                  </Typography>
                  <Tooltip title={i18n.t('app.report_stock.outcomes_description')} placement="top" arrow>
                    <img src={helpIcon} style={{ width: 16, height: 16 }} />
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="#49454F" fontSize={{ xs: 20, sm: 30, lg: 40 }} fontWeight={600}>
                    {i18n.numberToDelimited(formatTons(totalsData?.egressTotal) || 0, {
                      delimiter: i18n.t('config.delimiter'),
                    }) || 0}
                  </Typography>
                  <Typography color="#49454F" fontSize={{ xs: 9, sm: 12, lg: 16 }}>
                    {i18n.t('app.tons')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6} md={3} paddingLeft={4} color={'#49454F'}>
              <Stack spacing={-0.5}>
                <Stack direction="row" spacing={1}>
                  <Typography color="#49454F" fontSize={{ xs: 11, sm: 14, lg: 20 }} fontWeight={600}>
                    {i18n.t('app.report_stock.final_balance')}
                  </Typography>
                  <Tooltip title={i18n.t('app.report_stock.final_description')} placement="top" arrow>
                    <img src={helpIcon} style={{ width: 16, height: 16 }} />
                  </Tooltip>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography color="#49454F" fontSize={{ xs: 20, sm: 30, lg: 40 }} fontWeight={600}>
                    {i18n.numberToDelimited(formatTons(totalsData?.balanceFinal) || 0, {
                      delimiter: i18n.t('config.delimiter'),
                    }) || 0}
                  </Typography>
                  <Typography color="#49454F" fontSize={{ xs: 9, sm: 12, lg: 16 }}>
                    {i18n.t('app.tons')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <ChartStock data={data} />
        </Box>
      )}
    </Box>
  );
};
