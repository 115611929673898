import { GlobalFooter } from '@features/global-footer';
import { GlobalNav } from '@features/global-nav';
import { Container, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

type Props = {
  withHeader?: boolean;
  withFooter?: boolean;
  withSideSpaces?: boolean;
  footerConfig?: { withMarginTop: boolean };
};

export function PrivatePagesLayout({ withHeader, withFooter, withSideSpaces, footerConfig }: Props) {
  return (
    <Stack style={{ height: '100%' }}>
      {withHeader ? <GlobalNav /> : null}
      <Container
        disableGutters
        fixed={withSideSpaces}
        maxWidth={withSideSpaces ? 'xl' : false}
        style={{ flexGrow: 1 }}
      >
        <Outlet />
      </Container>
      {withFooter ? <GlobalFooter hasMarginTop={footerConfig?.withMarginTop} /> : null}
    </Stack>
  );
}
