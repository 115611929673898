import { Grid, Box, Stack, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import { AdminOrgDataPanel } from '@features/administrator/section-org-data/org-data-panel';
import { AdminCustomizationBar } from '@features/administrator/section-customization/customization-bar';
import { AdminCompanyList } from '@features/administrator/list-company/company-list';
import { AdminCountrysideList } from '@features/administrator/list-countryside/countryside-list';
import { AdminUserList } from '@features/administrator/list-user/user-list';

import { useService } from '@hooks/use-service';
import { useGlobalStore } from '@global-store/use-store';
import { useLocation } from 'react-router-dom';
import i18n from '../../libs/language';
import { useState } from 'react';
import { useEffectOnUpdate } from '@hooks/core';
import { organizationDataService } from '@services/domain/base/organization/organization-data';

export const AdministratorPage = () => {
  const { userOrganizationId } = useGlobalStore();
  const [inspectionModalToggle, setInspectionModalToggle] = useState(false);
  const [actionInCompany, setActionInCompany] = useState(false);
  const [actionInCountryside, setActionInCountryside] = useState(false);
  const location = useLocation();

  const {
    trigger, //
    summary: orgData, //
  } = useService(organizationDataService, { fetchOnMount: [{ query: userOrganizationId! }] });
  const reFetchOrgData = () => trigger({ query: userOrganizationId! });

  const theme = useTheme();
  const matchMD = useMediaQuery(theme.breakpoints.down('lg'));

  useEffectOnUpdate(() => {
    if (inspectionModalToggle) {
      setInspectionModalToggle(false);
    }
  }, [inspectionModalToggle]);

  return (
    <Box
      style={{
        backgroundColor: '#f3f2f7',
        width: '98%',
        margin: '0 auto',
        padding: 1,
      }}
    >
      <Stack>
        <Grid
          item
          xs={10}
          sx={{ display: 'flex', gap: 3, justifyContent: 'space-between', alignItems: 'center', my: 2 }}
        >
          <Box sx={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.administrator.title')}</Box>
          {orgData ? (
            <Box style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: '600' }}>{i18n.t('app.organization')}: </span>
              {orgData.name}
            </Box>
          ) : (
            <Stack style={{ width: '15%' }} justifyContent="center" alignItems="center">
              <CircularProgress size={16} />
            </Stack>
          )}
        </Grid>
        <AdminOrgDataPanel orgData={orgData} onSuccessTrigger={reFetchOrgData} updateAdminData={trigger} />
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'grid',
            gridTemplateColumns: matchMD ? 'auto' : '1fr 1fr 1fr 1fr',
            gridTemplateRows: matchMD ? 'auto' : 'auto auto 1fr 1fr',
            gap: 10,
          }}
        >
          <AdminCustomizationBar state={location.state} onRefreshAlborList={setInspectionModalToggle} />
          <AdminCompanyList setActionInCompany={setActionInCompany} />
          <AdminCountrysideList
            integrationModalClosed={inspectionModalToggle}
            actionInCompany={actionInCompany}
            setActionInCompany={setActionInCompany}
            setActionInCountryside={setActionInCountryside}
          />
          <AdminUserList
            actionInCompany={actionInCompany}
            actionInCountryside={actionInCountryside}
            setActionInCountryside={setActionInCountryside}
          />
        </Grid>
      </Stack>
    </Box>
  );
};
