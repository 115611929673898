import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CountrysideOptionsPayload, CountrysideOptionsData } from './countryside-option-list.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: countrysidesSelectService_DEPRECATED

// Implementado ✅
export async function countrysideOptionListService({ query: rawQuery }: CountrysideOptionsPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<CountrysideOptionsData>(ENDPOINT.DEVICES_REPORT_COUNTRYSIDES, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
