import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { ClosedSilobagData, SilobagClosePayload } from './silobag-close.types';

// deprecated service ref.: closeSilobag_DEPRECATED

// implementado ✅
export async function closeSilobagService({ query: rawQuery, body }: SilobagClosePayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.post<ClosedSilobagData>(ENDPOINT.SILOBAG_CLOSE, { query, body });

  return {
    response,
    summary: data,
  };
}
