import { useService } from '@hooks/use-service';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../libs/language';
import LupaIcon from '@assets/svg/search.svg';

import { listSelectableOrgService } from '@services/domain/base/organization/organization-selectable-list';

export function UserOrganizationSelector({ isMobile: mobile = false }) {
  const { summary, isLoading } = useService(listSelectableOrgService, { fetchOnMount: true });

  const {
    organizationId,
    setOrganizationId,
    setFilters,
    setFilteredUrl,
    setFiltersReportStock,
    setFiltersReportDevices,
    setFiltersReportActivity,
    setFiltersReportExistence,
    setFiltersReportStateStock,
    setSelectedOrganizationName,
  } = useGlobalStore();

  const handleOrganizationSelector = (event: SelectChangeEvent) => {
    setOrganizationId(+event.target.value);
    const orgName = summary?.find((elem) => elem.id === +event.target.value);
    setSelectedOrganizationName(orgName?.name ?? '');
    //setSelectedOrganizationName()
    //Para resetear los filtros globales en caso de cambiar de organización
    //silobags
    setFilteredUrl('');
    setFilters({
      countryside_id: '',
      lot_id: '',
      silobag_species_type_id: '',
      silobag_product_type_id: '',
      silobag_harvest_id: '',
      sat_view_active: '',
      is_silo_metrix_active: '',
      silobag_data_status_id: 2,
      color_id: '',
      name: '',
      from: '',
      to: '',
      active: true,
      paginationModel: { page: 0, pageSize: 50 },
    });
    //reporte de stock
    setFiltersReportStock({ silobagId: '', silobagName: '', countrysideId: '', countrysideName: '' });
    //reporte de dispositivos
    setFiltersReportDevices({
      lance_number: '',
      silobagId: '',
      countrysideId: undefined,
      installed: '',
      report: undefined,
      paginationModel: { page: 0, pageSize: 50 },
    });
    //reporte de actividades
    setFiltersReportActivity({
      silobagId: '',
      countrysideId: '',
      typeId: '',
      userId: '',
      paginationModel: { page: 0, pageSize: 50 },
    });
    //reporte de existencias
    setFiltersReportExistence({
      countrysideId: '',
      specieId: '',
      paginationModel: { page: 0, pageSize: 50 },
    });
    //reporte de estado de stock
    setFiltersReportStateStock({
      name: '',
      countrysideId: '',
      paginationModel: { page: 0, pageSize: 50 },
    });
    const selected = event.target.value as string;
    if (selected == '0') {
      setOrganizationId(undefined);
      setSelectedOrganizationName('');
    }
  };

  return (
    <Box ml={2} mr={2}>
      <FormControl size="small" sx={{ width: !mobile ? 200 : '100%', maxWidth: !mobile ? 200 : 500 }}>
        <InputLabel sx={{ fontSize: '0.9rem' }}>{i18n.t('app.navbar.organizations')}</InputLabel>
        <Select
          value={organizationId?.toString() ?? ''}
          label="Organizacion"
          startAdornment={<img src={LupaIcon} style={{ marginRight: 5 }} />}
          onChange={handleOrganizationSelector}
          sx={mobile ? { height: 50 } : { height: 35 }}
        >
          <MenuItem value={0}>{i18n.t('app.navbar.all_organizations')}</MenuItem>
          {isLoading ? (
            <CircularProgress />
          ) : (
            summary?.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
