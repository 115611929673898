import { useEffect, useState } from 'react';
import { useAuth } from '@hooks/use-auth';
import { useLocation } from 'react-router-dom';
import { Grid, TextField, Button, Link, Box, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router';
import { styledTextField } from '@styles/generic-styles';
import { PATH } from '@data/enums';
import { useGlobalStore } from '@global-store/use-store';
import { useEffectOnMount, useToggle } from '@hooks/core';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastAction } from '@ui/toast/toast-action';
import i18n from '../../../libs/language';
import EyeIcon from '@assets/svg/core/eye-hollow-green.svg';
import CrossEyeIcon from '@assets/svg/core/crossed-eye-green.svg';
import { useConnection } from '@hooks/use-connection';

interface ILoginRequest {
  username: string;
  password: string;
}

export const LoginForm = () => {
  const { setEmail } = useGlobalStore();
  const [loginRequest, setLoginRequest] = useState<ILoginRequest>({
    username: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useToggle(false);
  const { login, isLoading, isError } = useAuth();

  const { state } = useLocation();
  const navigate = useNavigate();

  const submit = async (event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    login({
      username: loginRequest.username,
      password: loginRequest.password,
      fromPath: state?.from,
    });
  };

  useEffectOnMount(() => {
    setEmail(undefined);
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword();
  };

  const isOnline = useConnection();

  const email = useGlobalStore.getState().email;

  useEffect(() => {
    if (!isOnline) {
      return navigate(PATH.NO_CONNECTION);
    } else if (isOnline && email) {
      return navigate(PATH.DASHBOARD);
    } else if (isOnline && !email) {
      return navigate(PATH.LOGIN);
    }
  }, [isOnline]);

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        spacing={3}
        component="form"
      >
        <Grid item xs={12}>
          <h1 style={{ fontWeight: '300', margin: 0 }}>{i18n.t('app.public_pages.login')}</h1>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="email"
            name="username"
            label={i18n.t('app.public_pages.user')}
            variant="outlined"
            size="small"
            onChange={(e) => {
              setLoginRequest((prev) => ({ ...prev, username: e.target.value }));
            }}
            sx={styledTextField}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            label={i18n.t('app.public_pages.password')}
            variant="outlined"
            size="small"
            sx={styledTextField}
            onChange={(e) => {
              setLoginRequest((prev) => ({ ...prev, password: e.target.value }));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                submit(e);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            variant="body2"
            onClick={() => {
              navigate(PATH.FORGOT_PASSWORD);
            }}
            underline="none"
            style={{ cursor: 'pointer' }}
          >
            {i18n.t('app.public_pages.forgot_password')}
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            sx={{ width: '400px', color: 'white' }}
            disableElevation
            onClick={submit}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} />
            ) : (
              i18n.t('app.public_pages.ingress')
            )}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Link
            variant="body2"
            onClick={() => {
              navigate(PATH.REGISTER);
            }}
            underline="none"
            style={{ cursor: 'pointer' }}
          >
            {i18n.t('app.public_pages.register_question')}
          </Link>
        </Grid>
      </Grid>
      {isError && <ToastAction message="Usuario o contraseña invalidos" severity="error" />}
    </Box>
  );
};
