import { ModalIOF } from '@ui/modal/modal';
import { configButtonStylesContained } from '@styles/generic-styles';
import i18n from '../../../../../libs/language';

type ObservationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  observations: string;
};

export const ObservationModal = ({ open, setOpen, observations }: ObservationModalProps) => {
  const content = observations && observations.length > 0 ? observations : i18n.t('app.without_observations');
    return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      <ModalIOF.Header>
        <ModalIOF.Title>{i18n.t('app.observations')}</ModalIOF.Title>
        <ModalIOF.Helper>
          <ModalIOF.Button close variant="contained" size="medium" sx={configButtonStylesContained}>
            {i18n.t('app.close')}
          </ModalIOF.Button>
        </ModalIOF.Helper>
      </ModalIOF.Header>
      <ModalIOF.Body>
        <p style={{ textAlign: 'center' }}>{content}</p>
      </ModalIOF.Body>
    </ModalIOF>
  );
};
