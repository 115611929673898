import {
  Box,
  Stack,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Button,
  Typography,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material';
import i18n from '../../libs/language';
import { stylesInput } from '@styles/generic-styles';
import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import SearchIcon from '@assets/svg/search.svg';
import { useRef } from 'react';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { TabletStateStock } from '@features/report-state-stock/tablet-state-stock';
import { useDebounce } from '@hooks/core';
import { useEffectOnUpdate } from '@hooks/core';
import { getWebSilobags } from '@services/web_silobags/web-silobags';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { stockStatusListService } from '@services/domain/page/report/status-stock/report-status-stock.info';

export function StateStockPage() {
  const { organizationId, filtersReportStateStock, setFiltersReportStateStock } = useGlobalStore();
  const inputRef = useRef<HTMLInputElement>(null);

  const { trigger: triggerGetWebSilobags, summary: webSilobagsCount } = useService(getWebSilobags, {
    fetchOnMount: [organizationId],
  });

  //StateStock Service
  const {
    trigger: getStateStock,
    summary: stateStockData,
    isLoading: isLoadingStateStock,
  } = useService(stockStatusListService, {
    fetchOnMount: true,
  });
  //Countrysides Service
  const queryCountrysideList = { organization_id: organizationId };
  const {
    //trigger: getCountrysides,
    summary: countrysides,
    isLoading: isLoadingCountryside,
    trigger: getCountrysides,
  } = useService(listCountrysideService, { fetchOnMount: [{ query: queryCountrysideList }] });

  useDebounce(() => getStateStock(), 500, [filtersReportStateStock.name]);

  const handlerCountrysideID = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFiltersReportStateStock({ ...filtersReportStateStock, countrysideId: selectedValue });
  };

  const removeFilter = () => {
    setFiltersReportStateStock({ name: '', countrysideId: '', paginationModel: { page: 0, pageSize: 50 } });
    getStateStock();
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffectOnUpdate(() => {
    getStateStock();
  }, [filtersReportStateStock.countrysideId, filtersReportStateStock.paginationModel, organizationId]);

  useEffectOnUpdate(() => {
    getCountrysides({ query: queryCountrysideList });
    triggerGetWebSilobags(organizationId);
  }, [organizationId]);

  return (
    <Box sx={{ width: '95%', margin: 'auto' }}>
      <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
        {i18n.t('app.state_stock_page.report_state_stock')}
      </Box>

      <Box component="section" position={'relative'}>
        <Stack direction={'row'} gap={1}>
          <FormControl style={{ width: '25%', fontSize: 14, marginRight: '10px', height: '100%' }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder={i18n.t('app.state_stock_page.search_by_name_silo_countryside')}
              style={stylesInput}
              value={filtersReportStateStock.name}
              onChange={(e) => {
                setFiltersReportStateStock({ ...filtersReportStateStock, name: e.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
                inputProps: { style: { fontSize: 13, height: 22 } },
              }}
              inputRef={inputRef}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 180, width: '25%', height: '100%' }}
            sx={stylesInput}
          >
            <InputLabel id="demo-multiple-name-label">{i18n.t('app.countrysides')}</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="countryside_id"
              input={<OutlinedInput label={i18n.t('app.countrysides')} />}
              onChange={(e) => handlerCountrysideID(e)}
              disabled={isLoadingCountryside}
              value={filtersReportStateStock.countrysideId}
            >
              <MenuItem value={''}>
                <Typography>{i18n.t('app.countrysides')}</Typography>
              </MenuItem>
              {countrysides?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{
              fontSize: 12,
              fontWeight: 500,
              paddingInline: 3,
              textTransform: 'uppercase',
              visibility:
                filtersReportStateStock.countrysideId || filtersReportStateStock.name ? '' : 'hidden',
              color: '#FF0000',
              '&:hover': { background: '#FF000022' },
            }}
            onClick={removeFilter}
            startIcon={<img src={DeleteIcon} alt="delete-icon" />}
          >
            {i18n.t('app.clear_filters')}
          </Button>

          <Box width={'100%'} display={'flex'} justifyContent={'end'}>
            <Box component={'div'} width={'20%'} zIndex={'99'} position={'absolute'}>
              {webSilobagsCount?.incomplete_silobags_count > 0 && (
                <Tooltip title={i18n.t('app.missing_tons')} placement="top-end">
                  <Typography color={'#7A7A7A'} textAlign={'end'} sx={{ cursor: 'default', marginRight: 2 }}>
                    {i18n.t('app.missing_tons_title')}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Stack>
      </Box>

      <Box>
        {/* Componente de la tabla de la vista */}
        <TabletStateStock
          data={stateStockData?.rows ?? []}
          count={stateStockData?.count ?? 0}
          isLoading={isLoadingStateStock}
          paginationModel={filtersReportStateStock?.paginationModel}
          setPaginationModel={(fn) => {
            const paginationModel = fn(filtersReportStateStock.paginationModel);
            setFiltersReportStateStock({ ...filtersReportStateStock, paginationModel });
          }}
        />
      </Box>
    </Box>
  );
}
