import { Box, Button, Fade, Modal, Stack } from '@mui/material';
import { Filters } from './map-filters';
import { Menu } from './map-menu';
import { useMap } from '../use-map';
import { PropsWithChildren, useState } from 'react';
import { SilobagSilosat } from '@features/silobags/silobag-detail/section-satellite/silobag-silosat';

import { TargetSilobag } from '../map.context';

export function MapUI() {
  const { map, hideUI } = useMap();

  // TODO (para emi): recordar mover el modal de silosat a map-menu.tsx en el futuro al lado del CarouselModalPhotoGallery
  const [openModal, setOpenModal] = useState(false);
  const [silobagData, setSilobagData] = useState<TargetSilobag>();
  const handleOnClose = () => {
    setOpenModal(false);
  };

  return (
    <Stack
      direction="row"
      ref={(node) => {
        const mapControls = map?.controls[google.maps.ControlPosition.TOP_LEFT];
        const mapControlsArray = mapControls?.getArray();

        if (node && !mapControlsArray?.includes(node))
          map?.controls[google.maps.ControlPosition.TOP_LEFT].push(node);
      }}
      style={{ pointerEvents: 'none', display: hideUI ? 'none' : '' }}
    >
      <Menu setOpenModal={setOpenModal} setSilobagData={setSilobagData} />
      <Filters />
      {silobagData ? (
        <MyModal open={openModal} onClose={handleOnClose}>
          <SilobagSilosat silosat={silobagData.sat_view} />
          <Box display={'flex'} justifyContent={'end'}>
            <Button variant="text" onClick={handleOnClose}>
              Cerrar
            </Button>
          </Box>
        </MyModal>
      ) : null}
    </Stack>
  );
}
type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',

    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
