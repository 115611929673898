import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { useGlobalStore } from '@global-store/use-store';
import { transformIntoQueryParamString } from '@utils/service';

import { SearchedSilobagData, SearchSilobagPayload } from './silobag-search.types';

export async function searchSilobagService({ query: search }: SearchSilobagPayload) {
  const { organizationId } = useGlobalStore.getState();
  const query = transformIntoQueryParamString(search, { organization_id: organizationId });

  const { response, data } = await BACKEND.get<SearchedSilobagData>(ENDPOINT.SILOBAGS_SEARCH, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
