import { Button, SxProps, Theme } from '@mui/material';
interface CustomButtonProps {
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';
  text?: string;
  size?: 'small' | 'medium' | 'large';
  onClick?: any;
  sx?: SxProps<Theme>;
}

export function ActionButton(props: CustomButtonProps) {
  const { variant, text, size, color, onClick, sx } = props;

  return (
    <Button variant={variant} size={size} sx={sx} onClick={onClick} color={color}>
      {text}
    </Button>
  );
}
