import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { OrganizationEditionPayload, UpdatedOrganizationData } from './organization-edition.types';

// deprecated service ref.: updateOrganizationData_DEPRECATED

// ✅ Implementado
export async function updateOrganizationDataService({ query: rawQuery, body }: OrganizationEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<UpdatedOrganizationData>(ENDPOINT.ORGANIZATIONS, {
    query,
    body,
  });

  return {
    response,
    summary: data,
  };
}
