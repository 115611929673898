import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CorvusEstablishmentResponse } from './get-establishment-corvus.types'

//getEstablishmentsCorvusService

export async function getEstablishmentsCorvusService() {
  const { response, data } = await BACKEND.get<CorvusEstablishmentResponse>(ENDPOINT.CORVUS_ESTABLISHMENTS);
  return {
    response,
    summary: data,
  };
};