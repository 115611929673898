import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

// ✅ Está procesado
export async function cmcsService_DEPRECATED(silobagId: string, silobagHistory: boolean = false) {
  const isHistory = silobagHistory ? '&silobagHistory=true' : '';
  const { response, data } = await BACKEND.post(ENDPOINT.CMCS, {
    params: `/${silobagId}?blockchain=true${isHistory}`,
  });

  return {
    response,
    summary: data,
  };
}

// ✅ Está procesado
export async function cmcaService_DEPRECATED(silobagIds: string | string[], silobagHistory: boolean = false) {
  const responses = [];
  const summaries = [];
  const silobagIdArray = Array.isArray(silobagIds) ? silobagIds : [silobagIds];
  for (const silobagId of silobagIdArray) {
    const params =
      new URLSearchParams({ silobagHistory: silobagHistory ? 'true' : 'false' }).toString() +
      '&blockchain=true';
    const { response, data } = await BACKEND.post(ENDPOINT.CMCS, { params: `/${silobagId}?${params}` });
    responses.push(response);
    summaries.push(data);
  }

  return { response: responses, summary: summaries };
}

// ❌ Falta procesar
export async function getCmcsService(silobagId: string) {
  const { response, data } = await BACKEND.get(ENDPOINT.CMCS, { params: `/silobag/${silobagId}` });
  return {
    response,
    summary: data,
  };
}
