import { ENDPOINT } from '@data/enums';
import { useGlobalStore } from '@global-store/use-store';

type FetchBackendParams = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  endpoint: ENDPOINT;
  params?: string;
  body?: unknown;
};

type FetchBackendResponse<TData = unknown> = {
  response: Response;
  data: TData;
};

async function fetchBackend<TData = unknown>({
  method,
  endpoint,
  body,
  params,
}: FetchBackendParams): Promise<FetchBackendResponse<TData>> {
  const { accessToken } = useGlobalStore.getState();
  const isAuthenticated = Boolean(accessToken);

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  if (isAuthenticated) headers.append('Authorization', `Bearer ${accessToken}`);

  const response = await fetch(import.meta.env.VITE_IOF_API + endpoint + (params ?? ''), {
    mode: 'cors',
    method,
    headers,
    body: JSON.stringify(body),
  });

  const data = await response.json();

  /* eslint-disable no-console */
  console.groupCollapsed('[fetchBackend]', method, endpoint);
  console.log('[fetchBackend] [PARAMS] ', params);
  console.log('[fetchBackend] [BODY] ', body);
  console.log('------------------------------------');
  console.log('[fetchBackend] [RESPONSE]', response);
  console.log('[fetchBackend] [DATA]', data);
  console.groupEnd();

  if (response.ok) return { response, data };

  return Promise.reject({
    response,
    data,
  });
}

type BackendMethodFn = <TData = unknown>(
  endpoint: ENDPOINT,
  config?: {
    params?: string;
    body?: unknown;
  }
) => ReturnType<typeof fetchBackend<TData>>;

type Backend = {
  get: BackendMethodFn;
  post: BackendMethodFn;
  put: BackendMethodFn;
  patch: BackendMethodFn;
  delete: BackendMethodFn;
};

export const BACKEND: Backend = {
  get: async (endpoint, config) => await fetchBackend({ method: 'GET', endpoint, ...config }),
  post: async (endpoint, config) => await fetchBackend({ method: 'POST', endpoint, ...config }),
  put: async (endpoint, config) => await fetchBackend({ method: 'PUT', endpoint, ...config }),
  patch: async (endpoint, config) => await fetchBackend({ method: 'PATCH', endpoint, ...config }),
  delete: async (endpoint, config) => await fetchBackend({ method: 'DELETE', endpoint, ...config }),
};
