import { useCallback, useState } from 'react';
import { useEffectOnMount, useToggle } from '@hooks/core';
import { useGlobalStore } from '@global-store/use-store';
import { useAuth } from './use-auth';
import { Prettify } from '@typed/extra/utils';

type UseServiceConfig<TFun extends (...arg: unknown[]) => Promise<{ summary: unknown }>, TSummary> = {
  fetchOnMount?: Parameters<TFun> | true;
  onSuccess?: (s: TSummary) => void;
  onError?: (e: unknown) => void;
  onComplete?: () => void;
};

type UseServiceReturn<TFun extends (...arg: unknown[]) => Promise<{ summary: unknown }>, TSummary> = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  summary: TSummary | undefined;
  trigger: (...args: Parameters<TFun>) => void;
  error: unknown;
};

// eslint-disable-next-line
export function useService<TFun extends (...arg: any[]) => Promise<{ summary: any }>>(
  service: TFun,
  config?: UseServiceConfig<TFun, Awaited<ReturnType<TFun>>['summary']>
): UseServiceReturn<TFun, Prettify<Awaited<ReturnType<TFun>>['summary']>> {
  const { expirationTokenDate, setAccessToken } = useGlobalStore();
  const { isAuthenticated, refreshLogin, logout } = useAuth();

  const [isLoading, setLoading] = useToggle(config?.fetchOnMount ? true : false);

  const [isSuccess, setIsSuccess] = useToggle(false);
  const [isError, setIsError] = useToggle(false);

  const [summary, setSummary] = useState<Awaited<ReturnType<TFun>>['summary'] | undefined>();
  const [error, setError] = useState<TFun>();

  const trigger = useCallback(
    async (...args: Parameters<TFun>) => {
      // const {onSuccess, onError, onComplete} = config;
      const { onSuccess, onError, onComplete } = config ?? {};
      setIsError(false);
      setLoading(true);
      setIsSuccess(false);
      try {
        if (isAuthenticated && expirationTokenDate < Date.now()) {
          console.log('[SERVICE] [TOKEN EXPIRED] Usando refresh token... ', expirationTokenDate < Date.now());
          await refreshLogin();
        }
        const { summary } = await service(...args);

        if (summary?.token) {
          setAccessToken(summary.token);
        }
        setSummary(summary);
        if (onSuccess) onSuccess(summary);
        setIsSuccess(true);
        // eslint-disable-next-line
      } catch (error: any) {
        setIsError(true);
        setError(error);
        if (onError) onError(error);
        console.log('[SERVICE] [ERROR] ', error);
        if (error?.response?.status === 401) {
          console.log('[SERVICE] [ERROR] [401] ', error?.response, error?.data);
          console.log('Token expirado. Refresh token expirado. Forzando logout...');
          logout();
          return;
        }
      } finally {
        setLoading(false);
        if (onComplete) onComplete();
      }
    },
    [service]
  );

  useEffectOnMount(() => {
    if (config?.fetchOnMount) {
      // eslint-disable-next-line
      const params: any = Array.isArray(config.fetchOnMount) ? config.fetchOnMount : [];
      trigger(...params);
    }
  });

  return {
    isLoading,
    isError,
    trigger,
    summary,
    isSuccess,
    error,
  };
}
