import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { EditedUserData, UserEditionPayload } from './user-edition.types';

// deprecated service ref.: updateUserService_DEPRECATED

// ✅ Implementado
export async function updateUserDataService({ query: rawQuery, body }: UserEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<EditedUserData>(ENDPOINT.USERS, { query, body });

  return {
    response,
    summary: data,
  };
}
