import { Divider, Paper, Stack, Typography } from '@mui/material';
import i18n from '../../libs/language';
import { deviceListService } from '@services/domain/page/report/device/report-device-info';

type DeviceListDataProps = Awaited<ReturnType<typeof deviceListService>>['summary'];

type TotalsDevicesProps = {
  total: DeviceListDataProps['total'];
  installed: DeviceListDataProps['installed'];
  uninstalled: DeviceListDataProps['uninstalled'];
  reporting: DeviceListDataProps['reporting'];
  notReporting: DeviceListDataProps['not_reporting'];
};

export function TotalsDevices({
  total,
  installed,
  uninstalled,
  reporting,
  notReporting,
}: TotalsDevicesProps) {
  return (
    <Paper elevation={0} style={{ padding: '20px 40px', borderRadius: '10px' }}>
      <Stack
        direction="row"
        flexWrap="wrap"
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography color="#49454F" fontSize={40} fontWeight={600}>
            {total}
          </Typography>
          <Typography color="#49454F" fontSize={20} fontWeight={600}>
            {i18n.t('app.report_devices.totals')}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={{ xs: 8 }} flexWrap="wrap">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#22A7F0" fontSize={40} fontWeight={600}>
              {installed}
            </Typography>
            <Typography color="#22A7F0" fontSize={20}>
              {i18n.t('app.report_devices.installed')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#6C757D" fontSize={40} fontWeight={600}>
              {uninstalled}
            </Typography>
            <Typography color="#6C757D" fontSize={20} minWidth="max-content">
              {i18n.t('app.report_devices.not_installed')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={{ xs: 8 }} flexWrap="wrap">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#6BAA00" fontSize={40} fontWeight={600}>
              {reporting}
            </Typography>
            <Typography color="#6BAA00" fontSize={20}>
              {i18n.t('app.report_devices.reporting')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography color="#FF0000" fontSize={40} fontWeight={600}>
              {notReporting}
            </Typography>
            <Typography color="#FF0000" fontSize={20} minWidth="max-content">
              {i18n.t('app.report_devices.not_reporting')}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}
