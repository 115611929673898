import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import {
  NotifListResponse,
  UserNotifListResponse,
  UserNotifListPayload,
} from './notification-setting-list.types';

// deprecated service ref.: notificationRecipientsService_DEPRECATED
export async function notificationSettingsListService() {
  const { response, data } = await BACKEND.get<NotifListResponse>(ENDPOINT.NOTIFICATION_RECIPIENTS);

  return {
    response,
    summary: data,
  };
}

// deprecated service ref.: getNotificationsByUserIdService_DEPRECATED
export async function userNotificationSettingsService({ query: rawQuery }: UserNotifListPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<UserNotifListResponse>(ENDPOINT.NOTIFICATION_RECIPIENTS, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
