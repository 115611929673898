import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Button,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  CircularProgress,
  Box,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  SelectChangeEvent,
} from '@mui/material';
import { stylesInput, configButtonStylesContained } from '@styles/generic-styles';
import { ModalIOF } from '@ui/modal/modal';
import { createSharePermissionService } from '@services/domain/base/silobag/share/share-creation';
import { updateSharePermissionService } from '@services/domain/base/silobag/share/share-edition';
import { useService } from '@hooks/use-service';
import { getPermissionDefaultValue, isValidEmail, getRandomColor } from '@utils/helper';
import ChevronDown from '@assets/svg/chevron-down.svg';
import i18n from '../../../libs/language';
import { useEffectOnUpdate, useToggle } from '@hooks/core';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { useGlobalStore } from '@global-store/use-store';
import { ToastAction } from '@ui/toast/toast-action';
import InviteIcon from '@assets/svg/core/invite.svg';
import { sharePermissionListService } from '@services/domain/base/silobag/share/share-list';

type SummarySilobagPermission = Awaited<ReturnType<typeof sharePermissionListService>>['summary'];

type QueryParameterSilobagPermission = Parameters<typeof sharePermissionListService>[0];

type BodySharePermission = Parameters<typeof createSharePermissionService>[0]['body'];

type SilobagShareProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: SummarySilobagPermission;
  getData: (siloId: QueryParameterSilobagPermission) => void;
  siloName?: string;
  siloId: string | string[];
  isLoadingData: boolean;
  //TODO TIPAR ESTADOS DE SILOBAG la persona que hizo este estados de massiveData
  setSilobagShared: (data: any) => void;
  massiveData?: any;
  hasInvalidStatus?: boolean;
  CountrysideName?: string;
};

export function SilobagShareModal({
  data,
  open,
  setOpen,
  siloName,
  siloId,
  getData,
  setSilobagShared,
  massiveData,
  hasInvalidStatus,
  CountrysideName,
}: SilobagShareProps) {
  const { organizationId } = useGlobalStore.getState();
  const [emails, setEmails] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [permissionValue, setPermissionValue] = useState(0);
  const dataSiloPermissions = data && data.response.length > 0 ? data.response : [];
  const massiveDataResponse = massiveData && massiveData.map((item: any) => item.response);
  const [disableButton, setDisableButton] = useToggle(true);
  const [attempted, setAttempted] = useState(false);
  const [step, setStep] = useState<number | null>(null);
  const [showToast, setShowToast] = useState(false);
  const silobagDataStatusId = hasInvalidStatus ? 1 : 2;
  //post para compartir Silobolsa
  const { trigger: postPermissions, isError } = useService(createSharePermissionService, {
    onSuccess: () => {
      setSilobagShared({
        message: i18n.t('app.silobag_share.silobag_shared'),
        state: true,
        type: 'success',
      });
      setEmails([]);
      setPermissionValue(0);
      getData({ query: siloId });
    },
  });
  useEffectOnUpdate(() => {
    if (isError) {
      setSilobagShared({
        message: i18n.t('app.silobag_share.silobag_already_shared'),
        state: isError,
        type: 'error',
      });
      setOpen(true);
    }
  }, [isError]);

  //put para actualizar permisos
  const { trigger: updatePermissions } = useService(updateSharePermissionService, {
    onSuccess: () => {
      getData({ query: siloId });
      setSilobagShared({
        message: i18n.t('app.silobag_share.permissions_updated'),
        state: true,
        type: 'success',
      });
    },
  });
  // get para obtener datos de silobolsa
  const paramsQuery = {
    silobag_data_status_id: silobagDataStatusId,
    organization_id: organizationId,
    active: true,
  };
  const {
    trigger: getSiloData,
    summary: dataSilo,
    isLoading: isLoadingSiloData,
  } = useService(listSilobagService, {
    fetchOnMount: [{ query: paramsQuery }],
  });

  useEffect(() => {
    if (open) {
      getSiloData({ query: paramsQuery });
    }
  }, [open, silobagDataStatusId, organizationId, getSiloData]);

  const filteredSiloPermission = dataSilo?.data.filter(
    (item) => item.full_permission === true && siloId.includes(item.id)
  );
  // silobolsas sin permisos
  const filteredSiloNoPermission = dataSilo?.data.filter(
    (item) => item.full_permission === false && siloId.includes(item.id)
  );

  const filteredSiloPermissionIds = filteredSiloPermission?.map((item) => item.id);
  //usuario con permisos heredados de establecimiento
  const userWithPermission = dataSiloPermissions
    ? dataSiloPermissions.filter((user) => user.inherited === true)
    : [];
  //usuario con permisos compartidos en silobolsa
  const userWithPermissionInSilo = dataSiloPermissions
    ? dataSiloPermissions.filter((user) => user.inherited === false)
    : [];
  //manejo del input de email
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ' ' || event.key === ',') {
      event.preventDefault();
      const email = inputValue.trim();
      if (email && isValidEmail(email) && !emails.includes(email)) {
        setEmails([...emails, email]);
        setInputValue('');
      }
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    setEmails(emails.filter((email: string) => email !== emailToDelete));
  };

  //manejo del select de compartir
  const SelectOnchange = (event: SelectChangeEvent) => {
    setPermissionValue(+event.target.value);
  };
  //manejo del select de actualizar permisos
  const SelectUpdateOnchange = (event: SelectChangeEvent<string | number>, permissionId: number) => {
    const selectedValue = +event.target.value;
    switch (selectedValue) {
      case 1:
        updatePermissions({
          query: permissionId,
          body: { read_permission: true, write_permission: false, full_permission: false, active: true },
        });
        break;
      case 2:
        updatePermissions({
          query: permissionId,
          body: { read_permission: true, write_permission: true, full_permission: false, active: true },
        });
        break;
      case 3:
        updatePermissions({
          query: permissionId,
          body: { read_permission: true, write_permission: true, full_permission: true, active: true },
        });
        break;
      case 4:
        updatePermissions({
          query: permissionId,
          body: { read_permission: false, write_permission: false, full_permission: false, active: false },
        });
        break;
    }
  };
  // CONTROL DE BOTON COMPARTIR

  const resetState = () => {
    setAttempted(false);
    setStep(1);
  };

  const checkPermissions = () => {
    if (!attempted && Array.isArray(filteredSiloNoPermission) && filteredSiloNoPermission.length > 0) {
      setStep(1);
    } else if (
      userWithPermission.length > 0 ||
      userWithPermissionInSilo.length > 0 ||
      (massiveDataResponse && massiveDataResponse.length > 0)
    ) {
      setStep(2);
    }
  };
  const permissions: BodySharePermission = [];

  const idsToUse =
    filteredSiloPermissionIds && filteredSiloPermissionIds.length > 0
      ? filteredSiloPermissionIds.map((silobag) => silobag.toString())
      : Array.isArray(siloId)
      ? siloId
      : [siloId];

  emails.forEach((email: string) => {
    idsToUse.forEach((id) => {
      permissions.push({
        silobag_id: id,
        email,
        read_permission: permissionValue !== 0,
        write_permission: permissionValue === 2 || permissionValue === 3,
        full_permission: permissionValue === 3,
      });
    });
  });

  useEffectOnUpdate(() => {
    const shouldEnableButton = emails.length > 0 && permissionValue !== 0;
    setDisableButton(!shouldEnableButton);
  }, [emails, permissionValue]);

  useEffect(() => {
    if (open) {
      setInputValue('');
      setShowToast(false);
      setEmails([]);
      setPermissionValue(0);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      resetState();
    } else {
      checkPermissions();
    }
  }, [open, attempted, filteredSiloNoPermission, userWithPermission, userWithPermissionInSilo, step]);

  const renderLoading = () => (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Stack>
  );
  const renderStepOne = () => (
    <ModalIOF.Body>
      <Typography variant="h6" gutterBottom>
        {i18n.t('app.silobag_share.permissions_share')}
      </Typography>
      <List>
        {filteredSiloNoPermission?.map((silo) => (
          <ListItem key={silo.id}>
            <ListItemText primary={silo.name} />
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: 'auto',
        }}
      >
        <ModalIOF.Button
          close
          variant="contained"
          size="medium"
          sx={configButtonStylesContained}
          onClick={() => setOpen(false)}
        >
          {i18n.t('app.close')}
        </ModalIOF.Button>
        <Button
          variant="contained"
          size="medium"
          disabled={!(Array.isArray(filteredSiloPermission) && filteredSiloPermission.length > 0)}
          sx={{ ...configButtonStylesContained, marginLeft: 2 }}
          onClick={() => {
            setAttempted(true);
            setStep(2);
          }}
        >
          {i18n.t('app.continue')}
        </Button>
      </Box>
    </ModalIOF.Body>
  );

  const renderStepTwo = () => (
    <ModalIOF.Body>
      <Autocomplete
        multiple
        limitTags={10}
        id="multiple-limit-tags"
        options={[]}
        getOptionLabel={(option) => option}
        defaultValue={filteredSiloPermissionIds || []}
        onChange={(_, value) => {
          if (value.length === 0) {
            setOpen(false);
            setShowToast(true);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Silobolsas" placeholder="Silobolsas" />}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option}
              {...getTagProps({ index })}
              sx={{
                minWidth: '100px',
                margin: '2px',
              }}
            />
          ))
        }
        sx={{ width: '100%' }}
        open={false}
      />

      <Typography variant="body2" sx={{ color: 'red', fontSize: '12px' }}>
        {i18n.t('app.silobag_share.press_enter_or_space')}
      </Typography>

      <Stack direction="row" spacing={2} sx={{ padding: '10px' }}>
        <div>
          <TextField
            label={i18n.t('app.silobag_share.ingress_users')}
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            sx={{ width: '300px' }}
            size="small"
            onKeyDown={handleInputKeyDown}
          />
          {emails.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={() => handleDeleteEmail(email)}
              sx={{ margin: '5px' }}
            />
          ))}
        </div>
        <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
          <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobag_share.type_permission')}</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="Permission_id"
            input={<OutlinedInput label={i18n.t('app.silobag_share.type_permission')} />}
            onChange={SelectOnchange}
            value={permissionValue.toString()}
          >
            <MenuItem value={1}>
              <Box display={'flex'} flexDirection="column">
                <Typography variant="body1">{i18n.t('app.silobag_share.view')}</Typography>
                {permissionValue !== 1 && (
                  <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                    {i18n.t('app.silobag_share.view_description')}
                  </Typography>
                )}
              </Box>
            </MenuItem>
            <MenuItem value={2}>
              <Box display={'flex'} flexDirection="column">
                <Typography variant="body1">{i18n.t('app.silobag_share.edit')}</Typography>
                {permissionValue !== 2 && (
                  <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                    {i18n.t('app.silobag_share.edit_description')}
                  </Typography>
                )}
              </Box>
            </MenuItem>
            <MenuItem value={3}>
              <Box display={'flex'} flexDirection="column">
                <Typography variant="body1">{i18n.t('app.silobag_share.access_complete')}</Typography>
                {permissionValue !== 3 && (
                  <Typography variant="body2" component="span" sx={{ fontSize: '12px' }}>
                    {i18n.t('app.silobag_share.access_complete_description')}
                  </Typography>
                )}
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          sx={configButtonStylesContained}
          style={{ width: '250px', maxHeight: '40px' }}
          onClick={() => {
            postPermissions({ body: permissions });
          }}
          disabled={disableButton}
        >
          {i18n.t('app.silobag_share.share')}
        </Button>
      </Stack>

      {filteredSiloPermissionIds?.length === 1 && (
        <>
          <div>
            <Typography sx={{ fontSize: '12px', marginTop: '10px' }}>
              {i18n.t('app.silobag_share.user_access')}
            </Typography>
          </div>
          <Accordion
            sx={{
              borderRadius: '10px',
              marginTop: '10px',
              boxShadow: 'none',
              display: (userWithPermission?.length || 0) < 0 ? 'none' : 'block',
            }}
          >
            <AccordionSummary>
              <Stack direction="row" justifyContent={'space-between'}>
                <div>
                  <Typography variant="body1">
                    Todos en{' '}
                    <span style={{ color: '#6baa00', fontWeight: 600 }}>
                      {' '}
                      {CountrysideName ? CountrysideName : 'Establecimiento'}
                    </span>
                  </Typography>
                  <Typography sx={{ fontSize: '12px' }}>
                    {userWithPermission?.length} {i18n.t('app.silobag_share.user_with_permission')}
                    <span>
                      {' '}
                      <img src={ChevronDown} alt="" />
                    </span>
                  </Typography>
                </div>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {userWithPermission?.map((user, index: number) => (
                <Stack direction={'row'} key={user.id}>
                  <span>
                    <Avatar
                      alt={user.users.email}
                      sx={{
                        width: '30px',
                        height: '30px',
                        margin: '5px',
                        fontSize: '12px',
                        bgcolor: getRandomColor(index),
                      }}
                    >
                      {user.users.email[0].toUpperCase()}
                    </Avatar>
                  </span>
                  <Typography key={user.id} variant="body1" sx={{ margin: '5px' }}>
                    {user.users.email}
                  </Typography>
                </Stack>
              ))}
            </AccordionDetails>
          </Accordion>
          {userWithPermissionInSilo &&
            userWithPermissionInSilo.length > 0 &&
            userWithPermissionInSilo.map((userPermission: any, index: number) => (
              <div style={{ marginTop: '10px' }} key={userPermission.permission_id}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Avatar
                      alt={userPermission.users.email}
                      sx={{
                        width: '30px',
                        height: '30px',
                        margin: '5px',
                        fontSize: '13px',
                        bgcolor: getRandomColor(index),
                      }}
                    >
                      {userPermission.users.email[0].toUpperCase()}
                    </Avatar>
                    <Typography variant="body1" sx={{ margin: '5px', fontSize: '14px' }}>
                      {userPermission.users.email}
                    </Typography>
                    {userPermission.is_guest && (
                      <Chip
                        label="Invitado"
                        icon={<img src={InviteIcon} alt="" />}
                        sx={{
                          margin: '5px',
                          fontSize: '10px',
                          bgcolor: '#6BAA0026',
                          color: '#6baa00',
                          fontWeight: 600,
                          borderRadius: '4px',
                        }}
                      />
                    )}
                  </Stack>
                  <FormControl variant="outlined" size="small" style={{ width: '35%' }} sx={stylesInput}>
                    <InputLabel id="demo-multiple-name-label">
                      {i18n.t('app.silobag_share.type_permission')}
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="Permission_id"
                      input={<OutlinedInput label={i18n.t('app.silobag_share.type_permission')} />}
                      onChange={(event) => SelectUpdateOnchange(event, userPermission.permission_id)}
                      // value={permissionUpdateValue}
                      defaultValue={getPermissionDefaultValue(userPermission)}
                    >
                      <MenuItem value={1}>
                        <div>
                          <Typography variant="body2">{i18n.t('app.silobag_share.view')}</Typography>
                        </div>
                      </MenuItem>
                      <MenuItem value={2}>
                        <div>
                          <Typography variant="body2">{i18n.t('app.silobag_share.edit')}</Typography>
                        </div>
                      </MenuItem>
                      <MenuItem value={3}>
                        <div>
                          <Typography variant="body2">
                            {i18n.t('app.silobag_share.access_complete')}
                          </Typography>
                        </div>
                      </MenuItem>
                      <MenuItem value={4}>
                        <div>
                          <Typography variant="body1" sx={{ color: 'red' }}>
                            {i18n.t('app.silobag_share.delete_permission')}
                          </Typography>
                        </div>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>
            ))}
        </>
      )}

      <Stack direction="row" justifyContent={'space-between'} sx={{ marginTop: '20px' }}>
        <div></div>
        <ModalIOF.Button
          close
          variant="contained"
          size="medium"
          sx={configButtonStylesContained}
          onClick={() => {
            setOpen(false);
          }}
        >
          {i18n.t('app.close')}
        </ModalIOF.Button>
      </Stack>
    </ModalIOF.Body>
  );

  const renderContent = () => {
    if (isLoadingSiloData) return renderLoading();
    switch (step) {
      case 1:
        return renderStepOne();
      case 2:
        return renderStepTwo();
      default:
        return renderLoading();
    }
  };

  return (
    <>
      <ModalIOF open={open} ModalIsOpen={setOpen}>
        <ModalIOF.Header>
          <ModalIOF.Title>
            <Typography variant="body1" sx={{ fontWeight: '300', fontSize: '1.5rem' }}>
              {i18n.t('app.silobag_share.share_silobag')} <span style={{ fontWeight: 600 }}> {siloName}</span>
            </Typography>
          </ModalIOF.Title>
        </ModalIOF.Header>
        {renderContent()}
      </ModalIOF>
      {showToast ? (
        <ToastAction message={i18n.t('app.silobag_share.cancel_message')} severity={'error'} />
      ) : null}
    </>
  );
}
