import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { SilobagDashboardInfoData } from './silobag-dashboard-info.types';
import { useGlobalStore } from '@global-store/use-store';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: getSilobagsDashboardInformation_DEPRECATED

// implementado ✅
export async function silobagDashboardInfoService() {
  const { organizationId } = useGlobalStore.getState();
  const query = transformIntoQueryParamString({ organization_id: organizationId });
  const { response, data } = await BACKEND.get<SilobagDashboardInfoData>(ENDPOINT.DASHBOARD_SILOBAGS, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
