import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CountrysidePermissionPostPayload, CountrysidePermissionPostData } from './countryside-permission-post.types';
import { transformIntoQueryParamString } from '@utils/service';

// ✅ implementado
export async function PostCountrysidePermissionService({ query: rawQuery, body }: CountrysidePermissionPostPayload) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.post<CountrysidePermissionPostData>(ENDPOINT.COUNTRYSIDE_PERMISSIONS, { query, body });

  return {
    response,
    summary: data,
  };
}
