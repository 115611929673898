import { Box, Stack, Typography } from '@mui/material';
import { useGlobalStore } from '@global-store/use-store';
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import i18n from '../../libs/language';
import { useEffectOnMount } from '@hooks/core';
import { useConnection } from '@hooks/use-connection';
import { useEffect, useState } from 'react';
import { PATH } from '@data/enums';

export function NotConnectedPage() {
  const { email } = useGlobalStore();
  const navigate = useNavigate();

  const isOnline = useConnection();

  useEffect(() => {
    if (!isOnline) {
      return navigate(PATH.NO_CONNECTION);
    } else if (isOnline && email) {
      return navigate(PATH.DASHBOARD);
    } else if (isOnline && !email) {
      return navigate(PATH.LOGIN);
    }
  }, [isOnline]);

  const [fondoVerde, setFondoVerde] = useState<string | undefined>(undefined);
  const [trigo, setTrigo] = useState<string | undefined>(undefined);

  useEffect(() => {
    // Función para cargar las imágenes desde la caché
    async function loadImagesFromCache() {
      try {
        const cache = await caches.open('image-cache-v1');

        // Obtener la respuesta de la caché para cada imagen
        const fondoVerdeResponse = await cache.match('/assets/no-connection/fondo-verde.svg');
        const trigoResponse = await cache.match('/assets/no-connection/trigo.svg');

        // Convertir la respuesta a blob y luego a URL
        const fondoVerdeUrl = await fondoVerdeResponse?.blob().then((blob) => URL.createObjectURL(blob));
        const trigoUrl = await trigoResponse?.blob().then((blob) => URL.createObjectURL(blob));

        // Establecer las URLs de las imágenes en el estado
        setFondoVerde(fondoVerdeUrl);
        setTrigo(trigoUrl);
      } catch (error) {
        console.error('Error al cargar las imágenes desde la caché:', error);
      }
    }

    // Llamar a la función para cargar las imágenes desde la caché
    loadImagesFromCache();
  }, []);

  const { setLanguage } = useGlobalStore();

  useEffectOnMount(() => {
    const browserLanguage = navigator.language;
    switch (browserLanguage) {
      case 'pt':
      case 'pt-BR':
        i18n.locale = 'pt';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'pt');
        break;
      case 'es':
      case 'es-ES':
        i18n.locale = 'es';
        setLanguage(i18n.locale);
        localStorage.setItem('LANGUAGE_PREFERENCE', 'es');
        break;
    }
  });

  return (
    <Stack direction={'row'} style={{ overflow: 'hidden' }} className="keep-scrolling-without-scroll">
      <Box width={'50vw'} height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Stack
          style={{ zIndex: 5, position: 'relative' }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'start'}
          width={{ xs: '100%', md: '60%' }}
          marginLeft={'30%'}
        >
          <Typography
            width={'100%'}
            sx={{ color: { xs: '#ffff', md: '#49454F' } }}
            fontSize={30}
            fontWeight={'bold'}
          >
            {i18n.t('app.no_connection')}
          </Typography>
        </Stack>
      </Box>
      <Box width={'50vw'} height={'100vh'} margin={0} display={'flex'} position="relative">
        <Box
          style={{ zIndex: 2, position: 'relative' }}
          height={'100vh'}
          display={'flex'}
          alignItems={'center'}
        >
          <img src={trigo} style={{ zIndex: 2, position: 'relative', width: '100%' }} />
        </Box>
        <Box
          width={'50%'}
          height={'100vh'}
          display={'flex'}
          justifyContent={'end'}
          alignItems={'end'}
          style={{ zIndex: 1, position: 'relative' }}
        >
          <img
            src={fondoVerde}
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: '#6BAA00',
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
}
