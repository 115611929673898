import {
  Grid,
  FormControl,
  InputAdornment,
  TextField,
  OutlinedInput,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Button,
  Box,
  Chip,
  SelectChangeEvent,
} from '@mui/material';
import { stylesInput } from '@styles/generic-styles';
import { SilobagListTablet } from './silobag-tablet';
import { useService } from '@hooks/use-service';
import { listCountrysideService } from '@services/domain/base/countryside/countryside-list';
import { useDebounce, useEffectOnUpdate } from '@hooks/core';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { listLotsService } from '@services/domain/base/lot/lot-list';
import { productListService } from '@services/domain/base/silobag/list/product-list';
import { harvestListService } from '@services/domain/base/silobag/list/harvest-list';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { useEffect, useState, useRef } from 'react';
import SingleInputDateRangePicker from '@ui/date-range/date-range';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { formatDateReverse } from '@utils/helper';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../libs/language';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from '@assets/svg/search.svg';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import es from 'dayjs/locale/es';

const alertLevel = [
  { id: 2, name: i18n.t('app.silobag_data.movement'), color: 'error' },
  { id: 4, name: i18n.t('app.silobag_data.quality_serious'), color: 'warning' },
  { id: 3, name: i18n.t('app.silobag_data.quality_min'), color: 'secondary' },
  { id: 1, name: i18n.t('app.silobag_data.optimun'), color: 'primary' },
];

const typeOfProductIOF = [
  { id: 1, name: 'SiloSAT', color: '#479be6' },
  { id: 2, name: 'SiloMETRIX', color: '#f0ac42' },
];

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];

//Tipo correspondiente a UpdateHeaderData
// type SummaryHeaderData = Pick<
//   Awaited<ReturnType<typeof listSilobagService>>['summary'],
//   'totalRemainingStock' | 'totalFilteredSilobags' | 'filteredRemainingStock' | 'web_silobags_stock'
// >;
type SilobagFilterProps = {
  updateHeaderData: (newState) => void;
  handleGetSiloListFilter: (newState: SummarySilobagList | undefined) => void;
  handleGetParamsFilter: (newState) => void;
  handleGetSiloListAll: (newState: SummarySilobagList | undefined) => void;
  extractionSuccess?: boolean;
  silobagSelected?: (ids: string[]) => void;
  silobagsSelected: (silobags: SummarySilobagList | undefined) => void;
  disableFilterExportBtn: (val: boolean) => void;
  setSiloListAll: (val: SummarySilobagList) => void;
  isSuccess: boolean;
  isSuccessClose: boolean;
  isMassiveSuccess: boolean;
  setIsNonValidatedSBList: (val: boolean) => void;
  setIsCloseList: (val: boolean) => void;
  isCloseList: boolean;
};

const locales = { es: es };
type LocaleKey = keyof typeof locales;

export function SilobagListFilters({
  updateHeaderData,
  handleGetSiloListFilter,
  handleGetSiloListAll,
  handleGetParamsFilter,
  isSuccess,
  isSuccessClose,
  isMassiveSuccess,
  extractionSuccess,
  silobagsSelected,
  disableFilterExportBtn,
  setSiloListAll,
  setIsNonValidatedSBList,
  setIsCloseList,
  isCloseList,
}: SilobagFilterProps) {
  const {
    organizationId,
    filters,
    setFilters,
    filteredUrl,
    setFilteredUrl,
    typeOfProduct,
    setTypeOfProduct,
  } = useGlobalStore();

  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState<boolean>(filters.active);
  const [nonValidatedSb, setNonValidatedSb] = useState<number>(filters.silobag_data_status_id);
  const [saveSiloFirstResponse, setSaveSiloFirstResponse] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [initRange, endRange] = dateRange;
  const initDate = initRange ? `${formatDateReverse(initRange?.$d)}` : null;
  const endDate = endRange ? `${formatDateReverse(endRange?.$d)}` : null;
  const [hasFilters, setHasFilters] = useState<boolean>(false);
  const locale: LocaleKey = 'es';
  const [selectedFilterNames, setSelectedFilterNames] = useState<Record<string, string>>({});
  const [silobagSelected, setSilobagSelected] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [sortingModel, setSortingModel] = useState<string>('');

  const getUrlWithFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('range');
    searchParams.delete('active');
    searchParams.delete('paginationModel');
    return `${searchParams ? `${searchParams.toString()}` : ''}`;
  };
  // Obtener la URL completa con los filtros aplicados y almacenarla en el estado
  useEffect(() => {
    const urlWithFilters = getUrlWithFilters();
    setFilteredUrl(urlWithFilters);
  }, [location.pathname, location.search, organizationId]);

  const updateURLWithFilters = () => {
    const queryParams = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]: any) => {
      if (value !== '' && key !== 'range' && key !== 'paginationModel') {
        queryParams.append(key, value);
      }
    });
    navigate(`?${queryParams.toString()}`);
  };

  useEffect(() => {
    updateURLWithFilters();
  }, [filters]);

  //Query Params silobag Service
  const { paginationModel, ...filtersWithoutPagination } = filters;
  const queryParamsSilobagService = {
    organization_id: organizationId,
    active: active,
    silobag_data_status_id: nonValidatedSb,
    take: paginationModel.pageSize ?? 50,
    page: paginationModel.page ?? 0,
    ...filtersWithoutPagination,
  };
  //Silobags Service
  const {
    trigger: getSiloData,
    summary: dataSilo,
    isLoading: isLoadingSiloData,
  } = useService(listSilobagService, {
    fetchOnMount: [{ query: queryParamsSilobagService }],
  });

  useDebounce(
    () => {
      getSiloData({ query: queryParamsSilobagService });
      if (filteredUrl.length) setHasFilters(true);
    },
    250,
    [organizationId, filteredUrl, active, filters, sortingModel, nonValidatedSb]
  );

  const silobagList = dataSilo?.data;

  //Countrysides Service
  const queryCountrysidesList = { organization_id: organizationId };
  const {
    trigger: getCountrysides,
    summary: countrysides,
    isLoading: isLoadingCountryside,
  } = useService(listCountrysideService, { fetchOnMount: [{ query: queryCountrysidesList }] });

  //Lots Service
  const queryLotListService = { id: organizationId };
  const {
    trigger: getLots,
    summary: lots,
    isLoading: isLoadingLots,
  } = useService(listLotsService, { fetchOnMount: [{ query: queryLotListService }] });

  // Species Service
  const { summary: species, isLoading: isLoadingSpecies } = useService(speciesListService, {
    fetchOnMount: true,
  });

  // Products Service
  const { summary: products, isLoading: isLoadingProducts } = useService(productListService, {
    fetchOnMount: true,
  });

  // Harvests Service
  const { summary: harvests, isLoading: isLoadingHarvest } = useService(harvestListService, {
    fetchOnMount: true,
  });

  useEffectOnUpdate(() => {
    getCountrysides({ query: queryCountrysidesList });
    getLots({ query: queryLotListService });
  }, [organizationId]);

  useEffect(() => {
    const saveFirstResponse = async () => {
      if (!saveSiloFirstResponse && dataSilo) {
        if (dataSilo.data.length > 0) {
          handleGetSiloListAll(dataSilo.data);
          //TODO: para que se usa ese handle aca ??
          //handleGetParamsFilter(dataSilo.data);
          setSaveSiloFirstResponse(true);
        }
      }
    };

    saveFirstResponse();
  }, [saveSiloFirstResponse, dataSilo]);

  useEffect(() => {
    disableFilterExportBtn(!hasFilters);
  }, [hasFilters]);

  //Massive Success
  useEffect(() => {
    if (isSuccess) {
      getSiloData({ query: queryParamsSilobagService });
      setHasFilters(true);
    }
  }, [isSuccess, organizationId, filteredUrl, active, sortingModel, filters]);

  //Extraction Success
  useEffectOnUpdate(() => {
    if (extractionSuccess) {
      getSiloData({ query: queryParamsSilobagService });
      setHasFilters(true);
    }
  }, [extractionSuccess]);

  // Update Silobags and remaining stock
  useEffectOnUpdate(() => {
    updateHeaderData({
      totalRemainingStock: dataSilo?.totalRemainingStock,
      silobagQty: dataSilo?.totalFilteredSilobags,
      remainingStock: dataSilo?.filteredRemainingStock,
      web_silobags_stock: dataSilo?.web_silobags_stock,
      totalFilteredSilobags: dataSilo?.totalFilteredSilobags,
      filteredInputWeight: dataSilo?.filteredInputWeight,
      filteredExtractedWeight: dataSilo?.filteredExtractedWeight,
      filteredRemainingStock: dataSilo?.filteredRemainingStock,
    });
    handleGetSiloListFilter(silobagList);
  }, [dataSilo]);

  // Handlers Select
  const updateNameFilter = (value: string) => {
    setFilters({ ...filters, name: value });
    setHasFilters(true);
    setSelectedFilterNames((prevNames) => ({ ...prevNames, name: value }));
    handleGetParamsFilter(Object.values(selectedFilterNames));
  };

  const handlerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value;
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      updateNameFilter(latestSelectedValue);
    }, 500);
    latestSelectedValue = selectedValue;
  };
  let typingTimer: NodeJS.Timeout;
  let latestSelectedValue: string = '';

  const handlerCountrysideID = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, countryside_id: selectedValue });
    setHasFilters(true);

    const selectedCountrysideName = countrysides?.find(
      (item: { id: number | string }) => item.id === selectedValue
    )?.name;
    setSelectedFilterNames((prevNames) => ({ ...prevNames, countryside: selectedCountrysideName ?? '' }));
    handleGetParamsFilter(Object.values(selectedFilterNames));
  };

  const handlerSilobagProductTypeID = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, silobag_product_type_id: selectedValue });
    setHasFilters(true);

    const selectedProductName = products?.find((item: { id: number | string }) => item.id === selectedValue)
      ?.name;
    if (selectedProductName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, product: selectedProductName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
    }
  };

  const handlerSilobagSpeciesTypeID = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, silobag_species_type_id: selectedValue });
    setHasFilters(true);

    const selectedSpeciesName = species?.find((item: { id: number | string }) => item.id === selectedValue)
      ?.name;
    if (selectedSpeciesName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, species: selectedSpeciesName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
    }
  };

  const handlerSilobagHarvestID = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, silobag_harvest_id: selectedValue });
    setHasFilters(true);

    const selectedHarvestName = harvests?.find((item: { id: number | string }) => item.id === selectedValue)
      ?.name;
    if (selectedHarvestName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, harvest: selectedHarvestName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
    }
  };

  const handlerTypeOfProduct = (e: SelectChangeEvent<number[]>) => {
    const selectedValues = e.target.value as number[];
    const selectedTypeOfProducts = typeOfProductIOF.filter((item) => selectedValues.includes(item.id));
    setTypeOfProduct(selectedTypeOfProducts);

    const selectedProductNames = selectedTypeOfProducts.map((product) => product.name);
    setSelectedFilterNames((prevNames) => ({ ...prevNames, typeOfProduct: selectedProductNames.join(', ') }));

    const newFilters = { ...filters };
    if (selectedValues.includes(1)) {
      newFilters.sat_view_active = true;
    } else {
      delete newFilters.sat_view_active;
    }
    if (selectedValues.includes(2)) {
      newFilters.is_silo_metrix_active = true;
    } else {
      delete newFilters.is_silo_metrix_active;
    }
    setFilters(newFilters);
    setHasFilters(true);
  };

  const handleAlertLevel = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, color_id: selectedValue });
    setHasFilters(true);

    const selectedAlertLevelName = alertLevel.find(
      (item: { id: number | string }) => item.id === selectedValue
    )?.name;
    if (selectedAlertLevelName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, alertLevel: selectedAlertLevelName }));
      handleGetParamsFilter(selectedAlertLevelName);
    }
  };

  const handleClickSiloClose = () => {
    setActive((prevActive) => !prevActive);
    setFilters({ ...filters, active: !active });
    setHasFilters(true);
    setIsCloseList(!isCloseList);
  };

  const handleClickNonValidatedSilobags = () => {
    const currentNonValidatedSb = filters.silobag_data_status_id;
    const newValue = currentNonValidatedSb === 2 ? 1 : 2;

    setNonValidatedSb(newValue);
    setFilters({ ...filters, silobag_data_status_id: newValue });
    setHasFilters(true);
  };

  useEffect(() => {
    if (filters.silobag_data_status_id === 1) {
      setIsNonValidatedSBList(true);
    } else {
      setIsNonValidatedSBList(false);
    }
  }, [filters]);

  useEffect(() => {
    if (isSuccessClose) {
      setFilters({ ...filters, active: false });
      setActive(false);
      setHasFilters(true);
    }
  }, [isSuccessClose]);

  useEffectOnUpdate(() => {
    if (isMassiveSuccess) {
      setFilters({ ...filters, active: false });
      setActive(false);
      setHasFilters(true);
    }
  }, [isMassiveSuccess]);

  useEffect(() => {
    if (initDate !== null) {
      setFilters({ ...filters, from: initDate, range: dateRange });
      setSelectedFilterNames((prevNames) => ({ ...prevNames, initDate: `From: ${initDate}` }));
      setHasFilters(true);
    }
    if (endDate !== null) {
      setFilters({ ...filters, to: endDate, range: dateRange });
      setSelectedFilterNames((prevNames) => ({ ...prevNames, endDate: `To: ${endDate}` }));
      setHasFilters(true);
    }
  }, [initDate, endDate]);

  const handleLotsId = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    setFilters({ ...filters, lot_id: selectedValue });
    const selectedLotName = (filters.countryside_id !== '' ? lotByCountrySideId : lots)?.find(
      (item: { id: number | string }) => item.id === selectedValue
    )?.name;
    if (selectedLotName) {
      setSelectedFilterNames((prevNames) => ({ ...prevNames, lot: selectedLotName }));
      handleGetParamsFilter(Object.values(selectedFilterNames));
      setHasFilters(true);
    }
  };

  useEffect(() => {
    handleGetParamsFilter(selectedFilterNames);
  }, [selectedFilterNames]);

  const removeFilter = () => {
    setFilters({
      countryside_id: '',
      lot_id: '',
      silobag_species_type_id: '',
      silobag_product_type_id: '',
      silobag_harvest_id: '',
      color_id: '',
      name: '',
      from: '',
      to: '',
      range: '',
      silobag_data_status_id: 2,
      active: true,
      paginationModel: { page: 0, pageSize: 50 },
    });
    setNonValidatedSb(2);
    setActive(true);
    setTypeOfProduct([]);
    setDateRange([null, null]);
    setSelectedFilterNames({});
    setHasFilters(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setIsCloseList(false);
  };

  useEffect(() => {
    silobagsSelected(silobagSelected);
  }, [silobagSelected]);

  useEffectOnUpdate(removeFilter, [organizationId]);

  const lotByCountrySideId =
    lots && lots.length > 0
      ? lots.filter((item) => item.countryside_id == filters.countryside_id)
      : silobagList?.filter((item) => item.lots.countrysides.id == filters.countryside_id);

  const hasNonEmptyFilters = (filters: Record<string, string>): boolean => {
    return Object.values(filters).some((value) => value !== '');
  };

  return (
    <>
      {isLoadingCountryside &&
      isLoadingLots &&
      isLoadingSpecies &&
      isLoadingProducts &&
      isLoadingHarvest &&
      isLoadingSiloData ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress sx={{ marginLeft: '10px', color: 'green' }} size={24} />
        </div>
      ) : (
        <>
          <Grid container style={{ marginTop: '20px' }} spacing={{ xs: 1, md: 0 }}>
            <Grid
              item
              xs={12}
              md={2.3}
              marginBottom={{ xs: 0, md: '10px' }}
              paddingBottom={{ xs: 0, md: '10px' }}
            >
              <FormControl style={{ width: '95%', fontSize: 14 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder={i18n.t('app.silobag_list.search_by_name_id')}
                  style={stylesInput}
                  onChange={handlerName}
                  inputRef={inputRef}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt="search-icon" />
                      </InputAdornment>
                    ),
                    inputProps: { style: { fontSize: 14 } },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">Tipo de Producto</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  style={{ border: 'none' }}
                  input={<OutlinedInput label="Tipo de Producto" />}
                  multiple
                  value={typeOfProduct.map((item) => item.id)}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={typeOfProductIOF.find((item) => item.id === value)?.name} />
                      ))}
                    </Box>
                  )}
                  onChange={handlerTypeOfProduct}
                >
                  {typeOfProductIOF.map((item) => (
                    <MenuItem key={item.id} value={item.id} style={{ color: item.color }}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">{i18n.t('app.countrysides')}</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="countryside_id"
                  input={<OutlinedInput label={i18n.t('app.countrysides')} />}
                  onChange={(e) => handlerCountrysideID(e)}
                  value={filters.countryside_id}
                  disabled={isLoadingCountryside}
                >
                  <MenuItem value="">{i18n.t('app.countrysides')}</MenuItem>
                  {countrysides?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">{i18n.t('app.lots')}</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="lot_id"
                  input={<OutlinedInput label={i18n.t('app.lots')} />}
                  onChange={(e) => handleLotsId(e)}
                  value={filters.lot_id}
                >
                  <MenuItem value="">{i18n.t('app.lots')}</MenuItem>
                  {filters.countryside_id !== ''
                    ? lotByCountrySideId?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    : lots?.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobag_data.specie')}</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="silobag_species_type_id"
                  input={<OutlinedInput label={i18n.t('app.silobag_data.specie')} />}
                  onChange={(e) => handlerSilobagSpeciesTypeID(e)}
                  value={filters.silobag_species_type_id}
                >
                  <MenuItem value="">{i18n.t('app.silobag_data.specie')}</MenuItem>
                  {species?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={1.6}>
              <FormControlLabel
                label={i18n.t('app.silobag_data.view_silo_close')}
                control={<Checkbox {...label} color="success" checked={!filters.active} />}
                labelPlacement="start"
                onChange={handleClickSiloClose}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobag_data.product')}</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="silobag_product_type_id"
                  input={<OutlinedInput label={i18n.t('app.silobag_data.product')} />}
                  onChange={(e) => handlerSilobagProductTypeID(e)}
                  value={filters.silobag_product_type_id}
                >
                  <MenuItem value="">{i18n.t('app.silobag_data.product')}</MenuItem>
                  {products?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobag_data.harvest')}</InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="silobag_harvest_id"
                  input={<OutlinedInput label={i18n.t('app.silobag_data.harvest')} />}
                  onChange={(e) => handlerSilobagHarvestID(e)}
                  value={filters.silobag_harvest_id}
                >
                  <MenuItem value="">{i18n.t('app.silobag_data.harvest')}</MenuItem>
                  {harvests?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <InputLabel id="demo-multiple-name-label">
                  {i18n.t('app.silobag_data.level_alert')}
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="color_id"
                  input={<OutlinedInput label={i18n.t('app.silobag_data.level_alert')} />}
                  onChange={(e) => handleAlertLevel(e)}
                  value={filters.color_id}
                >
                  <MenuItem value="">{i18n.t('app.silobag_data.level_alert')}</MenuItem>
                  {alertLevel.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Chip
                          label=""
                          color={item.color}
                          style={{ height: '0.7rem', marginRight: '0.3rem' }}
                        />
                        <span>{item.name}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl variant="outlined" size="small" style={{ width: '95%' }} sx={stylesInput}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                  <SingleInputDateRangePicker
                    label={i18n.t('app.silobag_data.range_storage')}
                    name={i18n.t('app.silobag_data.range_storage')}
                    onChange={(newValue) => setDateRange(newValue)}
                    value={filters.range !== '' ? filters.range : dateRange}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                label={i18n.t('app.silobag_data.view_non_validated_sb')}
                control={
                  <Checkbox {...label} color="success" checked={filters.silobag_data_status_id === 1} />
                }
                labelPlacement="start"
                onChange={handleClickNonValidatedSilobags}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                sx={{
                  fontWeight: 600,
                  textTransform: 'none',
                  display:
                    (hasFilters && hasNonEmptyFilters(selectedFilterNames)) || filteredUrl.length > 0
                      ? 'flex'
                      : 'none',
                  color: '#FF0000',
                }}
                onClick={removeFilter}
                startIcon={<img src={DeleteIcon} alt="delete-icon" />}
              >
                {i18n.t('app.silobag_data.delete_filters')}
              </Button>
            </Grid>
          </Grid>
          <SilobagListTablet
            silobagList={silobagList}
            isLoading={isLoadingSiloData}
            isActiveList={active}
            rowCount={dataSilo?.totalFilteredSilobags ?? 0}
            paginationModel={filters.paginationModel}
            setPaginationModel={(fn) => {
              const paginationModel = fn(filters.paginationModel);
              setFilters({ ...filters, paginationModel });
            }}
            //sortingModel={sortingModel}
            setSortingModel={setSortingModel}
            silobagSelected={setSilobagSelected}
            setSiloListAll={setSiloListAll}
          />
        </>
      )}
    </>
  );
}
