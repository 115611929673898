import CorvusLogo from '../../../../assets/svg/integrations-logos/corvus-logo.svg';
import {
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { configButtonStylesContained } from '@styles/generic-styles';
import EyeIcon from '@assets/svg/core/eye-hollow-green.svg';
import CrossEyeIcon from '@assets/svg/core/crossed-eye-green.svg';
import { useToggle } from '@hooks/core';
import i18n from '../../../../libs/language';
import { useService } from '@hooks/use-service';
import { loginCorvusService } from '@services/integration/corvus/auth-corvus';
import { useState } from 'react';
import { useGlobalStore } from '@global-store/use-store';
//payload
type LoginRequest = Parameters<typeof loginCorvusService>[0]['body'];

export const CorvusIntegration = () => {
  const [loginRequest, setLoginRequest] = useState<LoginRequest>({ userName: '', password: '' });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useToggle(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword();
  };
  const { setCorvusIntegrated } = useGlobalStore();

  const { trigger, isLoading, isSuccess, isError } = useService(loginCorvusService, {
    onSuccess: () => {
      setCorvusIntegrated(true);
    },
  });

  const handleLogin = (event: any) => {
    event.preventDefault();
    setFormSubmitted(true);
    if (loginRequest.userName && loginRequest.password) {
      trigger({ body: loginRequest });
    }
  };
  return (
    <>
      <Stack direction={'row'} justifyContent={'left'} sx={{ marginBottom: '35px' }}>
        <Typography variant={'h5'} fontWeight={500}>
          {i18n.t('app.integration_corvus.title')}
        </Typography>
      </Stack>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ marginTop: '25px' }}
        spacing={1}
      >
        <Box>
          <img src={CorvusLogo} style={{ width: 120 }} />{' '}
        </Box>
        {isSuccess ? (
          <Box sx={{ textAlign: 'center', padding: '10px' }}>
            <Typography variant={'h5'} fontWeight={600}>
              Su cuenta ha sido conectada exitosamente
            </Typography>
          </Box>
        ) : (
          <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ marginTop: '25px' }}
            spacing={1}
          >
            <Box sx={{ textAlign: 'center', padding: '10px' }}>
              <Typography variant={'h5'} fontWeight={500}>
                {i18n.t('app.integration_corvus.login_corvus')}
              </Typography>
              <Typography fontSize={15}>{i18n.t('app.integration_corvus.description')}</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <TextField
                label={i18n.t('app.integration_corvus.user')}
                variant="outlined"
                sx={{ marginBottom: '10px', width: '412px' }}
                type="email"
                error={formSubmitted && !loginRequest.userName}
                onChange={(e) => setLoginRequest({ ...loginRequest, userName: e.target.value })}
                required
              />
              <TextField
                label={i18n.t('app.integration_corvus.password')}
                variant="outlined"
                fullWidth
                sx={{ marginBottom: '10px', width: '412px' }}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setLoginRequest({ ...loginRequest, password: e.target.value })}
                error={formSubmitted && !loginRequest.password}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {isError && (
              <Box sx={{ textAlign: 'center', padding: '10px' }}>
                <Typography fontWeight={600} sx={{ color: 'red', fontSize: 15 }}>
                  {i18n.t('app.integration_corvus.error_login')}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              onClick={(e) => handleLogin(e)}
              sx={{ ...configButtonStylesContained, width: '412px' }}
            >
              {isLoading ? <CircularProgress size={24} /> : i18n.t('app.integration_corvus.ingress')}
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
};
