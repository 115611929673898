import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { WeightListResponse } from './weight-option-list.types';

// deprecated service ref.: getWeightSourceService_DEPRECATED

// ✅ Implementado
export async function weightOptionListService() {
  const { response, data } = await BACKEND.get<WeightListResponse>(ENDPOINT.SILOBAG_WEIGHT_SOURCE);

  return {
    response,
    summary: data,
  };
}
