import { ENDPOINT } from '@data/core/endpoints';
import { useGlobalStore } from '@global-store/use-store';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LastBalanceResponse } from './mov-stock-last-balance.types';

// deprecated service ref.: getLastBalanceReportStockService_DEPRECATED
export async function stockLastBalanceService() {
  const { organizationId } = useGlobalStore.getState();
  const query = transformIntoQueryParamString(organizationId);

  const { response, data } = await BACKEND.get<LastBalanceResponse>(ENDPOINT.REPORT_STOCK_LASTBALANCE, {
    query,
  });

  return {
    response,
    summary: data,
  };
}
