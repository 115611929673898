import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { PostVinculateHeavyLogs, PostVinculateHeavyLogsResponse } from './post-heavy-logs.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function postVinculateHeavyLogsCorvusService({ body, query: RawQuery }: PostVinculateHeavyLogs) {
  const query = transformIntoQueryParamString(RawQuery);
  const { response, data } = await BACKEND.post<PostVinculateHeavyLogsResponse>(ENDPOINT.CORVUS_VINCULATE_HEAVYLOGS, { body, query });
  return {
    response,
    summary: data,
  };
}
