import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { LoginIntegrationAlborResponse, LoginIntegrationPayload } from './albor-auth.types';

// deprecated service ref.: LoginIntegrationAlborService_DEPRECATED 

// ✅ Implementado
export async function loginAlborService(payload: LoginIntegrationPayload): Promise<{
  response: Response;
  summary: LoginIntegrationAlborResponse['results'];
  AlborToken: string;
}> {
  const { response, data } = await BACKEND.post<LoginIntegrationAlborResponse>(ENDPOINT.ALBOR_LOGIN, payload);
  const typedData = data as LoginIntegrationAlborResponse;

  return {
    response,
    summary: typedData.results,
    AlborToken: typedData.token,
  };
}
