import { Box, Modal, TextField } from '@mui/material';
import { createContext, useContext, useState, useEffect } from 'react';
import { ActionButton } from '..';

interface ModalStore {
  ModalStatus: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  HandleClose: () => void;
}

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  open: boolean;
  ModalIsOpen: (value: boolean) => void;
  width?: string;
}

export const ModalContext = createContext<ModalStore | undefined>(undefined);

export function ModalIOF({ children, open, ModalIsOpen, width }: ModalProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    ModalIsOpen(false);
  };

  const store: ModalStore = {
    ModalStatus: [isOpen, setIsOpen],
    HandleClose: handleClose,
  };

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width ? width : '50%',
    height: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 'none',
    padding: '20px',
    maxHeight: '90vh',
    overflowY: 'auto',
    outline: 'none',
  };

  return (
    <ModalContext.Provider value={store}>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={{ ...style }}>{children}</Box>
      </Modal>
    </ModalContext.Provider>
  );
}

function Header({ children }: { children: any }) {
  return (
    <Box
      style={{ display: 'flex', justifyContent: 'space-between' }}
      // sx={{ borderColor: 'divider', borderBottom: 1 }}
    >
      {children}
    </Box>
  );
}

function HeaderCenter({ children }: { children: any }) {
  return (
    <Box
      style={{ display: 'flex', justifyContent: 'center' }}
      // sx={{ borderColor: 'divider', borderBottom: 1 }}
    >
      {children}
    </Box>
  );
}

function Title({ children }: { children: any }) {
  return <h2>{children}</h2>;
}

function Helper({ children }: any) {
  return (
    <div
      style={{
        width: '25%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
}

function Input({ children }: { children: JSX.Element }) {
  return <TextField label={children} />;
}

function Button({ children, close, save, onClick, ...props }: any) {
  const modalContext = useContext(ModalContext);
  const handleClose = modalContext?.HandleClose;

  const saveButton = () => onClick && onClick();

  const closeModal = () => handleClose && handleClose();

  const handleClick = () => {
    if (close) closeModal();
    if (save) saveButton();
  };

  return <ActionButton onClick={handleClick} text={children} {...props} />;
}

function Body({ children }: { children: any }) {
  return <div>{children}</div>;
}

ModalIOF.Header = Header;
ModalIOF.HeaderCenter = HeaderCenter;
ModalIOF.Input = Input;
ModalIOF.Helper = Helper;
ModalIOF.Title = Title;
ModalIOF.Button = Button;
ModalIOF.Body = Body;
