import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { SpeciesListResponse } from './species-list.types';

// deprecated service ref.: getSpeciesService_DEPRECATED, getAllSpeciesService_DEPRECATED

// ✅ Implementado
export async function speciesListService() {
  const { response, data } = await BACKEND.get<SpeciesListResponse>(ENDPOINT.SILOBAG_SPECIES);

  return {
    response,
    summary: data,
  };
}
