import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { ReportDevicesData } from './report-device-info.types';
import { useGlobalStore } from '@global-store/use-store';

// deprecated service ref.: devicesReportService_DEPRECATED

// implementado ✅
export async function deviceListService() {
  const { organizationId, filtersReportDevices } = useGlobalStore.getState();

  const rawQuery = {
    lance_number: filtersReportDevices.lance_number,
    take: filtersReportDevices.paginationModel.pageSize,
    page: filtersReportDevices.paginationModel.page,
    silobags_ids: filtersReportDevices.silobagId,
    countrysides_ids: filtersReportDevices.countrysideId,
    organization_id: organizationId,
    reporting: filtersReportDevices.report,
    installed: filtersReportDevices.installed,
  };

  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<ReportDevicesData>(ENDPOINT.DEVICES_REPORT, { query });

  return {
    response,
    summary: data,
  };
}
