import { useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { AlertCard } from '@ui/alert-card';
import NotificationIcon from '../../../assets/images/dashboard/notifications.svg';
import CountrysideIcon from '../../../assets/svg/dashboard/countryside-icon.svg';
import CloseIcon from '@assets/svg/core/cross.svg';
import ChevronRight from '@assets/svg/core/chevron-right-blue.svg';
import i18n from '../../../libs/language';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums';
import { listGlobalAlertService } from '@services/domain/alert/global-alert-list';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';

type AlertSideProps = {
  openFromDashboard?: boolean;
};

export function AlertSidePanel({ openFromDashboard = false }: AlertSideProps) {
  const [open, setOpen] = useToggle(openFromDashboard);
  const openPanel = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);

  const [hideBadge, setHideBadge] = useToggle(false);
  const handleHideBadge = (value: boolean) => setHideBadge(value);

  return (
    <Box sx={{ paddingRight: 0 }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="global-nav-alert-btn"
        onClick={openPanel}
      >
        <Badge color="error" variant="dot" invisible={hideBadge}>
          <img src={NotificationIcon} />
        </Badge>
      </IconButton>
      <DrawerAlertCard open={open} onClose={handleCloseDrawer} onChangeBadge={handleHideBadge} />
    </Box>
  );
}

type DrawerProps = {
  open: boolean;
  onClose: () => void; //(newValue?: boolean) => void
  onChangeBadge: (b: boolean) => void;
};

export function DrawerAlertCard({ open, onClose, onChangeBadge }: DrawerProps) {
  const {
    trigger,
    summary: silobagsWithAlerts,
    isLoading,
  } = useService(listGlobalAlertService, { fetchOnMount: true });

  const {
    summary: speciesSummary, //
  } = useService(speciesListService, { fetchOnMount: true });

  // TODO: (refactor) revisar si el useEffect es necesario realmente
  useEffect(() => {
    if (silobagsWithAlerts?.length === 0) onChangeBadge(true);
  }, [silobagsWithAlerts, onChangeBadge]);

  return (
    <Drawer anchor="right" open={open} transitionDuration={200} onClose={onClose}>
      <Stack p={1} direction="row" justifyContent="space-between">
        <h1 style={{ fontSize: 26 }}>{i18n.t('app.alerts')}</h1>
        <Box>
          <IconButton onClick={onClose} size="large">
            <img src={CloseIcon} style={{ width: 16, height: 16 }} />
          </IconButton>
        </Box>
      </Stack>
      <Divider />
      <Stack
        p={1}
        direction="column"
        spacing={2}
        style={{ width: '95vw', maxWidth: '400px', overflowY: 'auto' }}
      >
        {isLoading ? (
          <Stack alignItems="center" pt={5}>
            <CircularProgress size={32} />
          </Stack>
        ) : (
          <>
            {silobagsWithAlerts && silobagsWithAlerts.length > 0 ? (
              silobagsWithAlerts.map((sb) => (
                <Fragment key={sb.id}>
                  <SilobagAlertCard
                    silobag={sb}
                    species={speciesSummary ?? []}
                    trigger={trigger}
                    onClose={onClose}
                  />
                </Fragment>
              ))
            ) : (
              <Box width="100%" alignItems="center" justifyContent="center">
                <Typography variant="h6" textAlign={'center'}>
                  {i18n.t('app.no_active_siloid_alerts')}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Stack>
    </Drawer>
  );
}

type SilobagAlertCardProps = {
  silobag: Awaited<ReturnType<typeof listGlobalAlertService>>['summary'][0];
  species: Awaited<ReturnType<typeof speciesListService>>['summary'];
  trigger: () => void;
  onClose: () => void;
};

export function SilobagAlertCard({ silobag, species, trigger, onClose }: SilobagAlertCardProps) {
  const { lots } = silobag;

  const navigate = useNavigate();

  const [expanded, toggleExpanded] = useToggle(false);

  const { image_url: silobagSpeciesURL } = species.find(
    (species) => species.id === silobag.silobag_species_types.id
  )!;

  const MAX_ALERT_QTY_INDICATOR = 999;
  const MAX_ALERT_STACK = 4; // max. 4 or 5, more than that will break the layout

  const hasManyAlerts = silobag.alerts.length > 1;

  return (
    <Accordion
      elevation={0}
      disableGutters
      TransitionProps={{ timeout: 0 }}
      sx={{
        border: '1px solid #E2E5EC',
        borderRadius: '8px',
        '&::before': { content: 'none' },
      }}
      expanded={expanded}
      onChange={(_, expanded) => toggleExpanded(hasManyAlerts ? expanded : false)}
    >
      <AccordionSummary
        sx={{
          padding: `8px 8px 8px 8px`,
          marginBottom: !expanded ? `${Math.min(silobag.alerts.length, MAX_ALERT_STACK) * 2}px` : '',
          '& .MuiAccordionSummary-content': { margin: 0 },
        }}
        style={{
          cursor: hasManyAlerts ? 'pointer' : 'auto',
        }}
      >
        <Stack justifyContent="space-between" style={{ width: '100%' }}>
          <Stack direction="row" style={{ width: '100%', fontSize: '0.75rem' }} marginBottom={1}>
            <Stack flex={1} spacing={0.3}>
              <Stack direction="row" alignItems="center" spacing={0.3}>
                <img src={CountrysideIcon} style={{ width: '16px', height: '16px' }} />
                <span>{lots.countrysides.name}</span>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.3}
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <img src={silobagSpeciesURL} style={{ width: '16px', height: '16px' }} />
                  <span>{silobag.name}</span>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  px={1}
                  sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    '&:hover': { background: '#22A7F02A' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    navigate(`${PATH.SILOBAG}/${silobag.id}`);
                    onClose();
                  }}
                >
                  <Box>
                    ID{' '}
                    <Box
                      sx={{
                        fontWeight: 600,
                        display: 'inline-block',
                        '&:hover': {
                          color: '#22a7f0',
                        },
                      }}
                    >
                      {silobag.id}
                    </Box>
                  </Box>
                  <Stack direction="row" spacing={0.3} justifyContent="center" alignItems="center">
                    <span style={{ color: '#22a7f0' }}>Ver</span>
                    <img src={ChevronRight} style={{ width: '8px', height: '8px' }} />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                style={{
                  background: '#49454F',
                  color: 'white',
                  borderRadius: '8px',
                  fontSize: '.65rem',
                  height: '16px',
                  minWidth: '16px',
                  //width: '16px',
                  padding: '4px 4px',
                }}
                justifyContent="center"
                alignItems="center"
              >
                {silobag.alerts.length < MAX_ALERT_QTY_INDICATOR
                  ? silobag.alerts.length
                  : `+${MAX_ALERT_QTY_INDICATOR}`}
              </Stack>
              <Box>{i18n.t('app.navbar.see_all')}</Box>
            </Stack>
          </Stack>

          <AlertCard
            alert={silobag.alerts[0]}
            deviceName={silobag.alerts[0].devices.lance_number}
            trigger={trigger}
          />
          {!expanded
            ? silobag.alerts
                .slice(1, MAX_ALERT_STACK)
                .map((alert, i) => (
                  <AlertCard
                    key={i}
                    alert={alert}
                    deviceName={alert.devices.lance_number}
                    trigger={trigger}
                    hidden
                    offset={i}
                  />
                ))
            : null}
        </Stack>
      </AccordionSummary>
      <AccordionDetails style={{ margin: 0, padding: '0 8px 8px 8px' }}>
        <Stack direction="column" spacing={1}>
          {silobag.alerts.slice(1).map((alert) => (
            <AlertCard
              key={alert.id}
              alert={alert}
              deviceName={alert.devices.lance_number}
              trigger={trigger}
            />
          ))}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
