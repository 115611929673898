import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

type Callback = () => void | Promise<void>;

export function useEffectOnMount(callback: EffectCallback | any) {
  // TODO: revisar tipo del callback

  useEffect(() => {
    callback();
  }, []);
}

export function useEffectOnUpdate(callback: Callback, dependencies: DependencyList) {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    callback();
  }, dependencies);
}

export function useEffectOnUnmount(callback: ReturnType<EffectCallback> | any) {
  // TODO revisar tipo del callback
  useEffect(() => callback, []);
}
