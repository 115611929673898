import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CountryL0Data } from './country-L0.types';

// deprecated service ref.: countriesL0Service_DEPRECATED

// implementado ✅
export async function listCountriesL0Service() {
  const { response, data } = await BACKEND.get<CountryL0Data>(ENDPOINT.COUNTRIES);

  return {
    response,
    summary: data,
  };
}
