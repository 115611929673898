import { useGlobalStore } from '@global-store/use-store';
import { useAuth } from '@hooks/use-auth';
import {
  Alert,
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  Link as LinkMui,
} from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import i18n from '../../../libs/language';
import { useService } from '@hooks/use-service';
import { useToggle } from '@hooks/core';
import { LANGUAGE_PREFERENCE } from '@data/to-refactor/constants';
import { styled } from '@mui/system';
import { UserOrganizationSelector } from '../org-selector';
import WorkLogo from '@assets/svg/work.svg';
import { PATH } from '@data/enums';
import LogoutIcon from '@assets/svg/logout.svg';
import ToggleIcon from '@assets/images/dashboard/toggle.svg';
import { updateUserLanguageService } from '@services/domain/user/extra/language-update';

type UserProfileDropdownProps = {
  isMobile: boolean; // Define the prop type here
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export function UserProfileDropdown({ isMobile }: UserProfileDropdownProps) {
  const { logout, isAdmin } = useAuth();
  const { userFullname, userID, setLanguage, email, organizationName } = useGlobalStore();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useToggle(false);
  const [profileTarget, setProfileTarget] = useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElLanguages, setAnchorElLanguages] = useState<null | HTMLElement>(null);
  const openReports = Boolean(anchorEl);
  const openLanguages = Boolean(anchorElLanguages);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => setProfileTarget(event.currentTarget);
  const handleCloseUserMenu = () => setProfileTarget(null);

  const openSnackBar = () => {
    setOpenSnackbar(true);
  };

  const { trigger: updateInBackend } = useService(updateUserLanguageService, {
    onSuccess: openSnackBar,
  });

  const preferenceLanguage = (newLocale: string) => {
    i18n.locale = newLocale;

    setLanguage(i18n.locale);
    localStorage.setItem('LANGUAGE_PREFERENCE', i18n.locale);

    // TODO: newLocale no está bien tipado así que hay que dejar 'string' acá
    const LANGUAGE_ID: Record<string, 1 | 2> = {
      es: 1,
      pt: 2,
    };
    const body = {
      prefered_language_id: LANGUAGE_ID[newLocale],
    };

    const isMapPath = location.pathname.includes('/map');
    if (isMapPath) window.location.reload();

    updateInBackend({ query: userID!, body });
    handleCloseUserMenu(); // TODO: usar handleCloseUserMenu
  };
  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const isSamePath = location.pathname === PATH.SILOBAG;
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const handleClickOpenLanguages = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorElLanguages(event.currentTarget);
  const handleCloseAnchor = () => setAnchorEl(null);
  const handleCloseAnchorLanguage = () => setAnchorElLanguages(null);

  const style = {
    color: '#000',
    width: '100%',
    textDecoration: 'none',
    fontSize: '1rem',
    '&.active': {
      textDecoration: 'none',
      fontWeight: 600,
      color: '#6BAA00',
    },
    '&:hover': { color: '#6baa00' },
  };

  return (
    <Box>
      {!isMobile ? (
        <Box>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert onClose={handleClose} severity="info" variant="filled" sx={{ width: '100%' }}>
              {i18n.t('app.navbar.snack_bar_msj')}
            </Alert>
          </Snackbar>
          <Tooltip title="Account settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="User profile photo" sx={{ bgcolor: deepPurple[500] }}>
                {userFullname ? userFullname[0] : 'U'}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', zIndex: 999 }}
            id="menu-appbar"
            anchorEl={profileTarget}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(profileTarget)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem>
              <Typography textAlign="center">{i18n.t('app.navbar.language')}</Typography>
            </MenuItem>
            <MenuItem sx={{ paddingLeft: 5 }} onClick={() => preferenceLanguage(LANGUAGE_PREFERENCE.ES)}>
              <Typography textAlign="center">{i18n.t('app.navbar.spanish')}</Typography>
            </MenuItem>
            <MenuItem sx={{ paddingLeft: 5 }} onClick={() => preferenceLanguage(LANGUAGE_PREFERENCE.PT)}>
              <Typography textAlign="center">{i18n.t('app.navbar.portuguese')}</Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                onClick={() =>
                  navigate(`${PATH.NOTIFICATION}/${userID}`, {
                    state: { userId: userID },
                  })
                }
                style={{ cursor: 'pointer' }}
              >
                {i18n.t('app.notification_page.custom_notifications')}
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography textAlign="center">
                <Link to="/reset" style={{ textDecoration: 'none', color: 'black' }}>
                  {i18n.t('app.navbar.change_password')}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => logout()}>
              <Typography textAlign="center">{i18n.t('app.navbar.logout')}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleDrawerOpen} sx={{ p: 1 }}>
            <img src={ToggleIcon} />
            <Avatar alt="User profile photo" sx={{ bgcolor: deepPurple[500] }}>
              {userFullname ? userFullname[0] : 'U'}
            </Avatar>
          </IconButton>
          <Box width={'50%'} height={'50vh'} display={open ? 'block' : 'none'}>
            <Drawer
              open={open}
              anchor="right"
              style={{
                width: '100%',
                marginLeft: '10rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '2px solid blac',
                maxHeight: 50,
              }}
              variant="persistent"
              transitionDuration={200}
            >
              <Box p={1} sx={{ width: '85vw', maxWidth: 400 }} role="presentation">
                <DrawerHeader style={{ display: 'fle', justifyContent: 'space-between' }}>
                  <Stack direction={'row'} spacing={1}>
                    <Avatar alt="User profile photo" sx={{ bgcolor: deepPurple[500] }}>
                      {userFullname ? userFullname[0] : 'U'}
                    </Avatar>
                    <Stack>
                      <Typography color={'49454F'} variant="body2">
                        {email}
                      </Typography>
                      <Stack direction={'row'} spacing={1}>
                        <img src={WorkLogo} />
                        <Typography color={'#7A7A7A'} variant="body2">
                          {organizationName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                  <IconButton onClick={handleDrawerClose}>x</IconButton>
                </DrawerHeader>
                <Divider />
                <Box mt={2}>
                  <UserOrganizationSelector isMobile={true} />
                  <Stack display={'flex'} spacing={2} marginTop={2} height={'100%'} width={'100%'}>
                    <LinkMui component={NavLink} sx={style} to={PATH.DASHBOARD}>
                      {i18n.t('app.navbar.dashboard')}
                    </LinkMui>
                    <LinkMui component={NavLink} sx={style} to={PATH.MAP}>
                      {i18n.t('app.navbar.map')}
                    </LinkMui>
                    <span style={{ pointerEvents: isSamePath ? 'none' : 'auto' }}>
                      <LinkMui component={NavLink} sx={style} to={PATH.SILOBAG}>
                        {i18n.t('app.navbar.silobags')}
                      </LinkMui>
                    </span>
                    <Box sx={{ ...style, cursor: 'pointer' }}>
                      <Box onClick={handleClick}>{i18n.t('app.navbar.reports')}</Box>
                      <Menu
                        open={openReports}
                        anchorEl={anchorEl}
                        onClose={handleCloseAnchor}
                        aria-selected="true"
                      >
                        <MenuItem sx={style} onClick={() => navigate(PATH.REPORT_STOCK)}>
                          {i18n.t('app.navbar.stock')}
                        </MenuItem>
                        <MenuItem sx={style} onClick={() => navigate(PATH.REPORT_DEVICE)}>
                          {i18n.t('app.navbar.devices')}
                        </MenuItem>
                        <MenuItem sx={style} onClick={() => navigate(PATH.REPORT_ACTIVITY)}>
                          {i18n.t('app.navbar.activity')}
                        </MenuItem>
                        {/* <MenuItem disabled sx={style}>
                          {i18n.t('app.navbar.alerts')}
                        </MenuItem> */}
                        <MenuItem sx={style} onClick={() => navigate(PATH.REPORT_STATE_STOCK)}>
                          {i18n.t('app.navbar.state_stock')}
                        </MenuItem>
                        <MenuItem sx={style} onClick={() => navigate(PATH.REPORT_SAT_SILOBAGS_EXISTENCE)}>
                          {i18n.t('app.navbar.existence_stock')}
                        </MenuItem>
                      </Menu>
                    </Box>
                    {isAdmin ? (
                      <LinkMui component={NavLink} sx={style} to={PATH.ADMINISTRATOR}>
                        {i18n.t('app.navbar.administrator')}
                      </LinkMui>
                    ) : null}
                    <Box sx={style}>
                      <Typography
                        onClick={() =>
                          navigate(`${PATH.ADMINISTRATOR}/notification/${userID}`, {
                            state: { userId: userID },
                          })
                        }
                        style={{ cursor: 'pointer' }}
                      >
                        {i18n.t('app.notification_page.custom_notifications')}
                      </Typography>
                    </Box>
                    <Box sx={{ ...style, cursor: 'pointer' }}>
                      <Box onClick={handleClickOpenLanguages}>{i18n.t('app.navbar.language')}</Box>
                      <Menu
                        open={openLanguages}
                        anchorEl={anchorElLanguages}
                        onClose={handleCloseAnchorLanguage}
                        aria-selected="true"
                      >
                        <MenuItem sx={style} onClick={() => preferenceLanguage(LANGUAGE_PREFERENCE.ES)}>
                          {i18n.t('app.navbar.spanish')}
                        </MenuItem>
                        <MenuItem sx={style} onClick={() => preferenceLanguage(LANGUAGE_PREFERENCE.PT)}>
                          {i18n.t('app.navbar.portuguese')}
                        </MenuItem>
                      </Menu>
                    </Box>
                    <Typography>
                      <Link to="/reset" style={{ ...style }}>
                        {i18n.t('app.navbar.change_password')}
                      </Link>
                    </Typography>
                  </Stack>
                </Box>
              </Box>

              <Box onClick={() => logout()} sx={{ marginTop: '50%' }}>
                <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                  <Divider style={{ width: '90%' }} />
                </Box>

                <Stack direction={'row'} spacing={1} ml={1} mt={1}>
                  <img src={LogoutIcon} alt="logout" style={{ paddingLeft: 1 }} />
                  <Typography sx={{ ...style }}>{i18n.t('app.navbar.logout')}</Typography>
                </Stack>
              </Box>
            </Drawer>
          </Box>
        </Box>
      )}
    </Box>
  );
}
