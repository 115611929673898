import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CountryL1Data, CountryL1Payload } from './country-L1.types';

// deprecated service ref.: countriesL1ByL0Service_DEPRECATED

// implementado ✅
export async function listCountriesL1ForL0Service({ query: { L0ID } }: CountryL1Payload) {
  const query = transformIntoQueryParamString(L0ID);

  const { response, data } = await BACKEND.get<CountryL1Data>(ENDPOINT.COUNTRIES_L1_BY_L0, { query });

  return {
    response,
    summary: data,
  };
}
