import { BaseTable } from '@ui/table';
import React from 'react';
import { Grid, Typography, Avatar, Stack, Button } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  DataGridProProps,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums';
import i18n from '../../libs/language';
import ChevronDown from '@assets/svg/chevron-down.svg';
import { formatTons } from '@utils/helper';
import ChevronRight from '@assets/svg/chevron-right.svg';

import GreenUpArrow from '@assets/svg/core/arrow-upward-green.svg';
import RedDownArrow from '@assets/svg/core/arrow-downward-red.svg';
import { stockMovementListService } from '@services/domain/page/report/movement-stock/report-mov-stock-info';
//boton del ver detalle

type ReportStockSummaryType = Awaited<ReturnType<typeof stockMovementListService>>['summary'];
type DataReportStockType = ReportStockSummaryType['data'];
type MovementDetailType = DataReportStockType[0]['movements_detail'][0];

function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector);
  const hasDetail = React.isValidElement(contentCache[id]);
  return (
    <Button
      endIcon={<img src={ChevronDown} />}
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
      sx={{
        color: '#22A7F0',
        fontSize: 13,
        padding: '0 8px',
        textTransform: 'none',
        background: isExpanded ? '#22A7F05A' : 'transparent',
        '&:hover': { background: '#22A7F02A' },
      }}
    >
      {i18n.t('app.report_stock.see_detail')}
    </Button>
  );
}

type TabletStockProps = {
  data?: ReportStockSummaryType['data'];
  isLoading?: boolean;
};

export default function TabletStock({ data, isLoading }: TabletStockProps) {
  const stockDataArrayWithId = data?.map((data, index) => ({
    id: `row_${index}`, // Generar un id simple usando el índice
    ...data,
  }));
  const navigate = useNavigate();

  const stockDataReverse = stockDataArrayWithId?.reverse();

  const columns: GridColDef[] = [
    {
      field: 'date',
      flex: 1,
      sortable: false,

      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.date')}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography color="#49454F" fontSize={13}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'income',
      flex: 1,
      sortable: false,

      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.income')}
        </Typography>
      ),
      renderCell: (params) => {
        const incomeFormat = formatTons(params.value, true) ?? 0;
        return (
          <>
            {incomeFormat > 0 ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={GreenUpArrow} />
                <Typography color="#73BD00" fontSize={13} fontWeight={600}>
                  {incomeFormat.toString().replace('.', ',')} Tn
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'egress',
      flex: 1,
      sortable: false,

      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.outcome')}
        </Typography>
      ),
      renderCell: (params) => {
        const egressFormat = formatTons(params.value, true) ?? 0;
        return (
          <>
            {egressFormat > 0 ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={RedDownArrow} />
                <Typography color="#FF0000" fontSize={13} fontWeight={600}>
                  {egressFormat.toString().replace('.', ',')} Tn
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'balance',
      flex: 1,
      sortable: false,

      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.balance')}
        </Typography>
      ),
      renderCell: (params) => {
        const balanceFormat = formatTons(params.value, true);
        // TODO: balanceFormat is possibly undefined (si es 'undefined' se muestra undefined en la tabla) ❌
        return (
          <Typography color="#49454F" fontSize={13}>
            {balanceFormat?.toString().replace('.', ',')} Tn
          </Typography>
        );
      },
    },
    {
      field: 'movements_detail_length',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.silobag_data.movement')}
        </Typography>
      ),
      renderCell: (params) => {
        const quantityMovement = params.row.movements_detail?.length || 0;
        return (
          <Typography color="#49454F" fontSize={13}>
            {quantityMovement}
          </Typography>
        );
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: CustomDetailPanelToggle,
      flex: 2,
    },
  ];
  //Sub columnas para el "Ver Detalle"
  const columnsDetail: GridColDef[] = [
    {
      field: 'description',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.type')}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography color="#49454F" fontSize={13}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'income',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.income')}
        </Typography>
      ),
      renderCell: (params) => {
        const movement = params.row.movimiento;
        const movementFormat = formatTons(movement, true);
        // TODO: movementFormat is possibly undefined (si es 'undefined' se muestra undefined en la tabla) ❌
        return (
          <>
            {movement > 0 ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={GreenUpArrow} />
                <Typography color="#73BD00" fontSize={13} fontWeight={600}>
                  {movementFormat?.toString().replace('.', ',')} Tn
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'egress',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.report_stock.outcome')}
        </Typography>
      ),
      renderCell: (params) => {
        const movement = params.row.movimiento;
        const movementFormat = formatTons(movement, true);
        // TODO: movementFormat is possibly undefined (si es 'undefined' se muestra undefined en la tabla) ❌
        return (
          <>
            {movement < 0 ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <img src={RedDownArrow} />
                <Typography color="#FF0000" fontSize={13} fontWeight={600}>
                  {Math.abs(movementFormat ?? 0)
                    .toString()
                    .replace('.', ',')}{' '}
                  Tn
                </Typography>
              </Stack>
            ) : null}
          </>
        );
      },
    },
    {
      field: 'silobag_id',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          ID Silo
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Stack width="100%" direction="row" spacing={1} alignItems="center">
            <Typography
              variant="body2"
              style={{
                fontWeight: 500,
                fontSize: '13px',
                color: '#05AADF',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => navigate(`${PATH.SILOBAG}/${params.value}`)}
            >
              {params.value}
            </Typography>
            <img src={ChevronRight} alt="go-to-silo" style={{ marginTop: 5 }} />
          </Stack>
        );
      },
    },
    {
      field: 'countryside_name',
      flex: 1,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.countryside')}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Typography color="#49454F" fontSize={13}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: 'user_fullname',
      flex: 2,
      sortable: false,
      renderHeader: () => (
        <Typography color="#49454F" fontSize={13} fontWeight={600}>
          {i18n.t('app.user')}
        </Typography>
      ),
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              sx={{
                width: 30,
                height: 30,
                margin: '5px',
                fontSize: '12px',
                bgcolor: '#880BE4',
              }}
            >
              {params.value && params.value[0]}
            </Avatar>
            <Typography color="#49454F" fontSize={13}>
              {params.value}
            </Typography>
          </Stack>
        );
      },
    },
  ];
  //Contenido del panel de detalle
  const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }) => (
      <Stack sx={{ borderBottom: '1px solid #E6E6E6' }}>
        <BaseTable
          rows={
            row.movements_detail.map((item: MovementDetailType, index: number) => ({
              ...item,
              id: index,
            })) ?? []
          }
          hideFooter
          columns={columnsDetail}
          initialPageSize={10}
          noBorders
          rowHeight={45}
        />
      </Stack>
    ),
    []
  );

  return (
    <Grid container sx={{ width: '100%', marginTop: '20px' }}>
      <Grid item xs={12}>
        <BaseTable
          rows={stockDataReverse ?? []}
          columns={columns}
          initialPageSize={50}
          loading={isLoading}
          rowHeight={60}
          getDetailPanelContent={getDetailPanelContent}
        />
      </Grid>
    </Grid>
  );
}
