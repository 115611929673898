import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { HeavyLogsPayload, HeavyLogsResponse } from './get-heavy-logs.types';
import { transformIntoQueryParamString } from '@utils/service';

//getCorvusHeavyLogs
export async function getCorvusHeavyLogs({ query: rawQuery }: HeavyLogsPayload) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.get<HeavyLogsResponse>(ENDPOINT.CORVUS_HEAVYLOGS, { query });
  return {
    response,
    summary: data,
  };
}