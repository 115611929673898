import { useEffectOnMount, useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Modal,
  FormControl,
  Button,
  Divider,
  Fade,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Radio,
  TextareaAutosize,
  Typography,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Chip,
  CircularProgress,
} from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import { DatePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
  silobagExtractionService_DEPRECATED,
  silobagMultipleExtractionService,
  updateExtractionService_DEPRECATED,
} from '@services/silobag_extraction/silobag_extraction';
import { configButtonStylesContained, stylesInput } from '@styles/generic-styles';
import dayjs, { Dayjs } from 'dayjs';
import { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';
import es from 'dayjs/locale/es';
import i18n from '../../../../libs/language';
import { ToastAction } from '@ui/toast/toast-action';
import { listSilobagService } from '@services/domain/base/silobag/base/silobag-list';
import { useGlobalStore } from '@global-store/use-store';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SummarySilobagList = Awaited<ReturnType<typeof listSilobagService>>['summary']['data'];

type SummaryRemainingStock = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['remaining_stock'];

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type ExtractionModalProps = {
  open: boolean;
  fromSilobag?: boolean;
  setOpen: (open: boolean) => void;
  siloName?: string | undefined;
  silobagOrExtractionId?: string | number | undefined;
  remainingStock?: SummaryRemainingStock;
  type: 'new' | 'view' | 'edit';
  extractionData?: any;
  fromHistory?: boolean;
  silobagsList?: SummarySilobagList;
  setSnackbarMessage: (message: string) => void;
  setTypeSnackbar: (type: 'success' | 'error') => void;
  setOpenSnackbar: (setOpen: boolean) => void;
  enableEditMode?: () => void;
  setRemainingStock?: (data: string | null) => void;
  writePermission?: boolean;
  siloTrigger: (query: SilobagDetailParams) => void;
  setExtractionSuccess?: (success: boolean) => void;
  selectedSilobags: SummarySilobagList;
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

const locales = { es: es };
type LocaleKey = keyof typeof locales;

type InputChg = ChangeEvent<HTMLInputElement>;
type TextAreaChg = ChangeEvent<HTMLTextAreaElement>;
type SelChg = SelectChangeEvent;

interface MultipleSilosDataProps {
  weigthSilo1: string | undefined;
  weigthSilo2: string | undefined;
  nameSilo1: string | undefined;
  nameSilo2: string | undefined;
}

// TODO: CAMBIAR NOMBRE DEL ARCHIVO
export function ExtractionAction({
  open,
  setOpen,
  siloName,
  silobagOrExtractionId,
  remainingStock,
  setSnackbarMessage,
  setTypeSnackbar,
  setOpenSnackbar,
  type,
  enableEditMode,
  extractionData,
  silobagsList,
  fromSilobag = false,
  fromHistory = false,
  setRemainingStock,
  writePermission,
  setExtractionSuccess,
  selectedSilobags,
}: ExtractionModalProps) {
  const initErrorData = {
    silobag_name: '',
    weigth: '',
    extraction_date: '',
    gross_weight: '',
    truck_weight: '',
    silo_groupal_extraction_1: '',
    silo_groupal_extraction_2: '',
  };
  const locale: LocaleKey = 'es';
  const [disableSave, setDisableSave] = useToggle(false);
  const [extractionType, setExtractionType] = useState(1);
  const [errorData, setErrorData] = useState(initErrorData);
  const [idToUse, setIdToUse] = useState(silobagOrExtractionId || []);
  const readonly = type === 'view';
  const [silobagExtraction, setSilobagExtraction] = useState<string[]>(siloName ? [siloName] : []);
  const [silobagName, setSilobagName] = useState(siloName || '');
  const [isMultipleExtraction, setIsMultipleExtraction] = useToggle(false);
  const [errorExtractionMsg, setErrorExtractionMsg] = useState('');
  const { filters, organizationId } = useGlobalStore();
  const queryParams = {
    active: true,
    silobag_data_status_id: filters.silobag_data_status_id,
    organization_id: organizationId,
  };

  useEffectOnMount(() => {
    if (selectedSilobags && selectedSilobags.length <= 2 && selectedSilobags.length > 0) {
      if (selectedSilobags.length === 2) setIsMultipleExtraction(true);
      setSilobagName(selectedSilobags[0].name);
      const selectedValues = selectedSilobags.map((obj) => obj.id);
      setSilobagExtraction(selectedValues);
      setIdToUse(selectedValues);
      const selectedSilobag1 = selectedSilobags?.find((silobag) => silobag.id === selectedValues[0]);
      setSilobagGrupalExtraction((prevState) => ({
        ...prevState,
        nameSilo1: selectedSilobag1?.name ?? '―',
      }));
      const selectedSilobag2 = silobagsList?.find((silobag) => silobag.id === selectedValues[1]);
      setSilobagGrupalExtraction((prevState) => ({
        ...prevState,
        nameSilo2: selectedSilobag2?.name ?? '―',
      }));
    }
  });

  const { summary: silosForExtraction, isLoading: isLoadingExportSilobagsList } = useService(
    listSilobagService,
    {
      fetchOnMount: [{ query: queryParams }],
    }
  );
  // FOR MULTIPLE SILOBAGS
  const [silobagGrupalExtraction, setSilobagGrupalExtraction] = useState<MultipleSilosDataProps>({
    weigthSilo1: '',
    weigthSilo2: '',
    nameSilo1: '',
    nameSilo2: '',
  });

  const handleOnClose = () => {
    setRemainingStock && setRemainingStock(0);
    setOpen(false);
  };

  const closeOnSuccess = () => {
    handleOnClose();
    setTypeSnackbar('success');
    if (type === 'new') setSnackbarMessage?.(i18n.t('app.silobag_detail.new_extraction'));
    if (type === 'edit') setSnackbarMessage?.(i18n.t('app.silobag_detail.edit_extraction'));

    setOpenSnackbar(true);
    setOpen(false);
    if (setExtractionSuccess) setExtractionSuccess(true);

    setTimeout(() => {
      if (fromSilobag) {
        setExtractionSuccess && setExtractionSuccess(false);
        setOpenSnackbar(false);
      }
    }, 3000);
  };

  const {
    trigger: triggerExtraction,
    isError: errorInExtraction,
    error: errorMsg,
    isLoading: isLoadingExtraction,
  } = useService(silobagExtractionService_DEPRECATED, {
    onSuccess: closeOnSuccess,
  });

  const {
    trigger: triggerMultipleExtraction,
    isError: errorInMultiExtraction,
    error: errorMultipleMsg,
    isLoading: isLoadingMultiExtraction,
  } = useService(silobagMultipleExtractionService, {
    onSuccess: closeOnSuccess,
  });

  const { trigger: triggerUpdateExtraction, isLoading: isLoadingUpdateExtraction } = useService(
    updateExtractionService_DEPRECATED,
    {
      onSuccess: closeOnSuccess,
    }
  );

  const isLoading = isLoadingExtraction || isLoadingMultiExtraction || isLoadingUpdateExtraction;
  const [manualWeightInput, setManualWeightInput] = useState(false);
  const [extractionValues, setExtractionValues] = useState({
    extraction_date: type !== 'new' ? extractionData.extraction_date : '',
    weight: type !== 'new' ? extractionData.weight : '',
    observations: type !== 'new' ? extractionData.observations : '',
    gross_weight: type !== 'new' ? extractionData.gross_weight : null,
    truck_weight: type !== 'new' ? extractionData.truck_weight : null,
    freight_transport: type !== 'new' ? extractionData.freight_transport : '',
    carrier: type !== 'new' ? extractionData.carrier : '',
    back_plate: type !== 'new' ? extractionData.back_plate : '',
    driver: type !== 'new' ? extractionData.driver : '',
    trailer_back_plate: type !== 'new' ? extractionData.trailer_back_plate : '',
    ctg_document: type !== 'new' ? extractionData.ctg_document : '',
    waybill: type !== 'new' ? extractionData.waybill : '',
    destination: type !== 'new' ? extractionData.destination : '',
    contract: type !== 'new' ? extractionData.contract : '',
  });

  const rootDataError = (key: string, value: string) => setErrorData((prev) => ({ ...prev, [key]: value }));
  const rootDataChange = (key: string, value: string) =>
    setExtractionValues((prev: any) => ({ ...prev, [key]: value })); // TODO: ASIGNAR TIPO A PREV

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
  };

  const parseWeight = (weight: string | undefined) => {
    return weight === '' || weight === undefined ? 0 : parseInt(weight, 10);
  };

  const HANDLERS = {
    silobag_name: (event: SelChg) => {
      rootDataError('silobag_name', '');
      const value = event.target.value as string;

      setSilobagName(value);
      const selectedValues = typeof value === 'string' ? value.split(',') : (value as string[]);

      if (selectedValues.length <= 2) {
        setSilobagExtraction(selectedValues);
        const selectedSilobag1 = silobagsList?.find((silobag) => silobag.id === value[0]);
        setSilobagGrupalExtraction((prevState) => ({
          ...prevState,
          nameSilo1: selectedSilobag1?.name ?? '-',
        }));
        const selectedSilobag2 = silobagsList?.find((silobag) => silobag.id === value[1]);
        setSilobagGrupalExtraction((prevState) => ({
          ...prevState,
          nameSilo2: selectedSilobag2?.name ?? '-',
        }));
      }

      setIdToUse(event.target.value as string);
    },
    extraction_date: (e: DateRange<Dayjs> | null) => {
      if (!e) return;
      const input = dayjs(e.toString()).format('YYYY-M-D');
      rootDataError('extraction_date', '');
      rootDataChange('extraction_date', input);
    },
    weight: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('weight', 'Solo se aceptan números.');
        return;
      }
      rootDataError('weight', '');
      rootDataChange('weight', input);
      setManualWeightInput(true);
    },
    observations: (event: TextAreaChg) => {
      const input = event.target.value;
      rootDataError('observations', '');
      rootDataChange('observations', input);
    },
    gross_weight: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('gross_weight', 'Solo se aceptan números.');
        return;
      }
      rootDataError('gross_weight', '');
      rootDataChange('gross_weight', input);
    },
    truck_weight: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('truck_weight', 'Solo se aceptan números.');
        return;
      }
      rootDataError('truck_weight', '');
      rootDataChange('truck_weight', input);
    },
    freighTransport: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('freight_transport', input);
    },
    carrier: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('carrier', input);
    },
    backPlate: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('back_plate', input);
    },
    driver: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('driver', input);
    },
    trailerBackPlate: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('trailer_back_plate', input);
    },
    ctgDocument: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('ctg_document', input);
    },
    waybill: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('waybill', input);
    },
    destination: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('destination', input);
    },
    contract: (e: InputChg) => {
      const input = e.target.value;
      rootDataChange('contract', input);
    },
    silo_group_1: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('silo_groupal_extraction_1', 'Solo se aceptan números.');
        return;
      }
      rootDataError('silo_groupal_extraction_1', '');
      setSilobagGrupalExtraction((prevData) => ({
        ...prevData,
        weigthSilo1: input,
      }));
    },
    silo_group_2: (e: InputChg) => {
      const input = e.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('silo_groupal_extraction_2', 'Solo se aceptan números.');
        return;
      }
      rootDataError('silo_groupal_extraction_2', '');
      setSilobagGrupalExtraction((prevData) => ({
        ...prevData,
        weigthSilo2: input,
      }));
    },
  };

  const calculateWeightIfNeeded = () => {
    const isSiloWeight1GT0 = parseWeight(silobagGrupalExtraction.weigthSilo1) > 0;
    const isSiloWeight2GT0 = parseWeight(silobagGrupalExtraction.weigthSilo2) > 0;
    const isValidExtractionWeight = isMultipleExtraction && (isSiloWeight1GT0 || isSiloWeight2GT0);

    if (!manualWeightInput) {
      if (isValidExtractionWeight) {
        const newWeight =
          parseWeight(silobagGrupalExtraction.weigthSilo1) + parseWeight(silobagGrupalExtraction.weigthSilo2);
        setExtractionValues((prevValues) => ({
          ...prevValues,
          weight: newWeight,
        }));
      } else if (isMultipleExtraction) {
        setExtractionValues((prevValues) => ({
          ...prevValues,
          weight: 0,
        }));
      } else if (!extractionValues.extraction_date) {
        setExtractionValues((prevValues) => ({
          ...prevValues,
          weight: extractionValues.weight || 0,
        }));
      }
    }

    //Refactorizar
  };

  useEffect(() => {
    calculateWeightIfNeeded();
  }, [silobagGrupalExtraction, isMultipleExtraction, manualWeightInput]);

  useEffect(() => {
    if (extractionValues.weight === '') {
      setManualWeightInput(false);
    }
  }, [extractionValues.weight]);

  const handleSave = () => {
    setDisableSave(true);
    try {
      const errorMsg = i18n.t('app.must_field');
      let error = false;
      if (silobagName === '') (error = true), rootDataError('silobag_name', errorMsg);
      if (extractionValues.weight === '') (error = true), rootDataError('weight', errorMsg);
      if (extractionValues.extraction_date === '') (error = true), rootDataError('extraction_date', errorMsg);

      if (isMultipleExtraction && !silobagGrupalExtraction.weigthSilo1)
        (error = true), rootDataError('silo_groupal_extraction_1', errorMsg);
      if (isMultipleExtraction && !silobagGrupalExtraction.weigthSilo2)
        (error = true), rootDataError('silo_groupal_extraction_2', errorMsg);

      if (error) {
        setDisableSave(false);
        return;
      }

      const body = {
        ...extractionValues,
        weight: parseInt(extractionValues.weight, 10),
        gross_weight: parseInt(extractionValues.gross_weight, 10),
        truck_weight: parseInt(extractionValues.truck_weight, 10),
      };

      if (type !== 'new') {
        triggerUpdateExtraction(body, idToUse);
        return;
      }

      if (isMultipleExtraction) {
        // Debido a cómo esta tipado en backend, es necesario hacer estos cambios (No se puede usar el mismo formato que en extracción simple (por ej. 2024-7-1))
        // el back solamente deja enviar la fecha completa (ej. "2023-09-06T13:29:00.891Z")

        // Este codigo se utiliza para cambiar el formato de la fecha ingregaso --> 2024-7-1 a 2024-07-01T17:13:12.931Z
        const extractionDate = extractionValues.extraction_date;

        const [year, month, day] = extractionDate.split('-');

        const formattedMonth = month.padStart(2, '0');
        const formattedDay = day.padStart(2, '0');

        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

        const currentDate = new Date();
        const hours = String(currentDate.getUTCHours()).padStart(2, '0');
        const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');
        const milliseconds = String(currentDate.getUTCMilliseconds()).padStart(3, '0');

        const multipleBody = {
          extraction_date: formattedDate + `T${hours}:${minutes}:${seconds}.${milliseconds}Z`,
          last_extraction: false,
          observations: extractionValues.observations,
          weight: extractionValues.weight,
          gross_weight: extractionValues.gross_weight,
          waybill: extractionValues.waybill,
          truck_weight: extractionValues.truck_weight,
          trailer_back_plate: extractionValues.trailer_back_plate,
          freight_transport: extractionValues.freight_transport,
          driver: extractionValues.driver,
          destination: extractionValues.destination,
          ctg_document: extractionValues.ctg_document,
          contract: extractionValues.contract,
          carrier: extractionValues.carrier,
          back_plate: extractionValues.back_plate,
          comercial_km: 0,
          comercial_price: '',
          silobag_extractions: [
            {
              silobag_id: idToUse[0],
              weight: silobagGrupalExtraction.weigthSilo1,
              gross_weight: extractionValues.gross_weight, // Puede ir vacio
              truck_weight: extractionValues.truck_weight, // Puede ir vacio
            },
            {
              silobag_id: idToUse[1],
              weight: silobagGrupalExtraction.weigthSilo2,
              gross_weight: extractionValues.gross_weight, // Puede ir vacio
              truck_weight: extractionValues.truck_weight, // Puede ir vacio
            },
          ],
        };
        triggerMultipleExtraction(multipleBody);
        return;
      }
      triggerExtraction(body, idToUse);
    } catch (error) {
      setTypeSnackbar('error');
      setSnackbarMessage('Ocurrió un error al realizar la extracción.');
      setOpenSnackbar(true);
      setOpen(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const idArray = Array.isArray(idToUse) ? idToUse : [idToUse];
      if (!idArray.length) {
        setRemainingStock && setRemainingStock(0);
        setIsMultipleExtraction(false);
        return;
      }

      // TODO: CHEQUEAR PROBLEMAS CON USESERVICE
      //if (summary && summary.remaining_stock) setRemainingStock(summary?.remaining_stock);
      if (silobagExtraction.length === 2) {
        let totalStockOnField: number = 0;
        // TODO: Arreglar Tipados;
        const { summary } = await silobagDataDetailService({ query: idArray[0].toString() });

        totalStockOnField = summary?.remaining_stock ?? 0;
        const siloID2Data = await silobagDataDetailService({ query: idArray[1].toString() });
        totalStockOnField = totalStockOnField + (+siloID2Data?.summary?.remaining_stock ?? 0);
        setRemainingStock && setRemainingStock(totalStockOnField);
        setIsMultipleExtraction(true);
      } else {
        const { summary } = await silobagDataDetailService({ query: idArray[0].toString() });
        if (summary && summary.remaining_stock)
          setRemainingStock && setRemainingStock(summary?.remaining_stock);
        setIsMultipleExtraction(false);
      }
    };

    fetchData();
  }, [idToUse]);
  // Esto se usa para mostrar los nombres de las silobolsas elegidos en extraccion multiple, en vez de sus IDs
  const idToNameMap: { [key: number]: string } = silobagsList
    ? silobagsList.reduce(
        (acc, silobag) => {
          acc[silobag.id] = silobag.name;
          return acc;
        },
        {} as { [key: number]: string }
      )
    : { [idToUse]: siloName };

  useEffectOnUpdate(() => {
    if (errorInExtraction || errorInMultiExtraction) {
      if (
        errorMsg?.data?.error === 'The extraction weight is more than twice the initial weight' ||
        errorMultipleMsg?.data?.error === 'The extraction weight is more than twice the initial weight'
      ) {
        setErrorExtractionMsg(i18n.t('app.silobags_extractions.extraction_more_than_doble'));
      } else {
        setErrorExtractionMsg(i18n.t('app.silobags_extractions.generic_error_msg'));
      }

      setDisableSave(false);
    }
  }, [errorInExtraction, errorInMultiExtraction]);

  const validationButtonSave =
    writePermission === false || disableSave || isLoading || (Array.isArray(idToUse) && idToUse.length === 0);

  return (
    <Box>
      {errorInExtraction && <ToastAction message={errorExtractionMsg} severity="error" />}
      {errorInMultiExtraction && <ToastAction message={errorExtractionMsg} severity="error" />}
      <MyModal open={open} onClose={handleOnClose}>
        <Box style={{ overflowY: 'auto' }}>
          <Box>
            <Typography variant="h5">{i18n.t('app.silobags_extractions.extraction_silobag')}</Typography>
          </Box>
          <Divider />
          <Stack direction={'row'} spacing={1} mt={'20px'}>
            <FormControl fullWidth required size="small" error={Boolean(errorData.silobag_name)}>
              <InputLabel>
                {silobagExtraction && silobagExtraction.length === 0
                  ? i18n.t('app.silobags_extractions.choose_two_silobags')
                  : i18n.t('app.silobags_extractions.silos')}
              </InputLabel>
              <Select
                multiple
                error={Boolean(errorData.silobag_name)}
                value={silobagExtraction}
                label={
                  silobagExtraction && silobagExtraction.length === 0
                    ? i18n.t('app.silobags_extractions.choose_two_silobags')
                    : i18n.t('app.silobags_extractions.silos')
                }
                disabled={siloName ? true : false}
                onChange={HANDLERS.silobag_name}
                required
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {(selected as number[]).map((value) => (
                      <Chip key={value} label={idToNameMap[value] || value} />
                    ))}
                  </Box>
                )}
              >
                {isLoadingExportSilobagsList ? (
                  <CircularProgress />
                ) : siloName ? (
                  <MenuItem value={siloName} key={siloName}>
                    {siloName}
                  </MenuItem>
                ) : (
                  silosForExtraction?.data
                    ?.filter((silobag: any) => silobag.write_permission)
                    .map((silobag: any) => (
                      <MenuItem key={silobag.id} value={silobag.id}>
                        {silobag.name}
                      </MenuItem>
                    ))
                )}
              </Select>
              {errorData.silobag_name ? <FormHelperText>{errorData.silobag_name}</FormHelperText> : null}
            </FormControl>
            <FormControl
              fullWidth
              required
              error={Boolean(errorData.extraction_date)}
              variant="outlined"
              size="small"
              style={{ width: '95%' }}
              sx={stylesInput}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                <Container style={{ width: '100%' }}>
                  <DatePicker
                    label={i18n.t('app.silobags_extractions.date_withdrawal')}
                    onChange={HANDLERS.extraction_date}
                    disabled={type === 'view' || type === 'edit'}
                    value={dayjs(extractionValues.extraction_date).utc()}
                    required
                  />
                </Container>
              </LocalizationProvider>
              {errorData.extraction_date ? (
                <FormHelperText>{errorData.extraction_date}</FormHelperText>
              ) : null}
            </FormControl>
          </Stack>
          <FormControl>
            <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
              <FormLabel>{i18n.t('app.silobags_extractions.type_extraction')}</FormLabel>
              <RadioGroup row defaultValue={1}>
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  onChange={() => setExtractionType(1)}
                  label={i18n.t('app.silobags_extractions.truck')}
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  onChange={() => setExtractionType(2)}
                  label={i18n.t('app.silobags_extractions.consolidated')}
                />
              </RadioGroup>
            </Stack>
          </FormControl>
          {extractionType === 1 ? (
            <Truck
              remainingStock={remainingStock}
              errorData={errorData}
              handlers={HANDLERS}
              extractionValues={extractionValues}
              readonly={readonly}
              isMultiple={isMultipleExtraction}
              multipleSilosData={silobagGrupalExtraction}
              setMultipleSilosData={setSilobagGrupalExtraction}
            />
          ) : (
            <Consolidate
              remainingStock={remainingStock}
              errorData={errorData}
              handlers={HANDLERS}
              extractionValues={extractionValues}
              readonly={readonly}
              isMultiple={isMultipleExtraction}
              multipleSilosData={silobagGrupalExtraction}
              setMultipleSilosData={setSilobagGrupalExtraction}
            />
          )}
          <Stack direction={'column'} spacing={2} style={{ marginTop: '10px' }}>
            <span>{i18n.t('app.silobags_extractions.others')}</span>
            <FormControl fullWidth size="small">
              <TextareaAutosize
                disabled={readonly}
                value={extractionValues.observations}
                onChange={HANDLERS.observations}
                placeholder={i18n.t('app.observations')}
                minRows={5}
                maxRows={10}
                style={{
                  resize: 'none',
                  fontFamily: 'IBM Plex Sans, sans-serif',
                  fontSize: '1rem',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: '5px',
                }}
              />
            </FormControl>
          </Stack>
        </Box>
        <Box>
          <Stack direction={'row'} flex={1} justifyContent={'end'} alignItems={'center'}>
            <Button style={{ color: '#000' }} onClick={handleOnClose}>
              Cancelar
            </Button>
            {!fromHistory && (
              <Button
                style={{ color: '#fff' }}
                variant="contained"
                disabled={validationButtonSave}
                sx={configButtonStylesContained}
                onClick={readonly ? enableEditMode : handleSave}
              >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : readonly ? 'Editar' : 'Guardar'}
              </Button>
            )}
          </Stack>
        </Box>
      </MyModal>
    </Box>
  );
}
// TODO: CAMBIAR VALORES ANY
type ExtractProps = {
  remainingStock: number;
  errorData: any;
  handlers: any;
  extractionValues: any;
  readonly: boolean;
  isMultiple: boolean;
  multipleSilosData: MultipleSilosDataProps;
  setMultipleSilosData: React.Dispatch<React.SetStateAction<MultipleSilosDataProps>>;
};

function Truck({
  remainingStock,
  errorData,
  handlers: HANDLERS,
  extractionValues,
  readonly,
  isMultiple,
  multipleSilosData,
  setMultipleSilosData,
}: ExtractProps) {
  return (
    <Box>
      <Box>
        {/* PESO CAMION */}
        <Typography variant="body1" gutterBottom>
          {i18n.t('app.silobags_extractions.weight_truck')}
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            disabled={readonly}
            size="small"
            label={i18n.t('app.silobags_extractions.gross_weight') + ' (Kg)'}
            onChange={HANDLERS.gross_weight}
            error={Boolean(errorData.gross_weight)}
            value={
              readonly && extractionValues?.gross_weight
                ? i18n.numberToDelimited(extractionValues?.gross_weight, {
                    delimiter: i18n.t('config.delimiter'),
                  })
                : extractionValues?.gross_weight
            }
            helperText={errorData.gross_weight}
            fullWidth
          />
          <TextField
            disabled={readonly}
            size="small"
            label="Tara"
            fullWidth
            onChange={HANDLERS.truck_weight}
            error={Boolean(errorData.truck_weight)}
            value={
              readonly && extractionValues?.truck_weight
                ? i18n.numberToDelimited(extractionValues?.truck_weight, {
                    delimiter: i18n.t('config.delimiter'),
                  })
                : extractionValues?.truck_weight
            }
            helperText={errorData.truck_weight}
          />

          <FormControl required error={Boolean(errorData.weight)} fullWidth>
            <TextField
              disabled={readonly}
              size="small"
              fullWidth
              label={i18n.t('app.silobags_extractions.gross_consolidated') + ' (Kg)'}
              required
              error={Boolean(errorData.weight)}
              onChange={HANDLERS.weight}
              value={
                readonly
                  ? i18n.numberToDelimited(extractionValues.weight, {
                      delimiter: i18n.t('config.delimiter'),
                    })
                  : extractionValues.weight
              }
            />
            <Typography variant="body2">
              Stock en campo (Kg):{' '}
              {i18n.numberToDelimited(remainingStock, { delimiter: i18n.t('config.delimiter') })} Kg
            </Typography>
            {errorData.weight ? <FormHelperText>{errorData.weight}</FormHelperText> : null}
          </FormControl>
        </Stack>
        {isMultiple && (
          <Box sx={{ width: '100%' }}>
            <MultipleExtraction
              multipleSilosData={multipleSilosData}
              setMultipleSilosData={setMultipleSilosData}
              errorData={errorData}
              handlers={HANDLERS}
            />
          </Box>
        )}
        {/* TRANSPORTE */}
        <Typography variant="body1" gutterBottom>
          {i18n.t('app.silobags_extractions.transport')}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Stack width={'100%'} spacing={1}>
            <TextField
              disabled={readonly}
              label={i18n.t('app.silobags_extractions.freight_broker')}
              size="small"
              fullWidth
              onChange={HANDLERS.freighTransport}
              value={extractionValues.freight_transport}
            />
            <TextField
              disabled={readonly}
              label={i18n.t('app.silobags_extractions.driver')}
              size="small"
              fullWidth
              onChange={HANDLERS.driver}
              value={extractionValues.driver}
            />
            <TextField
              disabled={readonly}
              label={i18n.t('app.silobags_extractions.chassis_attached')}
              size="small"
              fullWidth
              onChange={HANDLERS.trailerBackPlate}
              value={extractionValues.trailer_back_plate}
            />
          </Stack>
          <Stack width={'100%'} spacing={1}>
            <TextField
              disabled={readonly}
              label={i18n.t('app.silobags_extractions.carrier')}
              size="small"
              fullWidth
              onChange={HANDLERS.carrier}
              value={extractionValues.carrier}
            />
            <TextField
              disabled={readonly}
              label="Patente Chasis"
              size="small"
              fullWidth
              onChange={HANDLERS.backPlate}
              value={extractionValues.back_plate}
            />
          </Stack>
        </Stack>
        {/* DOCUMENTO */}
        <Typography variant="body1" gutterBottom>
          Documento
        </Typography>
        <Stack direction="row" spacing={1}>
          <TextField
            disabled={readonly}
            label={i18n.t('app.silobags_extractions.ctg')}
            size="small"
            fullWidth
            onChange={HANDLERS.ctgDocument}
            value={extractionValues.ctg_document}
          />
          <TextField
            disabled={readonly}
            label="Nro Carta de porte"
            size="small"
            fullWidth
            onChange={HANDLERS.waybill}
            value={extractionValues.waybill}
          />
        </Stack>
        {/* COMPRADOR */}
        <Typography variant="body1" gutterBottom>
          Comprador
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <TextField
            disabled={readonly}
            label={i18n.t('app.silobags_extractions.contract')}
            size="small"
            fullWidth
            onChange={HANDLERS.contract}
            value={extractionValues.contract}
          />
          <TextField
            disabled={readonly}
            label="Destino (Comprador)"
            size="small"
            fullWidth
            onChange={HANDLERS.destination}
            value={extractionValues.destination}
          />
        </Stack>
      </Box>
    </Box>
  );
}

function Consolidate({
  remainingStock,
  errorData,
  handlers: HANDLERS,
  extractionValues,
  readonly,
  isMultiple,
  multipleSilosData,
  setMultipleSilosData,
}: ExtractProps) {
  return (
    <Box>
      <Box sx={{ width: '100%' }}>
        <FormControl required error={Boolean(errorData.weight)}>
          <TextField
            disabled={readonly}
            size="small"
            fullWidth
            label={i18n.t('app.silobags_extractions.gross_consolidated') + ' (Kg)'}
            required
            error={Boolean(errorData.weight)}
            onChange={HANDLERS.weight}
            value={
              readonly
                ? i18n.numberToDelimited(extractionValues.weight, {
                    delimiter: i18n.t('config.delimiter'),
                  })
                : extractionValues.weight
            }
          />
          <Typography variant="body2">
            Stock en campo (Kg):{' '}
            {i18n.numberToDelimited(remainingStock, { delimiter: i18n.t('config.delimiter') })} Kg
          </Typography>
          {errorData.weight ? <FormHelperText>{errorData.weight}</FormHelperText> : null}
        </FormControl>
        {isMultiple && (
          <MultipleExtraction
            multipleSilosData={multipleSilosData}
            setMultipleSilosData={setMultipleSilosData}
            handlers={HANDLERS}
            errorData={errorData}
          />
        )}
      </Box>
    </Box>
  );
}

type MultipleExtractionProps = {
  handlers: any;
  errorData: any;
  multipleSilosData: MultipleSilosDataProps;
  setMultipleSilosData: React.Dispatch<React.SetStateAction<MultipleSilosDataProps>>;
};

function MultipleExtraction(MEProps: MultipleExtractionProps) {
  const { multipleSilosData, handlers: HANDLERS, errorData } = MEProps;

  return (
    <Box mt={2} mb={2} width={'100%'}>
      <Stack direction={'column'} spacing={2}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'start'}
          spacing={2}
        >
          <Typography width={'30%'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
            {multipleSilosData?.nameSilo1}
          </Typography>
          <FormControl required error={Boolean(errorData.silo_groupal_extraction_1)} sx={{ width: 270 }}>
            <TextField
              required
              label={'Peso consolidado por peso'}
              size="small"
              onChange={HANDLERS?.silo_group_1}
              error={Boolean(errorData.silo_groupal_extraction_1)}
              value={multipleSilosData.weigthSilo1}
            />
            {errorData.silo_groupal_extraction_1 ? (
              <FormHelperText>{errorData.silo_groupal_extraction_1}</FormHelperText>
            ) : null}
          </FormControl>
        </Stack>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={'center'}
          justifyContent={'start'}
          spacing={2}
          width={'100%'}
        >
          <Typography width={'30%'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}>
            {multipleSilosData?.nameSilo2}
          </Typography>
          <FormControl required error={Boolean(errorData.silo_groupal_extraction_2)} sx={{ width: 270 }}>
            <TextField
              required
              label={'Peso consolidado por peso'}
              size="small"
              onChange={HANDLERS?.silo_group_2}
              error={Boolean(errorData.silo_groupal_extraction_2)}
              value={multipleSilosData.weigthSilo2}
            />
            {errorData.silo_groupal_extraction_2 ? (
              <FormHelperText>{errorData.silo_groupal_extraction_2}</FormHelperText>
            ) : null}
          </FormControl>
        </Stack>
      </Stack>
    </Box>
  );
}

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
