import { useState } from 'react';

type IUseToggle = [boolean, (newValue?: unknown) => void];

export function useToggle(initValue: boolean): IUseToggle {
  const [value, setValue] = useState(initValue);

  const toggle = (newValue?: unknown) =>
    setValue((prev) => (typeof newValue === 'boolean' ? newValue : !prev));

  return [value, toggle];
}
