import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import SILOREAL_LOGO_BLACK from '../../assets/logos/siloreal-logo-black.webp';

import MailIcon from '@assets/svg/domain/footer/mail-green.svg';
import WebIcon from '@assets/svg/domain/footer/globe-green.svg';
import MapPinIcon from '@assets/svg/core/location-pin-green.svg';
import i18n from '../../libs/language';

export function GlobalFooter({ hasMarginTop = true }) {
  const navigate = useNavigate();

  return (
    <>
      <Divider sx={{ marginTop: hasMarginTop ? '45px' : 0 }} />
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        padding={'20px 100px 45px 100px'}
        fontSize={'0.8rem'}
        gap={20}
        component="footer"
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            padding: '20px 50px 45px 50px',
          },
          [theme.breakpoints.down('sm')]: {
            padding: '20px 25px 45px 25px',
          },
        })}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          display={'flex'}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems={'center'}
          spacing={2}
        >
          <Stack direction={'column'}>
            <Box
              width={'100%'}
              marginRight={'auto'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                style={{ width: 200, height: 75, objectFit: 'contain', cursor: 'pointer' }}
                src={SILOREAL_LOGO_BLACK}
                alt="SiloReal Company"
                onClick={() => navigate('/')}
              />
            </Box>{' '}
            <Typography color={'#7A7A7A'} alignItems={'start'}>
              2024 © SiloReal
            </Typography>
          </Stack>
          <Stack direction={'column'} spacing={2}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }}>
              <Stack spacing={2}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img style={{ width: 14, height: 14 }} src={MailIcon} />
                  <NavLink
                    to="#"
                    onClick={(e) => {
                      window.location.href = 'mailto:info@siloreal.com';
                      e.preventDefault();
                    }}
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    <Box>info@siloreal.com</Box>
                  </NavLink>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img style={{ width: 14, height: 14 }} src={WebIcon} />
                  <NavLink
                    to="https://siloreal.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                  >
                    <Box>siloreal.com</Box>
                  </NavLink>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center" style={{ minWidth: 280 }} width={'100%'}>
                <img style={{ width: 22, height: 22 }} src={MapPinIcon} />
                <Stack>
                  <Box>Uruguay 2522,</Box>
                  <Box style={{ color: '#7A7A7A' }}>B1644 Beccar, Provincia de Buenos Aires, Argentina</Box>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              display={'flex'}
              justifyContent={{ xs: 'center', md: 'end' }}
              alignItems={{ xs: 'center', md: 'end' }}
            >
              <Link
                style={{
                  color: '#6baa00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                href="https://calendar.app.google/MSjziwPnVQwD7RT56 "
                target="_blank"
              >
                {i18n.t('app.support_calendly')}
              </Link>
              <Link
                style={{
                  color: '#6baa00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                href="https://identityonfield.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
              >
                {i18n.t('app.support_problem')}
              </Link>
              <Link
                style={{
                  color: '#6baa00',
                  fontWeight: 600,
                  textDecoration: 'none',
                }}
                href="https://api.whatsapp.com/send/?phone=5491122443491&text=%C2%A1Hola%21&type=phone_number&app_absent=0"
                target="_blank"
              >
                {i18n.t('app.support_whatsapp')}
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
