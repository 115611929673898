import arrozIcon from '@assets/svg/species/ico-especies/arroz.svg';
import trigoIcon from '@assets/svg/species/ico-especies/trigo.svg';
import sojaIcon from '@assets/svg/species/ico-especies/soja.svg';
import maizIcon from '@assets/svg/species/ico-especies/maiz.svg';
import girasolIcon from '@assets/svg/species/ico-especies/girasol.svg';
import sorgoIcon from '@assets/svg/species/ico-especies/sorgo.svg';
import cebadaIcon from '@assets/svg/species/ico-especies/cebada.svg';
import milhetoIcon from '@assets/svg/milheto-icon.svg';

export const formatTons = (value: number | undefined, includeDecimals: boolean = false) => {
  if (value !== undefined) {
    const tons = value / 1000;
    return includeDecimals ? parseFloat(tons.toFixed(2)) : Math.round(tons);
  }
};

export const formatDate = (date: number | string | Date, includeTime: boolean = false): string => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getUTCDate().toString().padStart(2, '0');

  if (includeTime) {
    const hours = dateObj.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  } else {
    return `${day}/${month}/${year}`;
  }
};

export const formatDateReverse = (date: string | Date): string => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getUTCDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateTimeReverse = (date: string): string => {
  const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
  const dateObj = new Date(date);
  dateObj.setMinutes(dateObj.getMinutes() - timezoneOffsetInMinutes);
  const year = dateObj.getFullYear();
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getUTCDate().toString().padStart(2, '0');
  const hours = dateObj.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getUTCSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatDateTime = (date: string, format: number): string => {
  // 1: YYYY-MM-DD HH:MM:SS
  // 2: DD/MM/YYYY HH:MM:SS

  const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
  const dateObj = new Date(date);
  dateObj.setMinutes(dateObj.getMinutes() - timezoneOffsetInMinutes);
  const year = dateObj.getUTCFullYear();
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getUTCDate().toString().padStart(2, '0');
  const hours = dateObj.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');
  const seconds = dateObj.getUTCSeconds().toString().padStart(2, '0');

  switch (format) {
    case 1:
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    case 2:
      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    case 3:
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    case 4:
      return `${day}/${month}/${year}`;
    default:
      return 'Invalid format specified';
  }
};

export const DateMonth = (dateString: string): string => {
  const [day, month, year] = dateString.split('/');
  const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];

  const monthAbbreviation = months[parseInt(month, 10) - 1].toUpperCase().substring(0, 3);
  const yearAbbreviation = year.substring(2);

  return `${monthAbbreviation} ${yearAbbreviation}`;
};

export function calculatePastDays(numDays: number): Date {
  const today: Date = new Date();
  const millisecondsPerDay: number = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const pastTime: number = today.getTime() - numDays * millisecondsPerDay;
  const pastDate: Date = new Date(pastTime);
  return pastDate;
}

export const speciesIcons = [
  {
    id: 1,
    name: 'TRIGO',
    icon: trigoIcon,
  },
  {
    id: 2,
    name: 'SOJA',
    icon: sojaIcon,
  },
  {
    id: 3,
    name: 'GIRASOL',
    icon: girasolIcon,
  },
  {
    id: 4,
    name: 'SORGO',
    icon: sorgoIcon,
  },
  {
    id: 5,
    name: 'ARROZ',
    icon: arrozIcon,
  },
  {
    id: 6,
    name: 'MAIZ',
    icon: maizIcon,
  },
  {
    id: 23,
    name: 'CEBADA',
    icon: cebadaIcon,
  },
  {
    id: 27,
    name: 'MILHETO',
    icon: milhetoIcon,
  },
];

export const getProperty = (obj: any, path: string) => {
  // Función auxiliar recursiva para acceder a las propiedades anidadas
  const properties = path.split('.');
  let value = obj;
  for (let prop of properties) {
    if (value) {
      value = value[prop];
    } else {
      return undefined;
    }
  }
  return value;
};

export const getDevicesImgs = (data: any) => {
  const extras: any[] = [];
  if (data && data?.silobags_devices && data?.silobags_devices?.length > 0) {
    data?.silobags_devices?.forEach((v: any) => {
      v.silobags_devices_actions.forEach((elem: any) => {
        if ([1, 4].includes(elem.silobags_action_types_id))
          extras.push({
            label: data?.silobags_devices?.devices?.lance_number,
            url: elem.image,
            init_timestamp: elem.timestamp,
            init_lat: elem.lat,
            init_lon: elem.lon,
            init_accuracy: elem.accuracy,
            init_azimuth: elem.azimuth,
          });
      });
    });
  }

  return extras;
};
//TODO FUNCION EN PROCESO DE EXTRACCION SOLUCION MOMENTANEA PARA QUE MUESTRE SOLO LA PRIMERA EXTRACCION
export const setExtractionImgs = (event_id: number, data: any, setImg: (params: any) => void) => {
  try {
    const img: any = [];
    const historyItem = data?.silobags_history?.find((item: any) => item.id === event_id);
    if (historyItem && historyItem.silobags_extractions_versions) {
      const extractionsVersions = historyItem.silobags_extractions_versions.data;
      if (
        extractionsVersions &&
        !Array.isArray(extractionsVersions) &&
        extractionsVersions.silobag_extractions_images
      ) {
        extractionsVersions.silobag_extractions_images.forEach((ex: any) => {
          img.push({
            url: ex.image_url,
            label: 'Imagenes de Extracción',
            init_lat: ex.lat,
            init_lon: ex.lon,
            init_accuracy: ex.accuracy,
            init_timestamp: ex.timestamp,
            init_azimuth: ex.azimuth,
          });
        });
      }
    } else {
      setImg([]);
      return;
    }
    setImg(img);
  } catch (error: any) { } // TODO: revisar este try-catch sincrónico
};

export const setHistoricalPoeImgs = (event_id: number, data: any, setImg: (params: any) => void) => {
  let eventId: number | null = null;
  const historyItem = data && data?.silobags_history.find((item: any) => item.poe && item.poe.id);
  if (historyItem && historyItem.poe) {
    eventId = historyItem.poe.id;
  }
  try {
    const poeHistory = data && data?.silobags_history.find((v: any) => v.poe && v.poe.id === eventId);
    if (poeHistory) {
      const img: any = [];
      if (poeHistory.poe) {
        img.push({
          url: poeHistory.poe.image_url,
        });
      }
      if (poeHistory.poe?.poe_extra_photos) {
        img.push(
          ...poeHistory.poe.poe_extra_photos.map((photo: any) => ({
            url: photo.image_url,
            label: 'Fotos Extra Recorrida',
          }))
        );
      }

      if (poeHistory.poe?.poe_details) {
        for (const key in poeHistory.poe.poe_details) {
          if (poeHistory.poe.poe_details.hasOwnProperty(key)) {
            if (poeHistory.poe.poe_details[key].image_url) {
              img.push({
                url: poeHistory.poe.poe_details[key].image_url,
                label: '',
                init_lat: poeHistory.lat,
                init_lon: poeHistory.lon,
                init_accuracy: poeHistory.accuracy,
                init_timestamp: poeHistory.timestamp,
                init_azimuth: poeHistory.azimuth,
              });
            }
            const detail = poeHistory.poe.poe_details[key].breakage_details;
            detail.map((value: any) => img.push({ image_url: value.image_url }));
          }
        }
      } else {
        setImg([]);
        return;
      }
      setImg(img);
    }
  } catch (error: any) { } // TODO: revisar este try-catch sincrónico
};

export const setSiloMetricImg = (event_id: number, data: any, setImg: (params: any) => void) => {
  try {
    // TODO: (emi) optional chaining
    const devices =
      data &&
      data.silobags_devices &&
      data.silobags_devices.length > 0 &&
      data.silobags_devices.find((v: any) => v.id === event_id);
    const img: any = [];
    // TODO: (emi) early return
    if (devices?.silobags_devices_actions?.length > 0) {
      devices.silobags_devices_actions
        .filter((da: any) => da.silobags_action_types_id === 1)
        .forEach((da: any) =>
          img.push({
            url: da.image,
            label: devices?.devices?.lance_number?.length > 0 ? devices.devices.lance_number : '',
            init_lat: da.lat,
            init_lon: da.lon,
            init_accuracy: da.accuracy,
            init_timestamp: da.timestamp,
            init_azimuth: da.azimuth,
          })
        );
    } else {
      setImg([]);
      return;
    }

    setImg(img);
  } catch (error: any) {
    // TODO: (emi) revisar este try-catch sincrónico
    // TODO: (emi) (update) no es necesario, remover en el futuro
    console.error('Error Inesperado relacionado a funciones helpers de SiloMetrix', error);
  }
};

export const setDesvSilometricImg = (event_id: number, data: any, setImg: (params: any) => void) => {
  try {
    const devices = data?.silobags_devices?.find((v: any) => v.id === event_id);
    const img: any = [];
    if (!devices?.silobags_devices_actions?.length) return;

    devices.silobags_devices_actions.forEach((da: any) => {
      if (da.silobags_action_types_id === 11) {
        img.push({
          url: da.image,
          label: devices?.devices?.lance_number?.length > 0 ? devices.devices.lance_number : '',
          init_lat: da.lat,
          init_lon: da.lon,
          init_accuracy: da.accuracy,
          init_timestamp: da.timestamp,
          init_azimuth: da.azimuth,
          init_observations: da.observations,
        });
      }
    });

    setImg(img);
  } catch (error: any) {
    // TODO: (emi) revisar este try-catch sincrónico
    // TODO: (emi) (update) no es necesario, remover en el futuro
    console.error('Error Inesperado relacionado a funciones helpers de SiloMetrix', error);
  }
};

export const getPermissionDefaultValue = (user: any) => {
  const permissionTypes = {
    full_permission: 3,
    write_permission: 2,
    read_permission: 1,
    no_permission: '',
  };

  switch (true) {
    case user.full_permission:
      return permissionTypes.full_permission;
    case user.write_permission:
      return permissionTypes.write_permission;
    case user.read_permission:
      return permissionTypes.read_permission;
    default:
      return permissionTypes.no_permission;
  }
};

export const isValidEmail = (email: any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const findWorstTAS = (data: any): string => {
  const silobagsDevices = data?.silobags_devices;

  if (!silobagsDevices || silobagsDevices.length === 0) {
    return '';
  }

  let worstTAS: string = 'NA';

  const TASOrder = ['<1', '1-6', '>6', 'NA'];

  for (const silobagDevice of silobagsDevices) {
    const measurements = silobagDevice?.devices?.measurements;

    if (measurements && measurements.length > 0) {
      const lastMeasurement = measurements[0].TAS;

      if (typeof lastMeasurement === 'string') {
        const currentIdx = TASOrder.indexOf(lastMeasurement);
        const worstIdx = TASOrder.indexOf(worstTAS);

        if (currentIdx !== -1 && (worstIdx === -1 || currentIdx < worstIdx)) {
          worstTAS = lastMeasurement;
        }
      }
    }
  }

  return worstTAS;
};

export const lancePositions: any = {
  1: 'Inicio',
  2: 'Inicio-Medio',
  3: 'Medio',
  4: 'Medio-Fin',
  5: 'Fin',
};

// TODO: (emi) echarle un vistazo a esto en el futuro, de momento no tenemos abstracciones generales con Intl.
export function formatNumberWithSeparators(value: number) {
  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return formatter.format(value);
}

export const getRandomColor = (index: number) => {
  const colors = ['green', '#880BE4', '#1271C5', '#E59926', '#4AB0C7'];
  return colors[index % colors.length];
};

//FUNCIONES PARA RENDERIZAR DETALLE REPORTE DE ACTIVIDAD
export const EVENTS_IDS = {
  //EXTRACCION
  EXTRACTION: 1,
  //RECORRIDA
  POE: 2,
  //ARCHIVO
  FILE: 3,
  //ALTA == REGISTER
  REGISTER: 4,
  //EMBOLSADO
  BAGGING: 5,
  //CIERRE
  CLOSE: 6,
  //EDICION SILO
  EDITION_SILO: 7,
  EDITION_EXTRACTION: 8,
  //CORRECCION BALANCE AUTOMATICO
  AUTOMATIC_CORRECTION_BALANCE: 9,
  //ADICION SILOMETRIX
  ADD_SILOMETRIX: 11,
  //DESVINCULACION SILOMETRIX
  DESVINCULATE_SILOMETRIX: 12,
  //COMPLETADO DE DATOS
  COMPLETE_DATA: 14,
  //REGISTRO EN CAMPO
  REGISTER_IN_FIELD: 15,
  //REGISTRO WEB
  REGISTER_WEB: 16,
  BLOCKCHAIN: 17,
  CORVUS: 18,
  //CIERRE ORIGEN
  CLOSE_ORIGIN: 19,
  //MUESTRA CALIDAD
  QUALITY_SAMPLE: 20,
  //EDICION MUESTRA
  EDITION_SAMPLE: 21,
};

export const showSiloMetrixImg = (data: any, setImg: any, actionTypeId: number) => {
  try {
    //Segun la base de datos de la tabla silobags_actions_types 
    //Action type 1 = Foto dispositivo
    //Action type 11 = Foto desvinculacion dispositivo
    //eventId = 11 Adicion SiloMetrix
    //eventId = 12 Desvinculacion SiloMetrix
    const devices = data && data.silobags_devices
    const img = [];
    if (devices?.silobags_devices_actions?.length > 0) {
      devices.silobags_devices_actions
        .filter((da: any) => da.silobags_action_types_id === actionTypeId)
        .forEach((da: any) =>
          img.push({
            url: da.image,
            label: devices?.devices?.lance_number?.length > 0 ? devices.devices.lance_number : '',
            init_lat: da.lat,
            init_lon: da.lon,
            init_accuracy: da.accuracy,
            init_timestamp: da.timestamp,
            init_azimuth: da.azimuth,
          })
        );
    } else {
      setImg([]);
      return;
    }
    setImg(img);
  } catch (error: any) {
    console.error('Error Inesperado relacionado a funciones helpers de SiloMetrix', error);
  }
};


//***************** */