import { MapBase } from './base/map-base';
import { MapUI } from './ui/map-ui';
import { Controls } from './map-controls';

export function MainMap() {
  return (
    <MapBase>
      <MapUI />
      <Controls />
    </MapBase>
  );
}
