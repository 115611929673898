import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';
import { ISilobags_DEPRECATED } from '@utils/to-refactor/helper';

// ❔ preguntar a Víctor o Juan
export const getAllSilobagSelectService_DEPRECATED = async (organizationId: number) => {
  const orgIdParam = organizationId !== undefined ? `?organization_id=${organizationId}` : '';
  const { response, data } = await BACKEND.get(ENDPOINT.SILOBAGS, { params: orgIdParam });

  return {
    response,
    summary: data,
  };
};

export const massiveCloseService_DEPRECATED = async (body: ISilobags_DEPRECATED) => {
  const { response, data } = await BACKEND.post(ENDPOINT.SILOBAGS_MASSIVE_CLOSE, { body });

  return {
    response,
    summary: data,
  };
};
