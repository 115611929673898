import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LabProtocolBySilobagPayload, LabProtocolBySilobagResponse } from './lab-protocol-silobag-info.types';

export async function silobagLaboratoryProtocolsHistory({ query: rawQuery }: LabProtocolBySilobagPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<LabProtocolBySilobagResponse>(ENDPOINT.LAB_SILOBAG_PROTOCOL, {
    query,
  });

  return {
    response,
    summary: data,
  };
}

export async function lastSilobagLaboratoryProtocol({ query: rawQuery }: LabProtocolBySilobagPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<LabProtocolBySilobagResponse[0] | null>(
    ENDPOINT.LAB_LAST_SILOBAG_PROTOCOL,
    { query }
  );

  return {
    response,
    summary: data,
  };
}
