import { Grid, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { styledTextField } from '@styles/generic-styles';
import { useNavigate } from 'react-router';
import { PATH } from '@data/enums';
import { useGlobalStore } from '@global-store/use-store';
import { useState } from 'react';
import { ToastAction } from '@ui/toast/toast-action';
import { changeCredentialService } from '@services/domain/auth/change-auth-credential';
import { useService } from '@hooks/use-service';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from '../../../libs/language';
import { useToggle } from '@hooks/core';
import EyeIcon from '@assets/svg/core/eye-hollow-green.svg';
import CrossEyeIcon from '@assets/svg/core/crossed-eye-green.svg';

export function ResetForm() {
  const { email } = useGlobalStore();
  const [success, setSuccess] = useState(false);

  const [formReset, setFormReset] = useState({
    password: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useToggle(false);
  const [showConfirmPassword, setConfirmShowPassword] = useToggle(false);

  const {
    trigger: resetPassword,
    isError,
    isLoading,
  } = useService(changeCredentialService, {
    onSuccess: () => {
      setSuccess(true);
      setTimeout(() => {
        navigate(PATH.LOGIN);
      }, 1500);
    },
  });

  const handleResetPassword = (event: any) => {
    event.preventDefault();
    resetPassword({ body: formReset });
  };

  const handleConfirmPassword = (e: any) => {
    const inputConfirmPassword = e.target.value;
    setConfirmPassword(inputConfirmPassword);
    const password = formReset.password;
    setPasswordMatch(inputConfirmPassword === password);
  };

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword();
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword();
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={3}
      component="form"
    >
      <Grid item xs={12}>
        <h1 style={{ fontWeight: '300', margin: 0 }}>{i18n.t('app.public_pages.reset_password')}</h1>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="email"
          label={i18n.t('app.public_pages.email')}
          variant="outlined"
          size="small"
          type="email"
          //disabled={email !== undefined ? true : false}
          defaultValue={email !== undefined ? email : ''}
          sx={styledTextField}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={i18n.t('app.public_pages.password')}
          variant="outlined"
          size="small"
          sx={styledTextField}
          onChange={(e) => setFormReset({ ...formReset, password: e.target.value })}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          id="confirm-password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={i18n.t('app.public_pages.password_confirm')}
          variant="outlined"
          color={passwordMatch ? 'primary' : 'error'}
          size="small"
          sx={styledTextField}
          onChange={handleConfirmPassword}
          value={confirmPassword}
        />
        {!passwordMatch && formReset.password.length > 0 && (
          <p style={{ color: 'red', fontSize: '12px', margin: '0', textAlign: 'right' }}>
            Las contraseñas no coinciden
          </p>
        )}
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          sx={{ width: '200px', marginRight: '10px' }}
          disableElevation
          onClick={() => {
            navigate(PATH.LOGIN);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          sx={{ width: '200px', color: 'white' }}
          disableElevation
          onClick={handleResetPassword}
          type="submit"
          disabled={!passwordMatch}
        >
          {isLoading ? <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} /> : 'Enviar'}
        </Button>
      </Grid>
      {isError && <ToastAction message="Ha ocurrido un error con tus datos" severity="error" />}
      {success && <ToastAction message="Contraseña reseteada con exito" severity="success" />}
    </Grid>
  );
}
