import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { HarvestListResponse } from './harvest-list.types';

// deprecated service ref.: getHarvestsService_DEPRECATED

// ✅ Implementado
export async function harvestListService() {
  const { response, data } = await BACKEND.get<HarvestListResponse>(ENDPOINT.HARVESTS);

  return {
    response,
    summary: data,
  };
}
