import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput,
  InputLabel,
  Stack,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { styledTextField } from '@styles/generic-styles';
import { useNavigate } from 'react-router';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { PATH } from '@data/enums';
import { useService } from '@hooks/use-service';
import { useToggle, useEffectOnUpdate } from '@hooks/core';
import { useState } from 'react';
import { ToastAction } from '@ui/toast/toast-action';
import { useGlobalStore } from '@global-store/use-store';
import CircularProgress from '@mui/material/CircularProgress';
import i18n from '../../../libs/language';
import EyeIcon from '@assets/svg/core/eye-hollow-green.svg';
import CrossEyeIcon from '@assets/svg/core/crossed-eye-green.svg';
import { listCountriesL0Service } from '@services/domain/location/country-L0';
import { registerCredentialService } from '@services/domain/auth/register-auth-credential';
import { SelectChangeEvent } from '@mui/material/Select';

type RegisterCredentialBody = Parameters<typeof registerCredentialService>[0]['body'];

export function RegisterForm() {
  const [formRegister, setFormRegister] = useState<RegisterCredentialBody>({
    country_id: 0,
    name: '',
    legal_name: '',
    tax_id: '',
    phone: '',
    email: '',
    users: {
      username: '',
      fullname: '',
      password: '',
    },
  });
  const [country, setCountry] = useState<number>();
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useToggle(false);
  const [showConfirmPassword, setConfirmShowPassword] = useToggle(false);
  const [phoneArea, setPhoneArea] = useState('54');
  const [isChecked, setIsChecked] = useState(false);
  const [Error, setError] = useState(false);

  const navigate = useNavigate();
  const { setEmail, setLanguage } = useGlobalStore();

  const { summary: countries } = useService(listCountriesL0Service, { fetchOnMount: true });

  const {
    trigger: sendRegister,
    isError,
    isLoading,
  } = useService(registerCredentialService, {
    onSuccess: () => {
      navigate(PATH.VERIFY_REGISTER);
    },
  });

  const handleSelectCountry = (e: SelectChangeEvent<number>) => {
    const country_id = +e.target.value;
    setCountry(country_id);
    setFormRegister((FormRegister) => ({
      ...FormRegister,
      country_id: country_id,
    }));
  };

  const handleConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputConfirmPassword = e.target.value;
    setConfirmPassword(inputConfirmPassword);
    const password = formRegister.users.password;
    setPasswordMatch(inputConfirmPassword === password);
  };

  const submit = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (isChecked) {
      sendRegister({ body: formRegister });
    } else {
      setError(true);
    }
    if (formRegister.email.length > 0) {
      setEmail(formRegister.email);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword();
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setConfirmShowPassword();
  };

  const phoneAreaDetails = {
    54: {
      flag: '🇦🇷',
      placeholder: '+54 ',
    },
    55: {
      flag: '🇧🇷',
      placeholder: '+55 ',
    },
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffectOnUpdate(() => {
    //ERROR CHECKBOX REQUIRED OR OTHER INPUT
    setTimeout(() => {
      setError(false);
    }, 3000);
  }, [Error]);

  useEffectOnUpdate(() => {
    if (formRegister.country_id == 2 || formRegister.country_id == 4) {
      i18n.locale = 'pt';
      setLanguage(i18n.locale);
      setPhoneArea('55');
    } else {
      i18n.locale = 'es';
      setLanguage(i18n.locale);
      setPhoneArea('54');
    }
  }, [formRegister.country_id]);

  return (
    <Stack direction={'column'} width={{ xs: '100%', md: '50%' }} mt={1} spacing={{ xs: 2, md: 3 }}>
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Typography variant="h4" fontWeight={300} m={0}>
          {i18n.t('app.public_pages.register_company')}
        </Typography>
      </Box>
      <Box mt={2} display={'flex'} justifyContent={'center'} width={'100%'}>
        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
          <InputLabel id="country_id">Pais</InputLabel>
          <Select
            style={{ width: '100%' }}
            required
            id="country_id"
            sx={styledTextField}
            input={<OutlinedInput label="Pais" id="country_id" />}
            value={country}
            onChange={handleSelectCountry}
          >
            {countries?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        <TextField
          required
          id="legal_name"
          size="small"
          label={i18n.t('app.public_pages.legal_name')}
          variant="outlined"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              name: e.target.value,
              legal_name: e.target.value,
            }));
          }}
        />
        <TextField
          required
          id="tax_id"
          label={i18n.t('app.public_pages.cuit')}
          placeholder="Ej: 20934445025"
          variant="outlined"
          size="small"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              tax_id: e.target.value,
            }));
          }}
        />
      </Stack>
      <Box width={'100%'}>
        <TextField
          required
          id="fullname"
          label={i18n.t('app.public_pages.fullname')}
          size="small"
          variant="outlined"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              users: {
                ...FormRegister.users,
                fullname: e.target.value,
              },
            }));
          }}
        />
      </Box>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        <FormControl size="small" style={{ width: '144px' }}>
          <InputLabel>Área</InputLabel>
          <Select
            label="Área"
            renderValue={() => <div>{phoneAreaDetails[phoneArea as '54']?.flag}</div>}
            value={phoneArea}
            sx={{ backgroundColor: 'white' }}
            onChange={(e) => {
              setPhoneArea(e.target.value as string);
            }}
          >
            <MenuItem value={54}>
              🇦🇷 Argentina <span style={{ color: '#a7a7a7' }}>&nbsp;(+54)</span>
            </MenuItem>
            <MenuItem value={55}>
              🇧🇷 Brasil <span style={{ color: '#a7a7a7' }}>&nbsp;(+55)</span>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          required
          id="phone"
          size="small"
          type="number"
          label={i18n.t('app.public_pages.phone')}
          variant="outlined"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              phone: phoneArea + e.target.value,
            }));
          }}
          //TODO: hacer validaciones globales para aplicar en los inputs de la app
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          InputProps={{
            startAdornment: phoneAreaDetails[phoneArea as '54'] ? (
              <Box style={{ width: 44 }}>{phoneAreaDetails[phoneArea as '54']?.placeholder}</Box>
            ) : null,
          }}
        />
        <TextField
          required
          id="email"
          label={i18n.t('app.public_pages.email')}
          variant="outlined"
          size="small"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              email: e.target.value,
              users: {
                ...FormRegister.users,
                username: e.target.value,
              },
            }));
          }}
        />
      </Stack>
      <p style={{ fontSize: '12px', margin: '0', textAlign: 'left', color: '#A7A6A6' }}>
        Ejemplo: {phoneArea == '54' ? '+54 91157059192' : '+55 11912345678"'}
      </p>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 1 }}>
        <TextField
          required
          id="password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          label={i18n.t('app.public_pages.password')}
          variant="outlined"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={(e) => {
            setFormRegister((FormRegister) => ({
              ...FormRegister,
              users: {
                ...FormRegister.users,
                password: e.target.value,
              },
            }));
          }}
        />
        <TextField
          required
          id="confirm_password"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleConfirmPasswordVisibility}
                  edge="end"
                >
                  {showConfirmPassword ? <img src={EyeIcon} /> : <img src={CrossEyeIcon} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={i18n.t('app.public_pages.password_confirm')}
          variant="outlined"
          color={passwordMatch ? 'primary' : 'error'}
          size="small"
          sx={{ ...styledTextField, width: '100%' }}
          onChange={handleConfirmPassword}
          value={confirmPassword}
        />
      </Stack>
      {!passwordMatch && !undefined && (
        <p style={{ color: 'red', fontSize: '12px', margin: '0', textAlign: 'right' }}>
          Las contraseñas no coinciden
        </p>
      )}
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <FormControlLabel
          control={<Checkbox size="small" checked={isChecked} onChange={handleCheckboxChange} />}
          label={i18n.t('app.public_pages.accept_terms')}
        />
      </Box>
      <Stack direction={'row'}>
        <Button
          variant="outlined"
          sx={{ width: '200px', marginRight: '10px' }}
          disableElevation
          onClick={() => navigate(PATH.LOGIN)}
        >
          {i18n.t('app.return')}
        </Button>
        <Button
          variant="contained"
          sx={{ width: '200px', color: 'white' }}
          disableElevation
          onClick={submit}
          type="submit"
          disabled={!passwordMatch}
        >
          {isLoading ? (
            <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} />
          ) : (
            i18n.t('app.public_pages.create_account')
          )}
        </Button>
      </Stack>
      {isError && <ToastAction message={i18n.t('app.public_pages.error_register')} severity="error" />}
      {Error && <ToastAction message={i18n.t('app.public_pages.terms_conditions_error')} severity="error" />}
    </Stack>
  );
}
