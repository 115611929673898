import {
  Radio,
  FormControlLabel,
  Stack,
  Modal,
  Fade,
  Box,
  Typography,
  Button,
  Checkbox,
  Paper,
} from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import location from '../../../../assets/svg/location_searching.svg';
import i18n from '../../../../libs/language';
import DownloadIcon from '@assets/svg/core/download-alt-blue.svg';
import dayjs from 'dayjs';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type PoeSummary = Awaited<ReturnType<typeof silobagDataDetailService>>['summary']['poe'];

type PoeModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: PoeSummary;
  poeId?: number | null;
  eventWeb?: boolean;
  eventMobile?: boolean;
};

interface ImageType {
  mainImageUrl: string | undefined;
  mainLat: string | undefined;
  mainLon: string | undefined;
  mainAccuracy: number | string | undefined;
  mainTimestamp: string | undefined;
  mainAzimuth?: number | string | undefined;
  mainLabel: string | undefined;
}

export function PoeModal({ open, setOpen, data, poeId }: PoeModalProps) {
  const [listIDX, setListIDX] = useState(0);
  const poeSelected = data?.find((poe) => poe.id === poeId);
  const firstImage = {
    mainImageUrl: poeSelected?.image_url,
    mainLat: poeSelected?.lat,
    mainLon: poeSelected?.lon,
    mainAccuracy: poeSelected?.accuracy,
    mainTimestamp: poeSelected?.timestamp,
    mainLabel: poeSelected?.image_url ? i18n.t('app.silobag_history.photo_poe') : undefined,
  };

  // Manejo de valores nulos o indefenidos para garantizar que poeDetailsArray(Detalles) sea tratado correctamente como un array incluso si es undefined.
  const poeDetailsArray: ImageType[] =
    poeSelected?.poe_details
      .map((item, index) => ({
        mainImageUrl: item.image_url,
        mainLat: item.lat,
        mainLon: item.lon,
        mainAccuracy: item.accuracy,
        mainTimestamp: item.timestamp,
        mainLabel: i18n.t('app.silobag_history.photo_question', { index: index + 1 }),
      }))
      .filter((obj) => obj.mainImageUrl !== null) || [];

  // Manejo de valores nulos o indefenidos para garantizar que breakageDetailsData(Rotura) sea tratado correctamente como un array incluso si es undefined.
  const breakageDetailsData: ImageType[] =
    poeSelected?.poe_details
      .map((item) =>
        item.breakage_details.map((rot) => ({
          mainImageUrl: rot.main_image_url,
          mainLat: item.lat ?? rot.main_lat,
          mainLon: item.lon ?? rot.main_lon,
          mainAccuracy: item.accuracy ?? rot.main_accuracy,
          mainTimestamp: rot.main_timestamp,
          mainLabel: i18n.t('app.silobag_history.photo_breakage'),
        }))
      )
      .flat()
      .filter((data) => data.mainImageUrl !== null) || [];

  const breakageResolvedData: ImageType[] =
    poeSelected?.poe_details
      .map((item) =>
        item.breakage_details.map((rot) => ({
          mainImageUrl: rot.breackage_resolved_image_url,
          mainLat: rot.breackage_resolved_lat,
          mainLon: rot.breackage_resolved_lon,
          mainAccuracy: rot.breackage_resolved_accuracy ?? undefined,
          mainTimestamp: rot.breackage_resolved_timestamp,
          mainLabel: i18n.t('app.silobag_history.photo_breakage_resolved'),
        }))
      )
      .flat()
      .filter((data) => data.mainImageUrl !== null) || [];

  // Manejo de valores nulos o indefenidos para garantizar que qualityRevisionDetailsImages(Calado) sea tratado correctamente como un array incluso si es undefined.
  const qualityRevisionDetailsImages: ImageType[] =
    poeSelected?.poe_details
      .map((item) =>
        item.quality_revision_details.map((qual) => ({
          mainImageUrl: qual.image_url,
          mainLat: qual.lat,
          mainLon: qual.lon,
          mainAccuracy: qual.accuracy,
          mainTimestamp: qual.timestamp,
          mainLabel: i18n.t('app.silobag_history.photo_revition_quality'),
        }))
      )
      .flat()
      .filter((data) => data.mainImageUrl !== null) || [];

  // Manejo de valores nulos o indefenidos para garantizar que poeExtraPhotos sea tratado correctamente como un array incluso si es undefined.
  const poeExtraPhotos: ImageType[] =
    poeSelected?.poe_extra_photos?.map((item) => ({
      mainImageUrl: item.image_url,
      mainLat: item.lat,
      mainLon: item.lon,
      mainAccuracy: item.accuracy,
      mainTimestamp: item.timestamp,
      mainLabel: i18n.t('app.silobag_history.photo_extra'),
    })) || [];
  const flattenedPoeExtraPhotos = poeExtraPhotos.flat();

  // Se crea un array final con todas las imagenes y detalles de la POE
  const finalArray: ImageType[] = [
    firstImage,
    ...poeDetailsArray,
    ...breakageDetailsData,
    ...breakageResolvedData,
    ...qualityRevisionDetailsImages,
    ...flattenedPoeExtraPhotos,
  ];

  const SCROLL = {
    toLeft: () => setListIDX((prev) => (prev - 1 + finalArray.length) % finalArray.length),
    toRight: () => setListIDX((prev) => (prev + 1) % finalArray.length),
  };

  const handleClose = () => {
    setListIDX(0);
    setOpen(false);
  };

  const POE_STATUS: { [key: number]: { text: string; color: string; description: string } } = {
    1: {
      text: i18n.t('app.silobag_history.Optimum'),
      color: '#6baa00',
      description: i18n.t('app.silobag_history.descriptions.Optimum'),
    },
    2: {
      text: i18n.t('app.silobag_history.precaution'),
      color: '#FFDD00',
      description: i18n.t('app.silobag_history.descriptions.precaution'),
    },
    3: {
      text: i18n.t('app.silobag_history.high_risk'),
      color: '#FD7E14',
      description: i18n.t('app.silobag_history.descriptions.high_risk'),
    },
    4: {
      text: i18n.t('app.silobag_history.critical'),
      color: '#FF0000',
      description: i18n.t('app.silobag_history.descriptions.critical'),
    },
  };

  const poeStatusColor = POE_STATUS[poeSelected?.status_id ?? 1].color ?? '#6baa00';
  const poeStatusText = POE_STATUS[poeSelected?.status_id ?? 1].text ?? '-';
  const poeStatusDescription = POE_STATUS[poeSelected?.status_id ?? 1].description ?? '-';
  const isValid = poeSelected?.valid;
  const distance = poeSelected?.distance_to_sb_polygon;
  const accuracy = poeSelected?.accuracy;
  const totalDistance = (
    ((parseFloat(distance || '0') || 0) + (parseFloat(accuracy || '0') || 0)) /
    1000
  ).toFixed(2);

  const getWarningMessage = () => {
    if (totalDistance && isValid === null) {
      return i18n.t('app.silobag_history.poe_invalid');
    }
    if (isValid === false) {
      return i18n.t('app.silobag_history.poe_warning_description', { totalDistance: totalDistance || 0 });
    }
    return null;
  };

  const warningMessage = getWarningMessage();
  const silobagId = data && data.length > 0 ? data[0].silobag_id : null;
  const pascalCaseLabel = finalArray[listIDX]?.mainLabel?.replace(/ /g, '_');
  const formattedDate = finalArray[listIDX]?.mainTimestamp
    ? dayjs(Number(finalArray[listIDX]?.mainTimestamp)).format('YYYY_MM_DD-HH:mm(G[M]TZ)')
    : '';

  return (
    <MyModal onClose={handleClose} open={open}>
      <Box component={'div'}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{i18n.t('app.silobag_history.detail_poe')}</Typography>
          <DownloadLink
            url={finalArray[listIDX]?.mainImageUrl ?? ''}
            fileName={`${silobagId}-${pascalCaseLabel}-${formattedDate}`}
          />
        </Stack>
      </Box>
      <Stack
        direction={{ md: 'row', sm: 'column' }}
        spacing={2}
        height={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Stack spacing={2}>
          <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
            <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_history.general_evaluation')}</Typography>
            <Box display={'flex'} alignItems={'center'} component={'div'}>
              <Box
                component={'div'}
                width={'1rem'}
                height={'1rem'}
                style={{
                  backgroundColor: poeStatusColor,
                  border: '1px solid',
                  borderColor: poeStatusColor,
                  borderRadius: '50%',
                }}
              ></Box>
              <Typography variant="body1" color={'#6baa00'} marginLeft={'0.5rem'}>
                {poeStatusText}
              </Typography>
            </Box>
          </Stack>
          <Box>
            <Typography>{poeStatusDescription}</Typography>
            {warningMessage && (
              <Typography
                variant="body2"
                style={{ color: '#E30000', fontSize: '0.8rem' }}
                marginLeft={'0.5rem'}
              >
                {warningMessage.includes('app.silobag_history.poe_warning_description') && (
                  <img src={location} alt="release-icon" />
                )}
                {warningMessage}
              </Typography>
            )}
          </Box>
          {finalArray && finalArray.length > 0 ? (
            <Stack direction={'row'} spacing={2}>
              <Box>
                <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_data.lat')}</Typography>
                  <Typography>{finalArray[listIDX]?.mainLat ?? '-'}</Typography>
                </Stack>
              </Box>
              <Box>
                <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_data.long')}</Typography>
                  <Typography>{finalArray[listIDX]?.mainLon ?? '-'}</Typography>
                </Stack>
              </Box>
              <Box>
                <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_history.gps_accuracy')}</Typography>
                  <Typography>
                    {finalArray[listIDX]?.mainAccuracy
                      ? `${Number(finalArray[listIDX]?.mainAccuracy).toFixed(2)} mts`
                      : '-'}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={2}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.date')}</Typography>
                  <Typography>
                    {finalArray[listIDX]?.mainTimestamp
                      ? dayjs(Number(finalArray[listIDX]?.mainTimestamp)).isValid()
                        ? dayjs(Number(finalArray[listIDX]?.mainTimestamp)).format('D/M/YYYY - HH:mm')
                        : 'Fecha no disponible'
                      : '-'}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          ) : null}
          <Box component={'div'}>
            <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_history.description_election')}</Typography>
            <Typography>{poeSelected?.observations || '-'}</Typography>
          </Box>
          {poeSelected && poeSelected?.poe_details?.length > 0
            ? poeSelected?.poe_details.map((item) => (
                <Box key={item.id} component={'div'}>
                  <Stack direction={'row'} display={'flex'} alignItems={'center'} spacing={1}>
                    <Typography>{item.question_description}</Typography>
                    <FormControlLabel
                      control={<Radio checked={true} color="default" />}
                      label={item.result ? 'Si' : 'No'}
                      checked
                    />
                  </Stack>
                  {item.breakage_details.length > 0
                    ? item.breakage_details.map((rot, index) => (
                        <Stack
                          direction={'row'}
                          key={rot.id}
                          width={'100%'}
                          spacing={2}
                          marginBottom={'10px'}
                          alignItems={'center'}
                        >
                          <Stack
                            display={'flex'}
                            alignItems={'center'}
                            direction={'row'}
                            spacing={2}
                            width={'100%'}
                          >
                            <Typography fontWeight={'bold'}>
                              {i18n.t('app.silobag_history.breakage')} {index + 1}
                            </Typography>
                            <Stack direction={'row'} spacing={1}>
                              <Typography color={'#7A7A7A'}>
                                {i18n.t('app.silobag_history.reason_breakage')}
                              </Typography>
                              <Typography>{rot.breakage_motive}</Typography>
                            </Stack>
                          </Stack>
                          <Box width={'100%'} display={'flex'} justifyContent={'center'} component={'div'}>
                            <Stack direction={'row'}>
                              <FormControlLabel
                                control={<Checkbox checked={rot.was_breackage_resolved} disabled />}
                                label="Rotura resuelta"
                                labelPlacement="start"
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      ))
                    : null}
                  {item.quality_revision_details.length > 0
                    ? item.quality_revision_details.map((cal, index) => (
                        <Stack
                          key={cal.id}
                          direction={'row'}
                          spacing={2}
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'space-between'}
                        >
                          <Typography fontWeight={'bold'}>
                            {i18n.t('app.silobag_history.quality_revision.calado')} {index + 1}
                          </Typography>
                          <Stack direction={'row'} spacing={1}>
                            <Typography color={'#7A7A7A'}>
                              {i18n.t('app.silobag_history.quality_revision.sample_type')}
                            </Typography>
                            <Typography>{cal?.sample_types?.description}</Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1}>
                            <Typography color={'#7A7A7A'}>
                              {i18n.t('app.silobag_history.quality_revision.silo_position')}
                            </Typography>
                            <Typography>{cal?.silobags_positions?.description}</Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1}>
                            <Typography color={'#7A7A7A'}>
                              {i18n.t('app.silobag_history.quality_revision.value')}
                            </Typography>
                            <Typography>{cal?.value} %</Typography>
                          </Stack>
                        </Stack>
                      ))
                    : null}
                </Box>
              ))
            : null}
        </Stack>

        <Box width={'30%'} component={'div'}>
          {finalArray && finalArray?.length > 0 ? (
            <>
              <Box display={'flex'} justifyContent={'space-between'} component={'div'}>
                <Typography variant="body2" fontWeight={'bold'}>
                  Foto {listIDX + 1}/{finalArray.length}
                </Typography>
                {listIDX == 0 ? i18n.t('app.silobag_history.photo_detail_siloid') : ''}
              </Box>
              <Box style={{ width: '100%', height: '90%' }} component={'div'}>
                <Box className="slider-wrapper" component={'div'}>
                  <Box
                    component={'div'}
                    className="slider keep-scrolling-without-scroll"
                    style={{ position: 'absolute', width: '100%' }}
                  >
                    {finalArray.map((image, i) => (
                      <img
                        ref={(node) => {
                          if (node && listIDX === i) {
                            node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                          }
                        }}
                        style={{ width: '100%' }}
                        key={i}
                        src={typeof image === 'string' ? image : image?.mainImageUrl}
                        alt={'ass'}
                      />
                    ))}
                  </Box>
                  <Paper
                    sx={{
                      borderRadius: '100%',
                      left: '0.5rem',
                      width: '30px',
                      height: '30px',
                      position: 'absolute',
                      top: '50%',
                      maxHeight: '30px',
                      fontSize: '2rem',
                      cursor: 'pointer',
                      userSelect: 'none',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&: hover': {
                        opacity: '80%',
                      },
                    }}
                    onClick={SCROLL.toLeft}
                  >
                    <Box style={{ opacity: '70%' }} component={'div'}>
                      {'<'}
                    </Box>
                  </Paper>
                  <Paper
                    sx={{
                      borderRadius: '100%',
                      right: '0.5rem',
                      width: '30px',
                      height: '30px',
                      position: 'absolute',
                      top: '50%',
                      maxHeight: '30px',
                      fontSize: '2rem',
                      cursor: 'pointer',
                      userSelect: 'none',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&: hover': {
                        opacity: '80%',
                      },
                    }}
                    onClick={SCROLL.toRight}
                  >
                    <Box style={{ opacity: '70%' }} component={'div'}>
                      {'>'}
                    </Box>
                  </Paper>

                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    className="slider-nav"
                  >
                    {finalArray.map((_, i) => (
                      <Box
                        component={'div'}
                        key={i}
                        onClick={() => setListIDX(i)}
                        style={{
                          width: '13px',
                          backgroundColor: listIDX === i ? '#7A7A7A' : '#f3f2f7',
                          height: '13px',
                          borderRadius: '50%',
                          border: '1px solid #a7a7a7',
                        }}
                      ></Box>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </>
          ) : (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} component={'div'}>
              <Typography>{i18n.t('app.silobag_data.existence_images')}</Typography>
            </Box>
          )}
        </Box>
      </Stack>
      <Box display={'flex'} justifyContent={'end'} component={'div'}>
        <Button onClick={handleClose}>{i18n.t('app.close')}</Button>
      </Box>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '80%',
    overflowX: 'hidden',
    maxHeight: '95vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style} component={'div'}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

type DownloadLinkProps = {
  url: string;
  fileName?: string;
};
export function DownloadLink({ url, fileName = 'siloreal-image' }: DownloadLinkProps) {
  if (!url) {
    console.error('[SiloReal][DOWNLOAD-FILE] No URL provided');
    return null;
  }
  const ext = url.match(/\.([^./?#]+)($|\?|#)/)?.at(1);

  const handleDownload = () => {
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${ext}`;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
        console.warn('Refetching...');
        fetch(url, {
          method: 'GET',
          cache: 'no-cache',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.${ext}`;
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            console.info('Refetched successfully.');
          })
          .catch((error) => {
            console.error('Error (again) fetching the file:', error);
          });
      });
  };

  return (
    <Box>
      <Button
        sx={{
          color: '#22A7F0',
          textTransform: 'none',
          px: 2,
          '&:hover': { background: '#22a7f02a' },
        }}
        startIcon={<img src={DownloadIcon} />}
        onClick={handleDownload}
      >
        {i18n.t('app.download')}
      </Button>
    </Box>
  );
}
