import { useState } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

//En el componente que se use hacer un useState boleean para mostrar el toast asi como tambien renderizarlo el componente pasandole las props

type ToastProps = {
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  onClose?: () => void;
  onActionClick?: () => void;
  setOpenToast?: (data: boolean) => void;
  openToast?: boolean;
};

export const ToastAction = (props: ToastProps) => {
  const { message, severity, onClose, openToast, setOpenToast } = props;
  const [open, setOpen] = useState(openToast ?? true);

  const handleClose = () => {
    if (openToast) {
      setOpenToast && setOpenToast(false);
    } else {
      setOpen(false);
      if (onClose) onClose();
    }
  };

  return (
    <Snackbar
      open={openToast ?? open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={severity} onClose={handleClose} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
