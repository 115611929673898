import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CountryL2Data, CountryL2Payload } from './country-L2.types';

// deprecated service ref.: countriesL2ByL1Service_DEPRECATED

// implementado ✅
export async function listCountriesL2ForL1Service({ query: { L1ID } }: CountryL2Payload) {
  const query = transformIntoQueryParamString(L1ID);

  const { response, data } = await BACKEND.get<CountryL2Data>(ENDPOINT.COUNTRIES_L2_BY_L1, { query });

  return {
    response,
    summary: data,
  };
}
