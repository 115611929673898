import { useService } from '@hooks/use-service';
import { Box, Button, Divider, Fade, Modal, Stack, Typography } from '@mui/material';
import { updateExtractionService_DEPRECATED } from '@services/silobag_extraction/silobag_extraction';
import { PropsWithChildren } from 'react';
import i18n from '../../../../libs/language';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type ObservationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  extractionId: number | undefined;
  setSnackbarMessage: (message: string) => void;
  setTypeSnackbar: (type: 'success' | 'error') => void;
  setOpenSnackbar: (setOpen: boolean) => void;
  siloTrigger: (query: SilobagDetailParams) => void;
  siloID: string;
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

export function DeleteExtraction({
  open,
  setOpen,
  extractionId,
  setSnackbarMessage,
  setTypeSnackbar,
  setOpenSnackbar,
  siloTrigger,
  siloID,
}: ObservationModalProps) {
  const closeOnSuccess = () => {
    handleOnClose();
    setTypeSnackbar('success');
    setSnackbarMessage(i18n.t('app.silobag_detail.delete_extraction'));
    setOpenSnackbar(true);
    setOpen(false);
    siloTrigger({ query: siloID });
  };

  const { trigger: deleteExtraction } = useService(updateExtractionService_DEPRECATED, {
    onSuccess: closeOnSuccess,
  });

  const handleOnClose = () => {
    setOpen(false);
  };

  const handleDeleteExtraction = () => {
    const body = {
      weight: 0,
    };
    deleteExtraction(body, extractionId ?? 0);
  };

  return (
    <Box>
      <MyModal open={open} onClose={handleOnClose}>
        <Box>
          <Typography variant="h5" fontWeight={'bold'} mb={2}>
            {i18n.t('app.delete_extraction.delete')}
          </Typography>
          <Divider />
        </Box>
        <Box>
          <Typography>{i18n.t('app.delete_extraction.confirmation_msg')}</Typography>
        </Box>
        <Box display={'flex'} justifyContent={'end'}>
          <Stack spacing={2} direction={'row'}>
            <Button variant="text" onClick={handleOnClose} size="small">
              {i18n.t('app.cancel')}
            </Button>
            <Button
              variant="contained"
              style={{ color: '#ffff' }}
              size="small"
              onClick={handleDeleteExtraction}
            >
              {i18n.t('app.delete_extraction.delete')}
            </Button>
          </Stack>
        </Box>
      </MyModal>
    </Box>
  );
}

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
