import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { UpdatedSilobagData, SilobagEditionPayload } from './silobag-edition.types';

// deprecated service ref.: editSilobagService_DEPRECATED
export async function updateSilobagService({ query: rawQuery, body }: SilobagEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<UpdatedSilobagData>(ENDPOINT.SILOBAGS, { query, body });

  return {
    response,
    summary: data,
  };
}
