import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { VerifyRegisterForm } from '@features/public/verify-register/verify-register-form';

export function VerifyRegisterPage() {
  return (
    <AuthLayoutComponent>
      <VerifyRegisterForm />
    </AuthLayoutComponent>
  );
}
