import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { customBgWhite } from '@styles/generic-styles';

import CheckIcon from '@assets/svg/core/check.svg';
import BlueEyeIcon from '@assets/svg/core/eye-hollow-blue.svg';
import DisabledEyeIcon from '@assets/svg/core/eye-hollow-disabled.svg';
import ChevronDownIcon from '@assets/svg/core/chevron-down-black.svg';
import SiloMetrixExtendedLogo from '@assets/svg/siloreal/product-silo-metrix-extended.svg';
import CloseIcon from '@assets/svg/core/cross.svg';

import PendingIcon from '@assets/svg/domain/alert-states/exclamation.svg';
import RevisionIcon from '@assets/svg/domain/alert-states/reverse.svg';
import ResolveIcon from '@assets/svg/domain/alert-states/check.svg';
import DeleteIcon from '@assets/svg/domain/alert-states/crossed-bell.svg';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { SidePanel } from '@ui/side-panel/side-panel';
import { MouseEventHandler, PropsWithChildren, useState } from 'react';
import { useArray } from '@hooks/core';
import { useService } from '@hooks/use-service';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../../libs/language';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type SummarySilobagDevices = Awaited<
  ReturnType<typeof silobagDataDetailService>
>['summary']['silobags_devices'];

type ActiveAlert = SummarySilobagDevices[0]['devices']['activeAlerts'][0] & {
  deviceName: SummarySilobagDevices[0]['devices']['lance_number'];
};

type SummaryEventsHistory = Awaited<ReturnType<typeof silobagDataDetailService>>['summary']['historicAlerts'];

type DeviceInfo = Record<number, { name: string }>;

type Props = {
  silobagName?: string;
  isLoading: boolean;
  data?: SummarySilobagDevices;
  eventHistory?: SummaryEventsHistory;
  isClosed?: boolean;
  writePermission?: boolean;
  trigger: (query: SilobagDetailParams) => void;
};

export function DeviceAlertsSection({
  data,
  eventHistory,
  silobagName,
  isLoading,
  isClosed,
  writePermission,
  trigger,
}: Props) {
  const [alertPanelType, setAlertPanelType] = useState<'' | 'individual' | 'global'>('');
  const { array: targetAlerts, set: setTargetAlerts } = useArray<ActiveAlert>([]);

  const [historyPanelType, setHistoryPanelType] = useState<'' | 'individual' | 'global'>('');
  const [targetEventHistory, setTargetEventHistory] = useState<SummaryEventsHistory>([]);

  const handleAlertPanelClose = () => (setAlertPanelType(''), setTargetAlerts([]));
  const handleHistoryPanelClose = () => (setHistoryPanelType(''), setTargetEventHistory([]));

  // ?.filter((item) => item.active) --> TODO: PREGUNTAR A EMI PORQUE PUSO ESTE FILTRO
  const devices = data?.map((item) => ({
    ...item.devices,
    activeAlerts: item.devices.activeAlerts
      .filter((alert) => alert.metric_id !== 6)
      .map((alert) => ({ ...alert, deviceName: item.devices.lance_number })),
    position: item.position,
  }));

  const activeDevices = data?.map((item) => item.active);
  const devicesInfoByID: DeviceInfo = {};

  devices?.forEach((d) => (devicesInfoByID[d.id] = { name: d.lance_number }));

  return (
    <Paper elevation={0} style={{ padding: 16, borderRadius: 4 }} sx={customBgWhite}>
      <Grid container>
        <Grid
          item
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: 20,
          }}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent={{ md: 'space-between' }}
            width={'100%'}
          >
            <Stack direction="row" spacing={2}>
              <Box component="h1" margin={0}>
                {i18n.t('app.silobag_data.devices')}
              </Box>
              <img src={SiloMetrixExtendedLogo} />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button
                size="small"
                variant="outlined"
                disabled={isLoading}
                sx={{
                  color: 'black',
                  borderColor: '#C5BFBF',
                  borderRadius: 100,
                  padding: '5px 20px',
                  textTransform: 'none',
                  '&:hover': { background: '#EEE', borderColor: '#C5BFBF' },
                }}
                onClick={() => (setHistoryPanelType('global'), setTargetEventHistory(eventHistory ?? []))}
              >
                {i18n.t('app.silobag_devices.history_alerts')} (
                {isLoading ? <CircularProgress size={10} /> : eventHistory?.length ?? 0})
              </Button>
              {!isClosed ? (
                <Button
                  size="small"
                  variant="outlined"
                  disabled={isLoading || !writePermission}
                  sx={{
                    color: '#EAEAEA',
                    border: 'none',
                    borderRadius: 100,
                    padding: '5px 20px',
                    textTransform: 'none',
                    background: '#49454F',
                    '&:hover': { background: '#5e5d5e', border: 'none' },
                    '&:disabled': { color: '#BBB', background: '#5e5d5e21', border: 'none' },
                  }}
                  onClick={() => {
                    setTargetAlerts(devices?.flatMap((d) => d.activeAlerts) ?? []);
                    setAlertPanelType('global');
                  }}
                >
                  {i18n.t('app.silobag_devices.alert_actives')} (
                  {isLoading ? (
                    <CircularProgress size={10} />
                  ) : (
                    devices?.reduce((rec, dev) => rec + dev.activeAlerts.length, 0)
                  )}
                  )
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Grid>
        {isLoading ? (
          <Stack justifyContent="center" alignItems="center" style={{ width: '100%', height: 180 }}>
            <CircularProgress size={50} />
          </Stack>
        ) : !isClosed ? (
          <TableContainer>
            <Table style={{ border: '1px solid #E7E7E7' }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}> {i18n.t('app.silobag_devices.position')}</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>
                    {' '}
                    {i18n.t('app.silobag_devices.last_update')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }} align="center">
                    Instalada
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Dispositivo</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>MOV</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>CO2</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>HEG</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>TAS</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Batería</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>
                    {' '}
                    {i18n.t('app.silobag_devices.alert_actives')}
                  </TableCell>
                  <TableCell style={{ fontWeight: 600 }}> {i18n.t('app.silobag_devices.history')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices?.map((device, i) => {
                  const [lastMeasurement] = device.measurements;

                  const MOV_CODE = 4;
                  const CO2_CODE = 3;
                  const HEG_CODE = 5;

                  type ReduceAlerts = {
                    movAlerts: ActiveAlert[];
                    co2Alerts: ActiveAlert[];
                    hegAlerts: ActiveAlert[];
                  };
                  const alertsGroupedByType = device.activeAlerts.reduce<ReduceAlerts>(
                    (rec, alert) => {
                      if (alert.metric_id === MOV_CODE) rec.movAlerts.push(alert);
                      if (alert.metric_id === CO2_CODE) rec.co2Alerts.push(alert);
                      if (alert.metric_id === HEG_CODE) rec.hegAlerts.push(alert);

                      return rec;
                    },
                    { movAlerts: [], co2Alerts: [], hegAlerts: [] }
                  );

                  const { movAlerts, co2Alerts, hegAlerts } = alertsGroupedByType;

                  const hasMovAlerts = movAlerts.length > 0;
                  const hasCo2Alerts = co2Alerts.length > 0;
                  const hasHegAlerts = hegAlerts.length > 0;

                  const METRIC = {
                    MOV: device.movement_color_id,
                    CO2: lastMeasurement?.co2_percent,
                    HEG: lastMeasurement?.heg,
                    HUM: lastMeasurement?.humidity_percent,
                    TEMP: lastMeasurement?.temperature_celsius,
                    TAS: lastMeasurement?.TAS,
                    BAT: lastMeasurement?.battery_percent,
                  };

                  const POSITION = {
                    1: 'Inicio',
                    2: 'Inicio-Medio',
                    3: 'Medio',
                    4: 'Medio-Fin',
                    5: 'Fin',
                  } as const;

                  const COLOR = {
                    RED: '#FF0000',
                    YELLOW: '#FFDD00',
                    WHITE: '#FFFFFF',
                    BLACK: '#000000',
                    FALLBACK: '#777',
                  } as const;

                  const STYLES = {
                    MOV: {
                      width: '64px',
                      height: '32px',
                      background: activeDevices?.[i] ? device.movement_color_idToHex?.hex : COLOR.FALLBACK,
                      color: COLOR.WHITE,
                      fontWeight: 600,
                      borderRadius: 5,
                    },
                    CO2: {
                      width: '64px',
                      height: '32px',
                      background: activeDevices?.[i] ? device.co2_color_idToHex?.hex : COLOR.FALLBACK,
                      color: device.co2_color_idToHex?.hex === COLOR.YELLOW ? COLOR.BLACK : COLOR.WHITE,
                      fontWeight: 600,
                      borderRadius: 5,
                    },
                    HEG: {
                      width: '64px',
                      height: '32px',
                      background: activeDevices?.[i] ? device.heg_color_idToHex?.hex : COLOR.FALLBACK,
                      color: device.heg_color_idToHex?.hex === COLOR.YELLOW ? COLOR.BLACK : COLOR.WHITE,
                      fontWeight: 600,
                      borderRadius: 5,
                    },
                    TAS: {
                      width: '84px',
                      height: '32px',
                      background: 'transparent',
                      color: COLOR.BLACK,
                      // border: `1px solid ${COLOR.BLACK}`,
                      fontWeight: 600,
                      borderRadius: 5,
                    },
                    BAT: {
                      width: '76px',
                      height: '32px',
                      background: activeDevices?.[i] ? device.bat_color_idToHex?.hex : COLOR.FALLBACK,
                      color: device.bat_color_idToHex?.hex === COLOR.YELLOW ? COLOR.BLACK : COLOR.WHITE,
                      fontWeight: 600,
                      borderRadius: 5,
                    },
                  };

                  let tasLabel = '';
                  let tasStyle = {};

                  if (METRIC.TAS === '<1') {
                    tasLabel = '<1 mes';
                    tasStyle = {
                      ...STYLES.TAS,
                      background: activeDevices?.[i] ? '#FF7F27' : COLOR.FALLBACK,
                      color: 'white',
                    };
                  } else if (METRIC.TAS === '1-6') {
                    tasLabel = '1-6 meses';
                    tasStyle = {
                      ...STYLES.TAS,
                      background: activeDevices?.[i] ? COLOR.YELLOW : COLOR.FALLBACK,
                      color: 'black',
                    };
                  } else if (METRIC.TAS === '>6') {
                    tasLabel = '>6 meses';
                    tasStyle = {
                      ...STYLES.TAS,
                      background: activeDevices?.[i] ? '#6BAA00' : COLOR.FALLBACK,
                      color: 'white',
                    };
                  } else if (METRIC.TAS === 'NA') {
                    tasLabel = 'N/A';
                    tasStyle = {
                      ...STYLES.TAS,
                      background: 'transparent',
                      color: 'black',
                    };
                  } else {
                    tasLabel = '—';
                    tasStyle = {
                      ...STYLES.TAS,
                      background: 'transparent',
                      color: 'black',
                    };
                  }

                  const DISPLAY = {
                    MOV: METRIC.MOV ? (
                      METRIC.MOV === 2 ? (
                        'MOV'
                      ) : (
                        <img src={CheckIcon} style={{ width: 12, height: 12 }} />
                      )
                    ) : (
                      '—'
                    ),
                    CO2: METRIC.CO2 ? METRIC.CO2 + '%' : 'N/A',
                    HUM: METRIC.HUM ? METRIC.HUM + ' %' : '—',
                    TEMP: METRIC.TEMP ? METRIC.TEMP + ' °C' : '—',
                    HEG: METRIC.HEG ? METRIC.HEG + '%' : 'N/A',
                  };

                  const ACTIVE_STYLE = {
                    background: '#6baa00',
                    color: COLOR.WHITE,
                    fontWeight: 600,
                    borderRadius: 5,
                  };
                  const INACTIVE_STYLE = {
                    background: 'red',
                    color: COLOR.WHITE,
                    fontWeight: 600,
                    borderRadius: 5,
                  };

                  return (
                    <TableRow key={`${device.eui_id}-${i}`}>
                      <TableCell>{POSITION[device.position as 1 | 2 | 3 | 4 | 5]}</TableCell>
                      <TableCell>
                        {lastMeasurement?.timestamp
                          ? dayjs(lastMeasurement.timestamp).format('D/M/YYYY - HH:mm')
                          : '—'}
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          size="small"
                          style={activeDevices?.[i] ? ACTIVE_STYLE : INACTIVE_STYLE}
                          label={activeDevices?.[i] ? 'Sí' : 'No'}
                        />
                      </TableCell>
                      <TableCell>{device.lance_number}</TableCell>
                      <TableCell>
                        <Badge
                          badgeContent={movAlerts.length}
                          sx={{
                            '& .MuiBadge-badge': {
                              color: 'black',
                              background: activeDevices?.[i] ? '#ffff' : '#eeeeee',
                              border: `1px solid black`,
                            },
                          }}
                        >
                          <Chip
                            size="small"
                            clickable={hasMovAlerts}
                            disabled={!METRIC.MOV}
                            style={STYLES.MOV}
                            label={DISPLAY.MOV}
                            onClick={() => {
                              if (!writePermission || !hasMovAlerts) {
                                return;
                              }
                              setTargetAlerts(movAlerts);
                              setAlertPanelType('individual');
                            }}
                          />
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Badge
                          badgeContent={co2Alerts.length}
                          sx={{
                            '& .MuiBadge-badge': {
                              color: 'black',
                              background: 'white',
                              border: `1px solid black`,
                            },
                          }}
                        >
                          <Chip
                            size="small"
                            clickable={hasCo2Alerts}
                            disabled={!METRIC.CO2}
                            style={STYLES.CO2}
                            label={DISPLAY.CO2}
                            onClick={() => {
                              if (!writePermission || !hasCo2Alerts) {
                                return;
                              }
                              setTargetAlerts(co2Alerts);
                              setAlertPanelType('individual');
                            }}
                          />
                        </Badge>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <Stack alignItems="center">
                              <Box>Humedad intergranaria</Box>
                              <Box>{DISPLAY.HUM}</Box>
                              <Box>Temperatura intergranaria</Box>
                              <Box>{DISPLAY.TEMP}</Box>
                            </Stack>
                          }
                          placement="top"
                        >
                          <Badge
                            badgeContent={hegAlerts.length}
                            sx={{
                              '& .MuiBadge-badge': {
                                color: 'black',
                                background: 'white',
                                border: `1px solid black`,
                              },
                            }}
                          >
                            <Chip
                              size="small"
                              clickable={hasHegAlerts}
                              disabled={!METRIC.HEG}
                              style={STYLES.HEG}
                              label={DISPLAY.HEG}
                              onClick={() => {
                                if (!writePermission || !hasHegAlerts) {
                                  return;
                                }
                                setTargetAlerts(hegAlerts);
                                setAlertPanelType('individual');
                              }}
                            />
                          </Badge>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title={
                            <Box style={{ textAlign: 'center' }}>
                              TAS son las siglas de:
                              <br />
                              Tiempo de Almacenamiento Seguro
                            </Box>
                          }
                          placement="top"
                        >
                          <Chip size="small" disabled={!METRIC.TAS} style={tasStyle} label={tasLabel} />
                        </Tooltip>
                      </TableCell>
                      <TableCell style={{ fontWeight: 600, textAlign: 'center' }}>
                        <Chip disabled={!METRIC.BAT} label={(METRIC.BAT ?? '—') + ' %'} style={STYLES.BAT} />
                      </TableCell>
                      <TableCell style={{ width: 144 }}>
                        <Button
                          size="small"
                          disabled={!device.activeAlerts.length || !writePermission || !activeDevices?.[i]}
                          sx={{
                            color: '#22A7F0',
                            height: '32px',
                            '&:hover': { background: '#e8f8ff' },
                          }}
                          onClick={() => {
                            setTargetAlerts(device.activeAlerts);
                            setAlertPanelType('individual');
                          }}
                        >
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Box>{device.activeAlerts.length}</Box>{' '}
                            <img
                              src={
                                !writePermission || !activeDevices?.[i]
                                  ? DisabledEyeIcon
                                  : device.activeAlerts.length
                                  ? BlueEyeIcon
                                  : DisabledEyeIcon
                              }
                              style={{ width: 16, height: 16 }}
                            />
                          </Stack>
                        </Button>
                      </TableCell>
                      <TableCell style={{ width: 10 }}>
                        <Button
                          size="small"
                          disabled={
                            !METRIC.MOV &&
                            !METRIC.CO2 &&
                            !METRIC.HEG &&
                            !METRIC.TAS &&
                            !METRIC.BAT &&
                            !device.activeAlerts.length
                          }
                          style={{ color: '#22A7F0', height: '32px' }}
                          sx={{
                            '&:hover': { background: '#e8f8ff' },
                          }}
                          onClick={() => {
                            setTargetEventHistory(
                              eventHistory?.filter((e) => e.device_id === device.id) ?? []
                            );
                            setHistoryPanelType('individual');
                          }}
                        >
                          <img
                            src={
                              !METRIC.MOV &&
                              !METRIC.CO2 &&
                              !METRIC.HEG &&
                              !METRIC.TAS &&
                              !METRIC.BAT &&
                              !device.activeAlerts.length
                                ? DisabledEyeIcon
                                : BlueEyeIcon
                            }
                            style={{ width: 16, height: 16 }}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 600 }}>Posición</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Desvinvulación</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Dispositivo</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Alertas totales</TableCell>
                  <TableCell style={{ fontWeight: 600 }}>Historial</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {devices?.map((device) => {
                  const [lastMeasurement] = device.measurements;

                  const POSITION = {
                    1: 'Inicio',
                    2: 'Inicio-Medio',
                    3: 'Medio',
                    4: 'Medio-Fin',
                    5: 'Fin',
                  };

                  const METRIC = {
                    MOV: device.movement_color_id,
                    CO2: lastMeasurement?.co2_percent,
                    HEG: lastMeasurement?.heg,
                    HUM: lastMeasurement?.humidity_percent,
                    TEMP: lastMeasurement?.temperature_celsius,
                    TAS: lastMeasurement?.TAS,
                    BAT: lastMeasurement?.battery_percent,
                  };

                  return (
                    <TableRow key={device.eui_id}>
                      <TableCell>{POSITION[device.position as 1 | 2 | 3 | 4 | 5]}</TableCell>
                      <TableCell>
                        {dayjs(device?.updated_at ?? device.updated_at).format('D/M/YYYY - HH:mm')}
                      </TableCell>
                      <TableCell>{device.lance_number}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1} display={'flex'} alignItems="end" ml={5}>
                          <Box display={'flex'} alignItems="center" height="32px">
                            {device.historicAlerts.length}
                          </Box>{' '}
                        </Stack>
                      </TableCell>

                      <TableCell style={{ width: 10 }}>
                        <Button
                          size="small"
                          disabled={
                            !METRIC.MOV &&
                            !METRIC.CO2 &&
                            !METRIC.HEG &&
                            !METRIC.TAS &&
                            !METRIC.BAT &&
                            !device.activeAlerts.length
                          }
                          style={{ color: '#22A7F0', height: '32px' }}
                          sx={{
                            '&:hover': { background: '#e8f8ff' },
                          }}
                          onClick={() => {
                            setTargetEventHistory(
                              eventHistory?.filter((e) => e.device_id === device.id) ?? []
                            );
                            setHistoryPanelType('individual');
                          }}
                        >
                          <img
                            src={
                              !METRIC.MOV &&
                              !METRIC.CO2 &&
                              !METRIC.HEG &&
                              !METRIC.TAS &&
                              !METRIC.BAT &&
                              !device.activeAlerts.length
                                ? DisabledEyeIcon
                                : BlueEyeIcon
                            }
                            style={{ width: 16, height: 16 }}
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
      <AlertSidePanel
        open={alertPanelType !== ''}
        type={alertPanelType}
        silobagName={silobagName}
        alerts={targetAlerts}
        close={handleAlertPanelClose}
        trigger={trigger}
      />
      <HistorySidePanel
        open={historyPanelType !== ''}
        type={historyPanelType}
        data={{
          events: targetEventHistory,
          devicesInfoByID,
          silobagName,
        }}
        close={handleHistoryPanelClose}
      />
    </Paper>
  );
}

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { updateAlertStatusService } from '@services/domain/alert/alert-status-edition';

type Events = {
  MOV: SummaryEventsHistory;
  CO2: SummaryEventsHistory;
  HEG: SummaryEventsHistory;
};

type HistorySidePanelProps = {
  open: boolean;
  type: '' | 'individual' | 'global';
  close: () => void;
  data: {
    events: SummaryEventsHistory;
    devicesInfoByID: DeviceInfo;
    silobagName?: string;
  };
};

function HistorySidePanel({ open = false, type, close, data }: HistorySidePanelProps) {
  const isGlobalPanel = type === 'global';

  const EVENTS: Events = {
    MOV: data.events?.filter((e) => e.metric_id === 4),
    CO2: data.events?.filter((e) => e.metric_id === 3),
    HEG: data.events?.filter((e) => e.metric_id === 5),
  };

  return (
    <SidePanel open={open} close={close}>
      <Box component="header">
        <Stack direction="row">
          <Stack style={{ flex: 1, padding: 10 }} spacing={2}>
            <Box>
              <Box style={{ fontWeight: '600', fontSize: 14 }}>Historial</Box>
              <Box style={{ fontSize: 18 }}>
                {(isGlobalPanel
                  ? data.silobagName
                  : data.devicesInfoByID[data.events?.at(0)?.device_id ?? '-1']?.name) ?? null}
              </Box>
            </Box>
          </Stack>
          <Box>
            <IconButton onClick={close}>
              <img src={CloseIcon} style={{ width: 14, height: 14 }} />
            </IconButton>
          </Box>
        </Stack>
        <Divider />
      </Box>
      <HistoryPanelSection
        title={i18n.t('app.silobag_data.movement')}
        shortName="MOV"
        events={EVENTS.MOV}
        devicesInfoByID={data.devicesInfoByID}
      />
      <HistoryPanelSection
        title={i18n.t('app.silobag_metric.co2')}
        shortName="CO2"
        events={EVENTS.CO2}
        devicesInfoByID={data.devicesInfoByID}
      />
      <HistoryPanelSection
        title={i18n.t('app.silobag_metric.heg')}
        shortName="HEG"
        events={EVENTS.HEG}
        devicesInfoByID={data.devicesInfoByID}
      />
    </SidePanel>
  );
}

type HistoryPanelSectionProps = {
  title: string;
  shortName: string;
  events: SummaryEventsHistory;
  devicesInfoByID: DeviceInfo;
};

function HistoryPanelSection({ title, shortName, events, devicesInfoByID }: HistoryPanelSectionProps) {
  return (
    <>
      <Accordion
        disableGutters
        disabled={!events?.length}
        TransitionProps={{ timeout: 320, unmountOnExit: true }}
        elevation={0}
        style={{ background: '#FFF' }}
        sx={{ '&:before': { height: '0px' } }}
      >
        <AccordionSummary expandIcon={<img src={ChevronDownIcon} />}>
          <Stack direction="row" alignItems="center" spacing={2} style={{ width: '100%' }}>
            <Chip
              size="small"
              label={shortName}
              style={{ color: '#FFF', background: '#49454F', borderRadius: 8 }}
            />
            <Box style={{ flex: 1, fontWeight: 600 }}>{title}</Box>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          {events?.map((event) => {
            return (
              <Stack key={event.id}>
                <Timeline
                  sx={{
                    paddingRight: 0,
                    margin: '14px 0 0 0',
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                      margin: -1,
                    },
                  }}
                >
                  <TimelineItem style={{ minHeight: 25 }}>
                    <TimelineSeparator>
                      <div
                        style={{
                          width: 14,
                          height: '100%',
                          marginLeft: 5,
                          borderLeft: '1px solid #bdbdbd',
                          borderTop: '1px solid #bdbdbd',
                        }}
                      ></div>
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box style={{ position: 'absolute', left: 10, top: -10 }}>
                        <Stack direction="row" spacing={2}>
                          <Chip
                            size="small"
                            label={event.metric_id === 4 ? 'MOV' : event.measurement_value + '%'}
                            style={{
                              width: 'min-content',
                              height: 23,
                              fontWeight: 600,
                              color: event.colors.hex === '#FFDD00' ? 'black' : 'white',
                              background: event.colors.hex,
                              borderRadius: 5,
                            }}
                          />
                          <Chip
                            size="small"
                            label={devicesInfoByID[event.device_id].name}
                            style={{
                              width: 'min-content',
                              height: 23,
                              background: 'white',
                              border: '1px solid black',
                              borderRadius: 5,
                            }}
                          />
                        </Stack>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  {event.alert_actions.map((action) => {
                    const targetAutoMsg = '[Mensaje Automático] Estado de alerta cambiado de forma masiva.';
                    const displayedAutoMsg = i18n.t('app.alert.history.footnote');

                    type Desc = {
                      userMessage: string | null;
                      autoMessage: boolean;
                    };

                    const description: Desc = {
                      userMessage: action.description,
                      autoMessage: false,
                    };

                    if (action.description?.endsWith(targetAutoMsg)) {
                      description.userMessage = action.description.replace(targetAutoMsg, '');
                      description.autoMessage = true;
                    }

                    const STATES = {
                      1: i18n.t('app.alert.state.pending'),
                      2: i18n.t('app.alert.state.review'),
                      3: i18n.t('app.alert.state.resolve'),
                      5: i18n.t('app.alert.state.delete'),
                    };

                    return (
                      <TimelineItem key={action.id}>
                        <TimelineSeparator>
                          <TimelineDot style={{ background: 'black' }} />
                          <TimelineConnector style={{ width: '1px' }} />
                        </TimelineSeparator>
                        <TimelineContent style={{ fontSize: 14, paddingRight: 0 }}>
                          <Stack direction="row" style={{ width: '100%' }}>
                            <Stack style={{ flex: 1 }}>
                              <Box style={{ fontWeight: 600 }}>{STATES[action.alert_actions_types.id]}</Box>
                              <Box style={{ fontSize: 12 }}>
                                {dayjs(action.created_at).format('D/M/YYYY — HH:mm')}
                              </Box>
                            </Stack>
                            <Box style={{ fontSize: 10 }}>{action.users?.fullname}</Box>
                          </Stack>
                          <Box style={{ fontSize: 12, marginTop: 10 }}>
                            {description.userMessage}
                            {description.autoMessage ? (
                              <>
                                {description.userMessage ? <br /> : null}
                                <span style={{ color: '#999', fontSize: 10, fontStyle: 'italic' }}>
                                  {'— ' + displayedAutoMsg}
                                </span>
                              </>
                            ) : null}
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot style={{ background: 'black' }} />
                    </TimelineSeparator>
                    <TimelineContent style={{ paddingRight: 0 }}>
                      <Stack direction="row" style={{ width: '100%' }}>
                        <Stack style={{ fontSize: 14, flex: 1 }}>
                          <Box style={{ fontWeight: 600 }}>{i18n.t('app.alert.state.pending')}</Box>
                          <Box style={{ fontSize: 12 }}>
                            {dayjs(event.created_at).format('D/M/YYYY — HH:mm')}
                          </Box>
                        </Stack>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Stack>
            );
          })}
        </AccordionDetails>
      </Accordion>
      <Divider />
    </>
  );
}

type AlertPanelProps = {
  open: boolean;
  close: () => void;
  type: '' | 'individual' | 'global';
  alerts: ActiveAlert[] | null;
  silobagName?: string;
  trigger: (query: SilobagDetailParams) => void;
};

function AlertSidePanel({ open = false, close, alerts, type, silobagName, trigger }: AlertPanelProps) {
  const isGlobalPanel = type === 'global';

  const alertsSorted = alerts?.sort(
    (a, b) => new Date(b.measurement_created_at).getTime() - new Date(a.measurement_created_at).getTime()
  );

  return (
    <SidePanel open={open} close={close}>
      <Box component="header">
        <Stack direction="row">
          <Stack style={{ flex: 1, padding: 10 }} spacing={2}>
            <Box>
              <Box style={{ fontWeight: '600', fontSize: 14 }}>
                {i18n.t('app.silobag_devices.alert_actives')}
              </Box>
              <Box style={{ fontSize: 18 }}>
                {(isGlobalPanel ? silobagName : alertsSorted?.at(0)?.deviceName) ?? null}
              </Box>
            </Box>
            <AlertActionsMenu alerts={alertsSorted} massive trigger={trigger} />
          </Stack>
          <Box>
            <IconButton onClick={close}>
              <img src={CloseIcon} style={{ width: 14, height: 14 }} />
            </IconButton>
          </Box>
        </Stack>
        <Divider />
      </Box>
      <Box style={{ overflowY: 'auto' }}>
        <Stack spacing={1.4} style={{ margin: '10px 5px 10px 0' }}>
          {alertsSorted && alertsSorted?.length > 0 ? (
            alertsSorted?.map((al) => (
              <AlertCard
                key={al.id}
                alert={al}
                deviceName={isGlobalPanel ? al.deviceName : undefined}
                trigger={trigger}
              />
            ))
          ) : (
            <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant="h6" textAlign={'center'}>
                {i18n.t('app.no_active_alerts')}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    </SidePanel>
  );
}

type AlertCardProps = {
  alert: ActiveAlert;
  deviceName?: string;
  trigger: (query: SilobagDetailParams) => void;
};

function AlertCard({ alert, deviceName, trigger }: AlertCardProps) {
  const isMovAlert = alert.metric_id === 4;

  const DISPLAY = {
    VALUE: alert.measurement_value,
    DATE: dayjs(alert.created_at).format('D/M/YYYY — HH:mm'),
    SHORT_NAME: alert.metrics.short_name,
  };

  return (
    <Paper
      elevation={1}
      style={{
        background: 'linear-gradient(180deg, rgba(235,234,239,1) 33%, rgba(243,242,247,1) 66%)',
        fontSize: 12,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" style={{ width: '100%' }}>
        <Box style={{ width: '8px', background: alert.colors.hex, borderRadius: '4px 0 0 4px' }} />
        <Stack p={1.4} style={{ width: '100%' }} spacing={0.75}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box style={{ width: '100%', minWidth: 'max-content' }}>{DISPLAY.DATE}</Box>
            {!isMovAlert ? (
              <Box style={{ minWidth: 'max-content' }}>
                <span style={{ color: '#929292' }}>Valor alerta </span>
                <span style={{ fontWeight: 600 }}>{DISPLAY.VALUE} %</span>
              </Box>
            ) : null}
            <Chip
              size="small"
              label={DISPLAY.SHORT_NAME}
              style={{
                color: alert.colors.hex === '#FFDD00' ? 'black' : 'white',
                background: alert.colors.hex,
                fontWeight: 600,
                borderRadius: 7,
              }}
            />
          </Stack>
          {deviceName ? (
            <Box>
              <span style={{ color: '#929292', marginRight: 7 }}>Dispositivo </span>
              <span style={{ fontSize: 14, fontWeight: 600 }}>{deviceName}</span>
            </Box>
          ) : null}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box component="span" style={{ color: '#929292' }}>
              Estado
            </Box>
            <AlertActionsMenu alertID={alert.id} alertState={alert.alert_actions_types} trigger={trigger} />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

type AlertActionsMenuProps = {
  alerts?: ActiveAlert[];
  alertID?: number;
  massive?: true;
  alertState?: ActiveAlert['alert_actions_types'];
  trigger: (query: SilobagDetailParams) => void;
};

function AlertActionsMenu({ alertID, alerts, alertState, massive, trigger }: AlertActionsMenuProps) {
  /* Dropdown Menu for Alert Actions */
  const [dropdownMenuData, setDropdownMenuData] = useState<{
    open: boolean;
    anchorEl: HTMLButtonElement | null;
  }>({
    open: false,
    anchorEl: null,
  });
  const handleDropdownMenuOpen: MouseEventHandler<HTMLButtonElement> = (event) =>
    setDropdownMenuData({ open: true, anchorEl: event.currentTarget });
  const handleDropdownMenuClose = () => setDropdownMenuData({ open: false, anchorEl: null });

  /* Change Alert State Modal */
  const [modalData, setModalData] = useState<{
    open: boolean;
    state: 1 | 2 | 3 | 5;
  }>({
    open: false,
    state: alertState?.id ?? 1,
  });
  const handleModalOpen: MouseEventHandler<HTMLLIElement> = (event) => {
    const target = event.target as HTMLLIElement;
    setModalData({ open: true, state: target.value as 1 | 2 | 3 | 5 });
  };
  const handleModalClsoe = () => setModalData({ open: false, state: alertState?.id ?? 1 });
  const STATES = {
    1: i18n.t('app.alert.state.pending'),
    2: i18n.t('app.alert.state.review'),
    3: i18n.t('app.alert.state.resolve'),
    5: i18n.t('app.alert.state.delete'),
  };

  const optionStylesById = (id: 1 | 2 | 3 | 5) => ({
    background: modalData.open && modalData.state === id ? '#9bc45c' : '',
    '&:hover': { background: '#c3d7a5' },
  });

  return (
    <>
      <Button
        variant="outlined"
        style={{
          width: massive ? '70%' : '100%',
          marginBottom: massive ? '10px' : '',
          padding: massive ? 5 : 2,
          background: dropdownMenuData.open ? '#9bc45c' : '',
          border: '1px solid #C5BFBF',
          borderRadius: 5,
          textTransform: 'none',
        }}
        sx={{
          color: 'black',
          background: '#FFF',
          '&:hover': { background: '#c3d7a5' },
        }}
        onClick={handleDropdownMenuOpen}
        startIcon={massive ? null : <img src={PendingIcon} />}
        endIcon={massive ? null : <img src={ChevronDownIcon} />}
        size="small"
        disabled={massive && !alerts?.length}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: massive ? 16 : 12,
            fontWeight: 400,
            width: massive ? '' : '80%',
            height: 24,
            textAlign: 'left',
          }}
        >
          {massive ? i18n.t('app.silobag_devices.actions_massive') : STATES[alertState?.id as 1 | 2]}
        </Box>
      </Button>
      <Menu
        open={dropdownMenuData.open}
        onClose={handleDropdownMenuClose}
        anchorEl={dropdownMenuData.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{ width: 180 }}>
          <MenuItem
            sx={optionStylesById(1)}
            value={1}
            disabled={alertState?.id === 1}
            onClick={handleModalOpen}
          >
            <img src={PendingIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.pending')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(2)}
            value={2}
            disabled={alertState?.id === 2}
            onClick={handleModalOpen}
          >
            <img src={RevisionIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.review')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(3)}
            value={3}
            disabled={alertState?.id === 3}
            onClick={handleModalOpen}
          >
            <img src={ResolveIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.resolve')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(5)}
            value={5}
            disabled={alertState?.id === 5}
            onClick={handleModalOpen}
          >
            <img src={DeleteIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.delete')}
          </MenuItem>
        </Box>
      </Menu>
      <ChangeAlertStateModal
        alertID={alertID}
        alerts={alerts}
        massive={massive}
        isOpen={modalData.open}
        newState={modalData.state}
        close={handleModalClsoe}
        trigger={trigger}
      />
    </>
  );
}

type ChangeAlertStateModalProps = {
  alerts?: ActiveAlert[];
  alertID?: number;
  massive?: boolean;
  isOpen: boolean;
  newState: 1 | 2 | 3 | 5;
  close: () => void;
  trigger: (query: SilobagDetailParams) => void;
};

function ChangeAlertStateModal({
  alertID,
  alerts,
  massive,
  isOpen,
  newState,
  close,
  trigger,
}: ChangeAlertStateModalProps) {
  const { siloID } = useParams();

  const closeOnSuccess = () => {
    close();
    trigger({ query: siloID ?? 'id-not-found' });
  };

  const {
    trigger: createNewAction, //
    isLoading,
  } = useService(updateAlertStatusService, { onSuccess: closeOnSuccess });

  const { userID } = useGlobalStore();

  const [description, setDescription] = useState('');

  const submit = () => {
    const bodyForMassiveActions = alerts?.map((a) => ({
      alert_id: a.id!,
      user_id: userID!,
      alert_actions_type_id: newState,
      description,
    }));

    const bodyForSingleAction = {
      alert_id: alertID!,
      user_id: userID!,
      alert_actions_type_id: newState,
      description,
    };

    createNewAction({
      body: massive ? bodyForMassiveActions! : bodyForSingleAction,
    });
  };

  const isDeleteRequest = newState === 5;

  const STATES = {
    1: i18n.t('app.alert.state.pending'),
    2: i18n.t('app.alert.state.review'),
    3: i18n.t('app.alert.state.resolve'),
    5: i18n.t('app.alert.state.delete'),
  };

  return (
    <>
      <MyModal isOpen={isOpen} close={close}>
        <Box style={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.alert.modal.title')}</Box>
        <Divider />
        <Stack alignItems="center" spacing={2}>
          <Box>
            {massive ? i18n.t('app.alert.modal.massive.question') : i18n.t('app.alert.modal.single.question')}
            <span style={{ fontWeight: 600, color: isDeleteRequest ? 'red' : '#8dbd3a' }}>
              {STATES[newState]}
            </span>
            ?
          </Box>
          <TextField
            style={{ color: '#C5BFBF' }}
            fullWidth
            multiline
            rows={5}
            placeholder={i18n.t('app.alert.modal.placeholder.main')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
        {massive ? (
          <Stack style={{ color: '#d32f2f' }} spacing={0.5}>
            <Box alignSelf="center">{i18n.t('app.alert.modal.massive.warning_title')}</Box>
            <Box>{i18n.t('app.alert.modal.massive.warning1')}</Box>
            <Box>{i18n.t('app.alert.modal.massive.warning2')}</Box>
          </Stack>
        ) : null}
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
            }}
            onClick={close}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            style={{
              color: '#FFF',
              width: 115,
              height: 36.5,
              borderRadius: 100,
              textTransform: 'none',
            }}
            color={isDeleteRequest ? 'error' : 'primary'}
            onClick={submit}
          >
            {isLoading ? (
              <CircularProgress
                color={isDeleteRequest ? 'error' : 'primary'}
                style={{ width: 24, height: 24 }}
              />
            ) : (
              'Guardar'
            )}
          </Button>
        </Stack>
      </MyModal>
    </>
  );
}

type ModalProps = {
  isOpen: boolean;
  close: () => void;
};

function MyModal({ isOpen, close, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    close();
  };

  return (
    <Modal open={isOpen} hideBackdrop onClose={handleClose}>
      <Fade in={isOpen} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
