import BarleyIcon from '@assets/svg/siloreal/species/barley.svg';
import BeanIcon from '@assets/svg/siloreal/species/bean.svg';
import CornIcon from '@assets/svg/siloreal/species/corn.svg';
import RiceIcon from '@assets/svg/siloreal/species/rice.svg';
import SoyIcon from '@assets/svg/siloreal/species/soy.svg';
import WheatIcon from '@assets/svg/siloreal/species/wheat.svg';
import SunflowerIcon from '@assets/svg/siloreal/species/sunflower.svg';
import SorghumIcon from '@assets/svg/siloreal/species/sorghum.svg';
import SesameIcon from '@assets/svg/siloreal/species/sesame.svg';
import ChiaIcon from '@assets/svg/siloreal/species/chia.svg';
import RicePuitaIcon from '@assets/svg/siloreal/species/rice-puita.svg';
import MilhetoIcon from '@assets/svg/siloreal/species/milheto.svg';
import RyeIcon from '@assets/svg/siloreal/species/centeno.svg';
import ChickpeaIcon from '@assets/svg/siloreal/species/garbanzo.svg';

type SpeciesImgsByIdProps = Record<number, string>;

export const SPECIES_IMG_BY_ID: SpeciesImgsByIdProps = {
  1: WheatIcon,
  2: SoyIcon,
  3: SunflowerIcon,
  4: SorghumIcon,
  5: RiceIcon,
  6: CornIcon,
  12: RicePuitaIcon,
  23: BarleyIcon,
  24: BeanIcon,
  25: SesameIcon,
  26: ChiaIcon,
  27: MilhetoIcon,
  28: RyeIcon,
  29: ChickpeaIcon,
};
