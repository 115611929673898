import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { UpdateShareSilobagBody, UpdateShareSilobagResponse } from './share-edition.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: updateSilobagsPermissions_DEPRECATED

// Implementado ✅
export async function updateSharePermissionService({ body, query: rawQuery }: UpdateShareSilobagBody) {

  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<UpdateShareSilobagResponse>(ENDPOINT.SILO_PERMISSION, { query, body });

  return {
    response,
    summary: data
  };
}
