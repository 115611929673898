import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { RegisterPayload, RegisterResponse } from './register-auth-credential.types';

// deprecated service ref.: registerService_DEPRECATED
export async function registerCredentialService({ body }: RegisterPayload) {
  const { response, data } = await BACKEND.post<RegisterResponse>(ENDPOINT.REGISTER, { body });

  return {
    response,
    summary: data,
  };
}
