import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { SilobagInfoPayload, SilobagInfoResponse } from './get-silobag-info-corvus.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function getSilobagInfoCorvusService({ query: RawQuery }: SilobagInfoPayload) {
  const query = transformIntoQueryParamString(RawQuery);
  const { response, data } = await BACKEND.get<SilobagInfoResponse>(ENDPOINT.CORVUS_GET_SB_INFO, { query });
  return {
    response,
    summary: data,
  };
}
