import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { SilobagHistoryEventCategoriesData } from './silobag-history-event-categories.types';

// deprecated service ref.: getSilobagEventTypesService_DEPRECATED

// Implementado ✅
export async function silobagHistoryEventCategoriesService() {
  const { response, data } = await BACKEND.get<SilobagHistoryEventCategoriesData>(
    ENDPOINT.SILOBAG_EVENT_TYPES
  );

  return {
    response,
    summary: data,
  };
}
