import { useDebounce, useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import { Autocomplete, Box, Button, CircularProgress, Divider, Stack, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BlueEyeIcon from '@assets/svg/core/eye-blue.svg';
import LocationPinIcon from '@assets/svg/core/location-pin-blue.svg';
import LocationPinIconDisabled from '@assets/svg/core/location-pin-disabled.svg';

import SearchIcon from '@assets/svg/core/search-black.svg';
import i18n from '../../../libs/language';
import { SPECIES_IMG_BY_ID } from '@data/species';
import { useGlobalStore } from '@global-store/use-store';

import { searchSilobagService } from '@services/domain/base/silobag/base/silobag-search';

export function SearchBar() {
  const [open, setOpen] = useToggle(false);
  const [search, setSearch] = useState('');
  const { organizationId } = useGlobalStore();

  const isLoadingRef = useRef(false);

  const { trigger, summary } = useService(searchSilobagService, {
    onSuccess: () => (isLoadingRef.current = false),
  });

  const handleSearchSubmit = () => {
    if (search === '') return;
    trigger({ query: search });
  };

  const debounceDelay = 350;
  useDebounce(handleSearchSubmit, debounceDelay, [search]);

  useEffectOnUpdate(() => {
    handleSearchSubmit();
    setSearch('');
  }, [organizationId]);

  const navigate = useNavigate();

  const speciesIconStyles = { width: 24, height: 24 };
  // const firstSummaryItem = summary?.[0];
  // const speciesName =
  //   i18n.locale === 'pt'
  //     ? firstSummaryItem?.silobag_species_types?.silobag_species_types_translations?.[0]?.description ??
  //       firstSummaryItem?.silobag_species_types?.name
  //     : firstSummaryItem?.silobag_species_types?.name;

  const isLoading = isLoadingRef.current && Boolean(search);

  return (
    <Box sx={{ width: 260, minWidth: 110 }}>
      <Autocomplete
        clearOnEscape
        clearOnBlur
        componentsProps={{ paper: { sx: { width: 470 } } }}
        open={open && Boolean(search)}
        inputValue={search}
        loading={isLoading}
        loadingText={i18n.t('app.navbar.searchbar.searching')}
        noOptionsText={i18n.t('app.navbar.searchbar.no_results')}
        onBlur={() => {
          setOpen(false);
          isLoadingRef.current = false;
        }}
        onOpen={() => setOpen(true)}
        onInputChange={(event, newInputValue) => {
          if (!event || event.type === 'blur') return;
          setSearch(newInputValue);
          isLoadingRef.current = true;
        }}
        onChange={(_, value) => {
          if (value != null) {
            setSearch(''); // Clear search input after selection
            setOpen(false);
            navigate(`silobag/${value.id}`);
            isLoadingRef.current = false;
          }
        }}
        getOptionLabel={(option) => option['id'] + option['name']}
        options={isLoading ? [] : summary ?? []}
        groupBy={() => 'Silobolsas'}
        renderOption={(props, option, state) => (
          <Box key={option.id}>
            <Box component="li" {...props}>
              <Stack direction="row" spacing={2} style={{ width: '100%' }} alignItems="center">
                <Stack
                  style={{ width: 30, height: 30, background: '#6BAA0026', borderRadius: 10 }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={SPECIES_IMG_BY_ID[option?.silobag_species_types.id as 1]}
                    style={speciesIconStyles}
                  />
                </Stack>
                <Stack style={{ width: '100%', flex: 1, marginRight: 5 }} justifySelf="right">
                  <Box style={{ fontWeight: 600, fontSize: 14 }}>{option.name}</Box>
                  <Stack direction="row" justifyContent="space-between">
                    <Box sx={{ fontSize: 12 }}>
                      <span style={{ color: '#999' }}>{i18n.t('app.silobag_data.species')}</span>{' '}
                      {option.silobag_species_types.silobag_species_types_translations.at(0)?.description ??
                        '―'}
                    </Box>
                    <Box sx={{ fontSize: 12 }}>
                      <span style={{ color: '#999' }}>{i18n.t('app.silobag_data.product')}</span>{' '}
                      {option?.silobag_harvests?.name ?? '―'}
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1} style={{ marginTop: 5 }} alignSelf="flex-start">
                  <Button
                    style={{ color: 'black', padding: 3, minWidth: 20, zIndex: 1 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                      navigate(`silobag/${option.id}`);
                    }}
                  >
                    <img style={{ width: 16, height: 16 }} src={BlueEyeIcon} />
                  </Button>
                  <Button
                    style={{
                      color: 'black',
                      padding: 3,
                      minWidth: 20,
                      zIndex: 1,
                    }}
                    disabled={option.silobag_data_status_id === 1 || option.active === false}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(false);
                      navigate(`map`, {
                        state: { countryside_id: option.countryside_id, silobag_id: option.id },
                      });
                    }}
                  >
                    <img
                      style={{ width: 16, height: 16 }}
                      src={
                        option.silobag_data_status_id === 1 || option.active === false
                          ? LocationPinIconDisabled
                          : LocationPinIcon
                      }
                    />
                  </Button>
                </Stack>
              </Stack>
            </Box>
            {state.index !== (summary?.length ?? 0) - 1 ? <Divider /> : null}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={i18n.t('app.navbar.search_silo')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{
              '& .MuiInputBase-root': {
                height: 35,
                padding: '0 10px 0 10px',
                fontSize: '0.75rem',
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <img style={{ width: 14, height: 14 }} src={SearchIcon} />,
              endAdornment: <>{isLoading ? <CircularProgress size={18} thickness={6} /> : null}</>,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                  setOpen(true);
                }
              },
            }}
          />
        )}
        renderGroup={(params) => (
          <Box key={params.key}>
            <Box
              style={{
                color: '#aaa',
                fontSize: '0.8rem',
                padding: '0 16px',
                lineHeight: '20px',
              }}
            >
              {params.group}
            </Box>
            <Box>{params.children}</Box>
          </Box>
        )}
      />
    </Box>
  );
}
