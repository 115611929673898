import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { loginCorvusResponse, loginCorvusPayload } from './auth-corvus.types';

//loginCorvusService_DEPRECATED
//Implementado  ✅
export async function loginCorvusService({ body }: loginCorvusPayload) {
  const { response, data } = await BACKEND.post<loginCorvusResponse>(ENDPOINT.CORVUS_LOGIN, { body });
  return {
    response,
    summary: data,
  };
};
