import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { VerifySpeciesInsuranceResponse } from './la-segunda-species-types';


export async function verifySpeciesInsurance() {
  const { response, data } = await BACKEND.get<VerifySpeciesInsuranceResponse>(ENDPOINT.INSURANCE_SPECIES);

  return {
    response,
    summary: data,
  };
}