import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import PlusIcon from '@assets/svg/administrators/plus.svg';
import { CompanyViewEditModal } from './company-view-edit-modal';
import { useToggle } from '@hooks/core';
import { DeleteModal } from '../modal-deletion/delete-modal';
import { useEffect, useState } from 'react';
import { useService } from '@hooks/use-service';
import i18n from '../../../libs/language';
import { ToastAction } from '@ui/toast/toast-action';
import viewIcon from '@assets/svg/core/eye-hollow-blue.svg';
import editIcon from '@assets/svg/core/edit-square-hollow-blue.svg';
import editDisabledIcon from '@assets/svg/core/edit-square-hollow-disabled.svg';
import DeleteActionIcon from '@assets/svg/core/trash-red.svg';
import { listCompanyService } from '@services/domain/base/company/company-list';
import { deleteCompanyService } from '@services/domain/base/company/company-deletion';

type Unpacked<T> = T extends (infer U)[] ? U : T;
type CompanySummary = Unpacked<Awaited<ReturnType<typeof listCompanyService>>['summary']>;

export function AdminCompanyList({ setActionInCompany }: { setActionInCompany: (value: boolean) => void }) {
  const {
    trigger: reFetchCompanyListData,
    isLoading,
    summary: companies,
  } = useService(listCompanyService, {
    fetchOnMount: true,
  });

  const [openCompanyModal, toggleOpenCompanyModal] = useToggle(false);
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle(false);
  const [deleteSnackbar, toggleDeleteSnackbar] = useToggle(false);
  const [openErrorSnackbar, errorToggleDeleteCountryside] = useToggle(false);

  const handleCompanyModalState = (value: boolean) => toggleOpenCompanyModal(value);
  const handleDeleteModalState = (value: boolean) => toggleOpenDeleteModal(value);

  const [modalType, setModalType] = useState<'new' | 'view' | 'edit'>('new');
  const enableEditMode = () => setModalType('edit');

  // TODO: this state is not necessary, we can use the parent state directly
  const [targetData, setTargetData] = useState<CompanySummary>();

  const [newCompanyMsg, toggleNewCompanyMsg] = useToggle(false);

  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.down('lg'));

  // Este useEffect reestablece el valor de toggleNewCountrysideMsg para que se reutilice
  useEffect(() => {
    setTimeout(() => {
      toggleNewCompanyMsg(false);
    }, 3000);
  }, [newCompanyMsg]);

  const configButtonStyles = {
    color: '#FFF',
    fontSize: 12,
    borderRadius: 100,
    textTransform: 'none',
  };

  return (
    <>
      {deleteSnackbar && (
        <ToastAction message={i18n.t('app.administrator.deleted_company')} severity="success" />
      )}
      {openErrorSnackbar && (
        <ToastAction message={i18n.t('app.administrator.error_delete_countryside')} severity="error" />
      )}
      {newCompanyMsg && (
        <ToastAction
          message={
            modalType === 'new'
              ? i18n.t('app.administrator.new_company_toast')
              : i18n.t('app.administrator.edit_company_toast')
          }
          severity="success"
        />
      )}
      <Paper
        sx={{
          borderRadius: 4,
          padding: 2,
          height: 'min-content',
          gridColumn: matchMD ? '' : '1/3',
          gridRow: matchMD ? '' : '1/3',
        }}
        elevation={0}
      >
        <Grid
          item
          xs={10}
          sx={{
            display: 'flex',
            gap: 3,
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box sx={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.companies')}</Box>
          <Box style={{ display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="contained"
              size="small"
              disableElevation
              startIcon={<img src={PlusIcon} alt="plus icon" />}
              sx={configButtonStyles}
              onClick={() => {
                setModalType('new');
                toggleOpenCompanyModal(true);
              }}
            >
              {i18n.t('app.administrator.new_company')}
            </Button>
          </Box>
        </Grid>
        {!isLoading ? (
          <Grid item>
            <TableContainer
              style={{ border: '1px solid #e0e0e0', borderRadius: 5, maxHeight: 500, overflowY: 'auto' }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align={matchSM ? 'center' : 'left'}>{i18n.t('app.name')}</TableCell>
                    <TableCell sx={{ display: matchSM ? 'none' : '' }}>{i18n.t('app.cuit')}</TableCell>
                    <TableCell sx={{ display: matchSM ? 'none' : '' }}>{i18n.t('app.state')}</TableCell>
                    <TableCell align="center">{i18n.t('app.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companies?.map((company) => (
                    <TableRow key={company.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align={matchSM ? 'center' : 'left'}>{company.name}</TableCell>
                      <TableCell sx={{ display: matchSM ? 'none' : '' }}>{company.tax_id}</TableCell>
                      <TableCell sx={{ display: matchSM ? 'none' : '' }}>
                        {company.addresses?.localities.country_l1_name}
                      </TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1.5} flexWrap="nowrap" justifyContent="center">
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => {
                              setModalType('view');
                              setTargetData(company);
                              toggleOpenCompanyModal(true);
                            }}
                          >
                            <img style={{ width: 16, height: 16 }} src={viewIcon} />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            disabled={company.address_id == null}
                            onClick={() => {
                              setModalType('edit');
                              setTargetData(company);
                              toggleOpenCompanyModal(true);
                            }}
                          >
                            <img
                              style={{ width: 16, height: 16 }}
                              src={company.address_id != null ? editIcon : editDisabledIcon}
                            />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => (setTargetData(company), toggleOpenDeleteModal(true))}
                          >
                            <img style={{ width: 16, height: 16 }} src={DeleteActionIcon} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Paper>

      <CompanyViewEditModal
        open={openCompanyModal}
        type={modalType}
        parentFormData={targetData}
        onSuccessTrigger={reFetchCompanyListData}
        enableEditMode={enableEditMode}
        setIsOpen={handleCompanyModalState}
        toggleNewCompanyMsg={toggleNewCompanyMsg}
      />

      <DeleteModal
        toggleDeleteSnackbar={toggleDeleteSnackbar}
        open={openDeleteModal}
        setIsOpen={handleDeleteModalState}
        setIsError={errorToggleDeleteCountryside}
        targetTextRef="la compañía"
        targetName={targetData?.name ?? ''}
        targetID={targetData?.id ?? ''}
        service={deleteCompanyService}
        onSuccessTrigger={reFetchCompanyListData}
        setAction={setActionInCompany}
      />
    </>
  );
}
