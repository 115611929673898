import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { UploadedFileData, FileUploadPayload } from './file-upload.types';

// deprecated service ref.: UploadFile_DEPRECATED
export async function uploadFileService({ body }: FileUploadPayload) {
  const { response, data } = await BACKEND.post<UploadedFileData>(ENDPOINT.FILES, { body });

  return {
    response,
    summary: data,
  };
}
