import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { LabImportProtocolPayload } from './lab-protocol-import.types';
import { transformIntoQueryParamString } from '@utils/service';

export async function importLabProtocolService({ query: rawQuery, body }: LabImportProtocolPayload) {
  const formData = new FormData();
  formData.append('inputFile', body.inputFile);

  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.raw.post(ENDPOINT.LAB_PROTOCOL_IMPORT, { query, body: formData });

  return {
    response,
    summary: data,
  };
}
