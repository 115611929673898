import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import { TotalsDevices } from '@features/report-devices/tablet-devices';
import DashboardDevices from '@features/report-devices/dashboard-devices';
import { boxCircularProgressBarStyle, circularProgressBar } from '@styles/generic-styles';
import { useState, useEffect, ChangeEvent } from 'react';
import SearchIcon from '@assets/svg/search.svg';
import i18n from '../../libs/language';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { useGlobalStore } from '@global-store/use-store';
import { silobagOptionListService } from '@services/domain/page/report/device/silobag-option-list';
import { countrysideOptionListService } from '@services/domain/page/report/device/countryside-option-list';

import { deviceListService } from '@services/domain/page/report/device/report-device-info';

type InputChg = ChangeEvent<HTMLInputElement>;
type SilobagOptionType = Awaited<ReturnType<typeof silobagOptionListService>>['summary'];
type CountrysideOptionType = Awaited<ReturnType<typeof countrysideOptionListService>>['summary'];
type OptionType = SilobagOptionType[0];

export function ReportDevices() {
  const [deviceName, setDeviceName] = useState('');
  const [filteredSilobags, setFilteredSilobags] = useState<SilobagOptionType | undefined>([]);
  const [filteredCountrysides, setCountrysides] = useState<CountrysideOptionType | undefined>([]);

  const { organizationId, filtersReportDevices, setFiltersReportDevices } = useGlobalStore();

  // eslint-disable-next-line
  const { paginationModel, ...restFilters } = filtersReportDevices;
  const hasFilters = Object.values(restFilters).some((s) => s !== '');

  const {
    trigger: getDeviceReportData,
    summary: deviceReportData,
    isLoading: deviceLoading,
  } = useService(deviceListService, {
    fetchOnMount: true,
  });
  const {
    summary: silobagsDevicesData,
    isLoading: silobagsLoading,
    trigger: getSilobags,
  } = useService(silobagOptionListService, {
    fetchOnMount: [{ query: { organization_id: organizationId } }],
  });

  const {
    summary: countrysidesDevicesData,
    isLoading: countrysidesLoading,
    trigger: getCountrysides,
  } = useService(countrysideOptionListService, {
    fetchOnMount: [{ query: { organization_id: organizationId } }],
  });

  useEffect(() => {
    setFilteredSilobags(silobagsDevicesData);
    setCountrysides(countrysidesDevicesData);
  }, [silobagsLoading, countrysidesLoading]);

  useEffectOnUpdate(() => {
    getDeviceReportData();
    getSilobags({ query: { organization_id: organizationId } });
    getCountrysides({ query: { organization_id: organizationId } });
  }, [organizationId]);

  useEffectOnUpdate(() => {
    getDeviceReportData();
  }, [deviceName, filtersReportDevices]);

  // TODO: AGREGAR FUNCIONABILIDAD CUANDO ESTÉ EL ENDPOINT
  const handleSearchDevice = (e: InputChg) => {
    const selectedValue = e.target.value;
    setFiltersReportDevices({ ...filtersReportDevices, lance_number: selectedValue });
  };

  const handleSelectSilobagInstalled = (e: any) => {
    const selectedValue = e.target.value;
    setFiltersReportDevices({ ...filtersReportDevices, installed: selectedValue });
  };

  //Functions Selects Params
  const handleSelectCountryside = (event: any) => {
    const selectedValue = event.target.value;
    setFiltersReportDevices({ ...filtersReportDevices, countrysideId: selectedValue, silobagId: '' });
  };

  const handleSelectSilobag = (event: any) => {
    const selectedValue = event.target.value;
    setFiltersReportDevices({ ...filtersReportDevices, silobagId: selectedValue });
  };

  const handleSelectReport = (event: any) => {
    const selelectedValue = event.target.value;
    setFiltersReportDevices({ ...filtersReportDevices, report: selelectedValue });
  };

  type SilobagOptionListType = Awaited<ReturnType<typeof silobagOptionListService>>['summary'];
  type SilobagListType = SilobagOptionListType[0];

  const siloByCountrysideId =
    filteredSilobags && filteredSilobags.length > 0
      ? filteredSilobags.filter(
          (item: SilobagListType) => item?.countryside_id == filtersReportDevices.countrysideId
        )
      : [];

  const removeFilter = () => {
    setDeviceName('');
    // Estado Global
    setFiltersReportDevices({
      lance_number: '',
      countrysideId: '',
      silobagId: '',
      report: '',
      installed: '',
      paginationModel: {
        page: 0,
        pageSize: 50,
      },
    });
  };

  return (
    <Box style={{ margin: 'auto', maxWidth: '95%' }}>
      {deviceLoading && silobagsLoading && countrysidesLoading ? (
        <Box sx={boxCircularProgressBarStyle}>
          <CircularProgress sx={circularProgressBar} size={50} />
        </Box>
      ) : (
        <Box>
          <Box component="h1" fontWeight={500} style={{ color: '#49454F' }}>
            {i18n.t('app.report_devices.devices_reports_title')}
          </Box>
          {deviceReportData && (
            <TotalsDevices
              total={deviceReportData?.total}
              installed={deviceReportData?.installed}
              uninstalled={deviceReportData?.uninstalled}
              reporting={deviceReportData?.reporting}
              notReporting={deviceReportData?.not_reporting}
            />
          )}
          <section
            id="filters"
            style={{
              marginTop: '2rem',
              display: 'flex',
            }}
          >
            <Stack direction={'row'} spacing={2} width="100%">
              <TextField
                value={filtersReportDevices.lance_number}
                size="small"
                style={{ backgroundColor: '#fff', flex: 1 }}
                label={i18n.t('app.report_devices.search_device_name')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={SearchIcon} alt="search-icon" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearchDevice}
              />
              <FormControl size="small" style={{ flex: 1 }}>
                <InputLabel>{i18n.t('app.report_devices.status')}</InputLabel>
                <Select
                  style={{ border: 'none', backgroundColor: '#fff' }}
                  label={i18n.t('app.report_devices.status')}
                  onChange={handleSelectSilobagInstalled}
                  value={filtersReportDevices.installed}
                >
                  <MenuItem value={''}>
                    <Typography>{i18n.t('app.report_devices.status')}</Typography>
                  </MenuItem>
                  <MenuItem value={'true'}>
                    <Chip
                      size="small"
                      label={i18n.t('app.report_devices.installed')}
                      style={{
                        gap: '10px',
                        borderRadius: '8px',
                        backgroundColor: 'rgba(0, 123, 255, 0.10)',
                        color: '#22A7F0',
                      }}
                    />
                  </MenuItem>
                  <MenuItem value={'false'}>
                    <Chip
                      label={i18n.t('app.report_devices.not_installed')}
                      size="small"
                      color="default"
                      style={{ borderRadius: '10px' }}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" style={{ flex: 1 }}>
                <InputLabel>{i18n.t('app.report_devices.report')}</InputLabel>
                <Select
                  style={{ border: 'none', backgroundColor: '#fff' }}
                  label={i18n.t('app.report_devices.report')}
                  onChange={handleSelectReport}
                  value={filtersReportDevices.report}
                >
                  <MenuItem value={''}>
                    <Typography>{i18n.t('app.report_devices.report')}</Typography>
                  </MenuItem>
                  <MenuItem value={'true'}>
                    <Chip
                      label={i18n.t('app.report_devices.reporting')}
                      size="small"
                      style={{
                        borderRadius: '10px',
                        backgroundColor: 'rgba(107, 170, 0, 0.15)',
                        color: '#73BD00',
                      }}
                    />
                  </MenuItem>
                  <MenuItem value={'false'}>
                    <Chip
                      label={i18n.t('app.report_devices.delayed')}
                      size="small"
                      style={{
                        borderRadius: '8px',
                        backgroundColor: 'rgba(255, 0, 0, 0.1',
                        color: '#FF0000',
                      }}
                    />
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl size="small" style={{ flex: 1 }}>
                <InputLabel>{i18n.t('app.countrysides')}</InputLabel>
                <Select
                  style={{ border: 'none', backgroundColor: '#fff' }}
                  label={i18n.t('app.countrysides')}
                  onChange={handleSelectCountryside}
                  value={filtersReportDevices.countrysideId}
                >
                  <MenuItem value={''}>
                    <Typography>{i18n.t('app.countrysides')}</Typography>
                  </MenuItem>
                  {filteredCountrysides?.map((item: CountrysideOptionType[0]) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" style={{ color: '#000', flex: 1 }}>
                <InputLabel>{i18n.t('app.silobags')}</InputLabel>
                <Select
                  disabled={!filtersReportDevices.countrysideId}
                  style={{ border: 'none', color: '#000', backgroundColor: '#fff' }}
                  label={i18n.t('app.silobags')}
                  onChange={handleSelectSilobag}
                  value={filtersReportDevices.silobagId}
                >
                  <MenuItem value="">
                    <Typography>{i18n.t('app.silobags')}</Typography>
                  </MenuItem>
                  {siloByCountrysideId !== undefined
                    ? siloByCountrysideId &&
                      siloByCountrysideId.length > 0 &&
                      siloByCountrysideId.map((silo: OptionType) => (
                        <MenuItem key={silo?.id} value={silo?.id}>
                          {silo?.name}
                        </MenuItem>
                      ))
                    : filteredSilobags &&
                      filteredSilobags.length > 0 &&
                      filteredSilobags.map((silo: OptionType) => (
                        <MenuItem key={silo?.id} value={silo?.id}>
                          {silo?.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
              <Button
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                  paddingInline: 2,
                  textTransform: 'uppercase',
                  visibility: hasFilters ? '' : 'hidden',
                  color: '#FF0000',
                  '&:hover': { background: '#FF000022' },
                }}
                onClick={removeFilter}
                startIcon={<img src={DeleteIcon} alt="delete-icon" style={{ width: 14, height: 14 }} />}
              >
                {i18n.t('app.clear_filters')}
              </Button>
            </Stack>
          </section>
          <DashboardDevices
            devices={deviceReportData?.devices}
            paginationModel={filtersReportDevices?.paginationModel}
            setPaginationModel={(fn) => {
              const paginationModel = fn(filtersReportDevices.paginationModel);
              setFiltersReportDevices({ ...filtersReportDevices, paginationModel });
            }}
            isLoading={deviceLoading}
            rowCount={deviceReportData?.total ?? 0}
          />
        </Box>
      )}
    </Box>
  );
}
