import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CountrysidePermissionPayload, CountrysidePermissionData } from './countryside-permission-get.types';
import { transformIntoQueryParamString } from '@utils/service';
// ✅ implementado
export async function GetCountrysidePermissionService({ query: rawQuery }: CountrysidePermissionPayload) {
  const query = transformIntoQueryParamString(rawQuery);
  const { response, data } = await BACKEND.get<CountrysidePermissionData>(ENDPOINT.COUNTRYSIDE_PERMISSIONS, { query });
  return {
    response,
    summary: data,
  };
}
