import { ModalIOF } from '@ui/modal/modal';
import i18n from '../../../../libs/language';
import { useService } from '@hooks/use-service';
import { useEffectOnUpdate } from '@hooks/core';
import {
  Stack,
  Typography,
  Button,
  CircularProgress,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
} from '@mui/material';
import { configButtonStylesOutlined } from '@styles/generic-styles';
import { BaseTable } from '@ui/table';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatDate } from '@utils/helper';
import Download from '@assets/svg/donwload-new.svg';
import MoreSvg from '@assets/svg/three-points.svg';
import { useState } from 'react';
import { silobagCMCService } from '@services/domain/base/silobag/file/cmc';
import { silobagDataDetailService } from '@services/domain/base/silobag/base/silobag-data-detail';

type SilobagDetailParams = Parameters<typeof silobagDataDetailService>[0];

type CMCModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
  siloId: string;
  isLoadingData: boolean;
  triggerData: (siloId: string) => void;
  linkCMCInitial?: string;
  getSiloData: (query: SilobagDetailParams) => void;
};

export const CmcModal = ({
  open,
  setOpen,
  data,
  siloId,
  isLoadingData,
  triggerData,
  linkCMCInitial,
  getSiloData,
}: CMCModalProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const openAnchorEl = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>, row?: any) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const dataReverse = Array.isArray(data) ? [...data].reverse() : [];
  const {
    trigger: getPdf,
    summary: dataCMC,
    isLoading: isLoadingPdf,
    isSuccess: isSuccessPdf,
  } = useService(silobagCMCService, {
    onSuccess: () => triggerData(siloId ?? 'not-found'),
  });

  const downloadCMC = (path?: string) => {
    const link = document.createElement('a');
    link.href = path ?? '';
    link.download = `${path}`;
    link.click();
  };

  useEffectOnUpdate(() => {
    if (!isLoadingPdf && dataCMC) {
      const link = document.createElement('a');
      link.href = dataCMC.file_path;
      link.download = `${dataCMC.file_path}`;
      link.click();
    }
  }, [isLoadingPdf]);

  const copyAndCloseMenu = (hash: string) => {
    navigator.clipboard.writeText(hash);
    handleMenuClose();
  };

  const handleClose = () => {
    setOpen(false);
    if (isSuccessPdf) {
      getSiloData({ query: siloId ?? 'not-found' });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: i18n.t('app.modal_cmc.date'),
      flex: 1,
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'id',
      headerName: i18n.t('app.modal_cmc.type'),
      flex: 1,
      renderCell: (params) => {
        const value = data && data[0].id === params.value ? 'Inicial' : 'Actualizado';
        return value;
      },
    },
    {
      field: 'transaction_hash',
      headerName: 'Tx Hash',
      flex: 3,
      renderCell: (params) => {
        return (
          <Box>
            <Link
              href={`https://optimistic.etherscan.io/tx/${params.value}`}
              target="_blank"
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{
                  color: 'info.main',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  '&:hover': { textDecoration: 'none' },
                }}
              >
                {params.value}
              </Typography>
            </Link>
          </Box>
        );
      },
    },
    {
      field: 'file_path',
      headerName: '',
      flex: 0.5,
      renderCell: (params) => {
        const file_path = params.value;
        const row = params.row;
        return (
          <Stack direction={'row'}>
            <IconButton
              onClick={() => downloadCMC(file_path)}
              sx={{ color: 'info.main', padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            >
              {file_path && <img src={Download} alt="download" />}
            </IconButton>
            <Box>
              <IconButton
                id="basic-button"
                sx={{ color: 'info.main', padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
                aria-controls={openAnchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAnchorEl ? 'true' : undefined}
                onClick={(e) => handleMenu(e, row)}
              >
                {file_path && <img src={MoreSvg} alt="download" />}
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openAnchorEl}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  sx={{ color: '#22A7F0', fontSize: 12 }}
                  onClick={() => copyAndCloseMenu(selectedRow?.hash)}
                >
                  {i18n.t('app.modal_cmc.copy_document_hash')}
                </MenuItem>
                <MenuItem
                  sx={{ color: '#22A7F0', fontSize: 12 }}
                  onClick={() => copyAndCloseMenu(selectedRow?.transaction_hash)}
                >
                  {i18n.t('app.modal_cmc.copy_transaction_hash')}
                </MenuItem>
                <MenuItem
                  sx={{ color: '#22A7F0', fontSize: 12 }}
                  onClick={() => downloadCMC(selectedRow?.certificate_url)}
                >
                  {i18n.t('app.modal_cmc.download_certificate')}
                </MenuItem>
              </Menu>
            </Box>
          </Stack>
        );
      },
    },
  ];

  return (
    <ModalIOF open={open} ModalIsOpen={setOpen}>
      <ModalIOF.Header>
        <Typography variant="h5" fontWeight={500} component="div" sx={{ margin: '20px' }}>
          {i18n.t('app.modal_cmc.register_cmc')}
        </Typography>
      </ModalIOF.Header>
      <Stack direction={'row'} spacing={1}>
        <Button
          onClick={() => {
            linkCMCInitial
              ? downloadCMC(linkCMCInitial)
              : getPdf({ query: { id: siloId, blockchain: true } });
          }}
          disabled={isLoadingPdf && !linkCMCInitial}
          size="medium"
          variant="outlined"
          sx={{ ...configButtonStylesOutlined, width: '250px' }}
        >
          {i18n.t('app.modal_cmc.download_cmc_initial')}
        </Button>
        <Button
          onClick={() => getPdf({ query: { id: siloId, blockchain: true, silobagHistory: true } })}
          disabled={isLoadingPdf}
          size="medium"
          variant="outlined"
          sx={{ ...configButtonStylesOutlined, width: '250px' }}
        >
          {isLoadingPdf ? <CircularProgress size={20} /> : i18n.t('app.modal_cmc.generate_cmc_update')}
        </Button>
      </Stack>
      <ModalIOF.Body>
        <BaseTable
          columns={columns}
          rows={dataReverse ?? []}
          loading={isLoadingData}
          initialPageSize={5}
          noInternalBorders
        />
        <Stack direction={'row'} justifyContent={'flex-end'}>
          <Button size="medium" onClick={handleClose}>
            {i18n.t('app.close')}
          </Button>
        </Stack>
      </ModalIOF.Body>
    </ModalIOF>
  );
};
