import { TextField, Button, Stack, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { styledTextField } from '@styles/generic-styles';
import { PATH } from '@data/enums';
import { useService } from '@hooks/use-service';
import { useState } from 'react';
import { useGlobalStore } from '@global-store/use-store';
import { ToastAction } from '@ui/toast/toast-action';
import CircularProgress from '@mui/material/CircularProgress';
import { verifyCredentialService } from '@services/domain/auth/verify-auth-credential';

type VerifyCrendentialBody = Parameters<typeof verifyCredentialService>[0]['body'];

export function VerifyResetAccountForm() {
  const { email } = useGlobalStore();

  const [formVerify, setFormVerify] = useState<VerifyCrendentialBody>({
    username: email !== undefined ? email : '',
    verification_code: null,
  });

  const navigate = useNavigate();

  const {
    trigger: sendVerify,
    isError,
    isLoading,
  } = useService(verifyCredentialService, {
    onSuccess: () => {
      navigate(PATH.RESET);
    },
  });

  const handleVerify = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    sendVerify({ body: formVerify });
  };

  return (
    <Stack direction={'column'} spacing={{ xs: 3, md: 2 }} mt={2}>
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Typography variant="h4">Verificar Token</Typography>
      </Box>
      <TextField
        required
        id="email"
        label="Correo Electronico"
        variant="outlined"
        disabled={email !== undefined ? true : false}
        size="small"
        defaultValue={email !== undefined ? email : ''}
        sx={styledTextField}
        onChange={(e) =>
          setFormVerify({ ...formVerify, username: email !== undefined ? email : e.target.value })
        }
      />
      <TextField
        required
        id="verification_code"
        label="Código"
        variant="outlined"
        size="small"
        sx={styledTextField}
        onChange={(e) => setFormVerify({ ...formVerify, verification_code: +e.target.value })}
      />
      <Button
        variant="contained"
        sx={{ width: '400px', color: 'white' }}
        disableElevation
        onClick={handleVerify}
        type="submit"
      >
        {isLoading ? <CircularProgress sx={{ marginLeft: '10px', color: 'white' }} size={24} /> : 'Enviar'}
      </Button>

      {isError && (
        <ToastAction
          message="Error al Verificar Token, verifique que los datos sean correctos"
          severity="error"
        />
      )}
    </Stack>
  );
}
