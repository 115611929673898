import { I18n } from "i18n-js";
import es from "./es";
import pt from "./pt";

const i18n = new I18n({
  es,
  pt,
});

i18n.enableFallback = true;

i18n.defaultLocale = "es";
i18n.locale = "es";

export default i18n;