import { useGlobalStore } from '@global-store/use-store';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import { MouseEventHandler, PropsWithChildren, useEffect, useState } from 'react';
import ChevronDownIcon from '@assets/svg/core/chevron-down-black.svg';
import PendingIcon from '@assets/svg/domain/alert-states/exclamation.svg';
import RevisionIcon from '@assets/svg/domain/alert-states/reverse.svg';
import ResolveIcon from '@assets/svg/domain/alert-states/check.svg';
import DeleteIcon from '@assets/svg/domain/alert-states/crossed-bell.svg';
import i18n from '../../../libs/language';

import { updateAlertStatusService } from '@services/domain/alert/alert-status-edition';
import { listGlobalAlertService } from '@services/domain/alert/global-alert-list';

type SilobagSummary = Awaited<ReturnType<typeof listGlobalAlertService>>['summary'][0];
type AlertSummary = SilobagSummary['alerts'][0];

type AlertCardProps = {
  alert: AlertSummary;
  deviceName?: string;
  trigger: () => void;
  hidden?: boolean;
  offset?: number;
};

export function AlertCard({ alert, deviceName, trigger, hidden = false, offset = 0 }: AlertCardProps) {
  // TODO: refactorizar a una carpeta común
  const METRICS_CODES = {
    CO2: 3,
    MOV: 4,
    HEG: 5,
    BAT: 6,
  };

  const [measurementValue, setMeasurementValue] = useState<number | string>('');

  useEffect(() => {
    // TODO: consultar cómo debe ser el display cuando estas propiedades sean null o undefined
    if (alert.metrics.id === METRICS_CODES.CO2) setMeasurementValue(alert.measurements.co2_percent!);
    if (alert.metrics.id === METRICS_CODES.HEG) setMeasurementValue(alert.measurements.heg!);
    if (alert.metrics.id === METRICS_CODES.BAT) setMeasurementValue(alert.measurements.battery_percent!);
  }, [alert]);

  const DISPLAY = {
    VALUE: measurementValue,
    DATE: dayjs(alert.created_at).format('D/M/YYYY — HH:mm'),
    SHORT_NAME: alert.metrics.short_name,
  };

  const isMovAlert = alert.metrics.id === METRICS_CODES.MOV;

  return (
    <Paper
      style={{
        background: 'linear-gradient(180deg, rgba(235,234,239,1) 33%, rgba(243,242,247,1) 66%)',
        fontSize: 12,
        borderRadius: 4,
        // Hidden change properties
        width: hidden ? '94%' : '98%',
        position: hidden ? 'absolute' : 'relative',
        zIndex: hidden ? 100 - offset * 3 : 200,
        top: hidden ? `${58 + offset * 3}px` : '',
        left: hidden ? `${12 + offset * 3}px` : '',
      }}
    >
      <Stack direction="row" style={{ width: '100%' }}>
        <Box style={{ width: '8px', background: alert.colors.hex, borderRadius: '4px 0 0 4px' }} />
        <Stack p={1.4} style={{ width: '100%' }} spacing={0.75} visibility={hidden ? 'hidden' : 'visible'}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box style={{ width: '100%', minWidth: 'max-content' }}>{DISPLAY.DATE}</Box>
            {!isMovAlert ? (
              <Box style={{ minWidth: 'max-content' }}>
                <span style={{ color: '#929292' }}>{i18n.t('app.navbar.alert_value')} </span>
                <span style={{ fontWeight: 600 }}>{DISPLAY.VALUE} %</span>
                {/* <span style={{ fontWeight: 600 }}>-</span> */}
              </Box>
            ) : null}
            <Chip
              size="small"
              label={DISPLAY.SHORT_NAME}
              style={{
                color: alert.colors.hex === '#FFDD00' ? 'black' : 'white',
                background: alert.colors.hex,
                fontWeight: 600,
                borderRadius: 7,
              }}
            />
          </Stack>
          {deviceName ? (
            <Box>
              <span style={{ color: '#929292', marginRight: 7 }}>{i18n.t('app.navbar.device')} </span>
              <span style={{ fontSize: 14, fontWeight: 600 }}>{deviceName}</span>
            </Box>
          ) : null}
          <Stack direction="row" alignItems="center" spacing={2}>
            <Box component="span" style={{ color: '#929292' }}>
              {i18n.t('app.status')}
            </Box>
            <AlertActionsMenu alertID={alert.id} alertState={alert.alert_actions_types} trigger={trigger} />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

type AlertActionsMenuProps = {
  alertID: AlertSummary['id'];
  alertState: AlertSummary['alert_actions_types'];
  trigger: () => void;
};

function AlertActionsMenu({ alertID, alertState, trigger }: AlertActionsMenuProps) {
  /* Dropdown Menu for Alert Actions */
  const [dropdownMenuData, setDropdownMenuData] = useState<{
    open: boolean;
    anchorEl: HTMLButtonElement | null;
  }>({
    open: false,
    anchorEl: null,
  });
  const handleDropdownMenuOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setDropdownMenuData({ open: true, anchorEl: event.currentTarget });
  };
  const handleDropdownMenuClose = () => {
    setDropdownMenuData({ open: false, anchorEl: null });
  };

  /* Change Alert State Modal */
  const [modalData, setModalData] = useState<{
    open: boolean;
    state: 1 | 2 | 3 | 5;
  }>({
    open: false,
    state: alertState.id ?? 1,
  });
  const handleModalOpen: MouseEventHandler<HTMLLIElement> = (event) => {
    const target = event.target as HTMLLIElement & { value: 1 | 2 | 3 | 5 };
    const { value } = target;
    setModalData({ open: true, state: value });
  };
  const handleModalClose = () => {
    setModalData({ open: false, state: alertState.id ?? 1 });
    trigger();
  };

  const STATES = {
    1: i18n.t('app.alert.state.pending'),
    2: i18n.t('app.alert.state.review'),
    3: i18n.t('app.alert.state.resolve'),
    5: i18n.t('app.alert.state.delete'),
  };

  const optionStylesById = (id: 1 | 2 | 3 | 5) => ({
    background: modalData.open && modalData.state === id ? '#9bc45c' : '',
    '&:hover': { background: '#c3d7a5' },
  });

  return (
    <>
      <Button
        variant="outlined"
        style={{
          width: '100%',
          padding: 2,
          background: dropdownMenuData.open ? '#9bc45c' : '',
          border: '1px solid #C5BFBF',
          borderRadius: 5,
          textTransform: 'none',
        }}
        sx={{
          color: 'black',
          background: '#FFF',
          '&:hover': { background: '#c3d7a5' },
        }}
        onClick={handleDropdownMenuOpen}
        startIcon={<img src={PendingIcon} />}
        endIcon={<img src={ChevronDownIcon} />}
        size="small"
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 400,
            width: '80%',
            height: 24,
            textAlign: 'left',
          }}
        >
          {STATES[alertState.id]}
        </Box>
      </Button>
      <Menu
        open={dropdownMenuData.open}
        onClose={handleDropdownMenuClose}
        anchorEl={dropdownMenuData.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{ width: 180 }}>
          <MenuItem
            sx={optionStylesById(1)}
            value={1}
            disabled={alertState.id === 1}
            onClick={handleModalOpen}
          >
            <img src={PendingIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.pending')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(2)}
            value={2}
            disabled={alertState.id === 2}
            onClick={handleModalOpen}
          >
            <img src={RevisionIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.review')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(3)}
            value={3}
            disabled={alertState.id === 3}
            onClick={handleModalOpen}
          >
            <img src={ResolveIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.resolve')}
          </MenuItem>
          <MenuItem
            sx={optionStylesById(5)}
            value={5}
            disabled={alertState.id === 5}
            onClick={handleModalOpen}
          >
            <img src={DeleteIcon} style={{ marginRight: 7 }} />
            {i18n.t('app.alert.state.delete')}
          </MenuItem>
        </Box>
      </Menu>
      <ChangeAlertStateModal
        alertID={alertID}
        isOpen={modalData.open}
        newState={modalData.state}
        close={handleModalClose}
      />
    </>
  );
}

type ChangeAlertStateModalProps = {
  alertID: number;
  isOpen: boolean;
  newState: 1 | 2 | 3 | 5;
  close: () => void;
};

function ChangeAlertStateModal({ alertID, isOpen, newState, close }: ChangeAlertStateModalProps) {
  const {
    trigger: createNewAction, //
    isLoading,
  } = useService(updateAlertStatusService, { onSuccess: close });

  const { userID } = useGlobalStore();

  const [description, setDescription] = useState('');

  const submit = () => {
    const bodyForSingleAction = {
      alert_id: alertID,
      user_id: userID!,
      alert_actions_type_id: newState,
      description,
    };

    createNewAction({
      body: bodyForSingleAction,
    });
  };

  const isDeleteRequest = newState === 5;

  const STATES = {
    1: i18n.t('app.alert.state.pending'),
    2: i18n.t('app.alert.state.review'),
    3: i18n.t('app.alert.state.resolve'),
    5: i18n.t('app.alert.state.delete'),
  };

  return (
    <>
      <MyModal isOpen={isOpen} close={close}>
        <Box style={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.alert.modal.title')}</Box>
        <Divider />
        <Stack alignItems="center" spacing={2}>
          <Box>
            {i18n.t('app.alert.modal.single.question')}
            <span style={{ fontWeight: 600, color: isDeleteRequest ? 'red' : '#8dbd3a' }}>
              {STATES[newState]}
            </span>
            ?
          </Box>
          <TextField
            style={{ color: '#C5BFBF' }}
            fullWidth
            multiline
            rows={5}
            placeholder={i18n.t('app.alert.modal.placeholder.main')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
            }}
            onClick={close}
          >
            {i18n.t('app.cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            style={{
              color: '#FFF',
              width: 115,
              height: 36.5,
              borderRadius: 100,
              textTransform: 'none',
            }}
            color={isDeleteRequest ? 'error' : 'primary'}
            onClick={submit}
          >
            {isLoading ? (
              <CircularProgress
                color={isDeleteRequest ? 'error' : 'primary'}
                style={{ width: 24, height: 24 }}
              />
            ) : (
              i18n.t('app.save')
            )}
          </Button>
        </Stack>
      </MyModal>
    </>
  );
}

type ModalProps = {
  isOpen: boolean;
  close: () => void;
};

function MyModal({ isOpen, close, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    close();
  };

  return (
    <Modal open={isOpen} hideBackdrop onClose={handleClose}>
      <Fade in={isOpen} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
