//import { ModalIOF } from '@ui/modal/modal';
//import { FormIOF } from '@ui/form';
import { useGlobalStore } from '@global-store/use-store';
import { useEffectOnUpdate } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';

import { ChangeEvent, PropsWithChildren, useState } from 'react';
import i18n from '../../../libs/language';

import { listCountriesL0Service } from '@services/domain/location/country-L0';
import { listCountriesL1ForL0Service } from '@services/domain/location/country-L1';
import { listCountriesL2ForL1Service } from '@services/domain/location/country-L2';
import { listLocalityForL2Service } from '@services/domain/location/locality';
import { createCompanyService } from '@services/domain/base/company/company-creation';
import { updateCompanyDataService } from '@services/domain/base/company/company-edition';

type AdminModalProps = {
  open: boolean;
  type: 'new' | 'view' | 'edit';
  parentFormData?: any;
  onSuccessTrigger: () => void;
  enableEditMode: () => void;
  setIsOpen: (value: boolean) => void;
  toggleNewCompanyMsg: (value: boolean) => void;
};

type SelChg = SelectChangeEvent;
type InputChg = ChangeEvent<HTMLInputElement>;

export function CompanyViewEditModal({
  open,
  type,
  parentFormData,
  onSuccessTrigger,
  enableEditMode,
  setIsOpen,
  toggleNewCompanyMsg,
}: AdminModalProps) {
  const { organizationId } = useGlobalStore();

  const handleClose = () => {
    setIsOpen(false);
    setFormData(initData);
    setPhoneArea(initPhoneArea);
    setErrorData(initErrorData);
  };

  const closeOnSuccess = () => {
    handleClose();
    onSuccessTrigger();
    toggleNewCompanyMsg(true);
  };

  const {
    trigger: createInBackend, //
    isLoading: isCreationLoading, //
  } = useService(createCompanyService, { onSuccess: closeOnSuccess });
  const {
    trigger: updateInBackend, //
    isLoading: isUpdateLoading, //
  } = useService(updateCompanyDataService, { onSuccess: closeOnSuccess });

  const readonly = type === 'view';

  const initData = {
    country: type !== 'new' ? parentFormData?.addresses?.localities?.countries_l0?.id : '',
    tax_id: type !== 'new' ? parentFormData.tax_id : '',
    name: type !== 'new' ? parentFormData.name : '',
    legal_name: type !== 'new' ? parentFormData.legal_name : '',
    phone_area: type !== 'new' ? parentFormData.phone.substring(0, 2) : '54',
    phone: type !== 'new' ? parentFormData.phone.substring(2) : '',
    email: type !== 'new' ? parentFormData.email : '',
    address: {
      zip_code: type !== 'new' ? parentFormData?.addresses?.zip_code : '',
      street_name: type !== 'new' ? parentFormData?.addresses?.street_name : '',
      street_number: type !== 'new' ? parentFormData.addresses?.street_number : '',
      floor: type !== 'new' ? parentFormData.addresses?.floor : '',
      apartment: type !== 'new' ? parentFormData.addresses?.apartment : '',
      province: type !== 'new' ? parentFormData?.addresses?.localities?.countries_l1?.id : '',
      city: type !== 'new' ? parentFormData?.addresses?.localities?.countries_l2?.id : '',
      locality_id: type !== 'new' ? parentFormData?.addresses?.localities?.id : '',
    },
  };

  const initErrorData = {
    country: '',
    tax_id: '',
    name: '',
    legal_name: '',
    phone: '',
    email: '',
    address: {
      zip_code: '',
      street_name: '',
      street_number: '',
      province: '',
      city: '',
      locality_id: '',
    },
  };

  const phoneAreaDetails = {
    54: {
      flag: '🇦🇷',
      placeholder: '+54 ',
    },
    55: {
      flag: '🇧🇷',
      placeholder: '+55 ',
    },
  };
  const initPhoneArea = '54';

  const [formData, setFormData] = useState(initData);
  useEffectOnUpdate(() => setFormData(initData), [parentFormData, type]);
  const [errorData, setErrorData] = useState(initErrorData);
  const [phoneArea, setPhoneArea] = useState<'54' | '55'>(initPhoneArea);

  const {
    isLoading: isLoadingL0, //
    summary: summaryL0, //
  } = useService(listCountriesL0Service, { fetchOnMount: true });

  const {
    trigger: triggerL1,
    isLoading: isLoadingL1,
    summary: summaryL1,
  } = useService(listCountriesL1ForL0Service);
  useEffectOnUpdate(() => {
    if (type !== 'new' && initData.country) {
      const query = { L0ID: initData.country };
      triggerL1({ query });
    }
  }, [type, parentFormData]);

  const {
    trigger: triggerL2,
    isLoading: isLoadingL2,
    summary: summaryL2,
  } = useService(listCountriesL2ForL1Service);
  useEffectOnUpdate(() => {
    if (type !== 'new' && initData.address.province) {
      const query = { L1ID: initData.address.province };
      triggerL2({ query });
    }
  }, [type, parentFormData]);

  const {
    trigger: triggerLocality,
    isLoading: isLocalityLoading,
    summary: summaryLocality,
  } = useService(listLocalityForL2Service);
  useEffectOnUpdate(() => {
    if (type !== 'new' && initData.address.city) {
      const query = {
        L0ID: initData.country,
        L1ID: initData.address.province,
        L2ID: initData.address.city,
      };
      triggerLocality({ query });
    }
  }, [type, parentFormData]);

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
    ALPHANUMERIC_EXT: (value: string) => !/^[\w0-9\s-_&.áàâãéêíóôõúñç]*$/i.test(value),
    EMAIL: 'missing',
    IS_EMAIL: (value: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
  };

  const rootDataChange = (key: string, value: string) => setFormData((prev) => ({ ...prev, [key]: value }));
  const addressDataChange = (key: string, value: string) =>
    setFormData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [key]: value,
      },
    }));

  const rootDataError = (key: string, value: string) => setErrorData((prev) => ({ ...prev, [key]: value }));
  const addressDataError = (key: string, value: string) =>
    setErrorData((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [key]: value,
      },
    }));

  const HANDLERS = {
    country: (e: SelChg) => {
      rootDataError('country', '');
      rootDataChange('country', e.target.value);
      const query = { L0ID: parseInt(e.target.value, 10) };
      triggerL1({ query });

      /* Reset the other options that are dependant as well */
      addressDataError('province', '');
      addressDataChange('province', '');
      addressDataError('city', '');
      addressDataChange('city', '');
      addressDataError('locality_id', '');
      addressDataChange('locality_id', '');
    },
    tax_id: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('tax_id', 'Solo se aceptan números.');
        return;
      }
      rootDataError('tax_id', '');
      rootDataChange('tax_id', input);
    },
    name: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-), guiones bajos (_) y ampersand (&)';
        rootDataError('name', errorMsg);
        return;
      }
      rootDataError('name', '');
      rootDataChange('name', input);
    },
    legal_name: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ALPHANUMERIC_EXT(input)) {
        const errorMsg =
          'Solo se aceptan caracteres alfanuméricos, espacios, guiones (-), guiones bajos (_) y ampersand (&)';
        rootDataError('legal_name', errorMsg);
        return;
      }
      rootDataError('legal_name', '');
      rootDataChange('legal_name', input);
    },
    phone_area: (event: SelChg) => setPhoneArea(event.target.value as '54' | '55'),
    phone: (event: InputChg) => {
      const input = event.target.value;
      if (VALIDATION.ONLY_NUMS(input)) {
        rootDataError('phone', 'Solo se aceptan números.');
        return;
      }
      rootDataError('phone', '');
      rootDataChange('phone', input);
    },
    email: (e: InputChg) => {
      rootDataError('email', '');
      rootDataChange('email', e.target.value);
      if (!VALIDATION.IS_EMAIL(e.target.value)) {
        rootDataError('email', i18n.t('app.company_modal.email_format'));
      }
    },
    address: {
      zip_code: (event: InputChg) => {
        const input = event.target.value;
        if (VALIDATION.ONLY_NUMS(input)) {
          addressDataError('zip_code', 'Solo se aceptan números.');
          return;
        }
        addressDataError('zip_code', '');
        addressDataChange('zip_code', input);
      },
      street_name: (e: InputChg) => {
        addressDataError('street_name', '');
        addressDataChange('street_name', e.target.value);
      },
      street_number: (event: InputChg) => {
        const input = event.target.value;
        if (VALIDATION.ONLY_NUMS(input)) {
          addressDataError('street_number', 'Solo se aceptan números.');
          return;
        }
        addressDataError('street_number', '');
        addressDataChange('street_number', event.target.value);
      },
      floor: (e: InputChg) => {
        addressDataError('floor', '');
        addressDataChange('floor', e.target.value);
      },
      apartment: (e: InputChg) => {
        addressDataError('apartment', '');
        addressDataChange('apartment', e.target.value);
      },
      province: (e: SelChg) => {
        addressDataError('province', '');
        addressDataChange('province', e.target.value);
        const query = { L1ID: parseInt(e.target.value, 10) };
        triggerL2({ query });

        /* Reset the other options that are dependant as well */
        addressDataError('city', '');
        addressDataChange('city', '');
        addressDataError('locality_id', '');
        addressDataChange('locality_id', '');
      },
      city: (e: SelChg) => {
        addressDataError('city', '');
        addressDataChange('city', e.target.value);
        triggerLocality({
          query: {
            L0ID: formData.country,
            L1ID: formData.address.province,
            L2ID: parseInt(e.target.value, 10),
          },
        });

        /* Reset the other options that are dependant as well */
        addressDataError('locality_id', '');
        addressDataChange('locality_id', '');
      },
      locality_id: (e: SelChg) => {
        addressDataError('locality_id', '');
        addressDataChange('locality_id', e.target.value);
      },
    },
  };

  const submit = () => {
    const errorMsg = i18n.t('app.must_field');
    let error = false;
    if (formData.country === '') (error = true), rootDataError('country', errorMsg);
    if (formData.tax_id === '') (error = true), rootDataError('tax_id', errorMsg);
    if (formData.name === '') (error = true), rootDataError('name', errorMsg);
    if (formData.legal_name === '') (error = true), rootDataError('legal_name', errorMsg);
    if (formData.phone === '') (error = true), rootDataError('phone', errorMsg);
    if (formData.email === '') (error = true), rootDataError('email', errorMsg);
    if (formData.address.street_name === '') (error = true), addressDataError('street_name', errorMsg);
    if (formData.address.street_number === '') (error = true), addressDataError('street_number', errorMsg);
    if (formData.address.province === '') (error = true), addressDataError('province', errorMsg);
    if (formData.address.city === '') (error = true), addressDataError('city', errorMsg);
    if (formData.address.locality_id === '') (error = true), addressDataError('locality_id', errorMsg);

    if (error) return;

    const body = {
      name: formData.name,
      legal_name: formData.legal_name,
      tax_id: formData.tax_id,
      phone: `${phoneArea}${formData.phone}`,
      email: formData.email,
      organization_id: organizationId!,
      address: {
        id: undefined,
        floor: formData.address.floor,
        zip_code: parseInt(formData.address.zip_code, 10),
        apartment: formData.address.apartment,
        street_name: formData.address.street_name,
        street_number: parseInt(formData.address.street_number, 10),
        locality_id: formData.address.locality_id,
      },
    };

    if (type === 'new') createInBackend({ body });
    if (type === 'edit') {
      body.address.id = parentFormData.addresses?.id;
      updateInBackend({
        query: parentFormData.id,
        body,
      });
    }
  };

  return (
    <MyModal open={open} onClose={handleClose}>
      <Box style={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.administrator.new_company')}</Box>

      <FormControl
        fullWidth
        size="small"
        required
        disabled={readonly || !summaryL0?.length || isLoadingL0}
        error={Boolean(errorData.country)}
      >
        <InputLabel>País</InputLabel>
        <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
          <Select
            value={summaryL0 ? formData.country ?? '' : ''}
            label="País"
            onChange={HANDLERS.country}
            style={{ flex: 1 }}
          >
            {summaryL0?.map((country) => (
              <MenuItem key={country.id} value={country.id}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
          {isLoadingL0 ? <CircularProgress size={24} /> : null}
        </Stack>
        {errorData.country ? <FormHelperText>{errorData.country}</FormHelperText> : null}
      </FormControl>
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.cuit')}
        helperText={errorData.tax_id}
        error={Boolean(errorData.tax_id)}
        required
        fullWidth
        disabled={readonly}
        onChange={HANDLERS.tax_id}
        value={formData.tax_id}
      />
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.name')}
        helperText={errorData.name}
        error={Boolean(errorData.name)}
        required
        fullWidth
        disabled={readonly}
        onChange={HANDLERS.name}
        value={formData.name}
      />
      <TextField
        variant="outlined"
        size="small"
        label={i18n.t('app.company_modal.legal_name')}
        helperText={errorData.legal_name}
        error={Boolean(errorData.legal_name)}
        required
        fullWidth
        disabled={readonly}
        onChange={HANDLERS.legal_name}
        value={formData.legal_name}
      />
      <Stack direction="row" spacing={1}>
        <FormControl size="small" required disabled={readonly} style={{ width: '144px' }}>
          <InputLabel>Área</InputLabel>
          <Select
            label="Área"
            renderValue={() => <div>{phoneAreaDetails[phoneArea]?.flag}</div>}
            value={phoneArea}
            onChange={HANDLERS.phone_area}
          >
            <MenuItem value={54}>
              🇦🇷 Argentina <span style={{ color: '#a7a7a7' }}>&nbsp;(+54)</span>
            </MenuItem>
            <MenuItem value={55}>
              🇧🇷 Brasil <span style={{ color: '#a7a7a7' }}>&nbsp;(+55)</span>
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.phone')}
          helperText={errorData.phone}
          error={Boolean(errorData.phone)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.phone}
          value={formData.phone}
          InputProps={{
            startAdornment: phoneAreaDetails[phoneArea] ? (
              <Box style={{ width: 44 }}>{phoneAreaDetails[phoneArea]?.placeholder}</Box>
            ) : null,
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          label="E-mail"
          helperText={errorData.email}
          error={Boolean(errorData.email)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.email}
          value={formData.email}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.company_modal.zip_code')}
          helperText={errorData.address.zip_code}
          error={Boolean(errorData.address.zip_code)}
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.address.zip_code}
          value={formData.address.zip_code}
        />
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.company_modal.street')}
          helperText={errorData.address.street_name}
          error={Boolean(errorData.address.street_name)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.address.street_name}
          value={formData.address.street_name}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.company_modal.number')}
          helperText={errorData.address.street_number}
          error={Boolean(errorData.address.street_number)}
          required
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.address.street_number}
          value={formData.address.street_number}
        />
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.company_modal.floor')}
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.address.floor}
          value={formData.address.floor}
        />
        <TextField
          variant="outlined"
          size="small"
          label={i18n.t('app.company_modal.departament')}
          fullWidth
          disabled={readonly}
          onChange={HANDLERS.address.apartment}
          value={formData.address.apartment}
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <FormControl
          fullWidth
          size="small"
          required
          disabled={readonly || !summaryL1?.length || isLoadingL1}
          error={Boolean(errorData.address.province)}
        >
          <InputLabel>{i18n.t('app.state')}</InputLabel>
          <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
            <Select
              value={summaryL1 ? formData.address.province : ''}
              label={i18n.t('app.state')}
              onChange={HANDLERS.address.province}
              style={{ flex: 1 }}
            >
              {summaryL1?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {isLoadingL1 ? <CircularProgress size={24} /> : null}
          </Stack>
          {errorData.address.province ? <FormHelperText>{errorData.address.province}</FormHelperText> : null}
        </FormControl>
        <FormControl
          fullWidth
          size="small"
          required
          disabled={readonly || !summaryL2?.length || isLoadingL2}
          error={Boolean(errorData.address.city)}
        >
          <InputLabel>{i18n.t('app.company_modal.city')}</InputLabel>
          <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
            <Select
              value={summaryL2 ? formData.address.city : ''}
              label={i18n.t('app.company_modal.city')}
              onChange={HANDLERS.address.city}
              style={{ flex: 1 }}
            >
              {summaryL2?.map((country) => (
                <MenuItem key={country.id} value={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {isLoadingL2 ? <CircularProgress size={24} /> : null}
          </Stack>
          {errorData.address.city ? <FormHelperText>{errorData.address.city}</FormHelperText> : null}
        </FormControl>
      </Stack>
      <FormControl
        fullWidth
        size="small"
        required
        disabled={readonly || !summaryLocality?.length || isLocalityLoading}
        error={Boolean(errorData.address.locality_id)}
      >
        <InputLabel>{i18n.t('app.company_modal.locality')}</InputLabel>
        <Stack direction="row" spacing={1} flexWrap="nowrap" alignItems="center">
          <Select
            value={summaryLocality ? formData.address.locality_id : ''}
            label={i18n.t('app.company_modal.locality')}
            onChange={HANDLERS.address.locality_id}
            style={{ flex: 1 }}
          >
            {summaryLocality?.map((locality) => (
              <MenuItem key={locality.id} value={locality.id}>
                {locality.name}
              </MenuItem>
            ))}
          </Select>
          {isLocalityLoading ? <CircularProgress size={24} /> : null}
        </Stack>
        {errorData.address.locality_id ? (
          <FormHelperText>{errorData.address.locality_id}</FormHelperText>
        ) : null}
      </FormControl>
      <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
        <Button
          variant="outlined"
          style={{
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
            border: 'none',
            color: 'black',
          }}
          onClick={handleClose}
        >
          {i18n.t('app.cancel')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={
            isCreationLoading || isUpdateLoading || (parentFormData?.address_id == null && type === 'view')
          }
          style={{
            color: '#FFF',
            width: 115,
            borderRadius: 100,
            textTransform: 'none',
          }}
          onClick={() => (readonly ? enableEditMode() : submit())}
        >
          {isCreationLoading || isUpdateLoading ? (
            <CircularProgress style={{ width: 24, height: 24 }} />
          ) : readonly ? (
            'Editar'
          ) : (
            i18n.t('app.save')
          )}
        </Button>
      </Stack>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
