import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { ResetForm } from '@features/public/reset/reset-form';

export function ResetPage() {

  return (
    <AuthLayoutComponent>
      <ResetForm />
    </AuthLayoutComponent>
  );
}
