import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LabProtocolEditionPayload } from './lab-protocol-edition.types';

export async function updateLabProtocolService({ query: rawQuery, body }: LabProtocolEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put(ENDPOINT.LAB_PROTOCOL, {
    query,
    body,
  });

  return {
    response,
    summary: data,
  };
}
