import { useState } from 'react';

type UseArrayType<T> = {
  item: T;
  key: ReturnType<typeof crypto.randomUUID>;
};

export function useArray<T>(initArray: T[] = []) {
  const [array, setArray] = useState<UseArrayType<T>[]>(
    initArray.map((item) => ({ item, key: crypto.randomUUID() }))
  );

  const add = (index: number, element: T | T[]): void => {
    setArray((prev) => {
      const x = Array.isArray(element)
        ? element.map((item) => ({ item, key: crypto.randomUUID() }))
        : { item: element, key: crypto.randomUUID() };
      const relativeIndex = index >= 0 ? index : Math.max(prev.length + index + 1, 0);
      return prev.slice(0, relativeIndex).concat(x).concat(prev.slice(relativeIndex));
    });
  };

  const smash = (index: number, element: T | T[]): void => {
    setArray((prev) => {
      const x = Array.isArray(element)
        ? element.map((item) => ({ item, key: crypto.randomUUID() }))
        : { item: element, key: crypto.randomUUID() };
      const firstCut = Math.min(index, prev.length - 1);
      const parcialResult = prev.slice(0, firstCut).concat(x);
      if (index === -1) return parcialResult;

      const secondCut = Math.max(index + 1, -prev.length + 1);
      return parcialResult.concat(prev.slice(secondCut));
    });
  };

  const update = (index: number, element: T): void => {
    setArray((prev) => {
      return prev.map((item, i) => (i === index ? { item: element, key: item.key } : item));
    });
  };

  const remove = (index: number): void => {
    setArray((prev) => {
      const ceil = Math.min(index, prev.length - 1);
      const floor = Math.max(prev.length + index, 0);
      const relativeIndex = index >= 0 ? ceil : floor;
      return prev.slice(0, relativeIndex).concat(prev.slice(relativeIndex + 1));
    });
  };

  const set = (array: T[]) => {
    setArray(
      array.map((item) => ({
        item,
        key: crypto.randomUUID(),
      }))
    );
  };
  const clear = () => setArray([]);

  return {
    array: array.map((item) => item.item),
    keys: array.map((item) => item.key),
    set,
    add,
    smash,
    remove,
    update, // re-create this custom hook to avoid unnecessary re-renders
    clear,
  };
}
