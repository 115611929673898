import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CountrysideEditionPayload, UpdatedCountrysideData } from './countryside-edition.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: updateCountrysideService_DEPRECATED

// ✅ Implementado
export async function updateCountrysideDataService({ query: rawQuery, body }: CountrysideEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put<UpdatedCountrysideData>(ENDPOINT.COUNTRYSIDES, {
    query,
    body,
  });

  return {
    response,
    summary: data,
  };
}
