import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { CountrysideData, CountrysidePayload } from './countryside-list.types';
// ✅ implementado
// deprecated service ref.: getCountrysideService_DEPRECATED, countrysidesService_DEPRECATED, getCountrysidesService_DEPRECATED
export async function listCountrysideService({ query: rawQuery }: CountrysidePayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.get<CountrysideData>(ENDPOINT.COUNTRYSIDES, { query });

  return {
    response,
    summary: data,
  };
}
