import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { LabExportProtocolPayload } from './lab-protocol-export.types';

export async function exportLabProtocolService({ body }: LabExportProtocolPayload) {
  const { response, data } = await BACKEND.BLOB.post(ENDPOINT.LAB_PROTOCOL_EXPORT, { body });

  return {
    response,
    summary: data,
  };
}
