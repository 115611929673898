import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { AlborCountrysideResponse } from './albor-countryside-list.types';

// deprecated service ref.: getIntegrationAlborCountrysidesService_DEPRECATED

// Implementado ✅
export async function listAlborCountrysidesService() {
  const { response, data } = await BACKEND.get<AlborCountrysideResponse>(ENDPOINT.ALBOR_COUNTRYSIDES);

  return {
    response,
    summary: data,
  };
}
