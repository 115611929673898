import { useEffectOnUpdate } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useMap } from '../use-map';
import i18n from '../../../libs/language';
import { speciesListService } from '@services/domain/base/silobag/list/species-list';
import { productListService } from '@services/domain/base/silobag/list/product-list';
import { harvestListService } from '@services/domain/base/silobag/list/harvest-list';

type SelChg = SelectChangeEvent<number>;

export function Filters() {
  const { summary: species } = useService(speciesListService, { fetchOnMount: true });
  const { summary: products } = useService(productListService, { fetchOnMount: true });
  const { summary: harvests } = useService(harvestListService, { fetchOnMount: true });

  type FilterSel = {
    lot: number | '';
    species: number | '';
    product: number | '';
    harvest: string;
  };
  const initSelection: FilterSel = {
    lot: '',
    species: '',
    product: '',
    harvest: '',
  };

  const { ui, lotsOptions, MAP_UTILS } = useMap();

  const [filterSelection, setFilterSelection] = useState(initSelection);
  const updateFilSel = (key: string, value: number | string) =>
    setFilterSelection((prev) => ({ ...prev, [key]: value }));

  useEffectOnUpdate(() => {
    MAP_UTILS.FILTER(filterSelection);
  }, [filterSelection]);

  useEffectOnUpdate(() => {
    if (!ui.includes('detail')) {
      updateFilSel('lot', '');
    }
  }, [ui]);

  const isSelectionEmpty = Object.values(filterSelection).every((value) => value === '');

  return (
    <Stack direction="row" spacing={2} style={{ height: 'min-content', marginTop: 18 }}>
      {ui.includes('detail') ? (
        <Paper style={{ width: 175, pointerEvents: 'auto' }}>
          <FormControl fullWidth size="small" variant="filled">
            <InputLabel>Lote</InputLabel>
            <Select
              value={filterSelection.lot}
              label="Lote"
              onChange={(e: SelChg) => updateFilSel('lot', e.target.value)}
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {lotsOptions.map((lot) => (
                <MenuItem key={lot.id} value={lot.id}>
                  {lot.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      ) : null}
      <Paper style={{ width: 175, pointerEvents: 'auto' }}>
        <FormControl fullWidth size="small" variant="filled">
          <InputLabel>{i18n.t('app.silobag_data.specie')}</InputLabel>
          <Select
            value={filterSelection.species}
            label={i18n.t('app.silobag_data.specie')}
            onChange={(e) => updateFilSel('species', e.target.value)}
          >
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            {species?.map((species) => (
              <MenuItem key={species.id} value={species.id}>
                {species.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      <Paper style={{ width: 175, pointerEvents: 'auto' }}>
        <FormControl fullWidth size="small" variant="filled">
          <InputLabel>{i18n.t('app.silobag_data.product')}</InputLabel>
          <Select
            value={filterSelection.product}
            label={i18n.t('app.silobag_data.product')}
            onChange={(e) => updateFilSel('product', e.target.value)}
          >
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            {products?.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      <Paper style={{ width: 175, pointerEvents: 'auto' }}>
        <FormControl fullWidth size="small" variant="filled">
          <InputLabel>{i18n.t('app.silobag_data.harvest')}</InputLabel>
          <Select
            value={filterSelection.harvest}
            label={i18n.t('app.silobag_data.harvest')}
            onChange={(e) => updateFilSel('harvest', e.target.value)}
          >
            <MenuItem value="">
              <em>Ninguno</em>
            </MenuItem>
            {harvests?.map((harvest) => (
              <MenuItem key={harvest.id} value={harvest.name}>
                {harvest.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      {!isSelectionEmpty ? (
        <Stack justifyContent="center">
          <Button
            size="small"
            style={{
              color: '#F00',
              height: 30,
              background: '#ffe5e5',
              pointerEvents: 'auto',
              padding: '0 10px',
              borderRadius: 3,
              textTransform: 'none',
            }}
            onClick={() => setFilterSelection(initSelection)}
          >
            Borrar filtros
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
}
