import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { LocalityData, LocalityPayload } from './locality.types';

// deprecated service ref.: localityService_DEPRECATED

// implementado ✅
export async function listLocalityForL2Service({ query: rawQuery }: LocalityPayload) {
  const { L0ID, L1ID, L2ID } = rawQuery;
  const query = transformIntoQueryParamString(L0ID, L1ID, L2ID);

  const { response, data } = await BACKEND.get<LocalityData>(ENDPOINT.LOCALITIES, { query });

  return {
    response,
    summary: data,
  };
}
