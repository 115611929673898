import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { SyncCorvusPayload, SyncCorvusResponse } from './sync-corvus.types';

//SyncEntitiesCorvusService_DEPRECATED 
//Implementado  ✅
export async function syncEntitiesCorvusService({ body }: SyncCorvusPayload) {
  const { response, data } = await BACKEND.post<SyncCorvusResponse>(ENDPOINT.CORVUS_SYNC_ENTITIS, { body });
  return {
    response,
    summary: data,
  };
}