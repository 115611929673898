import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { LoginForm } from '@features/public/login/login-form';

export const LoginPage = () => {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
    });
  }
  return (
    <AuthLayoutComponent>
      <LoginForm />
    </AuthLayoutComponent>
  );
};
