import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

// ✅ Está procesado
export const silobagExtractionService_DEPRECATED = async (body: any, siloID: string) => {
  const { response, data } = await BACKEND.post(ENDPOINT.SILOBAG_EXTRACTION, { params: `/${siloID}`, body });

  return {
    response,
    summary: data,
  };
};

export const silobagMultipleExtractionService = async (body: any) => {
  const { response, data } = await BACKEND.post(ENDPOINT.SILOBAG_EXTRACTION, { body });

  return {
    response,
    summary: data,
  };
};

// ✅ Está procesado
export const updateExtractionService_DEPRECATED = async (body: any, extractionID: number) => {
  const { response, data } = await BACKEND.put(ENDPOINT.SILOBAG_EXTRACTION, {
    params: `/${extractionID}`,
    body,
  });

  return {
    response,
    summary: data,
  };
};
