import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { RegisterForm } from '@features/public/register/register-form';

export function RegisterPage() {
  return (
    <AuthLayoutComponent>
      <RegisterForm />
    </AuthLayoutComponent>
  );
}
