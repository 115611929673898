import { ENDPOINT } from '@data/enums';
import { BACKEND } from '@services/backend';

// ✅ Está procesado
export async function getReportActivitiesService_DEPRECATED(params: any) {
  const validParams = params?.length ? `?${params.filter(Boolean).join('&')}` : '';
  const { response, data } = await BACKEND.get(ENDPOINT.REPORT_ACTIVITY, { params: `/${validParams}` });

  return {
    response,
    summary: data,
  };
}

export async function getUserReportActivitiesFiltersService_DEPRECATED(params: any) {
  const validParams = params?.length ? `?${params.filter(Boolean).join('&')}` : '';
  const { response, data } = await BACKEND.get(ENDPOINT.REPORT_ACTIVITY_FILTERS, { params: `/${validParams}` });

  return {
    response,
    summary: data,
  };
}

// ✅ Está procesado
export async function getSilobagEventTypesService_DEPRECATED() {
  const { response, data } = await BACKEND.get(ENDPOINT.SILOBAG_EVENT_TYPES);

  return {
    response,
    summary: data,
  };
}
