import { DependencyList, EffectCallback } from 'react';
import { useTimeout } from './use-timeout';
import { useEffectOnUnmount, useEffectOnUpdate } from './use-effect';

export function useDebounce(callback: EffectCallback, delay: number, dependencies: DependencyList) {
  const { set, clear } = useTimeout(callback, delay);

  useEffectOnUpdate(set, dependencies);
  useEffectOnUnmount(clear);

  return { clear };
}
