import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  Modal,
  Paper,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import LocationSvg from '@assets/svg/location_searching-green.svg';
import i18n from '../../../../libs/language';
import dayjs from 'dayjs';
import DownloadIcon from '@assets/svg/core/download-alt-blue.svg';

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

// TODO: TIPAR CORRECTAMENTE CUANDO SE MERGEE EL TIPADO DE silobagDetailByIDService_DEPRECATED

type MobileCloseModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
};

export function MobileCloseModal({ open, setOpen, data }: MobileCloseModalProps) {
  const [originClosureData, setOriginClosureData] = useState<any>();
  const [listIDX, setListIDX] = useState(0);
  const [imageList, setImageList] = useState<string[]>([]);
  const [locationDataList, setLocationDataList] = useState<any[]>([]);

  useEffect(() => {
    const originData = data.find((elem: any) => elem.silobag_event_type_id === 19);
    setOriginClosureData(originData.silobag_closures);
  }, []);

  useEffect(() => {
    if (originClosureData) {
      const mainImage = originClosureData.image_url; // Imagen principal
      const extraImages = originClosureData.silobag_closures_images.map((closure: any) => closure.image_url); // Imágenes extra

      setImageList([mainImage, ...extraImages]);

      const mainLocationData = {
        lat: originClosureData.lat,
        lon: originClosureData.lon,
        created_at: originClosureData.created_at,
        accuracy: originClosureData.accuracy,
      };

      const extraLocationData = originClosureData.silobag_closures_images.map((closure: any) => ({
        lat: closure.lat,
        lon: closure.lon,
        created_at: closure.created_at,
        accuracy: closure.accuracy,
      }));

      setLocationDataList([mainLocationData, ...extraLocationData]);
    }
  }, [originClosureData]);

  const SCROLL = {
    toLeft: () => setListIDX((prev) => (prev - 1 + imageList?.length) % imageList?.length),
    toRight: () => setListIDX((prev) => (prev + 1) % imageList?.length),
  };

  const handleClose = () => {
    setOpen(false);
  };

  const silobagId = data[0].silobag_id;
  const pascalCaseLabel =
    listIDX == 0
      ? i18n.t('app.mobile_close_modal.photo_of_area')
      : i18n.t('app.silobag_data.silobag_extra_photo');

  const formattedDate = locationDataList[listIDX]?.created_at
    ? dayjs(locationDataList[listIDX]?.created_at).format('YYYY_MM_DD-HH:mm(G[M]TZ)')
    : '';

  return (
    <MyModal onClose={handleClose} open={open}>
      {originClosureData == undefined ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress sx={{ color: 'green' }} size={60} />
        </div>
      ) : (
        <>
          <Box component={'div'}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h5" fontWeight={'bold'}>
                {i18n.t('app.mobile_close_modal.closure_details_origin')}
              </Typography>
              <DownloadLink
                url={imageList[listIDX] ?? ''}
                fileName={`${silobagId}-${pascalCaseLabel}-${formattedDate}`}
              />
            </Stack>
          </Box>
          <Stack
            direction={{ md: 'row', sm: 'column' }}
            spacing={2}
            height={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            {/* SECCION DE DETALLE */}
            <Stack spacing={2}>
              <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                <img src={LocationSvg} alt="location-img" />
                <Typography color={'#6BAA00'}>
                  {i18n.t('app.mobile_close_modal.closure_distance', {
                    distance: originClosureData.distance_to_sb_polygon,
                  })}
                </Typography>
              </Box>
              <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={4}>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_data.lat')}:</Typography>
                  <Typography>{parseFloat(locationDataList[listIDX]?.lat)}</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_data.long')}:</Typography>
                  <Typography>{parseFloat(locationDataList[listIDX]?.lon)}</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.silobag_history.gps_accuracy')}:</Typography>
                  <Typography>{`${parseFloat(locationDataList[listIDX]?.accuracy).toFixed(
                    2
                  )} mts`}</Typography>
                </Box>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography color={'#7A7A7A'}>{i18n.t('app.date')}:</Typography>
                  <Typography>
                    {dayjs(locationDataList[listIDX]?.created_at).format('D/M/YYYY - HH:mm')}
                  </Typography>
                </Box>
              </Box>
              <Box gap={1}>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography>{i18n.t('app.mobile_close_modal.grain_loss')}</Typography>
                  <FormControlLabel
                    control={<Radio checked={true} color="default" />}
                    label={
                      originClosureData.silobag_closures_questions[0].result
                        ? i18n.t('app.yes')
                        : i18n.t('app.no')
                    }
                    checked
                  />
                </Box>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography>{i18n.t('app.mobile_close_modal.area_cleaned')}</Typography>
                  <FormControlLabel
                    control={<Radio checked={true} color="default" />}
                    label={
                      originClosureData.silobag_closures_questions[1].result
                        ? i18n.t('app.yes')
                        : i18n.t('app.no')
                    }
                    checked
                  />
                </Box>
                <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1}>
                  <Typography>{i18n.t('app.mobile_close_modal.plastic_recycled')}</Typography>
                  <FormControlLabel
                    control={<Radio checked={true} color="default" />}
                    label={
                      originClosureData.silobag_closures_questions[2].result
                        ? i18n.t('app.yes')
                        : i18n.t('app.no')
                    }
                    checked
                  />
                </Box>
              </Box>

              <Box>
                <Typography color={'#7A7A7A'}>{i18n.t('app.observations')}</Typography>
                <Typography>{originClosureData.observations}</Typography>
              </Box>
            </Stack>

            {/* SECCION DE IMAGENES */}
            <Box width={'30%'} component={'div'}>
              {/* TODO: CUANDO FUNCIONE, CHEQUEAR ARRAY SI HAY IMAGENES */}
              <Box display={'flex'} justifyContent={'space-between'} component={'div'}>
                <Typography variant="body2" fontWeight={'bold'}>
                  Foto {listIDX + 1}/{imageList?.length}
                </Typography>
                <Typography variant="body2">
                  {listIDX == 0
                    ? i18n.t('app.mobile_close_modal.photo_of_area')
                    : i18n.t('app.silobag_data.silobag_extra_photo')}
                </Typography>
              </Box>
              <Box style={{ width: '100%', height: '90%' }} component={'div'}>
                <Box className="slider-wrapper" component={'div'}>
                  <Box
                    component={'div'}
                    className="slider keep-scrolling-without-scroll"
                    style={{ position: 'absolute', width: '100%' }}
                  >
                    {imageList?.map((image: any, i: number) => (
                      <img
                        ref={(node) => {
                          if (node && listIDX === i) {
                            node.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                          }
                        }}
                        style={{ width: '100%' }}
                        key={i}
                        src={image}
                        alt={'image-mobile'}
                      />
                    ))}
                  </Box>
                  <Paper
                    sx={{
                      borderRadius: '100%',
                      left: '0.5rem',
                      width: '30px',
                      height: '30px',
                      position: 'absolute',
                      top: '50%',
                      maxHeight: '30px',
                      fontSize: '2rem',
                      cursor: 'pointer',
                      userSelect: 'none',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&: hover': {
                        opacity: '80%',
                      },
                    }}
                    onClick={SCROLL.toLeft}
                  >
                    <Box style={{ opacity: '70%' }} component={'div'}>
                      {'<'}
                    </Box>
                  </Paper>
                  <Paper
                    sx={{
                      borderRadius: '100%',
                      right: '0.5rem',
                      width: '30px',
                      height: '30px',
                      position: 'absolute',
                      top: '50%',
                      maxHeight: '30px',
                      fontSize: '2rem',
                      cursor: 'pointer',
                      userSelect: 'none',
                      transform: 'translateY(-50%)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&: hover': {
                        opacity: '80%',
                      },
                    }}
                    onClick={SCROLL.toRight}
                  >
                    <Box style={{ opacity: '70%' }} component={'div'}>
                      {'>'}
                    </Box>
                  </Paper>

                  <Stack
                    direction="row"
                    flexWrap="wrap"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                    className="slider-nav"
                  >
                    {imageList?.map((_: unknown, i: number) => (
                      <Box
                        component={'div'}
                        key={i}
                        onClick={() => setListIDX(i)}
                        style={{
                          width: '13px',
                          backgroundColor: listIDX === i ? '#7A7A7A' : '#f3f2f7',
                          height: '13px',
                          borderRadius: '50%',
                          border: '1px solid #a7a7a7',
                        }}
                      ></Box>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Stack>
        </>
      )}

      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} gap={2}>
        <Button onClick={handleClose}>{i18n.t('app.close')}</Button>
      </Box>
    </MyModal>
  );
}

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '85%',
    overflowX: 'hidden',
    maxHeight: '95vh',
    height: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style} component={'div'}>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

type DownloadLinkProps = {
  url: string;
  fileName?: string;
};
export function DownloadLink({ url, fileName = 'siloreal-image' }: DownloadLinkProps) {
  if (!url) {
    console.error('[SiloReal][DOWNLOAD-FILE] No URL provided');
    return null;
  }
  const ext = url.match(/\.([^./?#]+)($|\?|#)/)?.at(1);

  const handleDownload = () => {
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${ext}`;
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error fetching the file:', error);
        console.warn('Refetching...');
        fetch(url, {
          method: 'GET',
          cache: 'no-cache',
        })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.download = `${fileName}.${ext}`;
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            console.info('Refetched successfully.');
          })
          .catch((error) => {
            console.error('Error (again) fetching the file:', error);
          });
      });
  };

  return (
    <Box>
      <Button
        sx={{
          color: '#22A7F0',
          textTransform: 'none',
          px: 2,
          '&:hover': { background: '#22a7f02a' },
        }}
        startIcon={<img src={DownloadIcon} />}
        onClick={handleDownload}
      >
        {i18n.t('app.download')}
      </Button>
    </Box>
  );
}
