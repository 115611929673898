import { ActivityReportBoard } from '@features/report-activity/activity-board';
import { Box } from '@mui/material';

export function ActivityReportPage() {
  return (
    <Box sx={{ maxWidth: '95%', margin: 'auto' }}>
      <ActivityReportBoard />
    </Box>
  );
}
