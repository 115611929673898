import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CompanyData } from './company-list.types';

// deprecated service ref.: getCompanyService_DEPRECATED

// ✅ Implementado
export async function listCompanyService() {
  const { response, data } = await BACKEND.get<CompanyData>(ENDPOINT.COMPANIES);

  return {
    response,
    summary: data,
  };
}

// deprecated service ref.: getCompanyListService_DEPRECATED

// ✅ Implementado
export async function listRawCompanyDataService(/*{ query, body }: <TYPE>*/) {
  const { response, data } = await BACKEND.get<CompanyData>(ENDPOINT.COMPANIES);

  return {
    response,
    summary: data.map(({ id, name }) => ({ id, name })),
  };
}
