import {
  Box,
  Typography,
  Select,
  TextField,
  FormControl,
  Stack,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Tooltip,
} from '@mui/material';
import i18n from '../../../../libs/language';
import SearchIcon from '@assets/svg/search.svg';
import { stylesInput } from '@styles/generic-styles';
import { customBgWhite } from '@styles/generic-styles';
import { BaseTable } from '@ui/table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useService } from '@hooks/use-service';
import { getEstablishmentsCorvusService } from '@services/integration/corvus/get-establishment-corvus';
import { getProductsCorvusService } from '@services/integration/corvus/get-products-corvus';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from '@utils/helper';
import checkIcon from '@assets/svg/check-with-border.svg';
import checkIconYellow from '@assets/svg/check-icon-yellow.svg';
import editIcon from '@assets/svg/edit-svg-blue.svg';
import editIconYellow from '@assets/svg/edit-svg-yellow.svg';
import exclamationIcon from '@assets/svg/exclamation-icon.svg';
import exclamationIconYellow from '@assets/svg/exclamation-icon-yellow.svg';
import { formatNumberWithSeparators } from '@utils/helper';
import DeleteIcon from '@assets/svg/core/trash-red.svg';
import { getCorvusHeavyLogs } from '@services/integration/corvus/get-heavy-logs';
import { getSilobagInfoCorvusService } from '@services/integration/corvus/get-silobag-info-corvus';
import { deleteHeavyLogsCorvusService } from '@services/integration/corvus/delete-heavy-logs';
import clockIcon from '@assets/svg/clock.svg';

type BodyDeleteHeavyLogs = Parameters<typeof deleteHeavyLogsCorvusService>[0];

type SilobagInfoCorvusSummary = Awaited<
  ReturnType<typeof getSilobagInfoCorvusService>
>['summary']['heavyLogs'];

type HeavyLogsSummary = Awaited<ReturnType<typeof getCorvusHeavyLogs>>['summary'];
type ParametersHeavyLogs = Parameters<typeof getCorvusHeavyLogs>[0]['query'];

type stepType = {
  SELECT_HEAVY_LOGS: string;
  CONFIRM_HEAVY_LOGS: string;
  CORVUS_DETAIL: string;
};

type WeightType = {
  WET: string;
  DRY: string;
};

type HeavyLockState = {
  [key: number]: {
    description: string;
    icon: string;
  };
};

type CorvusTableProps = {
  step: string;
  params?: ParametersHeavyLogs;
  setParams?: (value: ParametersHeavyLogs) => void;
  getHeavyLogs: () => void;
  data?: HeavyLogsSummary | SilobagInfoCorvusSummary;
  dataSelected?: HeavyLogsSummary;
  isLoading: boolean;
  setHeavyLogsSelected: (value: HeavyLogsSummary) => void;
  setDateRange: (value: [Dayjs | null, Dayjs | null]) => void;
  dateRange: [Dayjs | null, Dayjs | null];
  deleteHeavyLog: ({ body, query }: BodyDeleteHeavyLogs) => void;
  isLoadingInfo: boolean;
  siloId?: string;
  stepType: stepType;
  weightState: string;
  handleWeightState: (value: string) => void;
  WeightType: WeightType;
};

export function CorvusTable(props: CorvusTableProps) {
  const {
    step,
    data,
    isLoading,
    setHeavyLogsSelected,
    dataSelected,
    dateRange,
    setDateRange,
    setParams,
    params,
    deleteHeavyLog,
    siloId,
    isLoadingInfo,
    stepType,
    weightState,
    handleWeightState,
    WeightType,
  } = props;
  const today = dayjs();
  const [startDate, endDate] = dateRange;
  const { summary: establishments } = useService(getEstablishmentsCorvusService, {
    fetchOnMount: true,
  });

  const { summary: products } = useService(getProductsCorvusService, { fetchOnMount: true });

  const dataFormated = data ? data.map((item) => ({ id: item.heavy_log_id, ...item })) : [];

  const rows =
    step === stepType.SELECT_HEAVY_LOGS || step === stepType.CORVUS_DETAIL ? dataFormated : dataSelected;

  function removeItemById(array: HeavyLogsSummary, id: number) {
    const arrayFiltered = array.filter((item) => item.heavy_log_id !== id);
    setHeavyLogsSelected(arrayFiltered);
  }

  const handleDateChange = (newDate: Dayjs | null, index: number) => {
    if (newDate) {
      const updatedDateRange = [...dateRange];
      updatedDateRange[index] = newDate;
      setDateRange(updatedDateRange as [Dayjs, Dayjs]);
    }
  };

  const handleReset = () => {
    setDateRange([null, null]);
    setParams && setParams({ description: '', establishmentId: '', productId: '' });
  };
  const handleRowSelected = (selectedRow: HeavyLogsSummary) => {
    setHeavyLogsSelected && setHeavyLogsSelected(selectedRow);
  };
  const heavyLockState: HeavyLockState = {
    3: {
      description: 'Descarga Automática',
      icon: checkIcon,
    },
    11: {
      description: 'Descarga Modificada',
      icon: editIconYellow,
    },
    24: {
      description: 'SobrePeso',
      icon: exclamationIconYellow,
    },
    25: {
      description: 'Tiempo minimo',
      icon: clockIcon,
    },
    28: {
      description: 'Descarga Manual en balanza',
      icon: checkIconYellow,
    },
    29: {
      description: 'Descarga Modificada en balanza',
      icon: editIcon,
    },
    31: {
      description: 'Descarga defectuosa en balanza',
      icon: exclamationIcon,
    },
  };
  const columns: GridColDef[] = [
    {
      field: 'corvus_state_heavy_check_id',
      headerName: 'Tipo',
      flex: 1,
      renderCell: (params) => {
        const valueId: number = params.value;
        const value = heavyLockState[valueId];
        return (
          <Tooltip title={value?.description ?? ''}>
            <img src={value?.icon ?? checkIcon} style={{ width: 16, height: 16 }} />
          </Tooltip>
        );
      },
    },
    {
      field: 'heavy_log_date',
      headerName: 'Fecha Pesada',
      flex: 1,
      renderCell: (params) => {
        const date = formatDateTime(params.value, 2);
        return <div>{date}</div>;
      },
    },
    { field: 'description', headerName: i18n.t('app.name'), flex: 1 },
    {
      field: 'corvus_establishment_id',
      headerName: i18n.t('app.countryside'),
      flex: 1,
      renderCell: (params) => {
        const establisment = params.row.corvus_establishment.name;
        return <div>{establisment}</div>;
      },
    },
    {
      field: 'corvus_product_id',
      headerName: i18n.t('app.silobag_data.product'),
      flex: 1,
      renderCell: (params) => {
        const product = params.row.corvus_product.name;
        return <div>{product}</div>;
      },
    },
    {
      field: 'type_weight',
      headerName: 'Tipo de Peso',
      flex: 1,
      renderCell: () => {
        const typeToRender =
          weightState == WeightType.WET
            ? i18n.t('app.integration_corvus.wet')
            : i18n.t('app.integration_corvus.dry');
        return <div>{typeToRender}</div>;
      },
    },
    {
      field: 'corvus_batch',
      headerName: i18n.t('app.silobag_data.lot_origin'),
      flex: 1,
      renderCell: (params) => {
        return <div>{params.value.name}</div>;
      },
    },
    {
      field: 'corvus_machine_id',
      headerName: 'Tolva',
      flex: 1,
      renderCell: (params) => {
        const machine = params.row.corvus_machine.name;
        return <div>{machine}</div>;
      },
    },
    {
      field: 'wet_weight',
      headerName: i18n.t('app.integration_corvus.weight_corvus'),
      flex: 1.2,
      renderCell: (params) => {
        const weightToRender = weightState == WeightType.WET ? +params.value : +params.row.dry_weight;
        return (
          <Stack direction={'row'} spacing={2} justifyContent={'center'} alignContent={'center'}>
            <p>{formatNumberWithSeparators(weightToRender)} kg</p>
          </Stack>
        );
      },
    },
    {
      field: 'id',
      headerName: '',
      flex: 0.3,
      renderCell: (params) => {
        const paramsId = params.row.heavy_log_id;
        const bodyRequest = {
          heavyLogs: [{ heavyLogId: paramsId }],
        };
        return (
          <Stack direction={'row'} spacing={2} justifyContent={'center'} alignContent={'center'}>
            {step !== stepType.SELECT_HEAVY_LOGS && (
              <Stack direction={'column'}>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (step === stepType.CONFIRM_HEAVY_LOGS) {
                      removeItemById(rows!, paramsId);
                    } else {
                      deleteHeavyLog({ body: bodyRequest, query: siloId ?? 'id-not-found' });
                    }
                  }}
                >
                  <img src={DeleteIcon} />
                </span>
              </Stack>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Typography variant="h6" fontWeight={500}>
        {step === stepType.SELECT_HEAVY_LOGS ? 'Elegir silobolsa en corvus' : ''}
      </Typography>
      {step === stepType.SELECT_HEAVY_LOGS ? (
        <Stack direction={'row'} spacing={1} sx={{ marginTop: '20px' }}>
          <FormControl
            style={{
              width: '250px',
              fontSize: 14,
              marginRight: '10px',
              height: '100%',
            }}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder={'Buscar por ' + i18n.t('app.name')}
              style={stylesInput}
              onChange={(e) =>
                setParams &&
                setParams((params: ParametersHeavyLogs) => ({ ...params, description: e.target.value }))
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="search-icon" />
                  </InputAdornment>
                ),
                inputProps: { style: { fontSize: 13, height: 22 } },
              }}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '250px', height: '100%', marginRight: '10px' }}
            sx={stylesInput}
          >
            <InputLabel id="demo-multiple-name-label">{i18n.t('app.countrysides')}</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="countryside_id"
              input={<OutlinedInput label={i18n.t('app.countrysides')} />}
              onChange={(e) =>
                setParams &&
                setParams((params: ParametersHeavyLogs) => ({ ...params, establishmentId: e.target.value }))
              }
              value={params?.establishmentId ?? ''}
            >
              <MenuItem value={''}>
                <Typography>{i18n.t('app.countrysides')}</Typography>
              </MenuItem>
              {establishments?.map((item) => (
                <MenuItem key={item.corvus_establishment_id} value={item.corvus_establishment_id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '250px', height: '100%', marginRight: '10px' }}
            sx={stylesInput}
          >
            <InputLabel id="demo-multiple-name-label">{i18n.t('app.silobag_data.product')}</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="countryside_id"
              input={<OutlinedInput label={i18n.t('app.silobag_data.product')} />}
              onChange={(e) =>
                setParams &&
                setParams((params: ParametersHeavyLogs) => ({ ...params, productId: e.target.value }))
              }
              value={params?.productId ?? ''}
            >
              <MenuItem value={''}>
                <Typography>{i18n.t('app.silobag_data.product')}</Typography>
              </MenuItem>
              {products?.map((item) => (
                <MenuItem key={item.corvus_product_id} value={item.corvus_product_id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '250px', height: '100%', marginRight: '10px' }}
            sx={stylesInput}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                label={i18n.t('app.report_stock.init_date')}
                value={startDate}
                onChange={(date) => handleDateChange(date ?? today, 0)}
                sx={stylesInput}
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      onKeyPress: (event: React.KeyboardEvent) => {
                        event.preventDefault();
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '250px', height: '100%', marginRight: '10px' }}
            sx={stylesInput}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
              <DatePicker
                label={i18n.t('app.report_stock.close_date')}
                value={endDate}
                onChange={(date) => handleDateChange(date ?? today, 1)}
                sx={stylesInput}
                slotProps={{
                  textField: {
                    size: 'small',
                    inputProps: {
                      onKeyPress: (event: React.KeyboardEvent) => {
                        event.preventDefault();
                      },
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="wet_weight"
              name="radio-buttons-group"
              sx={{ marginTop: -1.5 }}
            >
              <FormControlLabel
                value="wet_weight"
                control={<Radio size="small" />}
                label={
                  <Typography variant="body2" style={{ fontSize: '0.85rem', whiteSpace: 'nowrap' }}>
                    {i18n.t('app.integration_corvus.wet_weight')}
                  </Typography>
                }
                onClick={() => handleWeightState(WeightType.WET)}
              />
              <FormControlLabel
                value="dry_height"
                control={<Radio size="small" />}
                onClick={() => handleWeightState(WeightType.DRY)}
                label={
                  <Typography variant="body2" style={{ fontSize: '0.85rem', whiteSpace: 'nowrap' }}>
                    {i18n.t('app.integration_corvus.dry_weight')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Button
            sx={{
              fontSize: 12,
              fontWeight: 500,
              padding: 1,
              textTransform: 'uppercase',
              visibility:
                startDate || endDate || params?.description || params?.establishmentId || params?.productId
                  ? ''
                  : 'hidden',
              color: '#FF0000',
              '&:hover': { background: '#FF000022' },
            }}
            onClick={handleReset}
            startIcon={<img src={DeleteIcon} alt="delete-icon" />}
          >
            {i18n.t('app.clear_filters')}
          </Button>
        </Stack>
      ) : null}
      <Box sx={customBgWhite}>
        <BaseTable
          columns={columns}
          rows={rows ?? []}
          initialPageSize={50}
          loading={isLoading || isLoadingInfo}
          checkboxSelection={step === stepType.SELECT_HEAVY_LOGS}
          selectFullRow
          onRowSelected={handleRowSelected}
        />
      </Box>
    </Box>
  );
}
