import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { ProtBySilobagPayload, ProtBySilobagResponse } from './lab-protocol-list.types';

export async function labProtocolBySilobagListService({ body }: ProtBySilobagPayload) {
  const { response, data } = await BACKEND.post<ProtBySilobagResponse>(ENDPOINT.LAB_PROTOCOLS_BY_SILOBAG, {
    body,
  });

  return {
    response,
    summary: data,
  };
}
