import { Paper, Typography, Stack, Box, CircularProgress } from '@mui/material';
import i18n from '../../../../libs/language';
import { formatNumberWithSeparators } from '@utils/helper';
import { getCorvusHeavyLogs } from '@services/integration/corvus/get-heavy-logs';
import { getSilobagInfoCorvusService } from '@services/integration/corvus/get-silobag-info-corvus';

type SilobagInfoCorvusSummary = Awaited<ReturnType<typeof getSilobagInfoCorvusService>>['summary'];

type HeavyLogsSummary = Awaited<ReturnType<typeof getCorvusHeavyLogs>>['summary'];
type WeightType = {
  WET: string;
  DRY: string;
};

type SectionCorvusProps = {
  heavyLogsData: HeavyLogsSummary;
  silobagWeight?: number;
  corvusData?: SilobagInfoCorvusSummary;
  isSuccessData: boolean;
  step: string;
  isLoadingInfo: boolean;
  WeightType: WeightType;
  weightState: string;
};

export function SectionCorvus(props: SectionCorvusProps) {
  const { heavyLogsData, silobagWeight, corvusData, isSuccessData, isLoadingInfo, WeightType, weightState } =
    props;
  const totalWeight = heavyLogsData
    .map((item) => parseFloat(weightState === WeightType.WET ? item.wet_weight : item.dry_weight))
    .reduce((sum, current) => sum + current, 0);

  const establishmentFormat = heavyLogsData
    .map((item) => item.corvus_establishment.name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(', ');

  const establishmentNames = establishmentFormat.length > 0 ? establishmentFormat : '―';

  const productsFormat = heavyLogsData
    .map((item) => item.corvus_product.name)
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(', ');

  const productsNames = productsFormat.length > 0 ? productsFormat : '―';

  const difference = silobagWeight && totalWeight ? silobagWeight - totalWeight : 0;

  return (
    <Paper elevation={0} sx={{ margin: '10px', borderRadius: '10px', padding: '15px', height: '270px' }}>
      {isLoadingInfo ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" fontWeight={500}>
            Corvus
          </Typography>
          <Stack direction={'row'} spacing={15} sx={{ marginTop: '20px' }}>
            <Typography
              variant="body1"
              fontWeight={600}
              fontSize={15}
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <span
                style={{
                  color: '#A2A2A2',
                  fontWeight: 300,
                  marginRight: '5px',
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                }}
              >
                {i18n.t('app.countryside')}
              </span>{' '}
              {corvusData && isSuccessData ? corvusData.corvus_establishment : establishmentNames}
            </Typography>
            <Typography variant="body1" fontWeight={600} fontSize={15} sx={{ whiteSpace: 'nowrap' }}>
              <span
                style={{
                  color: '#A2A2A2',
                  fontWeight: 300,
                  marginRight: '5px',
                  fontSize: '13px',
                  whiteSpace: 'nowrap',
                }}
              >
                {i18n.t('app.silobag_data.product')}
              </span>{' '}
              {corvusData && isSuccessData ? corvusData.product : productsNames}
            </Typography>
          </Stack>

          <Stack direction={'row'} spacing={5} sx={{ marginTop: '20px' }}>
            <Stack direction={'column'}>
              <Typography variant="body1" fontWeight={600} fontSize={18}>
                {formatNumberWithSeparators(
                  corvusData && isSuccessData ? +corvusData.corvusAccumulatedWeight : totalWeight
                )}{' '}
                Kg
              </Typography>
              <span
                style={{
                  color: '#A2A2A2',
                  fontWeight: 300,
                  marginRight: '10px',
                  fontSize: '13px',
                  display: 'block',
                  whiteSpace: 'nowrap',
                }}
              >
                Total peso corvus
              </span>{' '}
            </Stack>

            <Stack direction={'column'}>
              <Typography variant="body1" fontWeight={600} fontSize={18} sx={{ whiteSpace: 'nowrap' }}>
                {formatNumberWithSeparators(
                  corvusData && isSuccessData ? +corvusData.iofDeltaWeight : difference
                )}{' '}
                Kg
              </Typography>
              <span
                style={{
                  color: '#A2A2A2',
                  fontWeight: 300,
                  fontSize: '13px',
                  display: 'block',
                }}
              >
                Diferencia
              </span>{' '}
            </Stack>
            {/* PARA COLOCAR MAS ADELANTE */}
            {/* <Box sx={{ padding: '10px 5px', backgroundColor: '#E9E8EC', borderRadius: '5px' }}>
          {' '}
          <Typography
            variant="body1"
            fontSize={12}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <span style={{ marginRight: '5px' }}>
              {' '}
              <img src={warningDisabledIcon} />
            </span>
            {i18n.t('app.integration_corvus.warning')}
          </Typography>
        </Box> */}
          </Stack>
        </Box>
      )}
    </Paper>
  );
}
