import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { PoeDaysEditionPayload, UpdatedPoeDaysResponse } from './poe-interval-edition.types';
import { transformIntoQueryParamString } from '@utils/service';

// deprecated service ref.: updateDaysBetweenRoundsService_DEPRECATED

// ✅ Implementado
export async function updatePoeIntervalDaysService({ query: orgID, body }: PoeDaysEditionPayload) {
  const query = transformIntoQueryParamString(orgID);

  const { response, data } = await BACKEND.put<UpdatedPoeDaysResponse>(ENDPOINT.ORGANIZATIONS, {
    query,
    body,
  });
  const { poe_cycle } = data;

  return {
    response,
    summary: { poe_cycle },
  };
}
