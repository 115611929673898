import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { transformIntoQueryParamString } from '@utils/service';

import { TutorialFlagEditionPayload } from './tutorial-flag-update.types';

// deprecated service ref.: setWasTutorialShow_DEPRECATED

// ✅ Implementado
export async function updateShowTutorialFlagService({ query: rawQuery, body }: TutorialFlagEditionPayload) {
  const query = transformIntoQueryParamString(rawQuery);

  const { response, data } = await BACKEND.put(ENDPOINT.USERS, { query, body });

  return {
    response,
    summary: data,
  };
}
