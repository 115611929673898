import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { LoginPayload, LoggedData } from './authentication.types';

// deprecated service ref.: loginService_DEPRECATED
export async function userAuthService({ body }: LoginPayload) {
  const { response, data } = await BACKEND.post<LoggedData>(ENDPOINT.LOGIN, { body });

  const organizationID = data.user.organization_id;
  const organizationPermissions = data.user.user_organizations_permissions.find(
    (org) => org.organization_id === organizationID
  );

  return {
    response,
    summary: {
      accessToken: data.token,
      refreshToken: data.refresh_token,
      userID: data.user.id,
      organizationName: data.user.organizations.name,
      was_tutorial_shown: data.user.was_tutorial_shown,
      was_agrotoken_integration_popup_shown: data.user.was_agrotoken_integration_popup_shown,
      was_la_segunda_popup_shown: data.user.was_la_segunda_popup_shown,
      was_rus_popup_shown: data.user.was_rus_popup_shown,
      userFullname: data.user.fullname,
      organizationTaxId: data.user.organizations.tax_id,
      organizationLogo: data.user.organizations.logo_url,
      prefered_language_id: data.user.prefered_language_id,
      email: data.user.email,
      country_id: data.user.organizations.country_id,
      corvusIntegration: data.integrations.corvus.isIntegrated,
      permissions: {
        organization: {
          id: organizationID,
          read: Boolean(organizationPermissions?.read_permission),
          write: Boolean(organizationPermissions?.write_permission),
          full: Boolean(organizationPermissions?.full_permission),
        },
        countryside: data.user.user_countrysides_permissions.map((countryside) => ({
          id: countryside.countryside_id,
          read: Boolean(countryside.read_permission),
          write: Boolean(countryside.write_permission),
          full: Boolean(countryside.full_permission),
        })),
      },
      awsTempCredentials: {
        AccessKeyId: data.awsTempCredentials.AccessKeyId,
        SecretAccessKey: data.awsTempCredentials.SecretAccessKey,
        SessionToken: data.awsTempCredentials.SessionToken,
      },
    },
  };
}
