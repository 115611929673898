import AuthLayoutComponent from '../../features/ui/auth-layout/auth-layout';
import { CompleteRegisterForm } from '@features/public/complete-register/complete-register-form';

export function CompleteRegister() {
  return (
    <AuthLayoutComponent>
      <CompleteRegisterForm />
    </AuthLayoutComponent>
  );
}
