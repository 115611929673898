import { useArray, useEffectOnUpdate, useToggle } from '@hooks/core';
import { useService } from '@hooks/use-service';
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  Modal,
  Stack,
  Switch,
  TextField,
} from '@mui/material';

import TrashIcon from '@assets/svg/core/trash-red.svg';
import DisabledTrashIcon from '@assets/svg/core/trash-hollow-disabled.svg';
import { ChangeEvent, PropsWithChildren, useRef, useState } from 'react';
import { useGlobalStore } from '@global-store/use-store';
import i18n from '../../../libs/language';

import { listPoeQuestionService } from '@services/domain/poe/poe-question-list';
import { createPoeQuestionService } from '@services/domain/poe/poe-question-edition';
import { poeIntervalDaysService } from '@services/domain/poe/poe-interval-days';
import { updatePoeIntervalDaysService } from '@services/domain/poe/poe-interval-edition';

type InputChg = ChangeEvent<HTMLInputElement>;

type InspRoundModalProps = {
  open: boolean;
  onClose: () => void;
};

export function InspectionRoundModal({ open, onClose }: InspRoundModalProps) {
  const { organizationId: orgID } = useGlobalStore();

  const handleClose = () => {
    onClose();
    clearNewQuestions();
    setPoeCycleDays(initPoeCycleDays);
    setOldQuestions(initOldQuestions);
    setErrorData(initErrorData);
  };

  const closeOnSuccess = () => {
    handleClose();
    getPoeCycleDays({ query: orgID! });
    getQuestionList();
  };

  // Days services
  const {
    trigger: getPoeCycleDays,
    isLoading: isPoeCycleLoading,
    summary: poeSummary,
  } = useService(poeIntervalDaysService, { fetchOnMount: [{ query: orgID! }] });
  const {
    trigger: updatePoeCycleDays, //
    isLoading: isUpdatePoeCycleDaysLoading, //
  } = useService(updatePoeIntervalDaysService, { onSuccess: closeOnSuccess });

  // Question services
  const {
    trigger: getQuestionList,
    isLoading: isQuestionListLoading,
    summary: questionList,
  } = useService(listPoeQuestionService, { fetchOnMount: true });
  const {
    trigger: updateQuestionList, //
    isLoading: isUpdateQuestionListLoading, //
  } = useService(createPoeQuestionService, { onSuccess: closeOnSuccess });

  const initErrorData = {
    poeCycleDays: '',
    questionList: '',
  };

  const initPoeCycleDays = poeSummary?.poe_cycle ?? 0;
  const [poeCycleDays, setPoeCycleDays] = useState(initPoeCycleDays);
  useEffectOnUpdate(() => setPoeCycleDays(initPoeCycleDays), [poeSummary]);

  const initOldQuestions = questionList ?? [];
  const { array: oldQuestions, update: updateOldQuestion, set: setOldQuestions } = useArray(initOldQuestions);
  useEffectOnUpdate(() => setOldQuestions(initOldQuestions), [questionList]);

  const {
    array: newQuestions,
    keys: newQstKeys,
    add: addNewQuestion,
    remove: removeNewQuestion,
    update: updateNewQuestion,
    clear: clearNewQuestions,
  } = useArray<{ active: boolean; description: string; isImageRequired: boolean }>([]);

  const [errorData, setErrorData] = useState(initErrorData);
  const updateError = (key: string, value: string) => setErrorData((prev) => ({ ...prev, [key]: value }));

  const VALIDATION = {
    ONLY_NUMS: (value: string) => !/^[0-9]*$/.test(value),
    ALPHANUMERIC_EXT: (value: string) => !/^[\w0-9\s-_.áàâãéêíóôõúñç]*$/i.test(value),
    EMAIL: 'missing',
  };

  const HANDLER = {
    days: (e: InputChg) => {
      const input = e.target.value;

      if (VALIDATION.ONLY_NUMS(input)) return updateError('poeCycleDays', 'Solo números');

      updateError('poeCycleDays', '');
      setPoeCycleDays(input ? parseInt(input, 10) : 0);
    },
    oldQuestion: {
      active: (e: InputChg, i: number) => {
        updateOldQuestion(i, {
          ...oldQuestions[i],
          active: e.target.checked,
        });
      },
    },
    newQuestion: {
      active: (e: InputChg, i: number) => {
        updateNewQuestion(i, {
          ...newQuestions[i],
          active: e.target.checked,
        });
      },
      description: (e: InputChg, i: number) => {
        if (newQuestions.every((q, idx) => (i !== idx ? q.description : true))) {
          updateError('questionList', '');
        }
        updateNewQuestion(i, {
          ...newQuestions[i],
          description: e.target.value,
        });
      },
      isImageRequired: (e: InputChg, i: number) => {
        updateNewQuestion(i, {
          ...newQuestions[i],
          isImageRequired: e.target.checked,
        });
      },
    },
  };

  const oldQuestionsToSend = oldQuestions
    .filter((q, i) => q.active !== questionList![i].active)
    .map((q) => ({ id: q.id, active: q.active }));

  const newQuestionsToSend = newQuestions.map((q) => ({
    active: q.active,
    description: q.description,
    is_image_required: q.isImageRequired,
  }));

  const submit = () => {
    if (newQuestions.some((q) => q.description.trim() === '')) {
      updateError('questionList', 'Campo obligatorio');
      return;
    }

    const BODY = {
      forPoeCycleDays: poeCycleDays,
      forQuestionList: [...oldQuestionsToSend, ...newQuestionsToSend],
    };

    if (BODY.forPoeCycleDays !== initPoeCycleDays) {
      updatePoeCycleDays({
        query: orgID!,
        body: { poe_cycle: BODY.forPoeCycleDays },
      });
    }

    if (BODY.forQuestionList.length !== 0) {
      updateQuestionList({ body: BODY.forQuestionList });
    }
  };

  const [shouldScrollToBottom, toggleScrollToBottom] = useToggle(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffectOnUpdate(() => {
    if (shouldScrollToBottom && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;

      toggleScrollToBottom(false);
    }
  }, [shouldScrollToBottom]);

  return (
    <MyModal open={open} onClose={handleClose}>
      <Box style={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.inspection_round_modal.title')}</Box>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>{i18n.t('app.inspection_round_modal.round_days')}</Box>
          <TextField
            variant="outlined"
            size="small"
            disabled={isPoeCycleLoading}
            helperText={errorData.poeCycleDays}
            error={Boolean(errorData.poeCycleDays)}
            onChange={HANDLER.days}
            value={poeCycleDays}
            style={{
              width: '120px',
            }}
          />
          {isPoeCycleLoading ? <CircularProgress size={24} /> : null}
        </Stack>
        <Stack ref={scrollRef} spacing={2} style={{ minHeight: 325, maxHeight: '65vh', overflowY: 'auto' }}>
          {oldQuestions?.map((question, i) => (
            <Stack key={question.id} direction="row" spacing={3} style={{ marginRight: 15 }}>
              <FormGroup style={{ flex: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={question.active}
                      onChange={(e: InputChg) => HANDLER.oldQuestion.active(e, i)}
                    />
                  }
                  label={
                    <Stack>
                      <Box style={{ fontSize: '0.66rem' }}>
                        {i18n.t('app.inspection_round_modal.question')}
                      </Box>
                      <Box style={{ fontWeight: 600 }}>{question.description}</Box>
                    </Stack>
                  }
                />
              </FormGroup>
              <Stack alignItems="center">
                <Box style={{ fontSize: '0.66rem' }}>
                  {i18n.t('app.inspection_round_modal.ask_for_photo')}
                </Box>
                {question.is_image_required ? i18n.t('app.yes') : i18n.t('app.no')}
              </Stack>
              <IconButton disabled>
                <img src={DisabledTrashIcon} style={{ width: 16, height: 16 }} />
              </IconButton>
            </Stack>
          ))}
          {newQuestions.map((question, i) => (
            <Stack
              key={newQstKeys[i]}
              direction="row"
              spacing={3}
              alignItems="center"
              style={{ marginRight: 15 }}
            >
              <FormGroup style={{ flex: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={question.active}
                      onChange={(e: InputChg) => HANDLER.newQuestion.active(e, i)}
                    />
                  }
                  label={
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Pregunta"
                      helperText={question.description ? '' : errorData.questionList}
                      error={!question.description && Boolean(errorData.questionList)}
                      onChange={(e: InputChg) => HANDLER.newQuestion.description(e, i)}
                      value={question.description}
                    />
                  }
                />
              </FormGroup>
              <Stack direction={'row'} spacing={1} alignItems="center">
                <Stack alignItems="center">
                  <Box style={{ fontSize: '0.66rem' }}>
                    {i18n.t('app.inspection_round_modal.ask_for_photo')}
                  </Box>
                  <Switch
                    checked={question.isImageRequired}
                    onChange={(e: InputChg) => HANDLER.newQuestion.isImageRequired(e, i)}
                  />
                </Stack>
                <IconButton
                  sx={{
                    color: '#F00',
                    width: 16,
                    minWidth: 16,
                    height: 16,
                    padding: 2,
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 1,
                    '&:hover': { background: '#FF000022' },
                  }}
                  onClick={() => {
                    if (newQuestions.every((q, idx) => (i !== idx ? q.description : true))) {
                      updateError('questionList', '');
                    }
                    removeNewQuestion(i);
                  }}
                >
                  <img src={TrashIcon} style={{ width: 16, height: 16 }} />
                </IconButton>
              </Stack>
            </Stack>
          ))}
        </Stack>
        <Stack alignItems="center" style={{ width: '100%' }}>
          <Button
            style={{ width: 'max-content', textTransform: 'none' }}
            onClick={() => {
              addNewQuestion(-1, { active: true, description: '', isImageRequired: false });
              toggleScrollToBottom(true);
            }}
          >
            + {i18n.t('app.inspection_round_modal.add_question')}
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end" marginBlockStart={2}>
          <Button
            variant="outlined"
            style={{
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
              border: 'none',
              color: 'black',
            }}
            onClick={handleClose}
          >
            {i18n.t('app.cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={
              // Checking available data to avoid sending empty requests when modal submit
              isQuestionListLoading ||
              isPoeCycleLoading ||
              // Avoid resend data when the request is already loading
              isUpdateQuestionListLoading ||
              isUpdatePoeCycleDaysLoading ||
              (poeCycleDays === initPoeCycleDays &&
                [...oldQuestionsToSend, ...newQuestionsToSend].length === 0)
            }
            style={{
              color: '#FFF',
              width: 115,
              borderRadius: 100,
              textTransform: 'none',
            }}
            onClick={submit}
          >
            {isUpdateQuestionListLoading || isUpdatePoeCycleDaysLoading ? (
              <CircularProgress style={{ width: 24, height: 24 }} />
            ) : (
              i18n.t('app.save')
            )}
          </Button>
        </Stack>
      </Stack>
    </MyModal>
  );
}

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

function MyModal({ open, onClose, children }: PropsWithChildren<ModalProps>) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    overflowY: 'auto',
  };

  const handleClose = (_: never, reason: string) => {
    if (reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Modal open={open} keepMounted onClose={handleClose}>
      <Fade in={open} appear={false} timeout={175}>
        <Box sx={style}>{children}</Box>
      </Fade>
    </Modal>
  );
}
