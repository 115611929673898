import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import PlusIcon from '@assets/svg/administrators/plus.svg';
import { UserViewEditModal } from './user-view-edit-modal';
import { useToggle } from '@hooks/core';
import { DeleteModal } from '../modal-deletion/delete-modal';
import { useService } from '@hooks/use-service';
import { useEffect, useState } from 'react';
import i18n from '../../../libs/language';
import { useNavigate } from 'react-router-dom';
import { PATH } from '@data/enums/paths';
import { ToastAction } from '@ui/toast/toast-action';
import notificationIcon from '@assets/svg/core/notification-svg.svg';
import viewIcon from '@assets/svg/core/eye-hollow-blue.svg';
import editIcon from '@assets/svg/core/edit-square-hollow-blue.svg';
import DeleteActionIcon from '@assets/svg/core/trash-red.svg';
import { useEffectOnUpdate } from '@hooks/core';
import { listUsersService } from '@services/domain/user/base/user-list';
import { deleteUserService } from '@services/domain/user/base/user-deletion';

type Unpacked<T> = T extends (infer U)[] ? U : T;
type UserSummary = Unpacked<Awaited<ReturnType<typeof listUsersService>>['summary']>;

type UserListProps = {
  actionInCountryside: boolean;
  setActionInCountryside: (value: boolean) => void;
  actionInCompany: boolean;
};

export function AdminUserList(props: UserListProps) {
  const { actionInCountryside, setActionInCountryside, actionInCompany } = props;
  const {
    trigger: reFetchUsersListData,
    isLoading,
    summary,
  } = useService(listUsersService, {
    fetchOnMount: true,
    onSuccess: () => setActionInCountryside(false),
  });

  const navigate = useNavigate();
  const [openUserModal, toggleOpenUserModal] = useToggle(false);
  const handleUserModalState = (value: boolean) => toggleOpenUserModal(value);
  const [openDeleteModal, toggleOpenDeleteModal] = useToggle(false);
  const [openDeleteSnackbar, toggleDeleteSnackbar] = useToggle(false);
  const [newEditUserToast, setNewEditUserToast] = useToggle(false);
  const handleDeleteModalState = (value: boolean) => toggleOpenDeleteModal(value);

  useEffectOnUpdate(() => {
    if (actionInCountryside || actionInCompany) {
      reFetchUsersListData();
    }
  }, [actionInCountryside, actionInCompany]);

  useEffect(() => {
    setTimeout(() => {
      toggleDeleteSnackbar(false);
    }, 3000);
  }, [openDeleteSnackbar]);

  const [modalType, setModalType] = useState<'new' | 'view' | 'edit'>('new');
  const enableEditMode = () => setModalType('edit');

  // TODO: This state isn't necessary, we can the parent state instead
  const [targetData, setTargetData] = useState<UserSummary>();
  const [targetNameToDelete, setTargetNameToDelete] = useState<JSX.Element>();

  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchMD = useMediaQuery(theme.breakpoints.down('lg'));

  const configButtonStyles = {
    color: '#FFF',
    fontSize: 12,
    borderRadius: 100,
    textTransform: 'none',
  };

  // Este useEffect reestablece el valor de setNewEditUserToast para que se reutilice
  useEffect(() => {
    setTimeout(() => {
      setNewEditUserToast(false);
    }, 3000);
  }, [newEditUserToast]);

  const getUserRole = (user: UserSummary) => {
    const roleMapping = {
      admin: 'Admin',
      user: 'Usuario',
      viewer: 'Visualizador',
    };
    const permissions = user.user_organizations_permissions[0];
    if (!permissions) return roleMapping.viewer;

    const { full_permission, write_permission } = permissions;

    if (full_permission) return roleMapping.admin;
    if (write_permission) return roleMapping.user;

    return roleMapping.viewer;
  };

  return (
    <>
      <Paper
        sx={{
          height: 'max-content',
          borderRadius: 4,
          padding: 2,
          gridColumn: matchMD ? '' : '3/5',
          gridRow: matchMD ? '' : '2/5',
        }}
        elevation={0}
      >
        {newEditUserToast && (
          <ToastAction
            message={
              modalType === 'new'
                ? i18n.t('app.administrator.new_user_toast')
                : i18n.t('app.administrator.edit_user_toast')
            }
            severity="success"
          />
        )}
        {openDeleteSnackbar && (
          <ToastAction message={i18n.t('app.administrator.deleted_user')} severity="success" />
        )}
        <Grid
          item
          xs={10}
          sx={{ display: 'flex', gap: 3, justifyContent: 'space-between', alignItems: 'center', mb: 2 }}
        >
          <Box sx={{ fontWeight: '600', fontSize: '24px' }}>{i18n.t('app.user_modal.users')}</Box>
          <Box style={{ display: 'flex', gap: 10, justifyContent: 'center', alignItems: 'center' }}>
            <Button
              variant="contained"
              size="small"
              disableElevation
              startIcon={<img src={PlusIcon} alt="plus icon" />}
              sx={configButtonStyles}
              onClick={() => {
                setModalType('new');
                toggleOpenUserModal(true);
              }}
            >
              {i18n.t('app.administrator.new_user')}
            </Button>
          </Box>
        </Grid>
        {!isLoading ? (
          <Grid item>
            <TableContainer
              style={{ border: '1px solid #e0e0e0', borderRadius: 5, maxHeight: 500, overflowY: 'auto' }}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: 70 }}>{i18n.t('app.name')}</TableCell>
                    <TableCell style={{ display: matchSM ? 'none' : '' }}>{i18n.t('app.data')}</TableCell>
                    <TableCell align="center" style={{ display: matchSM ? 'none' : '' }}>
                      {i18n.t('app.role')}
                    </TableCell>
                    <TableCell align="center">{i18n.t('app.actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary?.map((user) => (
                    <TableRow key={user.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>
                        <Box style={{ fontWeight: '600' }}>{user.fullname}</Box>
                        <Box style={{ color: '#a7a7a7' }}>{user.email}</Box>
                      </TableCell>
                      <TableCell
                        align={matchSM ? 'center' : 'left'}
                        style={{ display: matchSM ? 'none' : '' }}
                      >
                        <Box style={{ fontWeight: '600' }}>
                          {i18n.t('app.user_modal.dni')}:{' '}
                          {user.national_id == null || user.national_id === '' ? '―' : user.national_id}
                        </Box>
                        <Box style={{ color: '#a7a7a7' }}>{user.phone ? '+' + user.phone : '―'}</Box>
                      </TableCell>
                      <TableCell align="center" style={{ display: matchSM ? 'none' : '' }}>
                        {getUserRole(user)}
                      </TableCell>
                      <TableCell align="center" style={{ width: 150 }}>
                        <Stack direction="row" spacing={0.75} flexWrap="nowrap" justifyContent="center">
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => {
                              setModalType('view');
                              setTargetData(user);
                              toggleOpenUserModal(true);
                            }}
                          >
                            <img style={{ width: 16, height: 16 }} src={viewIcon} />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => {
                              setModalType('edit');
                              setTargetData(user);
                              toggleOpenUserModal(true);
                            }}
                          >
                            <img style={{ width: 16, height: 16 }} src={editIcon} />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() =>
                              navigate(`${PATH.ADMINISTRATOR}/notification/${user.id}`, {
                                state: { userId: user.id },
                              })
                            }
                          >
                            <img style={{ width: 18, height: 18 }} src={notificationIcon} />
                          </IconButton>
                          <IconButton
                            style={{ width: 16, height: 16 }}
                            onClick={() => (
                              setTargetData(user),
                              setTargetNameToDelete(
                                <span>
                                  {user.email} <span style={{ opacity: 0.4 }}>({user.fullname})</span>
                                </span>
                              ),
                              toggleOpenDeleteModal(true)
                            )}
                          >
                            <img style={{ width: 16, height: 16 }} src={DeleteActionIcon} />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </Paper>
      {openUserModal ? (
        <UserViewEditModal
          open={openUserModal}
          type={modalType}
          parentFormData={targetData}
          onSuccessTrigger={reFetchUsersListData}
          enableEditMode={enableEditMode}
          setIsOpen={handleUserModalState}
          setNewEditUserToast={setNewEditUserToast}
        />
      ) : null}
      <DeleteModal
        open={openDeleteModal}
        setIsOpen={handleDeleteModalState}
        targetTextRef="el usuario"
        targetName={targetNameToDelete ?? ''}
        targetID={targetData?.id ?? ''}
        service={deleteUserService}
        onSuccessTrigger={reFetchUsersListData}
        toggleDeleteSnackbar={toggleDeleteSnackbar}
      />
    </>
  );
}
