import * as React from 'react';
import { Dayjs } from 'dayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import {
  SingleInputDateRangeField,
  SingleInputDateRangeFieldProps,
} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

type FieldComponent = (<TDate>(
  props: SingleInputDateRangeFieldProps<TDate> & React.RefAttributes<HTMLInputElement>
) => React.JSX.Element) & { fieldType?: string };

const WrappedSingleInputDateRangeField = React.forwardRef(
  (props: SingleInputDateRangeFieldProps<Dayjs>, ref: React.Ref<HTMLInputElement>) => {
    return <SingleInputDateRangeField size="small" {...props} ref={ref} />;
  }
) as FieldComponent;

WrappedSingleInputDateRangeField.fieldType = 'single-input';

type SingleInputDateRangePickerProps = {
  label: string;
  name: string;
  value?: [Dayjs, Dayjs];
  onChange: (value: any) => void;
};

export default function SingleInputDateRangePicker(props: SingleInputDateRangePickerProps) {
  const { label, name, value, onChange } = props;
  return (
    <DateRangePicker
      value={value}
      onChange={onChange}
      slots={{ field: WrappedSingleInputDateRangeField }}
      label={label}
      name={name}
    />
  );
}
