import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';
import { CorvusProductResponse } from './get-products-corvus.type'
//getProductsCorvusService()
export async function getProductsCorvusService() {
  const { response, data } = await BACKEND.get<CorvusProductResponse>(ENDPOINT.CORVUS_PRODUCTS);
  return {
    response,
    summary: data,
  };
}