import { ENDPOINT } from '@data/core/endpoints';
import { BACKEND } from '@services/core/fetch-backend';

import { CountrysideCreationPayload, CreatedCountrysideData } from './countryside-creation.types';
// ✅ implementado
// deprecated service ref.: createCountrysideService_DEPRECATED
export async function createCountrysideService({ body }: CountrysideCreationPayload) {
  const { response, data } = await BACKEND.post<CreatedCountrysideData>(ENDPOINT.COUNTRYSIDES, { body });

  return {
    response,
    summary: data,
  };
}
