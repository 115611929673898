export const BoxCircularBar = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};
export const styledTextField = {
  backgroundColor: 'white',
  width: '400px',
};

export const boxCircularProgressBarStyle = {
  width: '100%',
  height: '80vh',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
};

export const circularProgressBar = {
  marginLeft: '10px',
  color: 'green',
};

export const CustomPaper = {
  boxShadow: 'none',
  backgroundColor: '#EFF3F5',
};

export const Title = {
  fontSize: '28px',
  fontWeight: '600',
  marginTop: '10px',
};

export const mainContainerStyle = {
  marginTop: '1rem',
  paddingLeft: '1rem',
  justifyContent: 'space-between',
  padding: '10px',
};

export const typographyStyle = {
  marginRight: '20px',
  fontWeight: '600',
};

export const stylesInput = {
  color: 'white',
  backgroundColor: 'white',
  width: '100%',
};

export const separatorReports = {
  borderRight: '1.6px solid #d3d0d0',
};

export const itemBalanceReports = {
  fontSize: '20px',
  fontWeight: 500,
};
export const itemTotalReports = {
  fontSize: '25px',
  fontWeight: 600,
};

export const balanceReports = {
  fontSize: '45px',
  fontWeight: 600,
};

export const balanceItemsReports = {
  fontSize: '35px',
  fontWeight: 600,
};

export const customBgWhite = {
  textAlign: 'left',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
  marginTop: '15px',
};

export const totalsReportsTablet = {
  display: 'flex',
  justifyContent: 'space-around',
  top: '181px',
  left: '50px',
  borderRadius: '10px',
  padding: '30px 40px 30px 40px',
  gap: '40px',
  backgroundColor: '#FFFFFF',
};

export const defaultTitles = {
  fontSize: '40px',
  fontWeight: '500',
  lineHeight: '31.8px',
};

export const defaultIntalledDescription = {
  fontFamily: 'Poppins',
  fontWeight: '500',
  fontSize: '20px',
  lineHeight: '20px',
};

export const defaultGridItemsReports = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const textCenter = {
  textAlign: 'center',
};

export const textDisabledTablet = {
  color: 'grey',
  fontSize: '14px',
};

export const buttonDataDetail = {
  color: '#09AAEB',
  fontWeight: 600,
  textTransform: 'none',
  marginTop: '10px',
  fontSize: '14px',
  padding: '0px',
};

export const LoadingBox = {
  width: '100%',
  heigth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
};

/*********************** */

export const DashboardWelcome = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const DashboardHeader = {
  display: 'flex',
  justifyContent: 'start',
};

export const DashboardHeaderButtons = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end', // Alinea los elementos verticalmente arriba
  flexDirection: 'row',
};

export const DashboardGridButton = {
  display: 'flex',
  justifyContent: 'flex-end',
};

export const DashboardButtons = {
  width: { xs: '100%', md: '50%' },
  marginRight: { xs: 0, md: 0.5 },
};

export const SiloSatHeader = {
  display: 'flex',
  justifyContent: 'start',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
};

export const SiloSatNoImagesBox = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: 'auto',
};

export const ImagesContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  maxHeight: '60vh',
};

export const SiloSatImagesData = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '12px',
  paddingLeft: '12px',
};

export const BoxSiloSatImages = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
};

export const BoxSiloSatStatus = {
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
};

export const ContainerSiloSatHistorial = {
  marginTop: '1rem',
  height: '70vh',
  maxHeight: '70vh',
  overflow: 'auto',
};

export const ElementSiloSatHistorial = { display: 'flex', justifyContent: 'space-between' };

export const SelectorsImageContainer = {
  display: 'flex',
  textAlign: 'right',
  borderTopLeftRadius: '5px',
  borderBottomLeftRadius: '5px',
  height: '80%',
};

export const SiloSatSelectedImage = {
  backgroundColor: '#6BAA00',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
};

export const configButtonStylesContained = {
  color: '#FFF',
  fontSize: 14,
  borderRadius: 100,
  textTransform: 'none',
  margin: '10px',
};

export const configButtonStylesOutlined = {
  fontSize: 14,
  borderRadius: 100,
  textTransform: 'none',
  margin: '10px',
};
